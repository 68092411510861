import { Upload, message } from "antd";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";

import { InboxOutlined } from "@ant-design/icons";
import React from "react";
import { UploadRequestOption } from "rc-upload/lib/interface";
import axios from "@utils/axios";
import { selectUser } from "@store/user";
import { useSelector } from "react-redux";

const { Dragger } = Upload;

export interface UploadMediaFile<T = any> extends UploadFile<T> {}

export interface UploadMediaProps {
    onSuccessUpload?: (files: UploadMediaFile[]) => void;
    onSaveAttachment: Function;
    mimeTypes?: string;
}

const UploadMedia: React.FC<UploadMediaProps> = ({ onSuccessUpload, onSaveAttachment, mimeTypes }) => {
    const { token } = useSelector(selectUser);

    const handleChange = async (info: UploadChangeParam) => {
        const { status } = info.file;
        let done: boolean = true;

        if (onSuccessUpload) {
            info.fileList.forEach((file: UploadMediaFile) => {
                if (file.status !== "done") {
                    return (done = false);
                }
            });

            if (done) {
                onSuccessUpload(info.fileList);
            }
        }

        if (status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    const handleCustomRequest = async ({ file, onProgress, onSuccess, onError }: UploadRequestOption) => {
        const _file = file as File;
        try {
            const response: any = await axios.post(
                "/vapor/signed-storage-url",
                {
                    content_type: _file.type,
                    expires: "",
                    visibility: "public-read"
                },
                {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        "Content-type": _file.type,
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            const { headers, bucket, key, uuid } = response.data;

            if ("Host" in headers) {
                delete headers.Host;
            }

            await axios.put(response.data.url, _file, {
                headers,
                onUploadProgress: (progressEvent: any) => {
                    if (onProgress) {
                        onProgress({
                            percent: (progressEvent.loaded / progressEvent.total) * 100
                        });
                    }
                }
            });

            await onSaveAttachment({
                bucket,
                key,
                uuid,
                name: _file.name,
                mime: _file.type,
                size: _file.size
            });

            onSuccess && onSuccess("success");
        } catch (error: any) {
            if (onError) {
                onError(error);
            }
        }
    };

    return (
        <Dragger
            showUploadList={{ showRemoveIcon: false }}
            onChange={handleChange}
            multiple
            customRequest={handleCustomRequest}
            accept={mimeTypes}
        >
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">Support for a single or bulk upload</p>
        </Dragger>
    );
};

export default UploadMedia;
