import { useContext, useEffect, useState } from "react";

import { MessageContext } from "@layout/MessageContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const useRedirectEventsHome = ({ hasInfo, isLoading, event }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["translations"]);
  const messageApi = useContext(MessageContext);
  const [redirectHome, setRedirectHome] = useState<boolean>(false);

  useEffect(() => {
    if (!hasInfo && !isLoading) setRedirectHome(true);
  });

  useEffect(() => {
    if (redirectHome) {
      messageApi.info({
        message: t("info"),
        description: t("page-not-accessible-without-required-event-info"),
      });
      event ? navigate(`/events/${event}`) : navigate("/events");
    }
  }, [redirectHome]);
};
