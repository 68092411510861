import { Language, Timezone } from "@store/internationalization/types";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import type { BaseQueryResult } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import type { RootState } from "@store/index";

const TIMEZONES = "Timezones";
const LANGUAGES = "Languages";

const internationalizationApi = createApi({
    reducerPath: "internationalization",
    tagTypes: [TIMEZONES, LANGUAGES],

    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/`,
        prepareHeaders: (headers, { getState }) => {
            const {
                user: { token }
            } = getState() as RootState;
            headers.set(`Authorization`, `Bearer ${token}`);

            return headers;
        }
    }),

    endpoints: (build) => ({
        getTimezones: build.query<Timezone[], null>({
            query: () => `/timezones`,
            transformResponse: (res: BaseQueryResult<any>): any => res,
            providesTags: (result: any) =>
                result
                    ? [
                          ...result.map(({ id }: any) => ({ type: TIMEZONES, id })),
                          { type: TIMEZONES, id: "LIST" }
                      ]
                    : [{ type: TIMEZONES, id: "LIST" }]
        }),
        getLanguages: build.query<Language[], null>({
            query: () => `/languages`,
            transformResponse: (res: BaseQueryResult<any>): any => res,
            providesTags: (result: any) =>
                result
                    ? [
                          ...result.map(({ id }: any) => ({ type: LANGUAGES, id })),
                          { type: LANGUAGES, id: "LIST" }
                      ]
                    : [{ type: LANGUAGES, id: "LIST" }]
        })
    })
});

export default internationalizationApi;

export const { useGetTimezonesQuery, useGetLanguagesQuery } = internationalizationApi;
