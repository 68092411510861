import { DndContext, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import React, { useEffect, useState } from "react";
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";

import { CSS } from "@dnd-kit/utilities";
import type { DragEndEvent } from "@dnd-kit/core";
import SearchableTable from "./SearchableTable/SearchableTable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    "data-row-key": string;
}

const Row = (props: RowProps) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: props["data-row-key"]
    });

    const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
        transition,
        cursor: "move",
        ...(isDragging ? { position: "relative", zIndex: 9999 } : {})
    };

    return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

export const DraggableTable: React.FC<any> = ({ columns, selectedRowKeys, data, onOrderChange }) => {
    const [hasOrderChanged, setHasOrderChanged] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState(data.map((item: any) => ({ ...item, key: item.id })));

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 1
            }
        })
    );

    useEffect(() => {
        setDataSource(data.map((item: any) => ({ ...item, key: item.id })));
    }, [data]);

    useEffect(() => {
        (async () => {
            if (hasOrderChanged === false) return;
            await onOrderChange(dataSource.map((item: any) => item.id));
            setHasOrderChanged(false);
        })();
    }, [hasOrderChanged]);

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            setDataSource((prev: any) => {
                const activeIndex = prev.findIndex((i: any) => i.key === active.id);
                const overIndex = prev.findIndex((i: any) => i.key === over?.id);
                return arrayMove(prev, activeIndex, overIndex);
            });
            setHasOrderChanged(true);
        }
    };

    return (
        <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext items={dataSource.map((i: any) => i.key)} strategy={verticalListSortingStrategy}>
                <SearchableTable
                    page={undefined}
                    selectable={false}
                    rowSelection={{
                        selectedRowKeys,
                        getCheckboxProps: () => ({ disabled: true })
                    }}
                    searchInputPlaceholder="hello"
                    paginationTotal={1}
                    components={{
                        body: {
                            row: Row
                        }
                    }}
                    pagination={false}
                    rowKey="key"
                    columns={columns}
                    data={dataSource}
                />
            </SortableContext>
        </DndContext>
    );
};
