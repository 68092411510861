import { Input, Table, TableProps } from "antd";
import React, { useEffect, useRef, useState } from "react";

import type { ColumnType } from "antd/es/table";
import { FileSearchOutlined } from "@ant-design/icons";
import type { TableRowSelection } from "antd/es/table/interface";
import styles from "./SearchableTable.module.scss";
import { useTranslation } from "react-i18next";

interface SearchableTableProps extends TableProps<any> {
    setPage?: (page: number) => void;
    columns: ColumnType<any>[];
    style?: { [key: string]: any };
    data: any[] | undefined;
    searchInputPlaceholder?: string;
    hidePagination?: boolean;
    disableSelectionCheckboxes?: boolean;
    paginationSize?: number | undefined;
    paginationTotal?: number | undefined;
    selectable: boolean;
    selectedRowKeys?: React.Key[];
    setSelectedRowKeys?: (keys: React.Key[]) => void;
    onSearchParamChange?: (str: string) => void;
    scrollHeight?: string;
    page: number | undefined;
}

const SearchableTable: React.FC<SearchableTableProps> = ({
    setPage,
    columns,
    loading,
    data,
    style,
    hidePagination,
    paginationSize,
    disableSelectionCheckboxes,
    paginationTotal,
    searchInputPlaceholder,
    selectable,
    selectedRowKeys,
    setSelectedRowKeys,
    onSearchParamChange,
    scrollHeight,
    page,
    ...props
}) => {
    const tableWrapperRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation(["translations"]);
    const [searchInput, setSearchInput] = useState<string>("");
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys && setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<any> = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
        getCheckboxProps: () => ({ disabled: disableSelectionCheckboxes || false })
    };

    const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            onSearchParamChange && onSearchParamChange(searchInput);
        }, 300);

        return () => clearTimeout(timeout);
    }, [searchInput]);

    return (
        <div className={styles["searchable-table"]} ref={tableWrapperRef}>
            {onSearchParamChange && typeof searchInput === "string" && (
                <Input
                    size="large"
                    value={searchInput}
                    onChange={onSearchInputChange}
                    className={styles["searchable-table__search-input"]}
                    prefix={<FileSearchOutlined />}
                    id="search-events-table-field"
                    data-testid="searchable-table-input-field"
                    placeholder={searchInputPlaceholder || undefined}
                />
            )}
            <div style={scrollHeight ? { height: scrollHeight, overflow: "auto" } : undefined}>
                <Table
                    pagination={
                        !hidePagination
                            ? {
                                  position: ["topRight", "bottomRight"],
                                  onChange: (page) => {
                                      setPage && setPage(page);
                                  },
                                  current: page,
                                  pageSize: paginationSize,
                                  total: paginationTotal,
                                  showSizeChanger: false
                              }
                            : false
                    }
                    locale={{ emptyText: t("no-data-available") }}
                    size="large"
                    scroll={{ x: "auto" }}
                    bordered
                    data-testid="searchable-table"
                    style={style}
                    dataSource={data}
                    loading={loading}
                    rowSelection={selectable || selectedRowKeys ? rowSelection : undefined}
                    columns={columns}
                    className={!selectable && selectedRowKeys ? "table-no-selections" : ""}
                    {...props}
                />
            </div>
        </div>
    );
};

export default SearchableTable;
