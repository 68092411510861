import { Link, useLocation, useParams } from "react-router-dom";

import { Breadcrumb as AntdBreadcrumb } from "antd";
import { selectBreadcrumb } from "@store/ui/selectors";
import styles from "./Breadcrumb.module.scss";
import { useGetEventQuery } from "@store/events";
import { useGetProfileQuery } from "@store/profile";
import { useSelector } from "@store/hooks";
import { useTranslation } from "react-i18next";

const Breadcrumb = () => {
    const { t } = useTranslation(["translations", "languages"]);
    const { eventId, profileId, userId, episodeId, contentType, language } = useParams();
    const location = useLocation();
    const locationSubpaths = location.pathname.split("/");
    const addingNewProfile = profileId === "new-profile";
    const addingNewEpisode = episodeId === "new-episode";
    const breadcrumb = useSelector(selectBreadcrumb);

    const getPath = (index: number, array: string[]) => {
        let path: string = "";
        for (let i = 0; i <= index; i++) {
            path += (i === 0 ? "" : "/") + array[i];
        }
        return path;
    };

    const { data: event } = useGetEventQuery({ eventId: eventId! }, { skip: !!!eventId });
    const { data: profile } = useGetProfileQuery(
        { profileId: profileId! },
        { skip: !!!profileId || addingNewProfile }
    );

    const getBreadcrumbTitle = (locationItem: string, index: number) => {
        const section = location.pathname.split("/")[1];
        if (section === "events") {
            if (index === 2 && event && eventId) return event.title;
            else if (
                index === 4 &&
                event &&
                eventId &&
                !addingNewEpisode &&
                !contentType &&
                event.episodes.find((ep) => ep.id === parseInt(locationItem))?.title
            )
                return event.episodes.find((ep) => ep.id === parseInt(locationItem))?.title;
            else if (language && index === 5 && locationItem) return t(`languages:${locationItem}`);
            else if (locationItem === "" || !Number.isNaN(parseInt(locationItem))) return locationItem;
            else return t(locationItem);
        } else if (section === "profiles") {
            if (index === 2 && profile && profileId && !addingNewProfile) return profile.name;
            else return t(locationItem);
        } else if (section === "speakers") {
            return t(locationItem);
        } else if (section === "logs") {
            if (index === 2 && event && eventId) return event.title;
            else return t(locationItem);
        } else return t(locationItem);
    };

    return (
        <AntdBreadcrumb
            separator=">"
            className={styles["breadcrumb"]}
            items={locationSubpaths
                .map((locationItem: string, index: number) => {
                    return {
                        key: locationItem,
                        title: (
                            <Link to={getPath(index, locationSubpaths)}>
                                {breadcrumb[index] || getBreadcrumbTitle(locationItem, index)}
                            </Link>
                        )
                    };
                })
                .filter((item) => item.key !== "")}
        ></AntdBreadcrumb>
    );
};

export default Breadcrumb;
