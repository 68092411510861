import { AuthorizedRoles, MultilingualFormInput } from "@components/FormElements";
import { DomainType, ProfileType } from "@store/profile/types";
import { Language, Timezone } from "@store/internationalization/types";
import { Space, Tooltip } from "antd";

import { EventType } from "@store/events/types";
import { FormInstance } from "antd/lib";
import { IonIcon } from "@ionic/react";
import QRCodeAndPreview from "@components/QRCodeAndPreview/QRCodeAndPreview";
import { informationCircleOutline } from "ionicons/icons";
import { mapLanguagesToOptions } from "@utils/forms/mapLanguagesToOptions";
import { mapTimezonesToOptions } from "@utils/forms/mapTimezonesToOptions";

interface EventFormArgs {
    t: (str: string) => string;
    availableDomains: DomainType[];
    profiles: ProfileType[];
    timezones: Timezone[];
    languages: Language[];
    validSlug: string | null;
    form: FormInstance;
    event: EventType;
}

export const eventFormInputs = ({
    t,
    availableDomains,
    profiles,
    timezones,
    languages,
    validSlug,
    form,
    event
}: EventFormArgs): MultilingualFormInput[] => {
    const currentDomain = form.getFieldValue("domain");

    const domainForPreview =
        currentDomain && validSlug !== null ? "https://" + currentDomain + `/${validSlug}` : null;

    const formInputs = [
        {
            name: "profile_id",
            type: "select",
            label: t("associated-profile"),
            options: profiles?.map((profile: ProfileType) => {
                return {
                    label: <>{profile.name}</>,
                    value: profile.id
                };
            }),
            authorizedRoles: [AuthorizedRoles.admin, AuthorizedRoles.superadmin],
            inputProps: {
                allowClear: false
            },
            rules: [{ required: true, message: t("field-required") }]
        },
        {
            name: "status",
            label: t("status-label"),
            type: "switch",
            inputProps: {
                checkedlabel: t("status-checked-description"),
                uncheckedlabel: t("status-unchecked-description"),
                "data-testid": "status"
            }
        },
        {
            name: "online_start_at",
            label: (
                <Space direction="horizontal">
                    {t("online-start-at-label")}
                    <Tooltip title={t("online-dates-disclaimer")}>
                        <IonIcon size="small" icon={informationCircleOutline} />
                    </Tooltip>
                </Space>
            ),
            dependencies: {
                status: [1, true]
            },
            hidden:
                form.getFieldValue("status") === undefined
                    ? event.status === false
                    : form.getFieldValue("status") === false,
            type: "date-picker",
            inputProps: {
                showTime: { format: "HH:mm" },
                format: "YYYY-MM-DD HH:mm",
                "data-testid": "online_start_at"
            }
        },
        {
            name: "online_finish_at",
            label: (
                <Space direction="horizontal">
                    {t("online-finish-at-label")}
                    <Tooltip title={t("online-dates-disclaimer")}>
                        <IonIcon size="small" icon={informationCircleOutline} />
                    </Tooltip>
                </Space>
            ),
            dependencies: {
                status: [1, true]
            },
            hidden:
                form.getFieldValue("status") === undefined
                    ? event.status === false
                    : form.getFieldValue("status") === false,
            type: "date-picker",
            inputProps: {
                showTime: { format: "HH:mm" },
                format: "DD-MM-YYYY HH:mm",
                "data-testid": "online_finish_at"
            }
        },
        {
            name: "maintenance",
            label: t("maintenance-label"),
            type: "switch",
            inputProps: {
                checkedlabel: t("maintenance-checked-description"),
                uncheckedlabel: t("maintenance-unchecked-description"),
                "data-testid": "maintenance"
            }
        },
        {
            name: "is_parked",
            label: t("parked-label"),
            type: "switch",
            inputProps: {
                checkedlabel: t("parked-checked-description"),
                uncheckedlabel: t("parked-unchecked-description")
            }
        },
        {
            name: "title",
            label: t("title-label"),
            type: "text",
            rules: [{ required: true, message: "This field is required" }]
        },
        {
            name: "domain",
            label: t("domain-label"),
            type: "select",
            options: availableDomains.map((domain) => {
                return {
                    label: domain.domain_name,
                    value: domain.domain_name
                };
            })
        },
        {
            name: "slug",
            label: t("slug"),
            type: "text",
            inputProps: {
                addonBefore: currentDomain ? `${currentDomain}/` : null,
                disabled: !!!currentDomain
            },
            addonAfter:
                !!domainForPreview && domainForPreview !== "" ? (
                    <QRCodeAndPreview previewUrl={domainForPreview} />
                ) : undefined
        },
        {
            name: "timezone",
            label: t("timezones-label"),
            type: "select",
            inputProps: {
                showSearch: true
            },
            options: mapTimezonesToOptions(timezones),
            rules: [{ required: true, message: "This field is required" }]
        },
        {
            name: "timezone_abbreviation",
            label: t("timezone-abbreviation-label"),
            type: "text"
        },
        {
            name: "ds_timezone_abbreviation",
            label: t("daylight-timezone-abbreviation-label"),
            type: "text"
        },
        {
            name: "languages",
            label: t("languages-label"),
            type: "select",
            inputProps: {
                mode: "multiple"
            },
            options: mapLanguagesToOptions(languages),
            rules: [{ required: true, message: "This field is required" }]
        },
        {
            name: "default_lang",
            label: t("default-language-label"),
            type: "select",
            options: mapLanguagesToOptions(
                languages.filter((language) => {
                    if (form.getFieldValue("languages") !== undefined) {
                        return form.getFieldValue("languages")?.includes(language.id);
                    } else {
                        return event.languages?.includes(language.id);
                    }
                })
            ),
            rules: [{ required: true, message: "This field is required" }]
        }
    ];

    return formInputs as MultilingualFormInput[];
};
