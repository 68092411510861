import EventAgendaPage from "@routes/events/EventAgenda";
import EventContents from "@routes/events/EventContents";
import EventEpisodes from "@routes/events/EventEpisodes";
import EventEvaluationForm from "@routes/events/EventEvaluationForm/EventEvaluationForm";
import EventLive from "@routes/events/EventLive/EventLive";
import EventMediaGallery from "@routes/events/EventMediaGallery/EventMediaGallery";
import EventPage from "@routes/events/Event";
import EventQandA from "./EventQ&A";
import EventReportsPage from "@routes/events/EventReports";
import EventStyles from "@routes/events/EventStyles/EventStyles";
import EventsPage from "@routes/events/Events";
import { Navigate } from "react-router-dom";
import ShareEvent from "@routes/events/ShareEvent";

const EventsRoutes = [
    { path: ["/events", "/events-binned"], page: <EventsPage />, auth: true },
    { path: "/events/:eventId/", page: <Navigate to="home" />, auth: true },
    {
        path: ["/events/:eventId/home/:episodeId", "/events/:eventId/home"],
        page: <EventPage />,
        auth: true
    },
    {
        path: "/events/:eventId/share",
        page: <ShareEvent />,
        auth: true
    },
    {
        path: ["/events/:eventId/episodes/:episodeId", "/events/:eventId/episodes"],
        page: <EventEpisodes />,
        auth: true
    },
    {
        path: "/events/:eventId/styles",
        page: <EventStyles />,
        auth: true
    },
    {
        path: [
            "/events/:eventId/contents",
            "/events/:eventId/contents/:contentType",
            "/events/:eventId/contents/:contentType/:language"
        ],
        page: <EventContents />,
        auth: true
    },
    {
        path: "/events/:eventId/media-gallery",
        page: <EventMediaGallery />,
        auth: true
    },
    {
        path: [
            "/events/:eventId/live",
            "/events/:eventId/live/:episodeId",
            "/events/:eventId/live/:episodeId/:liveType",
            "/events/:eventId/live/:episodeId/:liveType/:questionId"
        ],
        page: <EventLive />,
        auth: true
    },
    {
        path: [
            "/events/:eventId/evaluation-form",
            "/events/:eventId/evaluation-form/:episodeId/",
            "/events/:eventId/evaluation-form/:episodeId/questions-list",
            "/events/:eventId/evaluation-form/:episodeId/questions-list/:questionId",
            "/events/:eventId/evaluation-form/:episodeId/slots",
            "/events/:eventId/evaluation-form/:episodeId/slots/:slotId",
            "/events/:eventId/evaluation-form/:episodeId/slots/:slotId/:duplicateId"
        ],
        page: <EventEvaluationForm />,
        auth: true
    },
    {
        path: [
            "/events/:eventId/agenda/:episodeId",
            "/events/:eventId/agenda",
            "/events/:eventId/agenda/:episodeId/:agendaId",
            "/events/:eventId/agenda/:episodeId/:agendaId/:parentAgendaId"
        ],
        page: <EventAgendaPage />,
        auth: true
    },
    {
        path: ["/events/:eventId/reports", "/events/:eventId/reports/:episodeId"],
        page: <EventReportsPage />,
        auth: true
    },
    {
        path: ["/events/:eventId/q-and-a/", "/events/:eventId/q-and-a/:userId"],
        page: <EventQandA />,
        auth: true
    }
];

export default EventsRoutes;
