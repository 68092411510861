import { Alert, Button, Form, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";

import { ChangePasswordFormValues } from "@store/admin/types";
import { Error422 } from "@store/index";
import { mapErrorsToFields } from "@utils/mapErrorsToFields";
import { useChangePasswordMutation } from "@store/admin";
import { useNavigate } from "react-router-dom";
import { usePerformMutation } from "@utils/perform-mutation";
import { useTranslation } from "react-i18next";

interface ChangePasswordModalProps {
    description?: string;
    isOpen: boolean;
    setIsOpen: Function;
    successCallback: () => any;
    dismissable: boolean;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
    description,
    dismissable,
    setIsOpen,
    successCallback,
    isOpen
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation(["translations"]);
    const [changeExpiredPasswordForm] = Form.useForm();
    const [changePassword, { isLoading: isLoadingChangePassword }] = useChangePasswordMutation();
    const { performMutation } = usePerformMutation();

    useEffect(() => {
        if (isOpen === false) changeExpiredPasswordForm.resetFields();
    }, [isOpen]);

    const handleChangePassword = async (values: ChangePasswordFormValues) => {
        await performMutation({
            mutation: async () => await changePassword({ ...values, password: values.new_password }).unwrap(),
            successMessage: t("change-password-success"),
            errorMessage: t("change-password-error"),
            setFieldsWithErrors: (error: Error422) => {
                if (values) {
                    const parsedErrors = {
                        ...error,
                        errors: { ...error.errors, ["new_password"]: error.errors["password"] }
                    };
                    const valuesWithErrors = mapErrorsToFields({ error: parsedErrors, values });
                    changeExpiredPasswordForm.setFields(valuesWithErrors);
                }
            },
            onSuccessCallback: () => {
                successCallback();
                navigate("/");
                setIsOpen(false);
            }
        });
    };

    return (
        <Modal
            title={t("change-password")}
            open={isOpen}
            closeIcon={dismissable ? undefined : null}
            onCancel={() => (dismissable ? setIsOpen(false) : {})}
            cancelButtonProps={{ children: null }}
            footer={
                !dismissable ? (
                    <Button
                        type="primary"
                        loading={isLoadingChangePassword}
                        onClick={changeExpiredPasswordForm.submit}
                    >
                        {t("change-password")}
                    </Button>
                ) : (
                    <>
                        <Button onClick={() => setIsOpen(false)}>{t("cancel")}</Button>
                        <Button
                            type="primary"
                            loading={isLoadingChangePassword}
                            onClick={changeExpiredPasswordForm.submit}
                        >
                            {t("change-password")}
                        </Button>
                    </>
                )
            }
            maskClosable={false}
            forceRender={true}
        >
            {description && <Alert message={description} style={{ marginBottom: 30 }} />}
            <Form layout="vertical" form={changeExpiredPasswordForm} onFinish={handleChangePassword}>
                <Form.Item
                    name="old_password"
                    label={t("old-password")}
                    rules={[
                        {
                            required: true,
                            message: t("field-required")
                        }
                    ]}
                >
                    <Input type="password" />
                </Form.Item>
                <Form.Item
                    name="new_password"
                    label={t("password")}
                    rules={[
                        {
                            required: true,
                            message: t("field-required")
                        }
                    ]}
                >
                    <Input.Password autoComplete="new-password" />
                </Form.Item>
                <Form.Item
                    name="password_confirmation"
                    label={t("confirm-password")}
                    rules={[
                        {
                            required: true,
                            message: t("field-required")
                        },
                        ({ getFieldValue }: any) => ({
                            validator(_: any, value: any) {
                                if (!value || getFieldValue("new_password") === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(t("password-do-not-match")));
                            }
                        })
                    ]}
                >
                    <Input.Password autoComplete="new-password" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ChangePasswordModal;
