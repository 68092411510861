import { Alert, Space, Typography } from "antd";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { utcToTimeZone } from "@utils/timezones";

interface VisibilityTagProps {
    startAt: string | null;
    finishAt: string | null;
    timezone: string;
}

const VisibilityTag: React.FC<VisibilityTagProps> = ({ startAt, finishAt, timezone }) => {
    //TODO: add in UTC and local timezone in the logic
    const { t } = useTranslation(["translations"]);
    const isAlwaysVisible = !startAt && !finishAt;
    const isCurrentlyVisible =
        (!startAt || moment(startAt) < moment()) && (!finishAt || moment() < moment(finishAt));

    return (
        <Alert
            style={{ padding: "4px 10px", width: "fit-content" }}
            type={isAlwaysVisible || isCurrentlyVisible ? "success" : "error"}
            message={
                <Space direction="horizontal">
                    {isAlwaysVisible
                        ? t("always-visible")
                        : isCurrentlyVisible
                        ? t("currently-visible")
                        : t("not-currently-visible")}
                    <>
                        {startAt && (
                            <Typography.Text>
                                {t("from")}{" "}
                                {`${moment(
                                    utcToTimeZone({
                                        time: startAt,
                                        zone: timezone
                                    })
                                ).format("dd DD MMM yyyy")}`}
                            </Typography.Text>
                        )}
                        {finishAt && (
                            <Typography.Text>
                                {t("until")}{" "}
                                {`${moment(
                                    utcToTimeZone({
                                        time: finishAt,
                                        zone: timezone
                                    })
                                ).format("dd DD MMM yyyy")}`}
                            </Typography.Text>
                        )}
                    </>
                </Space>
            }
        />
    );
};

export default VisibilityTag;
