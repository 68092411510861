import { Button, Popover, Space } from "antd";
import { KeyOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { clearLogoutErrors, clearUser, selectUser } from "@store/user";
import { useDispatch, useSelector } from "@store/hooks";

import ChangePasswordModal from "@components/ChangePasswordModal";
import DarkModeSwitch from "@components/DarkModeSwitch";
import { logout } from "@utils/logout";
import styles from "./ActionsTopMenu.module.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ActionsTopMenu: React.FC = () => {
    const { t } = useTranslation(["translations"]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isOpenChangePassword, setIsOpenChangePassword] = useState<boolean>(false);

    const {
        token,
        identity,
        logoutStatus: { error: logoutError },
        loggedStatus: { loading: loggedLoading }
    } = useSelector(selectUser);

    useEffect(() => {
        dispatch(clearLogoutErrors());
    }, [logoutError, dispatch]);

    const isAuth = token && identity;

    return (
        <>
            <Space direction="horizontal" className={styles["actions-top-menu"]}>
                {isAuth && (
                    <Popover
                        content={
                            <Space direction="vertical">
                                <Button
                                    icon={<KeyOutlined />}
                                    onClick={() => setIsOpenChangePassword(true)}
                                    type="dashed"
                                >
                                    {t("change-password")}
                                </Button>
                                {isAuth && (
                                    <Button
                                        type="dashed"
                                        icon={<LogoutOutlined />}
                                        onClick={() => logout({ dispatch })}
                                        data-testid="logout-button"
                                    >
                                        {t("logout")}
                                    </Button>
                                )}
                            </Space>
                        }
                        title={t("user-settings")}
                        trigger="click"
                    >
                        <Button type="text" icon={<UserOutlined />} data-testid="user-name-button">
                            {identity.data.name}
                        </Button>
                    </Popover>
                )}
                {!isAuth && !loggedLoading && (
                    <Button
                        type="primary"
                        icon={<UserOutlined />}
                        onClick={() => navigate("/login")}
                        data-testid="login-button"
                    >
                        {t("login")}
                    </Button>
                )}
                <div className={styles["actions-top-menu__mode-container"]}>
                    <DarkModeSwitch />
                </div>
            </Space>
            {isAuth && (
                <ChangePasswordModal
                    dismissable={true}
                    isOpen={isOpenChangePassword}
                    successCallback={() => dispatch(clearUser())}
                    setIsOpen={setIsOpenChangePassword}
                />
            )}
        </>
    );
};

export default ActionsTopMenu;
