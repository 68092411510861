import { EventType } from "@store/events/types";
import { Navigate } from "react-router-dom";
import { selectUser } from "@store/user";
import { useSelector } from "react-redux";

interface AuthCheckProps {
    page: React.ReactElement;
    isAuth: boolean;
    isAuthProtected: boolean;
}

export const AuthCheck: React.FC<AuthCheckProps> = (props) => {
    const { token, qr_code, identity } = useSelector(selectUser);

    if (props.isAuth || !props.isAuthProtected) return props.page;
    else if (token !== undefined && qr_code === undefined && !!!identity?.data) return <></>;
    else return <Navigate to={`/login`} />;
};

export const getHasRequiredInfo = (
    event: EventType | undefined
): { hasEventRequiredInfo: boolean; isLoadingRequiredInfo: boolean } => {
    return {
        hasEventRequiredInfo: event
            ? !!event.timezone && event.languages.length > 0 && !!event.default_lang
            : false,
        isLoadingRequiredInfo: typeof event === "undefined"
    };
};
