import { Button, Divider, Layout, Space, Spin, Typography, notification, theme } from "antd";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";

import ActionsTopMenu from "@layout/ActionsTopMenu/ActionsTopMenu";
import Breadcrumb from "@layout/Breadcrumb/Breadcrumb";
import MainTopMenu from "@layout/MainTopMenu";
import { MessageContext } from "@layout/MessageContext";
import { Outlet } from "react-router-dom";
import React from "react";
import SideMenu from "@layout/SideMenu/SideMenu";
import styles from "./BaseLayout.module.scss";

const { Header, Content } = Layout;

interface BaseLayoutProps {
    isAuth: boolean;
    isLoading: boolean;
    hasError?: boolean;
    children?: React.ReactElement;
}

const BaseLayout: React.FC<BaseLayoutProps> = ({ isAuth, isLoading, children }) => {
    const [messageApi, contextHolder] = notification.useNotification();
    const {
        token: { colorBgContainer, colorBorder, borderRadius, colorBgElevated }
    } = theme.useToken();

    return (
        <Layout className={styles["base-layout"]}>
            {contextHolder}
            <MessageContext.Provider value={messageApi}>
                <style>
                    {`.ant-modal-body .ant-tabs-nav {
          background: ${colorBgElevated}
        }`}
                </style>
                <Spin spinning={isLoading}>
                    <Header
                        className={styles["base-layout__header"]}
                        style={{
                            background: colorBgContainer,
                            borderBottom: `1px solid ${colorBorder}`
                        }}
                    >
                        <div className={styles["base-layout__logo-container"]}>
                            <img src="/logo.png" alt="ADM Logo" />
                        </div>
                        <div style={{ flexGrow: 1 }}>{isAuth && <MainTopMenu />}</div>
                        <div className={styles["base-layout__actions-menu-container"]}>
                            <ActionsTopMenu />
                        </div>
                    </Header>
                    <Layout className={styles["base-layout__layout"]}>
                        {isAuth && <SideMenu />}
                        <Layout className={styles["base-layout__inner-layout"]}>
                            <div className={styles["base-layout__top-content"]}>
                                <Breadcrumb />
                            </div>
                            <Content
                                className={styles["base-layout__content"]}
                                style={{
                                    borderRadius: borderRadius,
                                    background: colorBgContainer,
                                    border: `1px solid ${colorBorder}`
                                }}
                            >
                                {!isLoading && (
                                    <>
                                        {children}
                                        <Outlet />
                                    </>
                                )}
                            </Content>
                            <Divider orientation="right" style={{ marginBottom: -7 }} orientationMargin={20}>
                                <Button
                                    style={{
                                        fontSize: 14,
                                        padding: "1px 5px"
                                    }}
                                    type="text"
                                >
                                    <Space direction="horizontal">
                                        <PhoneOutlined />
                                        Support
                                    </Space>
                                </Button>
                                <Divider type="vertical" />
                                <Button
                                    style={{
                                        fontSize: 14,
                                        padding: "1px 5px"
                                    }}
                                    type="text"
                                >
                                    <Space direction="horizontal">
                                        <MailOutlined />
                                        Contact
                                    </Space>
                                </Button>
                                <Divider type="vertical" />
                                <Typography.Text
                                    style={{
                                        alignSelf: "flex-end",
                                        marginBottom: -5,
                                        fontSize: 13
                                    }}
                                >
                                    ADM Media Consulting ©2023
                                </Typography.Text>
                            </Divider>
                        </Layout>
                    </Layout>
                </Spin>
            </MessageContext.Provider>
        </Layout>
    );
};

export default BaseLayout;
