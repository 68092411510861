import { Alert, Button, Space, Spin, Typography } from "antd";
import { BulbOutlined, GlobalOutlined, PlusOutlined } from "@ant-design/icons";
import { buildTranslation, translations } from "@translations/crudTranslationBuilder";
import { useCreateDomainMutation, useGetAvailableDomainsQuery } from "@store/profile";
import { useNavigate, useParams } from "react-router-dom";

import ActionsBanner from "@components/ActionsBanner/ActionsBanner";
import ColumnLayout from "@components/ColumnLayout/ColumnLayout";
import type { EpisodeFormValues } from "@routes/events/types";
import Error from "@components/Error";
import { Error422 } from "@store/index";
import Form from "@components/Form";
import PageTitle from "@components/PageTitle/PageTitle";
import SearchableTable from "@components/SearchableTable/SearchableTable";
import StickToTop from "@components/StickToTop/StickToTop";
import { mapErrorsToFields } from "@utils/mapErrorsToFields";
import { useEffect } from "react";
import { usePerformMutation } from "@utils/perform-mutation";
import { useSideMenu } from "@hooks/useSideMenu";
import { useTranslation } from "react-i18next";

const ProfileDomains = () => {
    useSideMenu({ isVisible: true, contentType: "profile" });
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { t } = useTranslation(["translations"]);
    const { profileId, domainId } = useParams();
    const addNewDomain = domainId === "new-domain";
    const { performMutation } = usePerformMutation();

    const [createDomain, { isLoading: isLoadingCreateDomain }] = useCreateDomainMutation();

    const {
        data: availableDomains,
        isFetching: isFetchingAvailableDomains,
        isLoading: isLoadingAvailableDomains,
        error: errorAvailableDomains
    } = useGetAvailableDomainsQuery({ profileId: profileId! }, { skip: !!!profileId });

    useEffect(() => {
        if (addNewDomain && form) form.resetFields();
    }, [addNewDomain, form]);

    const handleCUDomain =
        (method: "put" | "post" | "delete", domainId: number | string | undefined) =>
        async (values: EpisodeFormValues | null) => {
            const message = buildTranslation(t, {
                subject: translations.subject.user,
                method: translations.request[method]
            });

            await performMutation({
                mutation: async () => {
                    await createDomain({
                        profileId: profileId!,
                        ...values
                    }).unwrap();
                },
                successMessage: message(translations.outcome.success),
                errorMessage: message(translations.outcome.error),
                onSuccessCallback: () => {
                    navigate("/profiles/" + profileId + "/domains");
                },
                setFieldsWithErrors: (error: Error422) => {
                    if (values) {
                        const valuesWithErrors = mapErrorsToFields({ error, values });
                        form.setFields(valuesWithErrors);
                    }
                }
            });
        };

    const error = errorAvailableDomains;
    const loading = isLoadingCreateDomain || isFetchingAvailableDomains || isLoadingAvailableDomains;

    if (error) return <Error message={t("generic-error-message")} />;
    return (
        <Spin spinning={loading}>
            <PageTitle icon={<GlobalOutlined />} title={t("domains-page-title")} />
            <ColumnLayout
                firstColumn={
                    <>
                        <StickToTop
                            title={<Typography.Title level={3}>{t("domains-list")}</Typography.Title>}
                            actions={
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() => navigate("/profiles/" + profileId + "/domains/new-domain")}
                                    type="primary"
                                    data-testid="add-new-domain-button"
                                    disabled={addNewDomain}
                                >
                                    {t("add-new-domain")}
                                </Button>
                            }
                        ></StickToTop>
                        <SearchableTable
                            searchInputPlaceholder={t("search-episodes-table")}
                            columns={[
                                {
                                    key: "domain_name",
                                    dataIndex: "domain_name",
                                    title: t("domain-name")
                                }
                            ]}
                            disableSelectionCheckboxes={true}
                            selectable={false}
                            data={availableDomains?.map((item) => ({ ...item, key: item.domain_name }))}
                            hidePagination={true}
                            page={undefined}
                        />
                    </>
                }
                secondColumn={
                    addNewDomain ? (
                        <>
                            <StickToTop
                                title={<Typography.Title level={3}>{t("add-new-domain")}</Typography.Title>}
                            ></StickToTop>
                            <Form
                                items={[
                                    {
                                        name: "domain_name",
                                        label: t("domain-name"),
                                        type: "text",
                                        rules: [{ required: true, message: t("field-required") }]
                                    }
                                ]}
                                form={form}
                                languageSpecificLabel={t("language-specific-episodes-fields")}
                                onSubmit={handleCUDomain("post", undefined)}
                            ></Form>
                        </>
                    ) : (
                        <>
                            <StickToTop
                                title={<Typography.Title level={3}>{t("domain-actions")}</Typography.Title>}
                            ></StickToTop>
                            <Alert
                                style={{ marginTop: "9px", padding: "7px 12px" }}
                                message={t("no-domain-actions-alert")}
                                data-testid="no-domain-actions-alert"
                                type="info"
                                icon={<BulbOutlined />}
                                showIcon
                            ></Alert>
                        </>
                    )
                }
                secondColumnBanner={
                    addNewDomain && (
                        <ActionsBanner>
                            <Space direction="horizontal" align="end">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}
                                    size="large"
                                    onClick={form.submit}
                                    data-testid="save-domain-button"
                                >
                                    {t("save-new-domain")}
                                </Button>
                            </Space>
                        </ActionsBanner>
                    )
                }
            />
        </Spin>
    );
};

export default ProfileDomains;
