import { Timezone } from "@store/internationalization/types";

export interface SelectOption {
    label: string;
    value: string;
}

export const mapTimezonesToOptions = (timezones: Timezone[]): SelectOption[] => {
    return timezones.map((timezone: Timezone) => ({
        label: timezone.id,
        value: timezone.id
    }));
};
