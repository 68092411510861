import { useLocation } from "react-router-dom";
import { useMemo } from "react";

export const useQueryParams = (param: string) => {
    const { search } = useLocation();

    const queryParams = useMemo(() => new URLSearchParams(search), [search]);

    return queryParams.get(param);
};
