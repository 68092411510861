import { MultilingualFormInput } from "@components/FormElements";

interface EvaluationFormCategoryFormArgs {
  t: (str: string) => string;
}

export const evaluationFormCalendarFormInputs = ({
  t,
}: EvaluationFormCategoryFormArgs): MultilingualFormInput[] => {
  return [
    {
      name: "start_at",
      multilingual: false,
      label: t("start-at"),
      type: "date-picker",
      inputProps: {
        showTime: { format: "HH:mm" },
        format: "DD-MM-YYYY HH:mm",
      },
    },
    {
      name: "finish_at",
      multilingual: false,
      label: t("finish-at"),
      type: "date-picker",
      inputProps: {
        showTime: { format: "HH:mm" },
        format: "DD-MM-YYYY HH:mm",
      },
    },
  ];
};
