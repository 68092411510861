import { Alert, Button, Space, Spin, Typography } from "antd";
import { BulbOutlined, PlusOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { buildTranslation, translations } from "@translations/crudTranslationBuilder";
import {
    useCreateEpisodeMutation,
    useGetEpisodesQuery,
    useGetEventQuery,
    useUpdateEpisodeMutation
} from "@store/events";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ActionsBanner from "@components/ActionsBanner/ActionsBanner";
import ColumnLayout from "@components/ColumnLayout/ColumnLayout";
import type { EpisodeFormValues } from "@routes/events/types";
import { EpisodeType } from "@store/events/types";
import Error from "@components/Error";
import Form from "@components/Form";
import PageTitle from "@components/PageTitle/PageTitle";
import SearchableTable from "@components/SearchableTable/SearchableTable";
import StickToTop from "@components/StickToTop/StickToTop";
import { episodeDetailsFormInputs } from "@utils/forms/episodes/episodeDetails";
import { episodesTableColumns } from "@utils/layout/episodesTableColumns";
import { getHasRequiredInfo } from "@utils/general";
import { useCheckSlug } from "@hooks/useCheckSlug";
import { useGetLanguagesQuery } from "@store/internationalization";
import { usePerformMutation } from "@utils/perform-mutation";
import { useRedirectEventsHome } from "@hooks/useRedirectEventsHome";
import { useSideMenu } from "@hooks/useSideMenu";
import { useTranslation } from "react-i18next";

const EventEpisodes = () => {
    useSideMenu({ isVisible: true, contentType: "event" });
    const { t } = useTranslation(["translations"]);
    const { eventId, episodeId } = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const addNewEpisode = episodeId === "new-episode";
    const { performMutation } = usePerformMutation();

    const [page, setPage] = useState<number>(1);
    const [searchParam, setSearchParam] = useState<string>("");

    useEffect(() => setPage(1), [searchParam]);

    const [updateEpisode, { isLoading: isLoadingUpdateEpisode }] = useUpdateEpisodeMutation();
    const [createEpisode, { isLoading: isLoadingCreateEpisode }] = useCreateEpisodeMutation();

    const {
        data: event,
        isLoading: isLoadingEvent,
        isFetching: isFetchingEvent,
        error: errorEvent,
        isUninitialized: isUninitializedEvent
    } = useGetEventQuery({ eventId: eventId! }, { skip: !!!eventId });

    const {
        data: episodesData,
        isLoading: isLoadingEpisodesData,
        isFetching: isFetchingEpisodesData,
        error: errorEpisodesData,
        isUninitialized: isUninitializedEpisodesData
    } = useGetEpisodesQuery({ event: eventId, searchParam, page, paginate: true });

    const {
        data: languages,
        isFetching: isFetchingLanguages,
        isLoading: isLoadingLanguages,
        error: errorLanguages,
        isUninitialized: isUninitializedLanguages
    } = useGetLanguagesQuery(null);

    const { hasEventRequiredInfo, isLoadingRequiredInfo } = getHasRequiredInfo(event);

    useRedirectEventsHome({
        hasInfo: hasEventRequiredInfo,
        isLoading: isLoadingRequiredInfo,
        event: eventId
    });

    const selectedEpisode = useMemo(() => {
        if (!episodeId || !episodesData?.data) return undefined;
        return episodesData?.data.find((ep: EpisodeType) => String(ep.id) === episodeId);
    }, [episodeId, episodesData?.data]);

    useEffect(() => {
        if (addNewEpisode || !selectedEpisode) {
            form.resetFields();
        } else if (selectedEpisode && form) {
            form.resetFields();
            form.setFieldsValue({ ...selectedEpisode });
        }
    }, [selectedEpisode, addNewEpisode, form]);

    const handleCUEpisode =
        (method: "put" | "post", episodeId: number | string | null) =>
        async (values: EpisodeFormValues | null) => {
            const message = buildTranslation(t, {
                subject: translations.subject.episode,
                method: translations.request[method]
            });

            await performMutation({
                mutation: async () => {
                    method === "put"
                        ? await updateEpisode({
                              event: eventId,
                              episode: episodeId,
                              ...values,
                              is_qaa_form_hidden: values!.is_qaa_form_hidden || false,
                              show_to_frontend: values!.show_to_frontend || false
                          }).unwrap()
                        : await createEpisode({
                              event: eventId,
                              ...values,
                              is_qaa_form_hidden: values!.is_qaa_form_hidden || false,
                              show_to_frontend: values!.show_to_frontend || false
                          }).unwrap();
                },
                successMessage: message(translations.outcome.success),
                errorMessage: message(translations.outcome.error),
                onSuccessCallback: () => navigate("/events/" + eventId + "/episodes")
            });
        };

    const { validSlug, resetSlug, setSlugForValidation } = useCheckSlug({
        formInstance: form,
        originalSlug: selectedEpisode?.slug,
        eventId: eventId!,
        episodeId: addNewEpisode ? undefined : episodeId
    });

    useEffect(() => {
        if (selectedEpisode) resetSlug(selectedEpisode.slug);
        else resetSlug(null);
    }, [selectedEpisode]);

    const tableColumns = useMemo(() => {
        return episodesTableColumns({
            t,
            navigate
        });
    }, [t, navigate]);
    const tableData = useMemo(
        () => episodesData?.data?.map((item) => ({ ...item, key: item.id })),
        [episodesData?.data]
    );
    const formInputs = useMemo(() => {
        let domain = null;
        if (event?.domain) domain = event?.domain + (event?.slug ? `/${event?.slug}` : "") + "/sessions/";
        return episodeDetailsFormInputs({ t, eventDomain: domain, validSlug: validSlug });
    }, [t, event, episodeId, validSlug]);

    const error = errorEvent || errorLanguages || errorEpisodesData;
    const loading =
        isUninitializedEvent ||
        isUninitializedEpisodesData ||
        isUninitializedLanguages ||
        isLoadingEvent ||
        isFetchingEvent ||
        isLoadingRequiredInfo ||
        isFetchingLanguages ||
        isLoadingEpisodesData ||
        isLoadingUpdateEpisode ||
        isLoadingCreateEpisode ||
        isLoadingLanguages;

    if (error) return <Error message={t("generic-error-message")} />;
    return (
        <Spin spinning={loading}>
            <PageTitle icon={<VideoCameraOutlined />} title={t("episodes-page-title")} />
            <ColumnLayout
                firstColumn={
                    <>
                        <StickToTop
                            title={<Typography.Title level={4}>{t("episodes-list")}</Typography.Title>}
                            actions={
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() => navigate("/events/" + eventId + "/episodes/new-episode")}
                                    type="primary"
                                    data-testid="add-new-episode-button"
                                    disabled={addNewEpisode}
                                >
                                    {t("add-new-episode")}
                                </Button>
                            }
                        ></StickToTop>
                        <SearchableTable
                            page={page}
                            setPage={setPage}
                            searchInputPlaceholder={t("search-episodes-table")}
                            columns={tableColumns}
                            disableSelectionCheckboxes={true}
                            selectable={false}
                            loading={isFetchingEpisodesData}
                            data={tableData}
                            paginationSize={episodesData?.pagination.perPage}
                            paginationTotal={episodesData?.pagination.total}
                            onSearchParamChange={setSearchParam}
                            selectedRowKeys={episodeId ? [parseInt(episodeId)] : []}
                        />
                    </>
                }
                secondColumn={
                    selectedEpisode || addNewEpisode ? (
                        <>
                            <StickToTop
                                title={
                                    <Typography.Title level={4}>
                                        {addNewEpisode
                                            ? t("add-new-episode")
                                            : selectedEpisode?.title_default}
                                    </Typography.Title>
                                }
                            ></StickToTop>
                            <Form
                                items={formInputs}
                                form={form}
                                languageSpecificLabel={t("language-specific-episodes-fields")}
                                onSubmit={
                                    addNewEpisode
                                        ? handleCUEpisode("post", null)
                                        : handleCUEpisode("put", episodeId!)
                                }
                                onValuesChange={(changedValues: any) => {
                                    if (typeof changedValues.slug === "string")
                                        setSlugForValidation(changedValues.slug);
                                }}
                                defaultlanguage={event?.default_lang}
                                languages={event?.languages}
                                languageReferences={languages}
                            ></Form>
                        </>
                    ) : (
                        <>
                            <StickToTop
                                title={<Typography.Title level={4}>{t("episode-details")}</Typography.Title>}
                            ></StickToTop>
                            <Alert
                                style={{ marginTop: "9px", padding: "7px 12px" }}
                                message={t("select-episodes-to-edit")}
                                data-testid="no-episode-selected-alert"
                                type="info"
                                icon={<BulbOutlined />}
                                showIcon
                            ></Alert>
                        </>
                    )
                }
                secondColumnBanner={
                    (selectedEpisode || addNewEpisode) && (
                        <ActionsBanner>
                            <Space direction="horizontal" align="end">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    onClick={form.submit}
                                    data-testid="event-update-button"
                                >
                                    {addNewEpisode ? t("save-new-episode") : t("update-episode")}
                                </Button>
                            </Space>
                        </ActionsBanner>
                    )
                }
            />
        </Spin>
    );
};

export default EventEpisodes;
