import {
    AgendaResponse,
    CalendarType,
    CheckContentType,
    ContentsType,
    CssMediaType,
    EpisodesResponse,
    EvaluationFormCategoryType,
    EvaluationFormSlotsResponse,
    EventType,
    EventsResponse,
    MediaResponse,
    ReportType,
    SpeakerType,
    SpeakersResponse,
    StylesResponse,
    StylesType,
    UserType
} from "@store/events/types";
import {
    AmendContentPayload,
    AmendCssMediaPayload,
    AmendEventPayload,
    AmendStylesPayload,
    CreateEvaluationFormSlotPayload,
    DeleteEvaluationFormSlotPayload,
    EditEvaluationFormSlotPayload,
    GetAgendaPayload,
    GetCalendarsPayload,
    GetCssMediaPayload,
    GetEpisodesPayload,
    GetEvaluationFormCategoryPayload,
    GetEventsPayload,
    GetStylesPayload,
    PublishEvaluationFormSlotPayload,
    PublishSectionDraftPayload
} from "./payload-types";
import { RootState, paginateResponse } from "@store/index";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import type { BaseQueryResult } from "@reduxjs/toolkit/dist/query/baseQueryTypes";

const EVENTS = "Events";
const CALENDARS = "Calendars";
const USERS = "Users";
const EPISODES = "Episodes";
const AGENDAS = "Agendas";
const SPEAKERS = "Speakers";
const EVALUATION_FORM_CATEGORIES = "Evaluation_form_categories";
const MEDIA = "Media";
const REPORTS = "Reports";
const CSS_MEDIA = "Css_media";
const STYLES = "Styles";
const CONTENTS_STATUS = "Contents_status";
const CONTENTS = "Contents";
const EVALUATION_FORM_SLOTS = "Evaluation_Form_Slots";

const surveysApi = createApi({
    reducerPath: "events",
    tagTypes: [
        EVENTS,
        CALENDARS,
        USERS,
        EPISODES,
        AGENDAS,
        SPEAKERS,
        EVALUATION_FORM_CATEGORIES,
        EVALUATION_FORM_SLOTS,
        MEDIA,
        REPORTS,
        CSS_MEDIA,
        STYLES,
        CONTENTS_STATUS,
        CONTENTS
    ],

    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/`,
        prepareHeaders: (headers, { getState }) => {
            const {
                user: { token }
            } = getState() as RootState;
            headers.set(`Authorization`, `Bearer ${token}`);
            headers.set("Accept", "application/json");

            return headers;
        }
    }),

    endpoints: (build) => ({
        getEvents: build.query<EventsResponse, GetEventsPayload>({
            query: ({
                page = 1,
                step = 25,
                binned = 0,
                searchParam = "",
                paginate = true,
                mode = "",
                searchProfileId = ""
            }) => {
                return `/events?only_trashed=${binned}&page=${page}&mode=${mode}&step=${step}&paginate=${paginate}&q=${searchParam}&profile_id=${searchProfileId}`;
            },
            transformResponse: (res: BaseQueryResult<any>) => paginateResponse(res),
            providesTags: [EVENTS]
        }),
        getEvent: build.query<EventType, { eventId: string }>({
            query: ({ eventId }) => `/events/${eventId}`,
            transformResponse: (res: BaseQueryResult<any>) => {
                try {
                    return {
                        ...res.data,
                        languages: [
                            res.data.default_lang,
                            ...res.data.languages.filter((lng: string) => lng !== res.data.default_lang)
                        ]
                    };
                } catch {
                    return res.data;
                }
            },
            providesTags: [EVENTS]
        }),
        createEvent: build.mutation({
            query: ({ ...body }) => {
                return {
                    url: `/events`,
                    method: "POST",
                    body: body
                };
            },
            invalidatesTags: [EVENTS]
        }),
        updateEvent: build.mutation({
            query: ({ id, ...body }) => {
                return {
                    url: `/events/${id}`,
                    method: "PUT",
                    body: body
                };
            },
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [EPISODES, EVENTS, AGENDAS, EVALUATION_FORM_CATEGORIES]
        }),
        binEvents: build.mutation({
            query: ({ ...body }) => {
                if (!Array.isArray(body.ids)) {
                    console.warn("Invalid params for bin events");
                    return "";
                }
                return {
                    url: `/events/`,
                    method: "DELETE",
                    body: {
                        ids: body.ids
                    }
                };
            },
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [EVENTS]
        }),
        restoreEvent: build.mutation({
            query: ({ event }) => {
                return {
                    url: `/events/${event}/restore`,
                    method: "POST"
                };
            },
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [EVENTS]
        }),
        createCalendar: build.mutation<any, AmendEventPayload>({
            query: ({ eventId, episodeId, ...body }) => {
                return {
                    url: `/events/${eventId}/calendars`,
                    method: "POST",
                    body: {
                        episode_id: episodeId,
                        ...body
                    }
                };
            },
            invalidatesTags: [CALENDARS]
        }),
        updateCalendar: build.mutation<any, AmendEventPayload>({
            query: ({ eventId, calendarId, ...body }) => {
                return {
                    url: `/events/${eventId}/calendars/${calendarId}`,
                    method: "PUT",
                    body: body
                };
            },
            invalidatesTags: [CALENDARS]
        }),
        deleteCalendar: build.mutation<void, AmendEventPayload>({
            query: ({ eventId, calendarId }) => {
                return {
                    url: `/events/${eventId}/calendars/${calendarId}`,
                    method: "DELETE"
                };
            },
            invalidatesTags: [CALENDARS]
        }),
        getEpisodeCalendars: build.query<CalendarType[], GetCalendarsPayload>({
            query: ({ episode, event }) => `/events/${event}/calendars?episode_id=${episode}`,
            transformResponse: (res: BaseQueryResult<any>, _, arg: GetCalendarsPayload): any => {
                if (arg.type)
                    return res.data.filter((calendar: CalendarType) => {
                        return calendar.type === arg.type;
                    });
                else return res.data;
            },
            providesTags: () => [CALENDARS]
        }),
        getProfileUsers: build.query<UserType[], number | undefined>({
            query: (profile: number) => `profiles/${profile}/users`,
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            providesTags: () => [USERS]
        }),
        shareEvent: build.mutation({
            query: ({ event, calendar, ...body }) => {
                return {
                    url: `/events/${event}/share/`,
                    method: "POST",
                    body: body
                };
            }
        }),
        getEpisodes: build.query<EpisodesResponse, GetEpisodesPayload>({
            query: ({ event, searchParam, paginate = true, page = 1 }) => {
                return searchParam
                    ? `events/${event}/episodes?q=${searchParam}&paginate=${paginate}&page=${page}`
                    : `events/${event}/episodes?paginate=${paginate}&page=${page}`;
            },
            transformResponse: (res: BaseQueryResult<any>) => paginateResponse(res),
            providesTags: () => [EPISODES]
        }),
        updateEpisode: build.mutation({
            query: ({ event, episode, ...body }) => {
                return {
                    url: `/events/${event}/episodes/${episode}`,
                    method: "PUT",
                    body: body
                };
            },
            invalidatesTags: [EPISODES]
        }),
        createEpisode: build.mutation({
            query: ({ event, ...body }) => {
                return {
                    url: `/events/${event}/episodes`,
                    method: "POST",
                    body: body
                };
            },
            invalidatesTags: [EPISODES]
        }),
        cleanEpisodeQNA: build.mutation({
            query: ({ episode, ...body }) => {
                return {
                    url: `/episodes/${episode}/clean-qaa`,
                    method: "POST",
                    body
                };
            }
        }),
        getAgendas: build.query<AgendaResponse, GetAgendaPayload>({
            query: ({ event, episode, page }) => {
                return `events/${event}/agendas?episode_id=${episode}&page=${page}`;
            },
            transformResponse: (res: BaseQueryResult<any>) => paginateResponse(res),
            providesTags: [AGENDAS]
        }),
        updateAgenda: build.mutation({
            query: ({ event, agenda, episode_id, ...body }) => {
                return {
                    url: `/events/${event}/agendas/${agenda}`,
                    method: "PUT",
                    body: body
                };
            },
            invalidatesTags: (result) => [AGENDAS]
        }),
        createAgenda: build.mutation({
            query: ({ event, ...body }) => {
                return {
                    url: `/events/${event}/agendas`,
                    method: "POST",
                    body: body
                };
            },
            invalidatesTags: (result) => [AGENDAS]
        }),
        deleteAgenda: build.mutation({
            query: ({ event, agenda }) => {
                return {
                    url: `/events/${event}/agendas/${agenda}`,
                    method: "DELETE"
                };
            },
            invalidatesTags: [AGENDAS]
        }),
        getSpeakers: build.query<
            SpeakersResponse,
            { searchParam: string; page?: number; paginate?: boolean }
        >({
            query: ({ searchParam, page, paginate = true }) => {
                if (page) return `speakers?q=${searchParam}&page=${page}&paginate=${paginate}`;
                else return `speakers?q=${searchParam}&paginate=${paginate}`;
            },
            transformResponse: (res) => paginateResponse(res),
            providesTags: [SPEAKERS]
        }),
        getSpeaker: build.query<SpeakerType, { speakerId: string }>({
            query: ({ speakerId }) => {
                return `speakers/${speakerId}`;
            },
            transformResponse: (res: BaseQueryResult<any>) => res.data
        }),
        saveAttachment: build.mutation({
            query: ({ ...body }) => ({
                url: `media/save-uploaded`,
                method: "POST",
                body
            }),
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [MEDIA]
        }),
        getEvaluationFormCategories: build.query<
            EvaluationFormCategoryType[],
            GetEvaluationFormCategoryPayload
        >({
            query: ({ episode }) => {
                return `/episodes/${episode}/ef-question-categories`;
            },
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            providesTags: [EVALUATION_FORM_CATEGORIES]
        }),
        addEvaluationFormCategory: build.mutation({
            query: ({ episode, event, ...body }) => ({
                url: `/episodes/${episode}/ef-question-categories`,
                method: "POST",
                body
            }),
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [EVALUATION_FORM_CATEGORIES]
        }),
        createEvaluationFormQuestion: build.mutation<
            any,
            { categoryId: string; episodeId: string; eventId: string }
        >({
            query: ({ categoryId, episodeId, eventId, ...body }) => {
                return {
                    url: `/ef-question-categories/${categoryId}/ef-questions`,
                    method: "POST",
                    body: {
                        episode_id: episodeId,
                        event_id: eventId,
                        ...body
                    }
                };
            },
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [EVALUATION_FORM_CATEGORIES]
        }),
        updateEvaluationFormQuestion: build.mutation<any, { questionId: string; episodeId: string }>({
            query: ({ questionId, episodeId, ...body }) => {
                return {
                    url: `/ef-questions/${questionId}`,
                    method: "PUT",
                    body: {
                        episode_id: episodeId,
                        ...body
                    }
                };
            },
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [EVALUATION_FORM_CATEGORIES]
        }),
        deleteEvaluationFormQuestion: build.mutation<any, { questionId: string | number }>({
            query: ({ questionId }) => {
                return {
                    url: `/ef-questions/${questionId}`,
                    method: "DELETE"
                };
            },
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [EVALUATION_FORM_CATEGORIES]
        }),
        createEvaluationFormOption: build.mutation<any, { questionId: string; episodeId: string }>({
            query: ({ questionId, episodeId, ...body }) => {
                return {
                    url: `/ef-questions/${questionId}/ef-question-options`,
                    method: "POST",
                    body: {
                        episode_id: episodeId,
                        ...body
                    }
                };
            },
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [EVALUATION_FORM_CATEGORIES]
        }),
        updateEvaluationFormOption: build.mutation<any, { optionId: string; episodeId: string }>({
            query: ({ optionId, episodeId, ...body }) => {
                return {
                    url: `/ef-question-options/${optionId}`,
                    method: "PUT",
                    body: {
                        episode_id: episodeId,
                        ...body
                    }
                };
            },
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [EVALUATION_FORM_CATEGORIES]
        }),
        deleteEvaluationFormOption: build.mutation<any, { optionId: string | number }>({
            query: ({ optionId }) => {
                return {
                    url: `/ef-question-options/${optionId}`,
                    method: "DELETE"
                };
            },
            invalidatesTags: [EVALUATION_FORM_CATEGORIES]
        }),
        changeEvaluationFormCategoryOrder: build.mutation({
            query: ({ episode, ...body }) => {
                const url = `/episodes/${episode}/ef-question-categories/change-order`;
                return {
                    url: url,
                    method: "POST",
                    body
                };
            },
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: (result) => {
                return [EVALUATION_FORM_CATEGORIES];
            }
        }),
        changeEvaluationFormQuestionOrder: build.mutation({
            query: ({ category, ...body }) => {
                const url = `/ef-question-categories/${category}/ef-questions/change-order`;
                return {
                    url: url,
                    method: "POST",
                    body
                };
            },
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [EVALUATION_FORM_CATEGORIES]
        }),
        changeEvaluationFormOptionsOrder: build.mutation({
            query: ({ question, ...body }) => {
                const url = `/ef-questions/${question}/ef-question-options/change-order`;
                return {
                    url: url,
                    method: "POST",
                    body
                };
            },
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [EVALUATION_FORM_CATEGORIES]
        }),
        getMedia: build.query<
            MediaResponse,
            { eventId?: string | number; profileId?: string | number; page: number }
        >({
            query: ({ eventId, profileId, page }) => {
                if (eventId) return `/media?event_id=${eventId}&paginate=true&step=20&page=${page}`;
                else if (profileId)
                    return `/media?profile_id=${profileId}&paginate=true&step=20&page=${page}`;
                else return `/media?paginate=true&step=20&page=${page}`;
            },
            transformResponse: (res: BaseQueryResult<any>) => paginateResponse(res),
            providesTags: [MEDIA]
        }),
        amendAgendaMedias: build.mutation({
            query: ({ media, attach, ...body }) => ({
                url: attach === true ? `/media/${media}/attach` : `/media/${media}/detach`,
                method: "POST",
                body
            }),
            transformResponse: (res: BaseQueryResult<any>) => res?.data,
            invalidatesTags: [AGENDAS]
        }),
        amendAgendaMediaTitle: build.mutation({
            query: ({ media, ...body }) => ({
                url: `/agenda-media/${media}`,
                method: "PUT",
                body
            }),
            transformResponse: (res: BaseQueryResult<any>) => res?.data,
            invalidatesTags: [AGENDAS]
        }),
        attachOptionMedia: build.mutation<
            any,
            {
                optionId: number | string;
                mediaId: string | number;
            }
        >({
            query: ({ optionId, mediaId }) => {
                return {
                    url: `/quiz-question-options/${optionId}/attachMedia`,
                    method: "POST",
                    body: {
                        media_id: mediaId
                    }
                };
            },
            invalidatesTags: [EVALUATION_FORM_CATEGORIES]
        }),
        detachOptionMedia: build.mutation<
            any,
            {
                optionId: number | string;
            }
        >({
            query: ({ optionId }) => {
                return {
                    url: `/quiz-question-options/${optionId}/detachMedia`,
                    method: "DELETE"
                };
            },
            invalidatesTags: [EVALUATION_FORM_CATEGORIES]
        }),
        getReportsList: build.query<
            { common: ReportType[]; live: ReportType[]; vod: ReportType[] },
            { eventId: string; episodeId: string }
        >({
            query: ({ eventId, episodeId }) => {
                return `/events/${eventId}/episodes/${episodeId}/reports`;
            },
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            providesTags: [REPORTS]
        }),
        createSpeaker: build.mutation({
            query: ({ ...body }) => ({
                url: `/speakers`,
                method: "POST",
                body
            }),
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [SPEAKERS]
        }),
        updateSpeaker: build.mutation<any, { speakerId: string | number }>({
            query: ({ speakerId, ...body }) => ({
                url: `/speakers/${speakerId}`,
                method: "PUT",
                body
            }),
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [SPEAKERS]
        }),
        amendSpeakerMedia: build.mutation({
            query: ({ speakerId, mediaId, method }) => ({
                url: method === "post" ? `/media/${mediaId}/attach` : `/media/${mediaId}/detach`,
                method: "POST",
                body: {
                    model: "speaker",
                    params: {
                        id: speakerId
                    }
                }
            }),
            transformResponse: (res: BaseQueryResult<any>) => res?.data,
            invalidatesTags: [SPEAKERS]
        }),
        getCssMedia: build.query<CssMediaType[], GetCssMediaPayload>({
            query: ({ eventId }) => {
                return `events/${eventId}/css-media`;
            },
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            providesTags: [CSS_MEDIA]
        }),
        amendCssMedia: build.mutation<any, AmendCssMediaPayload>({
            query: ({ eventId, method, itemName, mediaId }) => ({
                url: method === "post" ? `/media/${mediaId}/attach` : `/media/${mediaId}/detach`,
                method: "POST",
                body: {
                    model: "css_media",
                    params: {
                        id: eventId
                    },
                    pivot: {
                        keyname: itemName
                    }
                }
            }),
            transformResponse: (res: BaseQueryResult<any>) => res?.data,
            invalidatesTags: [CSS_MEDIA]
        }),
        checkEpisodeSlugValidity: build.mutation({
            query: ({ ...body }) => ({
                url: `/episodes/check-slug`,
                method: "POST",
                body
            })
        }),
        getStyles: build.query<StylesResponse, GetStylesPayload>({
            query: ({ eventId, section = 1 }) => {
                return `/events/${eventId}/layouts/?section=${section}`;
            },
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            providesTags: [STYLES]
        }),
        amendStyles: build.mutation<StylesType, AmendStylesPayload>({
            query: ({ eventId, section = 1, styles }) => ({
                url: `/events/${eventId}/layouts/`,
                method: "POST",
                body: {
                    section: section,
                    draft: {
                        ...styles
                    }
                }
            }),
            invalidatesTags: [STYLES]
        }),
        publishStylesDraft: build.mutation({
            query: ({ eventId, section = 1 }) => ({
                url: `/events/${eventId}/layouts/publish`,
                method: "POST",
                body: {
                    section: section
                }
            }),
            invalidatesTags: [STYLES]
        }),
        discardStylesDraft: build.mutation({
            query: ({ eventId, section = 1 }) => ({
                url: `/events/${eventId}/layouts/discard`,
                method: "POST",
                body: {
                    section: section
                }
            }),
            invalidatesTags: [STYLES]
        }),
        downloadReports: build.mutation({
            query: ({ eventId, episodeId, reportId }) => ({
                url: reportId
                    ? `/events/${eventId}/episodes/${episodeId}/download-reports?report_id=${reportId}`
                    : `/events/${eventId}/episodes/${episodeId}/download-reports`,
                method: "POST",
                responseHandler: "text"
            }),
            transformResponse: (res: BaseQueryResult<any>) => res
        }),
        generateReports: build.mutation({
            query: ({ eventId, episodeId, reportId }) => ({
                url: reportId
                    ? `/events/${eventId}/episodes/${episodeId}/refresh-reports?report_id=${reportId}`
                    : `/events/${eventId}/episodes/${episodeId}/refresh-reports`,
                method: "POST"
            }),
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [REPORTS]
        }),
        checkContents: build.query<CheckContentType, { eventId: string | number }>({
            query: ({ eventId }) => {
                return `/events/${eventId}/check-content-sections`;
            },
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            providesTags: [CONTENTS_STATUS]
        }),
        getContentSection: build.query<
            ContentsType[],
            { eventId: string | number; langId: string; section: string | number; typeId?: number }
        >({
            query: ({ eventId, langId, section, typeId = 1 }) => {
                return `/events/${eventId}/content-sections/?lang_id=${langId}&type_id=${typeId}&section=${section}`;
            },
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            providesTags: [CONTENTS_STATUS]
        }),
        amendContents: build.mutation<any, AmendContentPayload>({
            query: ({ eventId, section = 1, langId, typeId = 1, ...values }) => ({
                url: `/events/${eventId}/content-sections`,
                method: "POST",
                body: {
                    lang_id: langId,
                    type_id: typeId,
                    section: section,
                    data: {
                        ...values
                    }
                }
            }),
            invalidatesTags: [CONTENTS, CONTENTS_STATUS]
        }),
        publishSectionDraft: build.mutation<any, PublishSectionDraftPayload>({
            query: ({ eventId, langId, section, typeId = 1 }) => ({
                url: `/events/${eventId}/content-sections/publish`,
                method: "POST",
                body: {
                    section: section,
                    lang_id: langId,
                    type_id: typeId
                }
            }),
            invalidatesTags: [CONTENTS, CONTENTS_STATUS]
        }),
        discardSectionDraft: build.mutation<any, PublishSectionDraftPayload>({
            query: ({ eventId, langId, section, typeId = 1 }) => ({
                url: `/events/${eventId}/content-sections/discard`,
                method: "POST",
                body: {
                    section: section,
                    lang_id: langId,
                    type_id: typeId
                }
            }),
            invalidatesTags: [CONTENTS, CONTENTS_STATUS]
        }),
        updateMediaStatus: build.mutation<
            any,
            { mediaStatus: { id: number; is_visible: 0 | 1; is_available: 0 | 1 }[] }
        >({
            query: ({ mediaStatus }) => ({
                url: `/agenda-media/status`,
                method: "POST",
                body: {
                    status: mediaStatus
                }
            }),
            invalidatesTags: [AGENDAS]
        }),
        checkEventSlug: build.mutation<any, { slug: string | null; domain: string; eventId: string }>({
            query: ({ slug, domain, eventId }) => ({
                url: `/events/check-slug`,
                method: "POST",
                body: {
                    slug,
                    domain,
                    event_id: eventId
                }
            })
        }),
        getEvaluationFormSlots: build.query<EvaluationFormSlotsResponse, { episodeId: string; page: number }>(
            {
                query: ({ episodeId, page }) => {
                    return `/episodes/${episodeId}/survey-slots?type=1&page=${page}`;
                },
                transformResponse: (res: BaseQueryResult<any>) => paginateResponse(res),
                providesTags: [EVALUATION_FORM_SLOTS]
            }
        ),
        createEvaluationFormSlot: build.mutation<any, CreateEvaluationFormSlotPayload>({
            query: ({ episodeId, ...body }) => {
                return {
                    url: `/episodes/${episodeId}/schedule-configuration`,
                    method: "POST",
                    body
                };
            },
            invalidatesTags: [EVALUATION_FORM_CATEGORIES, EVALUATION_FORM_SLOTS]
        }),
        updateEvaluationFormSlot: build.mutation<any, EditEvaluationFormSlotPayload>({
            query: ({ episodeId, slotId, ...body }) => {
                return {
                    url: `/episodes/${episodeId}/survey-slots/${slotId}`,
                    method: "PUT",
                    body
                };
            },
            invalidatesTags: [EVALUATION_FORM_CATEGORIES, EVALUATION_FORM_SLOTS]
        }),
        deleteEvaluationFormSlot: build.mutation<any, DeleteEvaluationFormSlotPayload>({
            query: ({ episodeId, slotId }) => {
                return {
                    url: `/episodes/${episodeId}/survey-slots/${slotId}`,
                    method: "DELETE"
                };
            },
            invalidatesTags: [EVALUATION_FORM_CATEGORIES, EVALUATION_FORM_SLOTS]
        }),
        publishEvaluationFormSlot: build.mutation<any, PublishEvaluationFormSlotPayload>({
            query: ({ episodeId, slotId, ...body }) => {
                return {
                    url: `/episodes/${episodeId}/survey-slots/${slotId}/publish`,
                    method: "POST",
                    body
                };
            },
            invalidatesTags: [EVALUATION_FORM_CATEGORIES, EVALUATION_FORM_SLOTS]
        })
    })
});

export default surveysApi;

export const {
    useGetEventsQuery,
    useGetEventQuery,
    useUpdateEventMutation,
    useGetEpisodeCalendarsQuery,
    useCreateCalendarMutation,
    useUpdateCalendarMutation,
    useDeleteCalendarMutation,
    useCreateEventMutation,
    useGetProfileUsersQuery,
    useShareEventMutation,
    useBinEventsMutation,
    useRestoreEventMutation,
    useGetEpisodesQuery,
    useUpdateEpisodeMutation,
    useCreateEpisodeMutation,
    useCleanEpisodeQNAMutation,
    useGetAgendasQuery,
    useGetSpeakersQuery,
    useUpdateAgendaMutation,
    useCreateAgendaMutation,
    useDeleteAgendaMutation,
    useSaveAttachmentMutation,
    useGetSpeakerQuery,
    useCreateEvaluationFormQuestionMutation,
    useUpdateEvaluationFormQuestionMutation,
    useDeleteEvaluationFormQuestionMutation,
    useGetEvaluationFormCategoriesQuery,
    useAddEvaluationFormCategoryMutation,
    useUpdateEvaluationFormOptionMutation,
    useChangeEvaluationFormCategoryOrderMutation,
    useChangeEvaluationFormQuestionOrderMutation,
    useChangeEvaluationFormOptionsOrderMutation,
    useGetMediaQuery,
    useAmendAgendaMediasMutation,
    useAmendAgendaMediaTitleMutation,
    useCreateEvaluationFormOptionMutation,
    useDeleteEvaluationFormOptionMutation,
    useAttachOptionMediaMutation,
    useDetachOptionMediaMutation,
    useGetReportsListQuery,
    useCreateSpeakerMutation,
    useUpdateSpeakerMutation,
    useAmendSpeakerMediaMutation,
    useGetCssMediaQuery,
    useAmendCssMediaMutation,
    useCheckEpisodeSlugValidityMutation,
    useGetStylesQuery,
    useAmendStylesMutation,
    usePublishStylesDraftMutation,
    useDiscardSectionDraftMutation,
    useDownloadReportsMutation,
    useGenerateReportsMutation,
    useCheckContentsQuery,
    useGetContentSectionQuery,
    useAmendContentsMutation,
    useDiscardStylesDraftMutation,
    usePublishSectionDraftMutation,
    useUpdateMediaStatusMutation,
    useCheckEventSlugMutation,
    useGetEvaluationFormSlotsQuery,
    useCreateEvaluationFormSlotMutation,
    useUpdateEvaluationFormSlotMutation,
    useDeleteEvaluationFormSlotMutation,
    usePublishEvaluationFormSlotMutation
} = surveysApi;
