import { Alert, Button, Divider, Modal, Space, Spin, Tabs, Typography } from "antd";
import { BulbOutlined, LockFilled, PlusOutlined } from "@ant-design/icons";
import { buildTranslation, translations } from "@translations/crudTranslationBuilder";
import { useAttachOptionMediaMutation, useDetachOptionMediaMutation } from "@store/events";
import {
    useBroadcastPollingQuestionMutation,
    useCreatePollingOptionMutation,
    useCreatePollingQuestionMutation,
    useDeletePollingOptionMutation,
    useDeletePollingQuestionMutation,
    useUpdatePollingOptionMutation,
    useUpdatePollingQuestionMutation
} from "@store/live";
import {
    useChangePollingOptionsOrderMutation as useChangeOptionsOrderMutation,
    useGetPollingQuestionCategoriesQuery
} from "@store/live";
import { useEffect, useMemo, useState } from "react";
import { useGetEventQuery, useGetMediaQuery } from "@store/events";
import { useNavigate, useParams } from "react-router-dom";

import ActionsBanner from "@components/ActionsBanner/ActionsBanner";
import DoubleColumnLayout from "@components/ColumnLayout/ColumnLayout";
import { DraggableTable } from "@components/SortableTable";
import Error from "@components/Error";
import { Error422 } from "@store/index";
import Form from "@components/Form";
import GalleryDrawer from "@components/GalleryDrawer";
import SearchableTable from "@components/SearchableTable/SearchableTable";
import StickToTop from "@components/StickToTop/StickToTop";
import { mapErrorsToFields } from "@utils/mapErrorsToFields";
import { pollingOptionFormInputs } from "@utils/forms/polling/pollingOptionFormInputs";
import { pollingOptionTableColumns } from "@utils/layout/pollingOptionTableColumns";
import { pollingQuestionFormInputs } from "@utils/forms/polling/pollingQuestionFormInputs";
import { pollingQuestionTableColumns } from "@utils/layout/pollingQuestionTableColumns";
import { setBreadcrumb } from "@store/ui";
import { useDispatch } from "@store/hooks";
import { useGetLanguagesQuery } from "@store/internationalization";
import { usePerformMutation } from "@utils/perform-mutation";
import { useSideMenu } from "@hooks/useSideMenu";
import { useTranslation } from "react-i18next";

const EventLive = () => {
    const { eventId, episodeId, questionId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useSideMenu({ isVisible: true, contentType: "event" });
    const { t } = useTranslation(["translations", "crud"]);
    const [questionForm] = Form.useForm();
    const [optionForm] = Form.useForm();
    const [questionBroadcastForm] = Form.useForm();
    const addingNewQuestion = questionId === "new-question";
    const [optionIdForMedia, setOptionIdForMedia] = useState<string | null>(null);
    const [optionsModalItem, setOptionsModalItem] = useState<null | string | number>(null);
    const [broadcastQuestionId, setBroadcastQuestionId] = useState<null | number>(null);
    const [mediaPage, setMediaPage] = useState<number>(1);
    const { performMutation } = usePerformMutation();

    const [changeOptionsOrder, { isLoading: isLoadingChangeOptionsOrder }] = useChangeOptionsOrderMutation();
    const [broadcastQuestion, { isLoading: isLoadingBroadcastQuestion }] =
        useBroadcastPollingQuestionMutation();

    const [attachOptionMedia, { isLoading: isLoadingAttachOptionsMedia }] = useAttachOptionMediaMutation();
    const [detachOptionMedia, { isLoading: isLoadingDetachOptionsMedia }] = useDetachOptionMediaMutation();

    const [updateQuestion, { isLoading: isLoadingEditQuestion }] = useUpdatePollingQuestionMutation();
    const [createQuestion, { isLoading: isLoadingCreateQuestion }] = useCreatePollingQuestionMutation();
    const [deleteQuestion, { isLoading: isLoadingDeleteQuestion }] = useDeletePollingQuestionMutation();

    const [updateOption, { isLoading: isLoadingEditOption }] = useUpdatePollingOptionMutation();
    const [createOption, { isLoading: isLoadingCreateOption }] = useCreatePollingOptionMutation();
    const [deleteOption, { isLoading: isLoadingDeleteOption }] = useDeletePollingOptionMutation();

    const {
        data: event,
        isLoading: isLoadingEvent,
        isFetching: isFetchingEvent,
        error: errorEvent,
        isUninitialized: isUninitializedEvent
    } = useGetEventQuery({ eventId: eventId! }, { skip: !eventId });

    const {
        data: languages,
        isFetching: isFetchingLanguages,
        isLoading: isLoadingLanguages,
        error: errorLanguages,
        isUninitialized: isUninitializedLanguages
    } = useGetLanguagesQuery(null);

    const {
        data: pollingQuestionCategories,
        isLoading: isLoadingPollingQuestionCategories,
        isFetching: isFetchingPollingQuestionCategories,
        error: errorPollingQuestionCategories,
        isUninitialized: isUninitializedPollingQuestionCategories,
        refetch: refetchPollingQuestionCategories
    } = useGetPollingQuestionCategoriesQuery({ episodeId: episodeId! }, { skip: !!!episodeId });

    const {
        data: mediaData,
        isLoading: isLoadingMediaData,
        isFetching: isFetchingMediaData,
        error: errorMediaData,
        isUninitialized: isUninitializedMediaData
    } = useGetMediaQuery({ eventId: eventId!, page: mediaPage });

    const selectedPollingCategory = useMemo(() => {
        if (!pollingQuestionCategories) return undefined;
        let evaluationFormCategoryItem = pollingQuestionCategories[0];
        if (evaluationFormCategoryItem) return evaluationFormCategoryItem;
        else return undefined;
    }, [pollingQuestionCategories]);

    const selectedPollingQuestion = useMemo(() => {
        if (!questionId || !selectedPollingCategory) return undefined;
        let pollingQuestionItem = selectedPollingCategory.questions?.find(
            (efq) => String(efq.id) === questionId
        );
        if (pollingQuestionItem) return pollingQuestionItem;
        else return undefined;
    }, [selectedPollingCategory, questionId]);

    useEffect(() => {
        if (selectedPollingQuestion)
            dispatch(setBreadcrumb({ index: 6, name: selectedPollingQuestion.content_default }));
        else dispatch(setBreadcrumb({ index: 6, name: null }));
        return () => {
            dispatch(setBreadcrumb({ index: 6, name: null }));
        };
    }, [selectedPollingQuestion]);

    useEffect(() => {
        if (optionsModalItem === "new" || optionsModalItem === null || !pollingQuestionCategories) {
            optionForm.resetFields();
        } else {
            const selectedOption = selectedPollingQuestion?.options?.find(
                (option) => option.id === optionsModalItem
            );
            optionForm.setFieldsValue(selectedOption);
        }
    }, [optionsModalItem, pollingQuestionCategories, optionForm, selectedPollingQuestion]);

    useEffect(() => {
        if (addingNewQuestion || !selectedPollingQuestion) questionForm.resetFields();
        else if (selectedPollingQuestion && questionForm) {
            questionForm.setFieldsValue({ ...selectedPollingQuestion });
        }
    }, [selectedPollingQuestion, addingNewQuestion, questionForm]);

    useEffect(() => {
        if (broadcastQuestionId === null) questionBroadcastForm.resetFields();
        else if (broadcastQuestionId && questionBroadcastForm) {
            const pollingQuestion = selectedPollingCategory?.questions.find(
                (question) => question.id === broadcastQuestionId
            );
            questionBroadcastForm.setFieldsValue({ visible_time: pollingQuestion?.visible_time });
        }
    }, [questionBroadcastForm, broadcastQuestionId, selectedPollingCategory]);

    const handleOptionsOrderChange = async (order: number[]) => {
        const message = buildTranslation(t, {
            subject: translations.subject.changeQuestionOptionsOrder,
            method: translations.request.general
        });
        await performMutation({
            mutation: async () => await changeOptionsOrder({ questionId: questionId!, order }).unwrap(),
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error)
        });
    };

    const handleCUDQuestion =
        (method: "delete" | "put" | "post", questionId: string | number) => async (values: any) => {
            const message = buildTranslation(t, {
                subject: translations.subject.pollingQuestion,
                method: translations.request[method]
            });

            await performMutation({
                mutation: async () => {
                    const otherValues = values || {};
                    method === "put"
                        ? await updateQuestion({
                              questionId: questionId,
                              episodeId: episodeId,
                              ...otherValues
                          }).unwrap()
                        : method === "post"
                        ? await createQuestion({
                              episodeId: episodeId,
                              categoryId: selectedPollingCategory?.id,
                              eventId: eventId,
                              ...otherValues
                          }).unwrap()
                        : await deleteQuestion({
                              questionId: questionId
                          }).unwrap();
                },
                successMessage: message(translations.outcome.success),
                errorMessage: message(translations.outcome.error),
                onSuccessCallback: () => navigate(`/events/${eventId}/live/${episodeId}/polling`),
                setFieldsWithErrors: (error: Error422) => {
                    if (values) {
                        const valuesWithErrors = mapErrorsToFields({ error: error, values });
                        questionForm.setFields(valuesWithErrors);
                    }
                }
            });
        };

    const handleCUDOption =
        (method: "delete" | "put" | "post", optionId: string | number) => async (values: any) => {
            const message = buildTranslation(t, {
                subject: translations.subject.evalFormOption,
                method: translations.request[method]
            });

            await performMutation({
                mutation: async () => {
                    const otherValues = values || {};
                    method === "put"
                        ? await updateOption({
                              optionId: optionId,
                              episodeId: episodeId,
                              ...otherValues
                          }).unwrap()
                        : method === "post"
                        ? await createOption({
                              questionId: questionId,
                              episodeId: episodeId,
                              ...otherValues
                          }).unwrap()
                        : await deleteOption({
                              optionId: optionId
                          }).unwrap();
                },
                successMessage: message(translations.outcome.success),
                errorMessage: message(translations.outcome.error),
                onSuccessCallback: () => setOptionsModalItem(null)
            });
        };

    const handleBroadcastQuestion = async (values: { visible_time: number }) => {
        const message = buildTranslation(t, {
            subject: translations.subject.broadcastQuestion,
            method: translations.request.general
        });
        await performMutation({
            mutation: async () =>
                await broadcastQuestion({
                    questionId: broadcastQuestionId!,
                    visibleTime: values.visible_time,
                    status: 1
                }),
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error),
            onSuccessCallback: () => {
                setBroadcastQuestionId(null);
            }
        });
    };

    const endBroadcastQuestion = async (questionId: number) => {
        const message = buildTranslation(t, {
            subject: translations.subject.broadcastQuestion,
            method: translations.request.general
        });
        await performMutation({
            mutation: async () =>
                await broadcastQuestion({
                    questionId: questionId,
                    visibleTime: 0,
                    status: 0
                }),
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error),
            onSuccessCallback: () => {
                setBroadcastQuestionId(null);
            }
        });
    };

    /** OPTION MEDIA */
    const handleOptionMedia = async (
        method: "post" | "delete",
        values: { optionId: string | number; mediaId?: number }
    ) => {
        const message = buildTranslation(t, {
            subject: translations.subject.mediaAttachment,
            method: method === "delete" ? translations.request.delete : translations.request.general
        });
        await performMutation({
            mutation: async () => {
                if (method === "post")
                    await attachOptionMedia({ ...values, mediaId: values.mediaId! }).unwrap();
                else await detachOptionMedia(values).unwrap();
            },
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error),
            onSuccessCallback: () => refetchPollingQuestionCategories()
        });
    };

    const tableData = useMemo(
        () => selectedPollingCategory?.questions?.map((item) => ({ ...item, key: item.id })),
        [selectedPollingCategory]
    );

    const error = errorPollingQuestionCategories || errorEvent || errorLanguages || errorMediaData;
    const loading =
        isUninitializedMediaData ||
        isUninitializedEvent ||
        isUninitializedLanguages ||
        isUninitializedPollingQuestionCategories ||
        isLoadingPollingQuestionCategories ||
        isFetchingPollingQuestionCategories ||
        isLoadingEvent ||
        isFetchingEvent ||
        isFetchingLanguages ||
        isLoadingLanguages ||
        isLoadingMediaData ||
        isFetchingMediaData ||
        isLoadingEditQuestion ||
        isLoadingCreateQuestion ||
        isLoadingDeleteQuestion ||
        isLoadingChangeOptionsOrder ||
        isLoadingEditOption ||
        isLoadingCreateOption ||
        isLoadingDeleteOption ||
        isLoadingAttachOptionsMedia ||
        isLoadingDetachOptionsMedia ||
        isLoadingBroadcastQuestion;

    if (error) return <Error message={t("generic-error-message")} />;
    return (
        <>
            <DoubleColumnLayout
                firstColumn={
                    <>
                        <StickToTop
                            title={<Typography.Title level={4}>{t("questions")}</Typography.Title>}
                            actions={
                                <Button
                                    type="primary"
                                    disabled={addingNewQuestion}
                                    onClick={() =>
                                        navigate(`/events/${eventId}/live/${episodeId}/polling/new-question`)
                                    }
                                    icon={<PlusOutlined />}
                                    data-testid="add-new-polling-question"
                                >
                                    {t("add-new-question")}
                                </Button>
                            }
                        />
                        {tableData?.length === 0 ? (
                            <Alert
                                message={t("no-questions-alert")}
                                data-testid="no-polling-questions-alert"
                                type="info"
                                icon={<BulbOutlined />}
                                showIcon
                            ></Alert>
                        ) : (
                            <SearchableTable
                                selectable={true}
                                page={undefined}
                                loading={loading}
                                hidePagination={true}
                                disableSelectionCheckboxes={true}
                                data={tableData || []}
                                selectedRowKeys={
                                    questionId && !isNaN(parseInt(questionId)) ? [parseInt(questionId)] : []
                                }
                                columns={pollingQuestionTableColumns({
                                    t,
                                    endBroadcastQuestion: endBroadcastQuestion,
                                    goToQuestion: (questionId: number) =>
                                        navigate(
                                            `/events/${eventId}/live/${episodeId}/polling/${questionId}/`
                                        ),
                                    onDeleteItem: (questionId: number) =>
                                        handleCUDQuestion("delete", questionId),
                                    setBroadcastQuestionId: (id: number) => setBroadcastQuestionId(id)
                                })}
                            />
                        )}
                    </>
                }
                secondColumn={
                    <>
                        {!questionId ? (
                            <>
                                <StickToTop
                                    title={
                                        <Typography.Title level={4}>{t("question-details")}</Typography.Title>
                                    }
                                />
                                <Alert
                                    type="info"
                                    message={t("select-question-to-edit")}
                                    icon={<BulbOutlined />}
                                    showIcon
                                    data-testid="no-question-selected-banner"
                                />
                            </>
                        ) : (
                            <>
                                <StickToTop
                                    title={
                                        <Typography.Title level={4}>
                                            {addingNewQuestion
                                                ? t("add-new-question")
                                                : selectedPollingQuestion?.content_default}
                                        </Typography.Title>
                                    }
                                />
                                <Form
                                    items={pollingQuestionFormInputs({ t })}
                                    languageSpecificLabel={t("language-specific-information")}
                                    form={questionForm}
                                    onSubmit={handleCUDQuestion(
                                        addingNewQuestion ? "post" : "put",
                                        questionId!
                                    )}
                                    defaultlanguage={event?.default_lang}
                                    languages={event?.languages}
                                    languageReferences={languages}
                                />
                                <Divider plain>{t("question-options")}</Divider>
                                {addingNewQuestion && (
                                    <Alert
                                        type="info"
                                        showIcon
                                        icon={<LockFilled />}
                                        message={t("save-question-to-add-options")}
                                        data-testid="save-question-to-add-options-alert"
                                    />
                                )}
                                {!addingNewQuestion && event?.languages && (
                                    <Space direction="vertical">
                                        <Tabs
                                            style={{ marginTop: "-10px" }}
                                            items={event?.languages.map((lg: string) => {
                                                return {
                                                    label: `${lg.toUpperCase()}`,
                                                    key: lg,
                                                    children: (
                                                        <DraggableTable
                                                            data={selectedPollingQuestion?.options || []}
                                                            onOrderChange={handleOptionsOrderChange}
                                                            columns={pollingOptionTableColumns({
                                                                t,
                                                                selectedLanguage: lg,
                                                                modalSetter: setOptionsModalItem,
                                                                onDeleteItem: (optionId: string | number) =>
                                                                    handleCUDOption("delete", optionId),
                                                                handleMedia: async (
                                                                    optionId: string,
                                                                    mediaId: number
                                                                ) => {
                                                                    if (mediaId === null)
                                                                        setOptionIdForMedia(optionId);
                                                                    else
                                                                        await handleOptionMedia("delete", {
                                                                            optionId: optionId!
                                                                        });
                                                                },
                                                                media: mediaData?.data
                                                            })}
                                                        />
                                                    )
                                                };
                                            })}
                                        />
                                        <Button
                                            icon={<PlusOutlined />}
                                            onClick={() => setOptionsModalItem("new")}
                                            data-testid="add-new-question-option"
                                        >
                                            {t("add-new-question-option")}
                                        </Button>
                                    </Space>
                                )}
                            </>
                        )}
                    </>
                }
                secondColumnBanner={
                    questionId ? (
                        <ActionsBanner>
                            <Space direction="horizontal" align="end">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    onClick={questionForm.submit}
                                    data-testid="save-new-polling-question"
                                >
                                    {addingNewQuestion ? t("save-new-question") : `${t("update-question")}`}
                                </Button>
                            </Space>
                        </ActionsBanner>
                    ) : undefined
                }
            />
            {/* Options modal */}
            <Modal
                forceRender={true}
                title={optionsModalItem === "new" ? t("add-new-question-option") : t("edit-question-option")}
                maskClosable={false}
                open={optionsModalItem !== null}
                onOk={optionForm.submit}
                confirmLoading={isLoadingEditOption || isLoadingCreateOption}
                onCancel={() => setOptionsModalItem(null)}
                data-testid="question-option-modal"
                okButtonProps={{
                    "data-testid": "create-edit-option-button"
                }}
            >
                <Form
                    form={optionForm}
                    onSubmit={(values: string) =>
                        handleCUDOption(
                            optionsModalItem === "new" ? "post" : "put",
                            optionsModalItem!
                        )(values)
                    }
                    items={pollingOptionFormInputs({ t })}
                    defaultlanguage={event?.default_lang}
                    languages={event?.languages}
                    languageReferences={languages}
                />
            </Modal>
            {/* Question broadcast modal */}
            <Modal
                forceRender={true}
                maskClosable={false}
                data-testid="broadcast-modal"
                title={
                    <>
                        {t("broadcast-question")}:{" "}
                        <Typography.Text type="secondary">
                            {
                                tableData?.find((question) => question.id === broadcastQuestionId)
                                    ?.content_default
                            }
                        </Typography.Text>
                    </>
                }
                open={broadcastQuestionId !== null}
                onOk={questionBroadcastForm.submit}
                confirmLoading={isLoadingBroadcastQuestion}
                onCancel={() => setBroadcastQuestionId(null)}
                okText={t("broadcast-question")}
                okButtonProps={{
                    "data-testid": "confirm-broadcast-question"
                }}
            >
                <Spin spinning={isLoadingBroadcastQuestion}>
                    <Form
                        form={questionBroadcastForm}
                        onSubmit={handleBroadcastQuestion}
                        items={[
                            {
                                name: "visible_time",
                                label: t("visible-time"),
                                type: "number",
                                inputProps: {
                                    "data-testid": "visible-time-broadcast-input"
                                }
                            }
                        ]}
                    />
                </Spin>
            </Modal>
            <GalleryDrawer
                profileId={event?.profile_id!}
                eventId={event?.id}
                title={t("attach-media-to-option")}
                setIsDrawerOpen={(_: any) => setOptionIdForMedia(null)}
                isDrawerOpen={optionIdForMedia !== null}
                gallery={mediaData?.data}
                paginationTotal={mediaData?.pagination.total}
                perPage={mediaData?.pagination.perPage}
                currentPage={mediaPage}
                pageSetter={setMediaPage}
                onAttachClick={async (itemId: number) => {
                    await handleOptionMedia("post", { optionId: optionIdForMedia!, mediaId: itemId });
                    setOptionIdForMedia(null);
                }}
                isLoadingAttachMedia={isLoadingAttachOptionsMedia}
            />
        </>
    );
};

export default EventLive;
