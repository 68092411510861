import { Form, Modal, Select, Spin, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useGetEventUsersFromLogsQuery, useGetLogsQuery } from "@store/admin";
import { useNavigate, useParams } from "react-router-dom";

import { Alert } from "antd";
import { BulbOutlined } from "@ant-design/icons";
import DoubleColumnLayout from "@components/ColumnLayout/ColumnLayout";
import Error from "@components/Error";
import { IonIcon } from "@ionic/react";
import MultilingualForm from "@components/Form";
import PageTitle from "@components/PageTitle/PageTitle";
import SearchableTable from "@components/SearchableTable/SearchableTable";
import StickToTop from "@components/StickToTop/StickToTop";
import SyntaxHighlighter from "react-syntax-highlighter";
import { debounce } from "lodash";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import js_beautify from "js-beautify";
import { listCircleOutline } from "ionicons/icons";
import { listOfModels } from "./listOfModels";
import { logsFilterFormInputs } from "@utils/forms/logs/logsFilterFormInputs";
import { logsTableColumns } from "@utils/layout/logs/logsTableColumns";
import { selectSearchAlgorithm } from "@utils/selectSearchAlgorithm";
import { useGetEventsQuery } from "@store/events";
import { usePermissionsCheck } from "@hooks/usePermissionsCheck";
import { useSideMenu } from "@hooks/useSideMenu";
import { useTranslation } from "react-i18next";

interface LogsFilters {
    causer_id: string;
    subject_type: string;
    date_from: string;
    date_to: string;
}

const Logs = () => {
    const navigate = useNavigate();
    const { eventId } = useParams();
    const { t } = useTranslation(["translations"]);
    useSideMenu({ isVisible: false, contentType: null });
    const [filterForm] = MultilingualForm.useForm();

    usePermissionsCheck({ necessaryRoles: ["is_superadmin"] });

    const [page, setPage] = useState<number>(1);
    const [changesModalContent, setChangesModalContent] = useState<{
        old: any;
        attributes: any;
    } | null>(null);
    const [logsFilters, setLogFilters] = useState<LogsFilters>({
        causer_id: "",
        subject_type: "",
        date_from: "",
        date_to: ""
    });

    const logsFilterSetter = debounce((args: { value: string; key: string }[]) => {
        args.forEach(({ value, key }) => {
            setLogFilters((ps: LogsFilters) => {
                const nextState = { ...ps, [key]: value };
                return nextState;
            });
        });
    }, 400);

    useEffect(() => {
        setPage(1);
    }, [logsFilters]);

    useEffect(() => {
        filterForm.resetFields();
        setLogFilters({
            causer_id: "",
            subject_type: "",
            date_from: "",
            date_to: ""
        });
    }, [eventId, filterForm]);

    const filters = Object.fromEntries(Object.entries(logsFilters).filter(([_, v]) => v !== ""));

    const {
        data: logsData,
        isLoading: isLoadingLogsData,
        isFetching: isFetchingLogsData,
        error: errorLogsData
    } = useGetLogsQuery({ eventId: eventId!, page: page, ...filters }, { skip: !!!eventId });

    const {
        data: eventLogsUsers,
        isLoading: isLoadingEventLogsUsers,
        isFetching: isFetchingEventLogsUsers,
        error: errorEventLogsUsers
    } = useGetEventUsersFromLogsQuery({ eventId: eventId! }, { skip: !!!eventId });

    const {
        data: eventsData,
        isLoading: isLoadingEventsData,
        isFetching: isFetchingEventsData,
        error: errorEventsData,
        isUninitialized: isUninitializedEventsData
    } = useGetEventsQuery({ paginate: 0, mode: "deflate", binned: 0 });

    const eventsForSelect = useMemo(() => {
        if (!eventsData) return [];
        return eventsData.data?.map((event) => {
            return {
                label: event.title,
                value: event.id
            };
        });
    }, [eventsData]);

    const error = errorLogsData || errorEventsData || errorEventLogsUsers;
    const loading =
        isLoadingLogsData ||
        isLoadingEventsData ||
        isLoadingEventLogsUsers ||
        isFetchingEventLogsUsers ||
        isUninitializedEventsData;

    if (error) return <Error message={t("generic-error-message")} />;
    return (
        <Spin spinning={loading}>
            <PageTitle
                icon={
                    <Typography.Title style={{ margin: 0 }}>
                        <IonIcon size="large" icon={listCircleOutline} style={{ marginBottom: -5 }} />
                    </Typography.Title>
                }
                title={t("logs-page-title")}
            >
                <Form.Item label={t("select-event")} style={{ position: "absolute", right: 50, top: 2 }}>
                    <Select
                        onChange={(val) => {
                            navigate(`/logs/${val}`);
                        }}
                        filterOption={(input, option) => selectSearchAlgorithm(input, option)}
                        showSearch={true}
                        value={eventId ? parseInt(eventId) : undefined}
                        style={{ width: 200 }}
                        placeholder={t("select-event")}
                        options={eventsForSelect}
                    />
                </Form.Item>
            </PageTitle>
            <DoubleColumnLayout
                firstColumn={
                    <>
                        <StickToTop>
                            <div style={{ marginBottom: -24, width: "100%" }}>
                                <MultilingualForm
                                    onSubmit={() => {}}
                                    items={logsFilterFormInputs({
                                        t,
                                        eventId,
                                        logsFilterSetter,
                                        listOfModels,
                                        eventLogsUsers: eventLogsUsers || []
                                    })}
                                    form={filterForm}
                                />
                            </div>
                        </StickToTop>
                        {!eventId ? (
                            <Alert
                                message={t("select-event-to-see-logs")}
                                data-testid="select-event-to-see-logs-alert"
                                icon={<BulbOutlined />}
                                showIcon
                            />
                        ) : (
                            <SearchableTable
                                selectable={false}
                                bordered
                                paginationSize={logsData?.pagination.perPage}
                                paginationTotal={logsData?.pagination.total}
                                setPage={setPage}
                                page={page}
                                loading={isFetchingEventsData || isFetchingLogsData}
                                columns={logsTableColumns({ modalSetter: setChangesModalContent })}
                                data={logsData?.logs.map((log) => ({ ...log, key: log.id })) || []}
                            />
                        )}
                    </>
                }
            />
            <Modal
                width="70%"
                title="Changes"
                onCancel={() => setChangesModalContent(null)}
                onOk={() => setChangesModalContent(null)}
                open={!!changesModalContent}
                okButtonProps={{
                    style: { display: "none" }
                }}
                cancelButtonProps={{
                    children: <>{t("close")}</>
                }}
            >
                {changesModalContent?.old && (
                    <>
                        <Typography.Title type="secondary" level={5}>
                            Old values
                        </Typography.Title>
                        <SyntaxHighlighter language="javascript" style={docco}>
                            {js_beautify(JSON.stringify(changesModalContent?.old))}
                        </SyntaxHighlighter>
                    </>
                )}
                {changesModalContent?.attributes && (
                    <>
                        <Typography.Title type="secondary" level={5}>
                            Updated values
                        </Typography.Title>
                        <SyntaxHighlighter language="javascript" style={docco}>
                            {js_beautify(JSON.stringify(changesModalContent?.attributes))}
                        </SyntaxHighlighter>
                    </>
                )}
            </Modal>
        </Spin>
    );
};

export default Logs;
