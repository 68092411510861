import { Button, List, Space, Typography } from "antd";
import { EditOutlined, SendOutlined } from "@ant-design/icons";

import DoubleColumnLayout from "@components/ColumnLayout/ColumnLayout";
import Error from "@components/Error";
import StickToTop from "@components/StickToTop/StickToTop";
import moment from "moment";
import { useGetQandAQuestionsQuery } from "@store/live";
import { useParams } from "react-router-dom";
import { useSideMenu } from "@hooks/useSideMenu";
import { useTranslation } from "react-i18next";

const EventQandA = () => {
    const { episodeId } = useParams();
    useSideMenu({ isVisible: true, contentType: "event" });
    const { t } = useTranslation(["translations"]);

    const {
        data: questionsData,
        isLoading: isLoadingQuestionsData,
        isFetching: isFetchingQuestionsData,
        error: errorQuestionsData,
        isUninitialized: isUninitializedQuestionsData
    } = useGetQandAQuestionsQuery({ episodeId: episodeId! }, { skip: !!!episodeId });

    const error = errorQuestionsData;
    const loading = isUninitializedQuestionsData || isFetchingQuestionsData || isLoadingQuestionsData;

    if (error) return <Error message={t("generic-error-message")} />;
    return (
        <>
            <DoubleColumnLayout
                firstColumn={
                    <>
                        <StickToTop
                            title={<Typography.Title level={4}>{t("questions")}</Typography.Title>}
                        ></StickToTop>
                        <List
                            dataSource={questionsData?.data}
                            bordered
                            pagination={{
                                current: questionsData?.pagination.current
                            }}
                            loading={loading}
                            renderItem={(item) => {
                                return (
                                    <List.Item
                                        key={item.id}
                                        actions={[
                                            <Button
                                                onClick={() => {}}
                                                icon={<EditOutlined />}
                                                key="edit-content"
                                                disabled
                                            >
                                                {t("edit-content")}
                                            </Button>,
                                            <Button
                                                onClick={() => {}}
                                                type="primary"
                                                icon={<SendOutlined />}
                                                key="send-to-chairman"
                                                disabled
                                            >
                                                {t("send-to-chairman")}
                                            </Button>
                                        ]}
                                    >
                                        <List.Item.Meta
                                            title={item.original_content}
                                            description={
                                                <Space direction="horizontal">
                                                    <Typography.Text type="secondary">
                                                        {t("created-at")}:{" "}
                                                        {moment(item.created_at).format("YYYY-MM-DD HH:mm")}
                                                    </Typography.Text>
                                                    <Typography.Text type="secondary">
                                                        {t("updated-at")}:{" "}
                                                        {moment(item.updated_at).format("YYYY-MM-DD HH:mm")}
                                                    </Typography.Text>
                                                </Space>
                                            }
                                        />
                                        {/* <Tag color="blue">{item.report_type}</Tag> */}
                                    </List.Item>
                                );
                            }}
                        />
                    </>
                }
            />
        </>
    );
};

export default EventQandA;
