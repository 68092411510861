import type { AgendaType, SpeakerType } from "@store/events/types";
import { timeZoneToUtc, utcToTimeZone } from "@utils/timezones";

import { MultilingualFormInput } from "@components/FormElements";
import { mapSpeakersToOptions } from "../mapSpeakersToOptions";
import moment from "moment";

interface AgendaFormArgs {
    t: (str: string) => string;
    eventTimezone: string | undefined;
    speakers: SpeakerType[];
}

export const agendaDetailsFormInputs = ({
    t,
    eventTimezone,
    speakers
}: AgendaFormArgs): MultilingualFormInput[] => {
    return [
        {
            name: "title",
            multilingual: true,
            label: t("title"),
            type: "text",
            inputProps: {
                "data-testid": "agenda-item-title-input"
            },
            rules: [{ required: true, message: t("field-required") }]
        },
        {
            name: "start_at",
            multilingual: false,
            label: `${t("start-at-live-only")} - ${eventTimezone}`,
            type: "date-picker",
            inputProps: {
                showTime: { format: "HH:mm" },
                format: "DD-MM-YYYY HH:mm",
                "data-testid": "agenda-item-start-at-input"
            },
            rules: [{ required: true, message: t("field-required") }]
        },
        {
            name: "duration",
            multilingual: false,
            label: t("duration"),
            type: "number",
            inputProps: {
                "data-testid": "agenda-item-duration-input"
            },
            rules: [{ required: true, message: t("field-required") }]
        },
        {
            name: "speakers",
            label: t("speakers"),
            type: "select",
            inputProps: {
                mode: "multiple"
            },
            options: mapSpeakersToOptions(speakers)
        }
    ];
};

export const getAgendaFormValues = (data: AgendaType, timezone: string | undefined) => {
    if (!data) return undefined;

    return {
        start_at: data?.start_at
            ? moment(utcToTimeZone({ time: data?.start_at, zone: timezone }))
            : undefined,
        title: data.title,
        duration: data.duration,
        speakers: data.speakers?.map((speaker) => String(speaker.id)) || []
    };
};

export const getAgendaApiValues = (values: AgendaType, timezone: string | undefined) => {
    return {
        start_at: values?.start_at ? timeZoneToUtc({ time: values?.start_at, zone: timezone }) : undefined,
        title: values.title,
        duration: values.duration,
        speakers_id:
            (values.speakers as unknown as string[])?.map((speaker: string) => parseInt(speaker)) || []
    };
};
