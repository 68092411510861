import ProfileDomainsPage from "@routes/profiles/ProfileDomains";
import ProfilePage from "@routes/profiles/Profile";
import ProfileUsersPage from "@routes/profiles/ProfileUsers";
import ProfilesPage from "@routes/profiles/Profiles";

const ProfilesRoutes = [
    { path: "/profiles", page: <ProfilesPage />, auth: true },
    { path: ["/profiles/:profileId"], page: <ProfilePage />, auth: true },
    {
        path: ["/profiles/:profileId/users", "/profiles/:profileId/users/:userId"],
        page: <ProfileUsersPage />,
        auth: true
    },
    {
        path: ["/profiles/:profileId/domains", "/profiles/:profileId/domains/:domainId"],
        page: <ProfileDomainsPage />,
        auth: true
    }
];

export default ProfilesRoutes;
