import { Button, Col, Form, Input, Row, Typography } from "antd";
import React, { useEffect } from "react";

import { Error422 } from "@store/index";
import { ResetPasswordFields } from "@store/admin/types";
import { clearToken } from "@utils/auth";
import { mapErrorsToFields } from "@utils/mapErrorsToFields";
import { selectUser } from "@store/user";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { usePerformMutation } from "@utils/perform-mutation";
import { useQueryParams } from "@hooks/useQueryParams";
import { useResetPasswordMutation } from "@store/admin/index";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ResetPassword: React.FC = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { t } = useTranslation(["translations"]);
    const { performMutation } = usePerformMutation();
    const { pathname } = useLocation();
    const pathName = pathname.split("/")[1];
    const { token } = useSelector(selectUser);

    const resetPasswordToken = useQueryParams("token");
    const email = useQueryParams("email");

    useEffect(() => {
        if (token) {
            clearToken();
            window.location.reload();
        }
    }, [token]);

    useEffect(() => {
        if (!resetPasswordToken || !email) navigate("/login");
    }, [resetPasswordToken, email]);

    const [resetPassword, { isLoading: isLoadingResetPassword }] = useResetPasswordMutation();

    const handleSubmitForm = async (values: ResetPasswordFields) => {
        if (!resetPasswordToken || !email) navigate("/login");
        await performMutation({
            mutation: async () =>
                await resetPassword({
                    password: values.password,
                    password_confirmation: values.password_confirmation,
                    email: email!,
                    token: resetPasswordToken!
                }).unwrap(),
            successMessage: t("password-reset-success"),
            errorMessage: t("password-reset-error"),
            setFieldsWithErrors: (error: Error422) => {
                const valuesWithErrors = mapErrorsToFields({ error, values });
                form.setFields(valuesWithErrors);
            },
            onSuccessCallback: () => navigate("/login")
        });
    };

    return (
        <Row
            style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "100px"
            }}
        >
            <Col lg={8} md={12} sm={24} xl={8} xxl={6}>
                <Typography.Title>{t(pathName)}</Typography.Title>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmitForm}
                    data-testid="reset-password-form"
                >
                    <Form.Item
                        name="password"
                        label={t("password")}
                        rules={[
                            {
                                required: true,
                                message: t("field-required")
                            }
                        ]}
                    >
                        <Input.Password autoComplete="new-password" />
                    </Form.Item>
                    <Form.Item
                        name="password_confirmation"
                        label={t("confirm-password")}
                        rules={[
                            {
                                required: true,
                                message: t("field-required")
                            },
                            ({ getFieldValue }: any) => ({
                                validator(_: any, value: any) {
                                    if (!value || getFieldValue("password") === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(t("password-do-not-match")));
                                }
                            })
                        ]}
                    >
                        <Input.Password autoComplete="new-password" />
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" loading={isLoadingResetPassword} type="primary">
                            {t(pathName)}
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
};

export default ResetPassword;
