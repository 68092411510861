import { Button, Space } from "antd";

import { ApartmentOutlined } from "@ant-design/icons";
import { MultilingualFormInput } from "@components/FormElements";

interface EventContentsInputsErrorsArgs {
    t: Function;
    handleComparisonModal?: Function;
}

const getContentLabel = ({
    label,
    name,
    handleComparisonModal
}: {
    label: string;
    name: string;
    handleComparisonModal?: Function;
}) => {
    return (
        <Space direction="horizontal">
            {label}
            <Button
                type="text"
                icon={<ApartmentOutlined />}
                onClick={() => handleComparisonModal && handleComparisonModal(name)}
            />
        </Space>
    );
};

export const eventContentsInputsErrors = ({
    t,
    handleComparisonModal
}: EventContentsInputsErrorsArgs): MultilingualFormInput[] => {
    const contentInputs: MultilingualFormInput[] = [
        {
            name: "registration_closed",
            label: getContentLabel({
                label: t("registration-closed"),
                name: "registration_closed",
                handleComparisonModal
            }),
            type: "rich-text-editor"
        }
    ];

    return contentInputs as MultilingualFormInput[];
};
