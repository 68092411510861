import { Alert, Button, Skeleton, Space, Spin, Typography } from "antd";
import { BulbOutlined, PlusOutlined } from "@ant-design/icons";
import { buildTranslation, translations } from "@translations/crudTranslationBuilder";
import {
    useAmendSpeakerMediaMutation,
    useCreateSpeakerMutation,
    useGetMediaQuery,
    useGetSpeakerQuery,
    useGetSpeakersQuery,
    useUpdateSpeakerMutation
} from "@store/events";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ActionsBanner from "@components/ActionsBanner/ActionsBanner";
import DoubleColumnLayout from "@components/ColumnLayout/ColumnLayout";
import Error from "@components/Error";
import { Error422 } from "@store/index";
import Form from "@components/Form";
import GalleryDrawer from "@components/GalleryDrawer";
import { IonIcon } from "@ionic/react";
import PageTitle from "@components/PageTitle/PageTitle";
import SearchableTable from "@components/SearchableTable/SearchableTable";
import { SpeakerType } from "@store/events/types";
import StickToTop from "@components/StickToTop/StickToTop";
import { mapErrorsToFields } from "@utils/mapErrorsToFields";
import { micCircleOutline } from "ionicons/icons";
import { mostUsedLanguages } from "@utils/mostUsedLanguages";
import { selectIsAdmin } from "@store/user";
import { speakersFormInputs } from "@utils/forms/speakers/speakersFormInputs";
import { speakersTableColumns } from "@utils/layout/speakers/speakersTableColumns";
import { useGetLanguagesQuery } from "@store/internationalization";
import { useGetProfilesQuery } from "@store/profile";
import { usePerformMutation } from "@utils/perform-mutation";
import { useSelector } from "@store/hooks";
import { useSideMenu } from "@hooks/useSideMenu";
import { useTranslation } from "react-i18next";

const Speakers = () => {
    const { speakerId } = useParams();
    const { t } = useTranslation(["translations"]);
    const navigate = useNavigate();
    useSideMenu({ isVisible: false, contentType: null });
    const [speakerForm] = Form.useForm();
    const [speakerIdForAvatar, setSpeakerIdForAvatar] = useState<number | null>(null);
    const [mediaPage, setMediaPage] = useState<number>(1);
    const addingNewSpeaker = speakerId === "new-speaker";
    const isAdmin = useSelector(selectIsAdmin);
    const { performMutation } = usePerformMutation();

    const [createSpeaker, { isLoading: isLoadingCreateSpeaker }] = useCreateSpeakerMutation();
    const [updateSpeaker, { isLoading: isLoadingUpdateSpeaker }] = useUpdateSpeakerMutation();
    const [amendSpeakerMedia, { isLoading: isLoadingAmendSpeakerMedia }] = useAmendSpeakerMediaMutation();

    const [searchParam, setSearchParam] = useState<string>("");
    const [page, setPage] = useState<number>(1);
    useEffect(() => setPage(1), [searchParam]);

    const {
        data: speakersData,
        isLoading: isLoadingSpeakersData,
        isFetching: isFetchingSpeakersData,
        error: errorSpeakers,
        isUninitialized: isUninitializedSpeakersData
    } = useGetSpeakersQuery({ searchParam: searchParam, page: page });

    const {
        data: profilesData,
        isFetching: isFetchingProfilesData,
        isLoading: isLoadingProfilesData,
        error: errorProfilesData
    } = useGetProfilesQuery(
        {
            mode: "deflate",
            paginate: false
        },
        { skip: !isAdmin }
    );

    const selectedSpeakerProfileId =
        speakersData?.data.find((speaker) => speaker.id === speakerIdForAvatar)?.profile_id || undefined;

    const {
        data: speaker,
        isLoading: isLoadingSpeaker,
        error: errorSpeaker
    } = useGetSpeakerQuery({ speakerId: speakerId! }, { skip: !!!speakerId || addingNewSpeaker });

    const {
        data: mediaData,
        isLoading: isLoadingMediaData,
        isFetching: isFetchingMediaData,
        error: errorMediaData,
        isUninitialized: isUninitializedMediaData
    } = useGetMediaQuery({
        profileId: selectedSpeakerProfileId,
        page: mediaPage
    });

    const {
        data: languages,
        isLoading: isLoadingLanguages,
        isFetching: isFetchingLanguages,
        error: errorLanguages,
        isUninitialized: isUninitializedLanguages
    } = useGetLanguagesQuery(null);

    useEffect(() => {
        if (addingNewSpeaker) speakerForm.resetFields();
        else if (speaker && speakerId && speaker.id === parseInt(speakerId) && speakerForm) {
            speakerForm.setFieldsValue({ ...speaker });
        }
    }, [speaker, addingNewSpeaker, speakerForm, speakerId]);

    const getSpeakerName = (speaker: SpeakerType | undefined) => {
        return `${speaker?.role_default} ${speaker?.firstname} ${speaker?.lastname}`;
    };

    const handleCUDSpeaker =
        (method: "put" | "post" | "delete", speakerId: string | null) => async (values: any) => {
            const message = buildTranslation(t, {
                subject: translations.subject.speaker,
                method: translations.request.general
            });

            await performMutation({
                mutation: async () => {
                    method === "put"
                        ? await updateSpeaker({
                              speakerId: speakerId!,
                              ...values
                          }).unwrap()
                        : await createSpeaker({
                              ...values
                          }).unwrap();
                },
                successMessage: message(translations.outcome.success),
                errorMessage: message(translations.outcome.error),
                onSuccessCallback: () => {
                    if (method === "post") navigate("/speakers");
                },
                setFieldsWithErrors: (error: Error422) => {
                    const valuesWithErrors = mapErrorsToFields({ error, values });
                    speakerForm.setFields(valuesWithErrors);
                }
            });
        };

    const handleAvatar = async (
        method: "post" | "delete",
        values: { speakerId: string | number; mediaId?: number }
    ) => {
        const message = buildTranslation(t, {
            subject: translations.subject.userAvatar,
            method: method === "delete" ? translations.request.delete : translations.request.general
        });

        await performMutation({
            mutation: async () => {
                await amendSpeakerMedia({
                    method: method,
                    speakerId: values.speakerId,
                    mediaId: values.mediaId
                }).unwrap();
            },
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error)
        });
    };

    const error = errorSpeakers || errorSpeaker || errorLanguages || errorProfilesData || errorMediaData;
    const loadingTable = isFetchingSpeakersData;
    const loadingForm = isLoadingSpeaker || !(addingNewSpeaker || String(speaker?.id) === speakerId);
    const loading =
        isLoadingSpeakersData ||
        isUninitializedSpeakersData ||
        isLoadingLanguages ||
        isFetchingProfilesData ||
        isLoadingProfilesData ||
        isFetchingLanguages ||
        isUninitializedLanguages ||
        isLoadingCreateSpeaker ||
        isLoadingUpdateSpeaker ||
        isLoadingAmendSpeakerMedia;

    if (error) return <Error message={t("generic-error-message")} />;
    return (
        <Spin spinning={loading}>
            <PageTitle
                icon={
                    <Typography.Title style={{ margin: 0 }}>
                        <IonIcon size="large" icon={micCircleOutline} style={{ marginBottom: -5 }} />
                    </Typography.Title>
                }
                title={t("speakers-page-title")}
            />
            <DoubleColumnLayout
                firstColumn={
                    <>
                        <StickToTop
                            title={<Typography.Title level={4}>{t("speakers-list")}</Typography.Title>}
                            actions={
                                <Button
                                    type="primary"
                                    icon={<PlusOutlined />}
                                    onClick={() => navigate("/speakers/new-speaker")}
                                    disabled={addingNewSpeaker}
                                >
                                    {t("add-new-speaker")}
                                </Button>
                            }
                        />
                        <SearchableTable
                            data={speakersData?.data.map((sp) => ({ ...sp, key: sp?.id })) || []}
                            columns={speakersTableColumns({
                                t,
                                goToSpeaker: (speakerId: string) => navigate(`/speakers/${speakerId}`),
                                removeAvatar: handleAvatar,
                                setSpeakerIdForAvatar: setSpeakerIdForAvatar
                            })}
                            paginationSize={speakersData?.pagination.perPage || 50}
                            paginationTotal={speakersData?.pagination.total}
                            loading={loadingTable}
                            selectable={true}
                            disableSelectionCheckboxes={true}
                            selectedRowKeys={speakerId ? [parseInt(speakerId)] : []}
                            setPage={setPage}
                            page={page}
                            searchInputPlaceholder={t("search-for-speakers")}
                            onSearchParamChange={setSearchParam}
                        />
                    </>
                }
                secondColumn={
                    <>
                        {!speakerId ? (
                            <>
                                <StickToTop
                                    title={
                                        <Typography.Title level={4}>{t("speaker-details")}</Typography.Title>
                                    }
                                />
                                <Alert
                                    type="info"
                                    message={t("select-speaker-to-edit")}
                                    icon={<BulbOutlined />}
                                    showIcon
                                    data-testid="no-speaker-selected-banner"
                                />
                            </>
                        ) : (
                            <>
                                <StickToTop
                                    title={
                                        <Typography.Title level={4}>
                                            {addingNewSpeaker ? (
                                                t("add-new-speaker")
                                            ) : String(speaker?.id) === speakerId ? (
                                                getSpeakerName(speaker)
                                            ) : (
                                                <Skeleton.Input
                                                    active={true}
                                                    style={{ height: 23 }}
                                                ></Skeleton.Input>
                                            )}
                                        </Typography.Title>
                                    }
                                />
                                <Skeleton loading={loadingForm} active={true}>
                                    <Form
                                        items={speakersFormInputs({
                                            t,
                                            isAdmin,
                                            profiles: profilesData?.data || []
                                        })}
                                        languageSpecificLabel={t("language-specific-information")}
                                        form={speakerForm}
                                        onSubmit={handleCUDSpeaker(
                                            addingNewSpeaker ? "post" : "put",
                                            speakerId!
                                        )}
                                        mostUsedLanguages={mostUsedLanguages}
                                        languages={languages ? languages?.map((ln) => ln.id) : []}
                                        languageReferences={languages}
                                    />
                                </Skeleton>
                            </>
                        )}
                    </>
                }
                secondColumnBanner={
                    speakerId ? (
                        <ActionsBanner>
                            <Space direction="horizontal" align="end">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={false}
                                    size="large"
                                    onClick={speakerForm.submit}
                                    data-testid="save-update-evaluation-form-question"
                                >
                                    {addingNewSpeaker ? t("save-new-speaker") : `${t("update-speaker")}`}
                                </Button>
                            </Space>
                        </ActionsBanner>
                    ) : undefined
                }
            />
            <GalleryDrawer
                profileId={selectedSpeakerProfileId!}
                title={t("change-speaker-avatar")}
                loading={isLoadingMediaData || isFetchingMediaData || isUninitializedMediaData}
                setIsDrawerOpen={(_: any) => setSpeakerIdForAvatar(null)}
                perPage={mediaData?.pagination.perPage}
                isDrawerOpen={speakerIdForAvatar !== null}
                gallery={speakerIdForAvatar ? mediaData?.data : []}
                currentPage={mediaPage}
                paginationTotal={mediaData?.pagination.total}
                pageSetter={setMediaPage}
                onAttachClick={async (itemId: number) => {
                    await handleAvatar("post", { speakerId: speakerIdForAvatar!, mediaId: itemId });
                    setSpeakerIdForAvatar(null);
                }}
                isLoadingAttachMedia={false}
            />
        </Spin>
    );
};

export default Speakers;
