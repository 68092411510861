import { Button, Space } from "antd";
import { EditOutlined, ShareAltOutlined } from "@ant-design/icons";
import { EpisodeType, EventType, LanguageType } from "@store/events/types";
import { closeCircleOutline, wifiOutline } from "ionicons/icons";

import { IonIcon } from "@ionic/react";

interface EventsTableColumnsArgs {
    t: Function;
    navigate: Function;
}

interface BinnedEventsTableColumnArgs extends EventsTableColumnsArgs {
    restoreEvent: Function;
}

export const eventsTableColumns = ({ t, navigate }: EventsTableColumnsArgs) => [
    {
        title: t("title"),
        dataIndex: "title",
        key: "title",
        render: (title: string, row: EventType) => {
            return (
                <Button type="text" onClick={() => navigate("/events/" + row.id + "/home")}>
                    {title}
                </Button>
            );
        }
    },
    {
        title: t("id"),
        dataIndex: "id",
        key: "id"
    },
    {
        title: t("status"),
        dataIndex: "status",
        key: "status",
        render: (status: string) => {
            return <IonIcon icon={parseInt(status) ? wifiOutline : closeCircleOutline} size="small" />;
        }
    },
    {
        title: t("domain"),
        key: "domain",
        dataIndex: "domain"
    },
    {
        title: t("episodes"),
        key: "episodes",
        dataIndex: "episodes",
        render: (episodes: EpisodeType[]) => {
            return episodes?.length;
        }
    },
    {
        title: t("timezone"),
        dataIndex: "timezone",
        key: "timezone"
    },
    {
        title: t("languages"),
        dataIndex: "languages",
        key: "languages",
        render: (languages: string[] | null, row: EventType) => {
            if (!languages) return 0;
            else return row.default_lang + "," + languages.filter((ln) => ln !== row.default_lang).join(",");
        }
    },
    {
        title: t("actions"),
        dataIndex: "id",
        key: "actions",
        render: (id: number | string) => {
            return (
                <Space direction="horizontal">
                    <Button
                        type="primary"
                        onClick={() => navigate("/events/" + id + "/home")}
                        icon={<EditOutlined />}
                        data-testid={"edit-event-button-" + id}
                        name={t("edit-event")}
                    />
                    <Button
                        type="default"
                        onClick={() => navigate("/events/" + id + "/share")}
                        icon={<ShareAltOutlined />}
                        data-testid={"share-event-button-" + id}
                        name={t("share-event")}
                    />
                </Space>
            );
        }
    }
];

export const binnedEventsTableColumns = ({ t, navigate, restoreEvent }: BinnedEventsTableColumnArgs) => {
    return [
        {
            title: t("title"),
            dataIndex: "title",
            key: "title-b",
            render: (title: string) => title
        },
        ...eventsTableColumns({ t, navigate }).filter(
            (column) => column.key !== "actions" && column.key !== "title"
        ),
        {
            title: t("restore"),
            key: "id",
            dataIndex: "id",
            render: (id: number | string) => {
                return (
                    <Button
                        type="primary"
                        onClick={async () => await restoreEvent({ event: id })}
                        icon={<EditOutlined />}
                        data-testid="restore-event-button"
                    >
                        {t("restore-event")}
                    </Button>
                );
            }
        }
    ];
};
