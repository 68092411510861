import React from "react";
import { theme } from "antd";

import styles from "./ActionsBanner.module.scss";

interface ActionsBannerProps {
  children: React.ReactNode;
  style?: {
    [key: string]: any;
  };
}

const ActionsBanner: React.FC<ActionsBannerProps> = ({ children, style }) => {
  const {
    token: { colorBgLayout, colorBorder, borderRadius },
  } = theme.useToken();
  return (
    <div
      className={styles["actions-banner"]}
      style={{
        background: colorBgLayout,
        borderBottomRightRadius: borderRadius,
        borderBottomLeftRadius: borderRadius,
        outline: `1px solid ${colorBorder}`,
        ...style,
      }}
    >
      <div className={styles["actions-banner__container"]}>{children}</div>
    </div>
  );
};

export default ActionsBanner;
