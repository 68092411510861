import LoginPage from "@routes/auth/Login";
import Reset2FA from "@routes/auth/Reset2FA";
import SetOrResetPassword from "@routes/auth/SetOrResetPassword";

const AuthRoutes = [
    { path: "/login", page: <LoginPage />, auth: false },
    { path: ["/reset-password", "/set-password"], page: <SetOrResetPassword />, auth: false },
    { path: "/reset-2fa", page: <Reset2FA />, auth: false }
];

export default AuthRoutes;
