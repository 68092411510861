import type { Moment } from "moment";
import { MultilingualFormInput } from "@components/FormElements";

interface LogsFilterFormInputsArgs {
    eventId: string | undefined;
    t: Function;
    logsFilterSetter: Function;
    listOfModels: string[];
    eventLogsUsers: { id: number | null; email: string | null }[];
}

export const logsFilterFormInputs = ({
    eventId,
    t,
    logsFilterSetter,
    listOfModels,
    eventLogsUsers
}: LogsFilterFormInputsArgs): MultilingualFormInput[] => {
    return [
        {
            name: "date-range",
            label: t("filter-by-dates"),
            type: "range-picker",
            spanWidth: {
                sm: 4,
                lg: 4
            },
            inputProps: {
                showTime: true,
                onChange: (range: Moment[]) => {
                    logsFilterSetter([
                        {
                            value: range?.[1]?.format("YYYY-MM-DD HH:mm") || "",
                            key: "date_to"
                        },
                        {
                            value: range?.[0]?.format("YYYY-MM-DD HH:mm") || "",
                            key: "date_from"
                        }
                    ]);
                },
                disabled: !!!eventId
            }
        },
        {
            name: "model",
            type: "select",
            spanWidth: {
                sm: 4,
                lg: 4
            },
            label: t("filter-by-subject"),
            options: listOfModels.map((model) => ({
                value: model,
                label: model.split("\\")[2]
            })),
            placeholder: t("subject-type"),
            inputProps: {
                disabled: !!!eventId,
                onChange: (val: string) => {
                    logsFilterSetter([{ value: val, key: "subject_type" }]);
                }
            }
        },
        {
            name: "user",
            type: "select",
            spanWidth: {
                sm: 4,
                lg: 4
            },
            placeholder: t("causer"),
            label: t("filter-by-user"),
            options: eventLogsUsers
                .filter((user) => user.id !== null)
                .map((user) => ({
                    value: String(user.id) || "",
                    label: user.email || String(user.id!)
                })),
            inputProps: {
                disabled: !!!eventId,
                onChange: (val: string) => {
                    logsFilterSetter([{ value: val, key: "causer_id" }]);
                }
            }
        }
    ];
};
