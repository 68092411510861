import { useEffect, useState } from "react";

import { FormInstance } from "antd";
import { debounce } from "lodash";
import { useCheckEpisodeSlugValidityMutation } from "@store/events";

export const useCheckSlug = ({
    formInstance,
    originalSlug,
    episodeId,
    eventId
}: {
    formInstance: FormInstance;
    originalSlug?: string | null;
    episodeId?: string;
    eventId: string;
}) => {
    const [checkSlug] = useCheckEpisodeSlugValidityMutation();
    const [validSlug, setValidSlug] = useState<string | null>(null);
    const [slugToValidate, setSlugToValidate] = useState<string | null>(null);

    useEffect(() => {
        if (slugToValidate === null || eventId === null) {
            setValidSlug(null);
            return;
        }
        (async () => {
            try {
                if (episodeId)
                    await checkSlug({
                        slug: slugToValidate,
                        event_id: eventId,
                        episode_id: episodeId
                    }).unwrap();
                else await checkSlug({ slug: slugToValidate, event_id: eventId }).unwrap();
                setValidSlug(slugToValidate);
                formInstance.setFields([
                    {
                        name: "slug",
                        errors: []
                    }
                ]);
            } catch (error: any) {
                setValidSlug(null);
                const slugErrors = error?.data?.errors?.slug;
                formInstance.setFields([
                    {
                        name: "slug",
                        errors: slugErrors
                    }
                ]);
            }
        })();
    }, [slugToValidate, eventId]);

    useEffect(() => {
        if (originalSlug) setValidSlug(originalSlug);
    }, [originalSlug]);

    const slugToValidateSetter = (slug: string) => setSlugToValidate(slug);
    const slugToValidateSetterDebounced = debounce(slugToValidateSetter, 500);
    const resetSlug = (slug: string | null) => setSlugToValidate(slug);

    return {
        validSlug,
        setSlugForValidation: slugToValidateSetterDebounced,
        resetSlug
    };
};
