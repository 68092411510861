import { MultilingualFormInput } from "@components/FormElements";

interface EvaluationFormCategoryFormArgs {
    t: (str: string) => string;
}

export const evaluationFormQuestionFormInputs = ({
    t
}: EvaluationFormCategoryFormArgs): MultilingualFormInput[] => {
    return [
        {
            name: "content",
            multilingual: true,
            label: t("content"),
            type: "text",
            inputProps: {
                "data-testid": "evaluation-form-question-content-input"
            },
            rules: [{ required: true, message: t("field-required") }]
        },
        {
            name: "legend",
            multilingual: true,
            label: t("legend"),
            type: "text",
            inputProps: {
                "data-testid": "evaluation-form-question-legend-input"
            }
        },
        {
            name: "min_allowed_choices",
            label: t("min-allowed-choices"),
            type: "number",
            inputProps: {
                "data-testid": "evaluation-form-question-min-choices-input"
            },
            rules: [{ required: true, message: t("field-required") }]
        },
        {
            name: "max_allowed_choices",
            label: t("max-allowed-choices"),
            type: "number",
            inputProps: {
                "data-testid": "evaluation-form-question-max-choices-input"
            },
            rules: [{ required: true, message: t("field-required") }]
        },
        {
            name: "is_radio",
            label: t("is-radio"),
            type: "switch",
            inputProps: {
                "data-testid": "evaluation-form-question-is-radio-input"
            }
        },
        {
            name: "is_inline",
            label: t("is-inline"),
            type: "switch",
            inputProps: {
                "data-testid": "evaluation-form-question-is-inline-input"
            }
        }
    ];
};
