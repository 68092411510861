import { useEffect } from "react";
import { useDispatch } from "@store/hooks";
import { setSideMenuVisible, setSideMenuContentType } from "@store/layout";
import { SideMenuContentTypes } from "store/layout/types";

interface SideMenuHookConfig {
  isVisible: boolean;
  contentType: SideMenuContentTypes;
}

export const useSideMenu = ({ isVisible, contentType }: SideMenuHookConfig) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSideMenuVisible(isVisible));
    dispatch(setSideMenuContentType(contentType));
  }, []);
};
