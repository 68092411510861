export const specificStylesReference = {
    header: [
        "headerBg_color",
        "headerLogo_width",
        "headerLogo_height",
        "headerGoBackBtnBg_color",
        "headerGoBackBtnText_color",
        "headerGoBackBtnBorder_color",
        "headerGoBackBtnBorder_radius",
        "headerGoBackBtnDisableBg_color",
        "headerGoBackBtnDisableText_color",
        "headerGoBackBtnActiveBg_color",
        "headerGoBackBtnActiveText_color",
        "headerGoBackBtnHover_color",
        "headerGoBackBtnHoverBg_color",
        "headerLogoutBtnBg_color",
        "headerLogoutBtnText_color",
        "headerLogoutBtnBorder_color",
        "headerLogoutBtnBorder_radius",
        "headerLogoutBtnHover_color",
        "headerLogoutBtnHoverBg_color"
    ],
    navbar: [
        "navbarBg_color",
        "navbarButton_color",
        "navbarButtonBg_color",
        "navbarButtonDisableBg_color",
        "navbarButtonDisable_color",
        "navbarButtonActiveBorder_color",
        "navbarButtonActive_color",
        "navbarButtonActiveHover_color",
        "navbarButtonActiveHoverBg_color"
    ],
    poll: [
        "pollBg_color",
        "pollCountdown_color",
        "pollTitle_color",
        "pollTitleFont_size",
        "pollTitle_weight",
        "pollSubtitle_color",
        "pollSubtitleFont_size",
        "pollSubtitle_weight",
        "pollFormTitle_color",
        "pollFormTitleFont_size",
        "pollFormTitle_weight",
        "pollFormText_color",
        "pollFormTextFont_size",
        "pollFormText_weight",
        "pollFormTextarea_color",
        "pollFormTextareaBg_color",
        "pollFormTextareaBorder_color",
        "pollFormTextareaBorder_radius",
        "pollFormMandatoryLabel_color",
        "pollFormMandatoryLabelFont_size",
        "pollFormMandatoryLabel_weight",
        "pollFormSelectBg_color",
        "pollFormSelectBorder_color",
        "pollFormSelectBorder_radius",
        "pollFormSelect_color",
        "pollFormInputBg_color",
        "pollFormInputBorder_color",
        "pollFormInputBorder_radius",
        "pollFormInput_color",
        "pollFormInputDisableBg_color",
        "pollFormInputDisable_color",
        "pollFormRadio_color",
        "pollFormRadioDisable_color",
        "pollButton_color",
        "pollButtonBg_color",
        "pollButtonBorder_radius",
        "pollButtonDisable_color",
        "pollButtonDisableBg_color",
        "pollButtonActive_color",
        "pollButtonActiveBg_color",
        "pollButtonHoverBg_color",
        "pollButtonHover_color"
    ],
    closedStatus: [
        "closedStatusBg_color",
        "closedStatusText_color",
        "closedStatusTextFont_size",
        "closedStatusText_weight"
    ],
    preStatus: ["preStatusBg_color", "preStatusText_color", "preStatusTextFont_size", "preStatusText_weight"],
    postStatus: [
        "postStatusBg_color",
        "postStatusText_color",
        "postStatusTextFont_size",
        "postStatusText_weight"
    ],
    cardSession: [
        "cardSessionPageBg_color",
        "cardSessionTitle_color",
        "cardSessionTitle_size",
        "cardSessionTitle_weight",
        "cardSessionSubtitle_color",
        "cardSessionSubtitle_size",
        "cardSessionSubtitle_weight",
        "cardSessionBorder_color",
        "cardSessionBorder_radius",
        "cardSessionBg_color"
    ],
    agenda: [
        "agendaPageBg_color",
        "agendaCardBg_color",
        "agendaCardHeaderTitle_color",
        "agendaCardHeaderTitleFont_size",
        "agendaCardHeaderTitle_weight",
        "agendaCardHeaderTimeTitle_color",
        "agendaCardHeaderTimeTitleFont_size",
        "agendaCardHeaderTimeTitleFont_weight",
        "agendaCardBodyTimeDuration_color",
        "agendaCardBodyTimeDurationFont_size",
        "agendaCardBodyTimeDurationFont_weight",
        "agendaCardBodyTitle_color",
        "agendaCardBodyTitleFont_size",
        "agendaCardBodyTitle_weight",
        "agendaCardBodySpeakerName_color",
        "agendaCardBodySpeakerName_weight",
        "agendaCardBodySpeakerNameFont_size",
        "agendaCardBodySpeakerRole_color",
        "agendaCardBodySpeakerRole_weight",
        "agendaCardBodySpeakerRoleFont_size",
        "agendaCardBodySpeakerBio_color",
        "agendaCardBodySpeakerBio_weight",
        "agendaCardBodySpeakerBioFont_size"
    ],
    evaluation: [
        "evaluationPageBg_color",
        "evaluationTitle_color",
        "evaluationTitleFont_size",
        "evaluationTitle_weight",
        "evaluationSubtitle_color",
        "evaluationSubtitleFont_size",
        "evaluationSubtitle_weight",
        "evaluationFormTitle_color",
        "evaluationFormTitleFont_size",
        "evaluationFormTitle_weight",
        "evaluationFormText_color",
        "evaluationFormTextFont_size",
        "evaluationFormText_weight",
        "evaluationFormTextarea_color",
        "evaluationFormTextareaBg_color",
        "evaluationFormTextareaBorder_color",
        "evaluationFormTextareaBorder_radius",
        "evaluationFormTextareaDisableBg_color",
        "evaluationFormMandatoryLabel_color",
        "evaluationFormMandatoryLabelFont_size",
        "evaluationFormMandatoryLabel_weight",
        "evaluationFormSelectBg_color",
        "evaluationFormSelectBorder_color",
        "evaluationFormSelectBorder_radius",
        "evaluationFormSelect_color",
        "evaluationFormInputBg_color",
        "evaluationFormInputBorder_color",
        "evaluationFormInputBorder_radius",
        "evaluationFormInput_color",
        "evaluationFormInputDisable_color",
        "evaluationFormInputDisableBg_color",
        "evaluationFormRadio_color",
        "evaluationButton_color",
        "evaluationButtonBg_color",
        "evaluationButtonBorder_radius",
        "evaluationButtonDisable_color",
        "evaluationButtonDisableBg_color",
        "evaluationButtonActive_color",
        "evaluationButtonActiveBg_color",
        "evaluationButtonHoverBg_color",
        "evaluationButtonHover_color"
    ],
    info: ["infoPageBg_color", "infoText_color", "infoTextFont_size", "infoText_weight"],
    qa: [
        "qaPageBg_color",
        "qaPageTitle_color",
        "qaPageTitle_weight",
        "qaPageTitleFont_size",
        "qaQuestionTextareaBg_color",
        "qaQuestionTextareaFont_size",
        "qaQuestionTextareaBorder_radius",
        "qaQuestionButtonBg_color",
        "qaQuestionButtonText_color",
        "qaQuestionButtonBorder_color",
        "qaQuestionButtonBorder_radius",
        "qaQuestionButtonDisable_color",
        "qaQuestionButtonDisableBg_color",
        "qaQuestionButtonActive_color",
        "qaQuestionButtonActiveBg_color",
        "qaQuestionButtonHover_color",
        "qaQuestionButtonHoverBg_color"
    ],
    login: [
        "loginInput_color",
        "loginInputBg_color",
        "loginInputBorder_color",
        "loginInputBorder_radius",
        "loginInputDisableBg_color",
        "loginInputDisable_color",
        "loginButtonBg_color",
        "loginButtonText_color",
        "loginButtonBorder_color",
        "loginButtonDisable_color",
        "loginButtonDisableBg_color",
        "loginButtonActive_color",
        "loginButtonActiveBg_color",
        "loginButtonHover_color",
        "loginButtonHoverBg_color"
    ],
    errorPage: [
        "errorPageBg_color",
        "errorPageButtonBg_color",
        "errorPageButtonText_color",
        "errorPageButtonBorder_color",
        "errorPageButtonBorder_radius",
        "errorPageButtonDisable_color",
        "errorPageButtonDisableBg_color",
        "errorPageButtonActive_color",
        "errorPageButtonActiveBg_color",
        "errorPageButtonHover_color",
        "errorPageButtonHoverBg_color"
    ]
};
