import { MultilingualFormInput } from "@components/FormElements";

export const userFormInputs = ({
    t,
    withPassword,
    rolesOptions
}: {
    t: Function;
    withPassword: boolean;
    rolesOptions: { value: string; label: string }[];
}): MultilingualFormInput[] => {
    const inputs = [
        {
            name: "name",
            label: t("user-name"),
            type: "text",
            multilingual: false,
            rules: [{ required: true, message: t("field-required") }]
        },
        {
            name: "email",
            label: t("user-email"),
            multilingual: false,
            type: "email",
            rules: [{ required: true, message: t("field-required") }]
        },
        {
            name: "roles",
            label: t("role"),
            multilingual: false,
            type: "select",
            options: rolesOptions,
            rules: [{ required: true, message: t("field-required") }]
        }
    ];

    return (withPassword ? [...inputs, ...passwordInputs({ t })] : inputs) as MultilingualFormInput[];
};

export const passwordInputs = ({ t }: { t: Function }): MultilingualFormInput[] => [
    {
        name: "password",
        label: t("user-password"),
        multilingual: false,
        type: "password",
        rules: [{ required: true, message: t("field-required") }]
    },
    {
        name: "password_confirmation",
        label: t("confirm-password"),
        multilingual: false,
        type: "password",
        rules: [{ required: true, message: "Please insert a password confirmation for the user" }],
        formItemProps: {
            dependencies: ["password"],
            rules: [
                {
                    required: true,
                    message: "Please confirm the user's password"
                },
                ({ getFieldValue }: { getFieldValue: Function }) => ({
                    validator(_: any, value: string) {
                        if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error("The new password that you entered do not match!"));
                    }
                })
            ]
        }
    }
];

export const passwordChangeInputs = ({ t }: { t: Function }): MultilingualFormInput[] => {
    const adjustedInputs = userFormInputs({ t, withPassword: true, rolesOptions: [] }).map((input) => {
        if (input.name === "name" || input.name === "email" || input.name === "roles") {
            return {
                ...input,
                hidden: true
            };
        } else return input;
    });

    return adjustedInputs;
};
