import { Button, Result, ResultProps } from "antd";

import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface ErrorMessageProps extends ResultProps {
    message: string;
    children?: React.ReactNode;
    noHomePageButton?: boolean;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message, children, status, noHomePageButton }) => {
    const { t } = useTranslation(["translations"]);
    const navigate = useNavigate();

    const extras = [<React.Fragment key="children">{children}</React.Fragment>];

    if (!noHomePageButton)
        extras.push(
            <Button
                type="primary"
                onClick={() => {
                    navigate("/");
                    window.location.reload();
                }}
                key="homepage-button"
            >
                {t("go-to-homepage")}
            </Button>
        );

    return <Result status={status || "error"} title={"Error"} subTitle={message} extra={extras}></Result>;
};

export default ErrorMessage;
