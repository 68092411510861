import { Space, Tag } from "antd";

import React from "react";
import { sizeFormatter } from "human-readable";
import { useTranslation } from "react-i18next";

interface FileSizeProps {
    size: string;
}

const FileSize: React.FC<FileSizeProps> = ({ size }) => {
    const { t } = useTranslation(["translations"]);
    const defaults = sizeFormatter();

    const fileSize = parseInt(size);
    const fileDimension: "large" | "medium" | "small" =
        fileSize > 400000 ? "large" : fileSize > 200000 ? "medium" : "small";
    const label = t(fileDimension);

    return (
        <Space direction="horizontal">
            {`${defaults(parseInt(size))}`}
            <Tag
                color={
                    fileDimension === "large" ? "error" : fileDimension === "medium" ? "warning" : "success"
                }
            >
                {label}
            </Tag>
        </Space>
    );
};

export default FileSize;
