import {
    ChangePasswordPayload,
    LogsDataType,
    ModeratorResponse,
    ModeratorType,
    ResetPasswordPayload,
    RoleType
} from "@store/admin/types";
import { RootState, paginateResponse } from "@store/index";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BaseQueryResult } from "@reduxjs/toolkit/dist/query/baseQueryTypes";

const LOGS = "Logs";
const USER_ROLES = "User_roles";
const EVENT_USERS_LOG = "Event_users_logs";
const MODERATORS = "Moderators";

const adminApi = createApi({
    reducerPath: "admin",
    tagTypes: [LOGS, USER_ROLES, EVENT_USERS_LOG, MODERATORS],
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/`,
        responseHandler: "json",
        prepareHeaders: (headers, { getState }) => {
            const {
                user: { token }
            } = getState() as RootState;
            headers.set(`Authorization`, `Bearer ${token}`);
            headers.set("Accept", "application/json");

            return headers;
        }
    }),
    endpoints: (build) => ({
        requestPasswordReset: build.mutation({
            query: ({ ...body }) => {
                return {
                    url: `/forgot-password`,
                    method: "POST",
                    body: body
                };
            }
        }),
        resetPassword: build.mutation<any, ResetPasswordPayload>({
            query: ({ ...body }) => {
                return {
                    url: `/reset-password`,
                    method: "POST",
                    body: body
                };
            }
        }),
        changePassword: build.mutation<any, ChangePasswordPayload>({
            query: ({ old_password, password, password_confirmation }) => {
                return {
                    url: `/change-password`,
                    method: "POST",
                    body: {
                        old_password,
                        password,
                        password_confirmation
                    }
                };
            }
        }),
        getLogs: build.query<
            LogsDataType,
            {
                eventId: string;
                page?: number;
                causer_type?: string;
                causer_id?: string;
                subject_type?: string;
                subject_id?: string;
                date_from?: string;
                date_to?: string;
            }
        >({
            query: ({ eventId, ...body }) => {
                return {
                    url: `/events/${eventId}/activity-logs`,
                    method: "GET",
                    params: body
                };
            },
            transformResponse: (res: BaseQueryResult<any>) => paginateResponse(res),
            providesTags: [LOGS]
        }),
        getAssignableUserRoles: build.query<RoleType[], void>({
            query: () => {
                return {
                    url: `/available-roles`,
                    method: "GET"
                };
            },
            transformResponse: (res: BaseQueryResult<any>): any => res.data,
            providesTags: [USER_ROLES]
        }),
        getEventUsersFromLogs: build.query<
            { id: number | null; email: string | null }[],
            { eventId: string }
        >({
            query: ({ eventId }) => {
                return {
                    url: `/events/${eventId}/users`,
                    method: "GET"
                };
            },
            transformResponse: (res: BaseQueryResult<any>): any => res.data,
            providesTags: [EVENT_USERS_LOG]
        }),
        reset2FA: build.mutation<any, void>({
            query: () => {
                return {
                    url: `/forgot-2fa`,
                    method: "POST"
                };
            }
        }),
        restore2FA: build.mutation<any, { url: string }>({
            query: ({ url }) => {
                return {
                    url: url,
                    method: "POST"
                };
            }
        }),
        createModerator: build.mutation<any, { eventId: string; values: { [key: string]: string } }>({
            query: ({ eventId, ...values }) => {
                return {
                    url: `/events/${eventId}/moderators`,
                    method: "POST",
                    body: values
                };
            },
            invalidatesTags: [MODERATORS]
        }),
        updateModerator: build.mutation<
            any,
            { eventId: string; moderatorId: number; values: { [key: string]: string } }
        >({
            query: ({ eventId, moderatorId, ...values }) => {
                return {
                    url: `/events/${eventId}/moderators/${moderatorId}`,
                    method: "PUT",
                    body: values
                };
            },
            invalidatesTags: [MODERATORS]
        }),
        deleteModerator: build.mutation<any, { eventId: string; moderatorId: number }>({
            query: ({ eventId, moderatorId }) => {
                return {
                    url: `/events/${eventId}/moderators/${moderatorId}`,
                    method: "DELETE"
                };
            },
            invalidatesTags: [MODERATORS]
        }),
        getModerators: build.query<ModeratorResponse, { eventId: string; page?: number }>({
            query: ({ eventId, page = 1 }) => {
                return {
                    url: `/events/${eventId}/moderators?page=${page}`,
                    method: "GET"
                };
            },
            transformResponse: (res: BaseQueryResult<any>) => paginateResponse(res),
            providesTags: [MODERATORS]
        })
    })
});

export default adminApi;

export const {
    useResetPasswordMutation,
    useRequestPasswordResetMutation,
    useChangePasswordMutation,
    useGetLogsQuery,
    useGetAssignableUserRolesQuery,
    useGetEventUsersFromLogsQuery,
    useReset2FAMutation,
    useRestore2FAMutation,
    useCreateModeratorMutation,
    useUpdateModeratorMutation,
    useGetModeratorsQuery,
    useDeleteModeratorMutation
} = adminApi;
