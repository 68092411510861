import { specificStyleCategorySeparator } from "./SpecificStyles";

export const formatSpecificStyles = (styles: { [key: string]: { [key: string]: any } }) => {
    const prefixes = Object.keys(styles)
        .map((styleKey) => {
            return styleKey.split(specificStyleCategorySeparator)[0];
        })
        .filter((value, index, array) => array.indexOf(value) === index);

    const stylesObject = prefixes.reduce((prev: any, current: any) => {
        return { ...prev, [current]: {} };
    }, {});

    Object.keys(styles).forEach((styleKey) => {
        const [prefix, suffix] = styleKey.split(specificStyleCategorySeparator);
        let parsedValue: any = styles[styleKey];
        if (parsedValue && typeof parsedValue === "object" && "metaColor" in parsedValue) {
            parsedValue = "#" + parsedValue?.toHex();
        }
        if (parsedValue || parsedValue === 0) {
            stylesObject[prefix][suffix] = parsedValue;
        }
    });

    return stylesObject;
};
