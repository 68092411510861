import { Button, Result } from "antd";

import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Page404: React.FC = () => {
    const { t } = useTranslation(["translations"]);
    const navigate = useNavigate();

    return (
        <Result
            status="404"
            title={t("error-404")}
            subTitle={t("error-404-description")}
            extra={
                <Button type="primary" onClick={() => navigate(`/`)}>
                    {t("go-to-homepage")}
                </Button>
            }
        />
    );
};

export default Page404;
