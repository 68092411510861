import { Col, Row, theme } from "antd";

import React from "react";
import styles from "./ColumnLayout.module.scss";

interface ColumnLayoutProps {
    style?: {
        [key: string]: any;
    };
    firstColumn: React.ReactNode;
    secondColumn?: React.ReactNode;
    thirdColumn?: React.ReactNode | null;
    secondColumnBanner?: React.ReactNode;
    thirdColumnBanner?: React.ReactNode;
    hideContents?: boolean;
    banner?: React.ReactNode;
}

const DoubleColumnLayout: React.FC<ColumnLayoutProps> = ({
    style,
    firstColumn,
    secondColumn,
    thirdColumn,
    secondColumnBanner,
    thirdColumnBanner,
    hideContents,
    banner
}) => {
    const {
        token: { colorBorder }
    } = theme.useToken();

    return (
        <Row
            gutter={[0, 16]}
            className={styles["column-layout"] + " " + (!!banner ? styles["column-layout--with-banner"] : "")}
            style={{ ...style }}
        >
            <Col
                xs={24}
                lg={!secondColumn && !thirdColumn ? 24 : !thirdColumn && thirdColumn !== null ? 11 : 6}
                className={`${styles["column-layout__first-column"]} ${
                    hideContents ? styles["column-layout__first-column--hidden"] : ""
                }`}
            >
                <div className={styles["column-layout__first-column-container"]}>{firstColumn}</div>
            </Col>
            {secondColumn && (
                <Col
                    xs={24}
                    lg={thirdColumn === null ? 17 : !thirdColumn ? 13 : 8}
                    className={`${styles["column-layout__second-column"]} ${
                        hideContents ? styles["column-layout__second-column--hidden"] : ""
                    }`}
                    style={{
                        borderLeft: `1px solid ${colorBorder}`
                    }}
                >
                    <div className={styles["column-layout__column-container"]} data-testid="column-container">
                        <div className={styles["column-layout__column-inner-container"]}>{secondColumn}</div>
                        {secondColumnBanner}
                    </div>
                </Col>
            )}
            {thirdColumn && (
                <Col
                    xs={24}
                    lg={10}
                    className={`${styles["column-layout__third-column"]} ${
                        hideContents ? styles["column-layout__third-column--hidden"] : ""
                    }`}
                    style={{
                        borderLeft: `1px solid ${colorBorder}`
                    }}
                >
                    <div className={styles["column-layout__column-container"]}>
                        <div className={styles["column-layout__column-inner-container"]}>{thirdColumn}</div>
                        {thirdColumnBanner}
                    </div>
                </Col>
            )}
            {banner && <>{banner}</>}
        </Row>
    );
};

export default DoubleColumnLayout;
