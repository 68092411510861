import { Button, Spin } from "antd";
import { CalendarType, EventType } from "@store/events/types";
import { buildTranslation, translations } from "@translations/crudTranslationBuilder";
import { timeZoneToUtc, utcToTimeZone } from "@utils/timezones";
import {
    useCreateCalendarMutation,
    useDeleteCalendarMutation,
    useGetEventQuery,
    useUpdateCalendarMutation,
    useUpdateEventMutation
} from "@store/events";
import { useEffect, useState } from "react";
import { useGetAvailableDomainsQuery, useGetProfilesQuery } from "@store/profile";
import { useGetLanguagesQuery, useGetTimezonesQuery } from "@store/internationalization";

import ActionsBanner from "@components/ActionsBanner/ActionsBanner";
import ColumnLayout from "@components/ColumnLayout/ColumnLayout";
import Error from "@components/Error";
import Form from "@components/Form";
import { HomeOutlined } from "@ant-design/icons";
import PageTitle from "@components/PageTitle/PageTitle";
import { eventFormInputs } from "@utils/forms/events/eventFormInputs";
import { getFormValues } from "@utils/forms/getFormValues";
import moment from "moment";
import { selectIsAdmin } from "@store/user";
import { useCheckEventSlug } from "@hooks/useCheckEventSlug";
import { useParams } from "react-router-dom";
import { usePerformMutation } from "@utils/perform-mutation";
import { useSelector } from "@store/hooks";
import { useSideMenu } from "@hooks/useSideMenu";
import { useTranslation } from "react-i18next";

const Event = () => {
    const { eventId } = useParams();
    const [form] = Form.useForm();
    useSideMenu({ isVisible: true, contentType: "event" });
    const { t } = useTranslation(["translations"]);
    const [_, setFormTrigger] = useState<boolean>(false);
    const isAdmin = useSelector(selectIsAdmin);
    const { performMutation } = usePerformMutation();

    const [updateEvent, { isLoading: isPuttingEvent }] = useUpdateEventMutation();

    const [updateCalendar, { isLoading: loadingUpdateCalendar }] = useUpdateCalendarMutation();
    const [createCalendar, { isLoading: loadingCreateCalendar }] = useCreateCalendarMutation();
    const [deleteCalendar, { isLoading: loadingDeleteCalendar }] = useDeleteCalendarMutation();

    const {
        data: event,
        error: errorEvent,
        isLoading: isLoadingEvent,
        isFetching: isFetchingEvent,
        isUninitialized: isUninitializedEvent,
        refetch: refetchEvent
    } = useGetEventQuery({ eventId: eventId! }, { skip: eventId === undefined });

    const [selectedProfileId, setSelectedProfileId] = useState<number | null>(event?.profile_id || null);

    const {
        data: timezones,
        isFetching: isFetchingTimezones,
        isLoading: isLoadingTimezones,
        error: errorTimezones,
        isUninitialized: isUninitializedTimezones
    } = useGetTimezonesQuery(null);

    const {
        data: profilesData,
        isFetching: isFetchingProfilesData,
        isLoading: isLoadingProfilesData,
        error: errorProfilesData
    } = useGetProfilesQuery(
        {
            mode: "deflate",
            paginate: false
        },
        { skip: !isAdmin }
    );

    const {
        data: languages,
        isFetching: isFetchingLanguages,
        isLoading: isLoadingLanguages,
        error: errorLanguages,
        isUninitialized: isUninitializedLanguages
    } = useGetLanguagesQuery(null);

    const {
        data: availableDomains,
        isFetching: isFetchingAvailableDomains,
        isLoading: isLoadingAvailableDomains,
        error: errorAvailableDomains,
        isUninitialized: isUninitializedAvaliableDomains
    } = useGetAvailableDomainsQuery(
        { eventId: eventId!, profileId: selectedProfileId! },
        { skip: !!!eventId || selectedProfileId === null }
    );

    useEffect(() => {
        if (event) setSelectedProfileId(event.profile_id);
    }, [event]);

    const onlineCalendar = event?.calendars.find((calendar) => calendar.type === 1);

    const handleUpdateEvent = async (values: any) => {
        const message = buildTranslation(t, {
            subject: translations.subject.event,
            method: translations.request["put"]
        });

        await performMutation({
            mutation: async () => {
                if (onlineCalendar && (values.online_start_at || values.online_finish_at)) {
                    await updateCalendar({
                        eventId: eventId!,
                        calendarId: onlineCalendar.id,
                        type: 1,
                        start_at: timeZoneToUtc({
                            time: values.online_start_at,
                            zone: values.timezone
                        }),
                        finish_at: timeZoneToUtc({
                            time: values.online_finish_at,
                            zone: values.timezone
                        })
                    }).unwrap();
                } else if (onlineCalendar && !values.online_start_at && !values.online_finish_at) {
                    await deleteCalendar({
                        eventId: eventId!,
                        calendarId: onlineCalendar.id
                    }).unwrap();
                } else if (!onlineCalendar && (values.online_start_at || values.online_finish_at)) {
                    await createCalendar({
                        eventId: eventId!,
                        type: 1,
                        start_at: timeZoneToUtc({
                            time: values.online_start_at,
                            zone: values.timezone
                        }),
                        finish_at: timeZoneToUtc({
                            time: values.online_finish_at,
                            zone: values.timezone
                        })
                    }).unwrap();
                }
                await updateEvent({
                    id: eventId,
                    ...getFormValues({
                        values: {
                            ...values,
                            slug: values?.slug || null
                        },
                        excludeValues: ["online_start_at", "online_finish_at"]
                    })
                }).unwrap();
            },
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error),
            onSuccessCallback: () => refetchEvent()
        });
    };

    const { validSlug, setSlugForValidation } = useCheckEventSlug({
        eventId: eventId!,
        originalSlug: event?.slug || null,
        formInstance: form
    });

    useEffect(() => {
        if (event) {
            form.setFieldsValue(
                (() => {
                    const onlineCal = event?.calendars.find((calendar: CalendarType) => calendar.type === 1);
                    const onlineStartAt = utcToTimeZone({ time: onlineCal?.start_at, zone: event.timezone });
                    const onlineFinishAt = utcToTimeZone({
                        time: onlineCal?.finish_at,
                        zone: event.timezone
                    });
                    return {
                        ...event,
                        online_start_at: onlineStartAt ? moment(onlineStartAt) : undefined,
                        online_finish_at: onlineFinishAt ? moment(onlineFinishAt) : undefined,
                        languages: event.languages || []
                    };
                })()
            );
            setSlugForValidation(event.slug || "", event.domain);
        }
    }, [form, event]);

    const formInputs = () => {
        if (!event || !form || !languages) return [];
        return eventFormInputs({
            t,
            availableDomains: availableDomains || [],
            profiles: profilesData?.data || [],
            timezones: timezones || [],
            languages: languages || [],
            validSlug: validSlug,
            form: form,
            event: event
        });
    };

    const error =
        errorEvent || errorTimezones || errorLanguages || errorProfilesData || errorAvailableDomains;
    const loading =
        isUninitializedEvent ||
        isUninitializedTimezones ||
        isUninitializedLanguages ||
        isFetchingTimezones ||
        isFetchingLanguages ||
        isFetchingAvailableDomains ||
        isLoadingAvailableDomains ||
        isLoadingEvent ||
        loadingCreateCalendar ||
        loadingUpdateCalendar ||
        loadingDeleteCalendar ||
        isFetchingEvent ||
        isPuttingEvent ||
        (selectedProfileId && isUninitializedAvaliableDomains) ||
        isLoadingTimezones ||
        isLoadingLanguages ||
        isFetchingProfilesData ||
        isLoadingProfilesData;

    if (error) return <Error message={t("event-generic-error")} />;
    return (
        <Spin spinning={loading}>
            <PageTitle icon={<HomeOutlined />} title={t("events-general-form-title")} />
            <ColumnLayout
                firstColumn={
                    <Form
                        form={form}
                        items={formInputs()}
                        onValuesChange={(changedValues: EventType, values) => {
                            setFormTrigger((ps) => !ps);
                            if (changedValues.languages) {
                                if (!changedValues.languages?.includes(values.default_lang)) {
                                    form.setFieldsValue({
                                        default_lang: null
                                    });
                                }
                            }
                            if (changedValues.profile_id) {
                                setSelectedProfileId(changedValues.profile_id);
                                form.setFieldsValue({ domain: null, slug: null });
                            } else {
                                if (!!!values.domain) form.setFieldValue("slug", "");
                            }
                            setSlugForValidation(values.slug || "", values.domain);
                        }}
                        onSubmit={handleUpdateEvent}
                    />
                }
                banner={
                    <ActionsBanner>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            onClick={form.submit}
                            data-testid="event-update-button"
                        >
                            {t("update-event")}
                        </Button>
                    </ActionsBanner>
                }
            />
        </Spin>
    );
};

export default Event;
