import { BarChartOutlined, DownloadOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, List, Space, Spin, Tag, Typography } from "antd";
import { buildTranslation, translations } from "@translations/crudTranslationBuilder";
import { useGenerateReportsMutation, useGetEventQuery, useGetReportsListQuery } from "@store/events";

import DoubleColumnLayout from "@components/ColumnLayout/ColumnLayout";
import EpisodeSelector from "@components/EpisodeSelector/EpisodeSelector";
import Error from "@components/Error";
import PageTitle from "@components/PageTitle/PageTitle";
import axios from "@utils/axios";
import { getHasRequiredInfo } from "@utils/general";
import moment from "moment";
import { saveAs } from "file-saver";
import { selectUser } from "@store/user";
import { useParams } from "react-router-dom";
import { usePerformMutation } from "@utils/perform-mutation";
import { useRedirectEventsHome } from "@hooks/useRedirectEventsHome";
import { useSelector } from "@store/hooks";
import { useSideMenu } from "@hooks/useSideMenu";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const EventReports = () => {
    const { eventId, episodeId } = useParams();
    useSideMenu({ isVisible: true, contentType: "event" });
    const { t } = useTranslation(["translations"]);
    const user = useSelector(selectUser);
    const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false);
    const { performMutation } = usePerformMutation();

    const [generateReports, { isLoading: isLoadingGenerateReports }] = useGenerateReportsMutation();

    const {
        data: event,
        isLoading: isLoadingEvent,
        isFetching: isFetchingEvent,
        isUninitialized: isUninitializedEvent,
        error: errorEvent
    } = useGetEventQuery({ eventId: eventId! }, { skip: !!!eventId });

    const {
        data: reports,
        isLoading: isLoadingReports,
        isFetching: isFetchingReports,
        error: errorReports,
        isUninitialized: isUninitializedReports
    } = useGetReportsListQuery(
        { episodeId: episodeId!, eventId: eventId! },
        { skip: !!!episodeId || !!!eventId }
    );

    const { hasEventRequiredInfo, isLoadingRequiredInfo } = getHasRequiredInfo(event);

    useRedirectEventsHome({
        hasInfo: hasEventRequiredInfo,
        isLoading: isLoadingRequiredInfo,
        event: eventId
    });

    const handleDownloadFile = async (reportId?: number, reportName?: string) => {
        const message = buildTranslation(t, {
            subject: translations.subject.reportDownload,
            method: translations.request.general
        });

        const url = reportId
            ? `/events/${eventId}/episodes/${episodeId}/download-reports?report_id=${reportId}`
            : `/events/${eventId}/episodes/${episodeId}/download-reports`;

        setIsLoadingDownload(true);
        await performMutation({
            mutation: async () => {
                const res = await axios.post(
                    url,
                    {},
                    {
                        headers: { Authorization: `Bearer ${user.token}` },
                        responseType: "blob"
                    }
                );
                const mimeType =
                    reportId !== undefined
                        ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        : "application/zip";
                const blob = new Blob([res.data], {
                    type: mimeType
                });
                const fileName = reportName
                    ? `event-${eventId}-episode-${episodeId}-${reportName}`
                    : `event-${eventId}-episode-${episodeId}-all-reports`;
                saveAs(blob, fileName);
            },
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error)
        });
        setIsLoadingDownload(false);
    };

    const handleGenerateReports = async (reportId?: number) => {
        const message = buildTranslation(t, {
            subject: translations.subject.reportGeneration,
            method: translations.request.general
        });

        await performMutation({
            mutation: async () =>
                reportId
                    ? await generateReports({
                          eventId,
                          episodeId,
                          reportId
                      }).unwrap()
                    : await generateReports({
                          eventId,
                          episodeId
                      }).unwrap(),
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error)
        });
    };

    const areReportsAvailable = (reports?.common?.length || 0) > 0;

    const error = errorEvent || errorReports;
    const loading =
        isUninitializedReports ||
        isUninitializedEvent ||
        isLoadingEvent ||
        isFetchingEvent ||
        isLoadingReports ||
        isLoadingDownload ||
        isLoadingGenerateReports ||
        isFetchingReports;

    if (error) return <Error message={t("generic-error-message")} />;
    return (
        <Spin spinning={loading} className="page-spinner">
            <EpisodeSelector
                redirectionSubpath="reports"
                defaultEpisodeId={event?.default_episode.id}
                episodes={event?.episodes}
            />
            <PageTitle
                icon={<BarChartOutlined />}
                title={t("reports-page-title")}
                additionalContent={
                    <Space direction="horizontal">
                        <Button onClick={() => handleGenerateReports()}>
                            <ReloadOutlined />
                            {areReportsAvailable ? t("regenerate-reports") : t("generate-reports")}
                        </Button>
                        <Button
                            disabled={!areReportsAvailable}
                            type="primary"
                            onClick={() => handleDownloadFile()}
                        >
                            <DownloadOutlined />
                            {t("download-all")}
                        </Button>
                    </Space>
                }
            />
            {reports && (
                <DoubleColumnLayout
                    firstColumn={
                        <>
                            <List
                                dataSource={reports.common}
                                bordered
                                renderItem={(item) => {
                                    return (
                                        <List.Item
                                            key={item.basename}
                                            actions={[
                                                <Button
                                                    onClick={() => handleGenerateReports(item.id)}
                                                    icon={<ReloadOutlined />}
                                                    key="refresh-single"
                                                >
                                                    {t("refresh-report")}
                                                </Button>,
                                                <Button
                                                    onClick={() => handleDownloadFile(item.id, item.basename)}
                                                    icon={<DownloadOutlined />}
                                                    key="download-single"
                                                >
                                                    {t("download")}
                                                </Button>
                                            ]}
                                        >
                                            <List.Item.Meta
                                                title={item.basename}
                                                description={
                                                    <Space direction="horizontal">
                                                        <Typography.Text type="secondary">
                                                            {t("created-at")}:{" "}
                                                            {moment(item.created_at).format(
                                                                "YYYY-MM-DD HH:mm"
                                                            )}
                                                        </Typography.Text>
                                                        <Typography.Text type="secondary">
                                                            {t("updated-at")}:{" "}
                                                            {moment(item.updated_at).format(
                                                                "YYYY-MM-DD HH:mm"
                                                            )}
                                                        </Typography.Text>
                                                    </Space>
                                                }
                                            />
                                            <Tag color="blue">{item.report_type}</Tag>
                                        </List.Item>
                                    );
                                }}
                            />
                        </>
                    }
                />
            )}
        </Spin>
    );
};

export default EventReports;
