export const mediaGallery = [
    {
        name: "favicon",
        label: "favicon",
        description: "favicon-description",
        category: "website"
    },
    {
        name: "header-logo-desktop",
        label: "header-logo-desktop",
        description: "header-logo-desktop-description",
        category: "website"
    },
    {
        name: "popup-logo-desktop",
        label: "popup-logo-desktop",
        description: "popup-logo-desktop-description",
        category: "website"
    },
    {
        name: "registration-form-logo",
        label: "registration-form-logo",
        description: "registration-form-logo-description",
        category: "website"
    },
    {
        name: "footer-logo-desktop",
        label: "footer-logo-desktop",
        description: "footer-logo-desktop-description",
        category: "website"
    },
    {
        name: "header-logo-mobile",
        label: "header-logo-mobile",
        description: "header-logo-mobile-description",
        category: "website"
    },
    {
        name: "header-background-mobile",
        label: "header-background-mobile",
        description: "header-background-mobile-description",
        category: "website"
    },
    {
        name: "footer-background-mobile",
        label: "footer-background-mobile",
        description: "footer-background-mobile-description",
        category: "website"
    }
];
