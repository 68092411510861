export const suffixes = [
    "Bg_color",
    "Title_color",
    "Text_color",
    "Font_size",
    "Text_weight",
    "Input_color",
    "Button_color",
    "Font_weight",
    "Border_radius",
    "Subtitle_color",
    "Subtitle_weight",
    "Title_weight",
    "Textarea_color",
    "TextareaBorder_color",
    "Label_color",
    "Border_color",
    "Hover_color",
    "Disable_color",
    "Active_color",
    "Select_color",
    "Title_size",
    "Subtitle_size"
];

export const getSpecificStylesLabel = ({ label, t }: { label: string; t: Function }) => {
    let subject = null;
    let matchingSuffix = null;

    suffixes.forEach((suffix) => {
        if (label.endsWith(suffix)) {
            subject = label.replaceAll(suffix, "");
            matchingSuffix = suffix;
        }
    });

    if (subject === null) return t(label);
    else return `${t(`styles:${matchingSuffix}`)}${t("styles:of-the")}${t(`styles:${subject}`)}`;
};
