import { clearCompleteLoginErrors, clearLoginErrors, selectUser } from "@store/user";
import { useDispatch, useSelector } from "@store/hooks";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const useResetLoginErrors = ({ loginForm, twoFAForm }: { loginForm: any; twoFAForm: any }) => {
    const {
        loggedStatus: { error: loggedError },
        completeLoginStatus: { loading: loadingCompleteLogin, error: errorCompleteLogin },
        loginStatus: { error: loginError, loading: loginLoading }
    } = useSelector(selectUser);

    const dispatch = useDispatch();
    const { t } = useTranslation(["translations"]);

    useEffect(() => {
        if (loginError && loginError?.code !== 461) {
            loginForm.setFields([
                {
                    name: "email",
                    errors: [""]
                },
                {
                    name: "password",
                    errors: [loginError.status === 422 ? t("unauthorized") : t("generic-error-message")]
                }
            ]);
            dispatch(clearLoginErrors());
        }
    }, [loginError, loginForm, dispatch]);

    useEffect(() => {
        if (errorCompleteLogin) {
            twoFAForm.setFields([
                {
                    name: "one_time_password",
                    errors: [
                        errorCompleteLogin.status === 422 ? t("unauthorized") : t("generic-error-message")
                    ]
                }
            ]);
            dispatch(clearCompleteLoginErrors());
        }
    }, [errorCompleteLogin, twoFAForm, dispatch]);
};
