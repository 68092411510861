import { Button, Popconfirm, Space, Tag } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { ModeratorType } from "@store/admin/types";

interface AdminProfilesTableColumnsArgs {
    t: Function;
    goToAdminUser: Function;
    handleCUDAdminUser: Function;
}

export const adminProfilesTableColumns = ({
    t,
    goToAdminUser,
    handleCUDAdminUser
}: AdminProfilesTableColumnsArgs) => [
    {
        title: t("id"),
        dataIndex: "id",
        key: "id"
    },
    {
        title: t("email"),
        dataIndex: "email",
        key: "email",
        render: (email: string, row: ModeratorType) => {
            return (
                <Button type="text" data-testid="episode-title-button" onClick={() => goToAdminUser(row.id)}>
                    {email}
                </Button>
            );
        }
    },
    {
        title: t("role"),
        dataIndex: "role",
        key: "role",
        render: (_: string, row: ModeratorType) => {
            return row.is_chairman ? <Tag>{t("chairman")}</Tag> : <Tag>{t("moderator")}</Tag>;
        }
    },
    {
        title: t("actions"),
        dataIndex: "actions",
        key: "actions",
        width: 110,
        render: (_: any, row: ModeratorType) => {
            return (
                <Space direction="vertical">
                    <Space direction="horizontal">
                        <Button
                            icon={<EditOutlined />}
                            data-testid="edit-evaluation-form-question"
                            onClick={() => goToAdminUser(row.id)}
                        />
                        <Popconfirm
                            title={t("delete-item-warning-text")}
                            okText={t("delete")}
                            data-testid="delete-evaluation-form-question-confirm"
                            cancelText={t("cancel")}
                            onConfirm={() => handleCUDAdminUser("delete", row.id)()}
                        >
                            <Button
                                icon={<DeleteOutlined />}
                                data-testid="delete-evaluation-form-question"
                                title={t("delete-question")}
                            />
                        </Popconfirm>
                    </Space>
                </Space>
            );
        }
    }
];
