import { useCallback, useEffect, useState } from "react";

import { FormInstance } from "antd";
import { debounce } from "lodash";
import { useCheckEventSlugMutation } from "@store/events";

export const useCheckEventSlug = ({
    formInstance,
    originalSlug,
    eventId
}: {
    formInstance: FormInstance;
    originalSlug: string | null;
    eventId: string;
}) => {
    const [checkEventSlug] = useCheckEventSlugMutation();
    const [validSlug, setValidSlug] = useState<string | null>(null);
    const [slugToValidate, setSlugToValidate] = useState<string | null>(null);
    const [domain, setDomain] = useState<string | null>(null);

    useEffect(() => {
        performValidationDebounced(slugToValidate, eventId, domain);
    }, [slugToValidate, domain, eventId]);

    const performValidation = async (
        slugToValidate: string | null,
        eventId: string,
        domain: string | null
    ) => {
        if (slugToValidate === null || eventId === null || domain === null) {
            setValidSlug(null);
            return;
        }
        try {
            await checkEventSlug({
                slug: slugToValidate,
                eventId,
                domain: domain
            }).unwrap();
            setValidSlug(slugToValidate);
            formInstance.setFields([
                {
                    name: "slug",
                    errors: []
                }
            ]);
        } catch (error: any) {
            setValidSlug(null);
            const slugErrors = error?.data?.errors?.slug;
            formInstance.setFields([
                {
                    name: "slug",
                    errors: slugErrors
                }
            ]);
        }
    };

    const performValidationDebounced = useCallback(debounce(performValidation, 500), []);

    useEffect(() => {
        if (originalSlug) setValidSlug(originalSlug);
    }, [originalSlug]);

    const slugToValidateSetter = (slug: string, domain: string) => {
        setDomain(domain);
        setSlugToValidate(slug);
    };

    const resetSlug = (slug: string | null) => setSlugToValidate(slug);

    return {
        validSlug,
        setSlugForValidation: slugToValidateSetter,
        resetSlug
    };
};
