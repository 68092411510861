import React, { useMemo } from "react";
import { listCircleOutline, micCircleOutline, peopleCircleOutline } from "ionicons/icons";
import { selectIsProfileAdmin, selectUser } from "@store/user";
import { useLocation, useNavigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { Menu } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import { useSelector } from "@store/hooks";
import { useTranslation } from "react-i18next";

const TopMenu: React.FC = () => {
    const { t } = useTranslation(["translations"]);
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector(selectUser);
    const isProfileAdmin = useSelector(selectIsProfileAdmin);

    const menuItems = useMemo(() => {
        const menuItems = [
            {
                label: t("events"),
                key: "events",
                icon: <IonIcon size="small" icon={peopleCircleOutline} />,
                onClick: () => navigate("/events")
            },
            {
                label: isProfileAdmin ? t("profile") : t("profiles"),
                key: "profiles",
                icon: <UserAddOutlined />,
                onClick: () =>
                    isProfileAdmin
                        ? navigate(`/profiles/${user.identity?.data.profile.id}`)
                        : navigate("/profiles")
            },
            {
                label: "Speakers",
                key: "speakers",
                icon: <IonIcon size="small" icon={micCircleOutline} />,
                onClick: () => navigate("/speakers")
            }
        ];
        if (user?.identity?.data.is_superadmin) {
            menuItems.push({
                label: "Logs",
                key: "logs",
                icon: <IonIcon size="small" icon={listCircleOutline} />,
                onClick: () => navigate("/logs")
            });
        }
        return menuItems;
    }, []);

    const activeMenuItem = menuItems.find(
        (menuItem) => menuItem.key === location.pathname.split(/[/-]/)[1]
    )?.key;
    const activeItems = activeMenuItem ? [activeMenuItem] : undefined;

    return <Menu style={{ flex: 1 }} selectedKeys={activeItems} mode="horizontal" items={menuItems} />;
};

export default TopMenu;
