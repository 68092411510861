import { Space, Typography, theme } from "antd";

import React from "react";
import styles from "./PageTitle.module.scss";

interface PageTitleProps {
    icon?: React.ReactNode;
    title: React.ReactNode;
    subtitle?: React.ReactNode;
    level?: 1 | 2 | 3 | 4 | 5 | undefined;
    style?: {
        [key: string]: any;
    };
    children?: React.ReactNode;
    hideContents?: boolean;
    additionalContent?: React.ReactNode;
}

const PageTitle: React.FC<PageTitleProps> = ({
    icon,
    title,
    subtitle,
    level,
    children,
    style,
    hideContents,
    additionalContent
}) => {
    const {
        token: { colorBgContainer }
    } = theme.useToken();

    return (
        <div
            className={styles["page-title"]}
            style={{
                background: colorBgContainer,
                visibility: hideContents ? "hidden" : "inherit",
                ...style
            }}
        >
            <div className={styles["page-title__container"]}>
                <Typography.Title level={level || 1} className={styles["page-title__title"]}>
                    {icon ? (
                        <Space direction="horizontal">
                            <div className={styles["page-title__icon-container"]}>{icon}</div>
                            {title}
                        </Space>
                    ) : (
                        <>{title}</>
                    )}
                </Typography.Title>
                {subtitle && (
                    <Typography.Title level={5} type="secondary" className={styles["page-title__subtitle"]}>
                        {subtitle}
                    </Typography.Title>
                )}
            </div>
            {children && <>{children}</>}
            {additionalContent && (
                <div className={styles["page-title__additional-content-container"]}>{additionalContent}</div>
            )}
        </div>
    );
};

export default PageTitle;
