import {
    Alert,
    Form as AntdForm,
    Button,
    Divider,
    List,
    Modal,
    Space,
    Spin,
    Switch,
    Tabs,
    Tag,
    Typography
} from "antd";
import { BulbOutlined, CalendarOutlined, CopyOutlined, DiffOutlined, PlusOutlined } from "@ant-design/icons";
import { agendaDetailsFormInputs, getAgendaApiValues } from "@utils/forms/agenda/agendaDetails";
import { buildTranslation, translations } from "@translations/crudTranslationBuilder";
import {
    useAmendAgendaMediaTitleMutation,
    useAmendAgendaMediasMutation,
    useCreateAgendaMutation,
    useDeleteAgendaMutation,
    useGetAgendasQuery,
    useGetEventQuery,
    useGetMediaQuery,
    useGetSpeakersQuery,
    useSaveAttachmentMutation,
    useUpdateAgendaMutation,
    useUpdateMediaStatusMutation
} from "@store/events";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ActionsBanner from "@components/ActionsBanner/ActionsBanner";
import AgendaItem from "@components/AgendaItem/AgendaItem";
import { AgendaType } from "@store/events/types";
import DoubleColumnLayout from "@components/ColumnLayout/ColumnLayout";
import EpisodeSelector from "@components/EpisodeSelector/EpisodeSelector";
import Error from "@components/Error";
import FileUpload from "@components/FileUpload";
import Form from "@components/Form";
import { MediaFileType } from "@store/events/types";
import MediaListItem from "@components/MediaListItem/MediaListItem";
import PageTitle from "@components/PageTitle/PageTitle";
import StickToTop from "@components/StickToTop/StickToTop";
import { getAgendaFormValues } from "@utils/forms/agenda/agendaDetails";
import { getHasRequiredInfo } from "@utils/general";
import { useGetLanguagesQuery } from "@store/internationalization";
import { usePerformMutation } from "@utils/perform-mutation";
import { useRedirectEventsHome } from "@hooks/useRedirectEventsHome";
import { useSideMenu } from "@hooks/useSideMenu";
import { useTranslation } from "react-i18next";

export enum AttachmentModal {
    null = "null",
    new = "info",
    gallery = "error"
}

const EventAgenda = () => {
    const isVisibleSuffix = "-is_visible";
    const isAvailableSuffix = "-is_available";
    const { eventId, episodeId, agendaId, parentAgendaId } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation(["translations"]);
    useSideMenu({ isVisible: true, contentType: "event" });
    const [form] = Form.useForm();
    const [mediaAvailablityForm] = AntdForm.useForm();
    const [attachmentTabActive, setAttachmentTabActive] = useState<boolean>(false);
    const [isMediaModalOpen, setIsMediaModalOpen] = useState<boolean>(false);
    const [mediaPage, setMediaPage] = useState<number>(1);
    const [attachmentModal, setAttachmentModal] = useState<{
        mode: AttachmentModal;
        language: null | string;
    }>({
        mode: AttachmentModal.null,
        language: null
    });
    const addingNewAgendaItem = agendaId === "add-new-agenda-item";
    const { performMutation } = usePerformMutation();

    const [agendaPage, setAgendaPage] = useState<number>(1);

    const {
        data: languages,
        isLoading: isLoadingLanguages,
        isFetching: isFetchingLanguages,
        error: errorLanguages,
        isUninitialized: isUninitializedLanguages
    } = useGetLanguagesQuery(null);

    const {
        data: event,
        isLoading: isLoadingEvent,
        isFetching: isFetchingEvent,
        error: errorEvent,
        isUninitialized: isUninitializedEvent
    } = useGetEventQuery({ eventId: eventId! }, { skip: !!!eventId });

    const {
        data: speakersData,
        isLoading: isLoadingSpeakersData,
        isFetching: isFetchingSpeakersData,
        error: errorSpeakersData,
        isUninitialized: isUninitializedSpeakersData
    } = useGetSpeakersQuery({ searchParam: "", paginate: true });

    const {
        data: mediaData,
        isLoading: isLoadingMediaData,
        isFetching: isFetchingMediaData,
        error: errorMediaData,
        isUninitialized: isUninitializedMediaData
    } = useGetMediaQuery({ eventId: eventId!, page: mediaPage });

    const {
        data: agendaData,
        isLoading: isLoadingAgendaData,
        isFetching: isFetchingAgendaData,
        error: errorAgendaData,
        isUninitialized: isUninitializedAgendaData
    } = useGetAgendasQuery(
        { event: eventId!, episode: episodeId!, page: agendaPage },
        { skip: !!!eventId || !!!episodeId }
    );

    const { hasEventRequiredInfo, isLoadingRequiredInfo } = getHasRequiredInfo(event);

    useRedirectEventsHome({
        hasInfo: hasEventRequiredInfo,
        isLoading: isLoadingRequiredInfo,
        eventId
    });

    const [updateAgenda, { isLoading: isLoadingUpdateAgenda }] = useUpdateAgendaMutation();
    const [createAgenda, { isLoading: isLoadingCreateAgenda }] = useCreateAgendaMutation();
    const [deleteAgenda, { isLoading: isLoadingDeleteAgenda }] = useDeleteAgendaMutation();

    const [amendAgendaMedia, { isLoading: isLoadingAmendAgendaMedia }] = useAmendAgendaMediasMutation();
    const [saveAttachment, { isLoading: isLoadingSaveAttachment }] = useSaveAttachmentMutation();
    const [updateMediaStatus, { isLoading: isLoadingUpdateMediaStatus }] = useUpdateMediaStatusMutation();
    const [amendAgendaMediaTitle, { isLoading: isLoadingAmendAgendaMediaTitle }] =
        useAmendAgendaMediaTitleMutation();

    const selectedAgendaItem = useMemo(() => {
        if (!agendaId || !agendaData?.data) return undefined;
        let agendaItem = agendaData.data.find((ag: AgendaType) => String(ag.id) === agendaId);
        if (agendaItem) return agendaItem;
        for (let i = 0; i < agendaData.data.length; i++) {
            if (!agendaItem) {
                agendaItem = agendaData.data[i].children.find((agIt: AgendaType) => {
                    return String(agIt.id) === agendaId;
                });
            }
        }
        return agendaItem;
    }, [agendaData?.data, agendaId]);

    useEffect(() => {
        if (addingNewAgendaItem) form.resetFields();
        else if (selectedAgendaItem && event && form) {
            form.resetFields();
            form.setFieldsValue(getAgendaFormValues(selectedAgendaItem, event?.timezone));
        }
    }, [selectedAgendaItem, addingNewAgendaItem, form, event]);

    const handleCUDAgenda =
        (method: "post" | "put" | "delete", agendaItemId?: string | number) => async (values: any) => {
            const message = buildTranslation(t, {
                subject: translations.subject.agenda,
                method: translations.request?.[method]
            });
            await performMutation({
                mutation: async () => {
                    method === "post"
                        ? await createAgenda({
                              event: eventId,
                              episode_id: parseInt(episodeId!),
                              ...getAgendaApiValues(values, event?.timezone),
                              agenda_id: parentAgendaId ? parseInt(parentAgendaId) : null
                          }).unwrap()
                        : method === "put"
                        ? await updateAgenda({
                              event: eventId,
                              agenda: selectedAgendaItem?.id,
                              ...getAgendaApiValues(values, event?.timezone)
                          }).unwrap()
                        : await deleteAgenda({
                              event: eventId,
                              agenda: agendaItemId
                          }).unwrap();
                },
                successMessage: message(translations.outcome.success),
                errorMessage: message(translations.outcome.error),
                onSuccessCallback: () => navigate(`/events/${eventId}/agenda/${episodeId}`)
            });
        };

    const handleAgendaMedia = async ({ attach, media, title, langId }: any) => {
        const messageModifyer = (attach ? "POST" : "DELETE").toLowerCase();
        await performMutation({
            mutation: async () => {
                if (selectedAgendaItem?.id) {
                    await amendAgendaMedia({
                        attach,
                        event: eventId,
                        media: media,
                        model: "agenda",
                        params: {
                            id: selectedAgendaItem.id
                        },
                        pivot: {
                            title: title,
                            lang_id: langId || attachmentModal.language
                        }
                    }).unwrap();
                }
            },
            successMessage: t(`agenda-media-${messageModifyer}-success`),
            errorMessage: t(`agenda-media-${messageModifyer}-error`)
        });
        setAttachmentModal({ mode: AttachmentModal.null, language: null });
    };

    const handleSuccessFileUpload = () => {
        setAttachmentModal((ps) => ({
            mode: AttachmentModal.gallery,
            language: ps.language
        }));
    };

    const handleSaveAttachment = async (attachmentInfo: any) => {
        await performMutation({
            mutation: async () => {
                await saveAttachment({
                    event_id: eventId,
                    ...attachmentInfo
                }).unwrap();
            },
            successMessage: t("file-upload-success"),
            errorMessage: t("file-upload-error"),
            onSuccessCallback: () => {
                setAttachmentModal((ps) => ({
                    mode: AttachmentModal.gallery,
                    language: ps.language
                }));
            },
            onErrorCallback: () => {
                setAttachmentModal((ps) => ({
                    mode: AttachmentModal.null,
                    language: null
                }));
            }
        });
    };

    const handleAmendAgendaMediaTitle = async ({ media, title }: any) => {
        const message = buildTranslation(t, {
            subject: translations.subject.agendaMediaTitle,
            method: translations.request.put
        });
        await performMutation({
            mutation: async () => {
                await amendAgendaMediaTitle({
                    media,
                    title
                }).unwrap();
            },
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error)
        });
    };

    useEffect(() => {
        if (selectedAgendaItem && mediaAvailablityForm) {
            selectedAgendaItem?.media.forEach((mediaItem) => {
                mediaAvailablityForm.setFieldValue(
                    mediaItem.pivot.id + isVisibleSuffix,
                    mediaItem.pivot.is_visible
                );
                mediaAvailablityForm.setFieldValue(
                    mediaItem.pivot.id + isAvailableSuffix,
                    mediaItem.pivot.is_available
                );
            });
        }
    }, [selectedAgendaItem, mediaAvailablityForm, isMediaModalOpen]);

    const handleUpdateMediaStatus = async (values: any) => {
        const message = buildTranslation(t, {
            subject: translations.subject.mediaAvailability,
            method: translations.request?.general
        });

        const uniqueIds = Object.keys(values)
            .map((value) => value.split("-")[0])
            .filter((value, index, array) => array.indexOf(value) === index);

        const parsedValues = uniqueIds.map((uniqueId) => {
            return {
                id: parseInt(uniqueId),
                is_visible: values[uniqueId + isVisibleSuffix] as 0 | 1,
                is_available: values[uniqueId + isAvailableSuffix] as 0 | 1
            };
        });

        await performMutation({
            mutation: async () =>
                await updateMediaStatus({
                    mediaStatus: parsedValues
                }).unwrap(),
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error),
            onSuccessCallback: () => setIsMediaModalOpen(false),
            onErrorCallback: () => setIsMediaModalOpen(false)
        });
    };

    const error = errorLanguages || errorEvent || errorAgendaData || errorMediaData || errorSpeakersData;

    const loading =
        isUninitializedLanguages ||
        isUninitializedAgendaData ||
        isUninitializedEvent ||
        isUninitializedMediaData ||
        isUninitializedSpeakersData ||
        isLoadingEvent ||
        isFetchingEvent ||
        isLoadingUpdateMediaStatus ||
        isLoadingAgendaData ||
        isFetchingAgendaData ||
        isLoadingAmendAgendaMediaTitle ||
        isLoadingUpdateAgenda ||
        isLoadingCreateAgenda ||
        isLoadingDeleteAgenda ||
        isLoadingLanguages ||
        isFetchingLanguages ||
        isLoadingSpeakersData ||
        isFetchingSpeakersData;

    if (error) return <Error message={t("generic-error-message")} />;
    return (
        <Spin spinning={loading} className="page-spinner">
            <EpisodeSelector
                redirectionSubpath="agenda"
                defaultEpisodeId={event?.default_episode.id}
                episodes={event?.episodes}
            />
            <PageTitle icon={<CalendarOutlined />} title={t("agenda-page-title")} />
            <DoubleColumnLayout
                firstColumn={
                    episodeId && agendaData ? (
                        <>
                            <StickToTop
                                title={<Typography.Title level={4}>{t("agenda-list")}</Typography.Title>}
                                actions={
                                    <Button
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        disabled={addingNewAgendaItem && !parentAgendaId}
                                        onClick={() =>
                                            navigate(
                                                `/events/${eventId}/agenda/${episodeId}/add-new-agenda-item`
                                            )
                                        }
                                        data-testid="add-new-agenda-item-button"
                                    >
                                        {t("add-new-agenda-item")}
                                    </Button>
                                }
                            ></StickToTop>
                            <List
                                itemLayout="vertical"
                                size="large"
                                bordered
                                pagination={{
                                    position: "bottom",
                                    current: agendaPage,
                                    onChange: (pageNumber) => setAgendaPage(pageNumber),
                                    pageSize: agendaData?.pagination.perPage,
                                    total: agendaData?.pagination.total
                                }}
                                locale={{ emptyText: t("list-is-empty") }}
                                dataSource={agendaData.data}
                                renderItem={(agendaItem) => (
                                    <AgendaItem
                                        agendaItem={agendaItem}
                                        eventData={event}
                                        deleteAgenda={handleCUDAgenda}
                                        key={agendaItem.id}
                                    />
                                )}
                            />
                        </>
                    ) : null
                }
                secondColumn={
                    agendaId || addingNewAgendaItem ? (
                        <>
                            <StickToTop
                                title={
                                    <Typography.Title level={4} data-testid="selected-agenda-item-title">
                                        {addingNewAgendaItem
                                            ? // parentAgendaId ? (
                                              //     <Space direction="horizontal">
                                              //         {t("add-new-nested-agenda-item")}{" "}
                                              //         <Tag>
                                              //             {t("parent-agenda-item")}:{" "}
                                              //             {
                                              //                 agendaData?.find(
                                              //                     (ag) => String(ag.id) === parentAgendaId
                                              //                 )?.title_default
                                              //             }
                                              //         </Tag>
                                              //     </Space>
                                              // ) : (
                                              t("add-new-agenda-item")
                                            : // )
                                              selectedAgendaItem?.title_default}
                                    </Typography.Title>
                                }
                            ></StickToTop>
                            <Tabs
                                defaultActiveKey="form"
                                type="card"
                                onChange={(key: string) => {
                                    if (key === "attachments") setAttachmentTabActive(true);
                                    else setAttachmentTabActive(false);
                                }}
                                items={[
                                    {
                                        label: t("general-information"),
                                        key: "form",
                                        children: (
                                            <Form
                                                items={agendaDetailsFormInputs({
                                                    t,
                                                    eventTimezone: event?.timezone,
                                                    speakers: speakersData?.data || []
                                                })}
                                                languageSpecificLabel={t("language-specific-information")}
                                                form={form}
                                                onSubmit={
                                                    addingNewAgendaItem
                                                        ? handleCUDAgenda("post")
                                                        : handleCUDAgenda("put")
                                                }
                                                defaultlanguage={event?.default_lang}
                                                languages={event?.languages}
                                                languageReferences={languages}
                                            ></Form>
                                        )
                                    },
                                    {
                                        label: t("attachments"),
                                        key: "attachments",
                                        children: addingNewAgendaItem ? (
                                            <Alert
                                                message={t("save-agenda-item-berfore-attachment")}
                                                data-testid="save-agenda-item-berfore-attachment-alert"
                                                icon={<BulbOutlined />}
                                                showIcon
                                            />
                                        ) : (
                                            <>
                                                <Button
                                                    onClick={() => setIsMediaModalOpen(true)}
                                                    icon={<DiffOutlined />}
                                                    disabled={
                                                        !(
                                                            selectedAgendaItem?.media &&
                                                            selectedAgendaItem?.media.length > 0
                                                        )
                                                    }
                                                >
                                                    {t("change-media-availability")}
                                                </Button>
                                                <Divider />
                                                <Tabs
                                                    defaultActiveKey="form"
                                                    items={event?.languages.map((lang: string) => {
                                                        return {
                                                            label: lang.toUpperCase(),
                                                            key: lang,
                                                            children: (
                                                                <>
                                                                    <List
                                                                        locale={{
                                                                            emptyText: t("list-is-empty")
                                                                        }}
                                                                        itemLayout="horizontal"
                                                                        dataSource={
                                                                            selectedAgendaItem?.media?.filter(
                                                                                (mediaItem) => {
                                                                                    return (
                                                                                        mediaItem.pivot
                                                                                            .lang_id === lang
                                                                                    );
                                                                                }
                                                                            ) || []
                                                                        }
                                                                        renderItem={(item) => {
                                                                            return (
                                                                                <MediaListItem
                                                                                    attach={false}
                                                                                    mediaItem={item}
                                                                                    pivot={item.pivot}
                                                                                    onEditNameClick={
                                                                                        handleAmendAgendaMediaTitle
                                                                                    }
                                                                                    onActionClick={() =>
                                                                                        handleAgendaMedia({
                                                                                            method: "DELETE",
                                                                                            media: item.id,
                                                                                            title: item.pivot
                                                                                                .title,
                                                                                            langId: lang
                                                                                        })
                                                                                    }
                                                                                />
                                                                            );
                                                                        }}
                                                                    />
                                                                    <Divider>{t("attach-files")}</Divider>
                                                                    <Space direction="horizontal">
                                                                        <Button
                                                                            type="primary"
                                                                            icon={<PlusOutlined />}
                                                                            onClick={() =>
                                                                                setAttachmentModal({
                                                                                    mode: AttachmentModal.new,
                                                                                    language: lang
                                                                                })
                                                                            }
                                                                        >
                                                                            {t("add-new-file")}
                                                                        </Button>
                                                                        <Button
                                                                            type="primary"
                                                                            icon={<CopyOutlined />}
                                                                            onClick={() =>
                                                                                setAttachmentModal({
                                                                                    mode: AttachmentModal.gallery,
                                                                                    language: lang
                                                                                })
                                                                            }
                                                                        >
                                                                            {t("add-from-existing-files")}
                                                                        </Button>
                                                                    </Space>
                                                                </>
                                                            )
                                                        };
                                                    })}
                                                />
                                            </>
                                        )
                                    }
                                ]}
                            />
                        </>
                    ) : episodeId && agendaData ? (
                        <>
                            <StickToTop
                                title={
                                    <Typography.Title level={4}>{t("agenda-item-details")}</Typography.Title>
                                }
                            />
                            <Alert
                                style={{ marginTop: "9px", padding: "7px 12px" }}
                                message={t("select-agenda-item-to-edit")}
                                data-testid="no-agenda-item-selected-alert"
                                showIcon
                                icon={<BulbOutlined />}
                                type="info"
                            ></Alert>
                        </>
                    ) : null
                }
                secondColumnBanner={
                    (selectedAgendaItem || addingNewAgendaItem) &&
                    !attachmentTabActive && (
                        <ActionsBanner>
                            <Space direction="horizontal" align="end">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={false}
                                    size="large"
                                    onClick={form.submit}
                                    data-testid="agenda-item-save-button"
                                >
                                    {addingNewAgendaItem
                                        ? t("save-new-agenda-item")
                                        : t("update-agenda-item")}
                                </Button>
                            </Space>
                        </ActionsBanner>
                    )
                }
            />
            <Modal
                title={attachmentModal.mode === AttachmentModal.new ? t("add-file") : t("attach-file")}
                width={"60%"}
                open={attachmentModal.mode !== AttachmentModal.null}
                maskClosable={false}
                onCancel={() => setAttachmentModal({ mode: AttachmentModal.null, language: null })}
                footer={null}
            >
                <Spin spinning={isLoadingAmendAgendaMedia || isLoadingSaveAttachment}>
                    <Tabs
                        centered={true}
                        type="card"
                        activeKey={attachmentModal.mode}
                        onChange={(key) =>
                            setAttachmentModal((ps) => ({
                                mode: key as AttachmentModal.gallery | AttachmentModal.new,
                                language: ps.language
                            }))
                        }
                        items={[
                            {
                                label: t("add-new-file-to-gallery"),
                                key: AttachmentModal.new,
                                children: (
                                    <FileUpload
                                        onSaveAttachment={handleSaveAttachment}
                                        onSuccessUpload={handleSuccessFileUpload}
                                        mimeTypes={[
                                            "image/jpeg",
                                            "image/png",
                                            "image/webp",
                                            "application/pdf"
                                        ].join(", ")}
                                    />
                                )
                            },
                            {
                                label: t("attach-file-from-gallery"),
                                key: AttachmentModal.gallery,
                                children: (
                                    <List
                                        locale={{ emptyText: t("list-is-empty") }}
                                        pagination={{
                                            current: mediaPage,
                                            onChange: (pageNumber) => setMediaPage(pageNumber),
                                            pageSize: mediaData?.pagination.perPage,
                                            total: mediaData?.pagination.total
                                        }}
                                        dataSource={mediaData?.data || []}
                                        renderItem={(item: MediaFileType) => (
                                            <MediaListItem
                                                mediaItem={item}
                                                attach={true}
                                                onActionClick={(title: string) =>
                                                    handleAgendaMedia({
                                                        attach: true,
                                                        event: eventId,
                                                        media: item.id,
                                                        title: title
                                                    })
                                                }
                                            />
                                        )}
                                    />
                                )
                            }
                        ]}
                    />
                </Spin>
            </Modal>
            {/* Media availability modal */}
            <Modal
                title={t("change-media-availability")}
                width={"60%"}
                open={isMediaModalOpen}
                maskClosable={false}
                forceRender={true}
                confirmLoading={isLoadingUpdateMediaStatus}
                onOk={mediaAvailablityForm.submit}
                onCancel={() => setIsMediaModalOpen(false)}
            >
                <Spin spinning={isLoadingUpdateMediaStatus || isLoadingMediaData || isFetchingMediaData}>
                    {selectedAgendaItem?.media && selectedAgendaItem?.media.length > 0 && (
                        <AntdForm
                            layout="horizontal"
                            labelWrap
                            scrollToFirstError
                            form={mediaAvailablityForm}
                            onFinish={handleUpdateMediaStatus}
                        >
                            <List
                                bordered
                                locale={{
                                    emptyText: t("list-is-empty")
                                }}
                                style={{ marginTop: 10 }}
                                itemLayout="horizontal"
                                dataSource={selectedAgendaItem?.media || []}
                                renderItem={(item) => {
                                    return (
                                        <List.Item
                                            actions={[
                                                <Space direction="vertical">
                                                    <AntdForm.Item
                                                        name={item.pivot.id + isVisibleSuffix}
                                                        label={t("is-visible")}
                                                        style={{ marginBottom: 5 }}
                                                        valuePropName="checked"
                                                    >
                                                        <Switch
                                                            checkedChildren={t("yes")}
                                                            unCheckedChildren={t("no")}
                                                        />
                                                    </AntdForm.Item>
                                                    <AntdForm.Item
                                                        name={item.pivot.id + isAvailableSuffix}
                                                        label={t("is-downloadable")}
                                                        style={{ marginBottom: 5 }}
                                                        valuePropName="checked"
                                                    >
                                                        <Switch
                                                            checkedChildren={t("yes")}
                                                            unCheckedChildren={t("no")}
                                                        />
                                                    </AntdForm.Item>
                                                </Space>
                                            ]}
                                        >
                                            <List.Item.Meta
                                                avatar={
                                                    <img src={item.url} style={{ width: "300px" }} alt="" />
                                                }
                                                title={item.pivot.title}
                                                description={
                                                    <Tag>
                                                        {t("language")}:{" "}
                                                        {t(`languages:${item.pivot.lang_id}`)}
                                                    </Tag>
                                                }
                                            />
                                        </List.Item>
                                    );
                                }}
                            />
                        </AntdForm>
                    )}
                </Spin>
            </Modal>
        </Spin>
    );
};

export default EventAgenda;
