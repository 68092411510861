import { Alert, Button, Divider, Space, Spin, Typography } from "antd";
import { BulbOutlined, PlusOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { buildTranslation, translations } from "@translations/crudTranslationBuilder";
import {
    useCreateUserMutation,
    useDeleteUserMutation,
    useGetProfileQuery,
    useUpdateUserMutation
} from "@store/profile";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ActionsBanner from "@components/ActionsBanner/ActionsBanner";
import ColumnLayout from "@components/ColumnLayout/ColumnLayout";
import Error from "@components/Error";
import { Error422 } from "@store/index";
import Form from "@components/Form";
import { FormInstance } from "antd/lib";
import PageTitle from "@components/PageTitle/PageTitle";
import { ProfileUserType } from "@store/profile/types";
import SearchableTable from "@components/SearchableTable/SearchableTable";
import StickToTop from "@components/StickToTop/StickToTop";
import { mapErrorsToFields } from "@utils/mapErrorsToFields";
import { useGetAssignableUserRolesQuery } from "@store/admin";
import { useGetProfileUsersQuery } from "@store/profile";
import { usePerformMutation } from "@utils/perform-mutation";
import { useSideMenu } from "@hooks/useSideMenu";
import { useTranslation } from "react-i18next";
import { userFormInputs } from "@utils/forms/profiles/userFormInputs";
import { usersTableColumns } from "@utils/layout/profiles/profileUsersTableColumns";

const ProfileUsers = () => {
    useSideMenu({ isVisible: true, contentType: "profile" });
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { t } = useTranslation(["translations"]);
    const { profileId, userId } = useParams();
    const addNewUser = userId === "new-user";
    const { performMutation } = usePerformMutation();
    const [pageUsersTable, setPageUsersTable] = useState<number>(1);

    const [createUser, { isLoading: isLoadingCreateUser }] = useCreateUserMutation();
    const [updateUser, { isLoading: isLoadingUpdateUser }] = useUpdateUserMutation();
    const [deleteUser, { isLoading: isLoadingDeleteUser }] = useDeleteUserMutation();

    const {
        data: profileUsersData,
        isLoading: isLoadingProfileUsersData,
        isFetching: isFetchingProfileUsersData,
        isUninitialized: isUninitializedProfileUsersData,
        error: errorProfileUsersData
    } = useGetProfileUsersQuery({ profileId: profileId! }, { skip: !!!profileId });

    const {
        data: availableRoles,
        isLoading: isLoadingAvailableRoles,
        isFetching: isFetchingAvailableRoles,
        isUninitialized: isUninitializedAvailableRoles,
        error: errorAvailableRoles
    } = useGetAssignableUserRolesQuery();

    const {
        data: profile,
        isFetching: isFetchingProfile,
        isLoading: isLoadingProfile,
        error: errorProfile
    } = useGetProfileQuery({ profileId: profileId! }, { skip: !!!profileId });

    const rolesOptions = availableRoles?.map((role) => {
        return {
            value: role.name,
            label: role.name
        };
    });

    const selectedUser = useMemo(() => {
        if (!userId || !profileUsersData) return undefined;
        return profileUsersData?.data.find((pu: ProfileUserType) => String(pu.id) === userId);
    }, [userId, profileUsersData]);

    useEffect(() => {
        if (addNewUser || !selectedUser) form.resetFields();
        else if (selectedUser && form) {
            form.resetFields();
            form.setFieldsValue({ ...selectedUser, roles: selectedUser.roles.map((role) => role.name) });
        }
    }, [selectedUser, addNewUser, form]);

    const handleCUDUser =
        (submittedForm: FormInstance | null) =>
        (method: "put" | "post" | "delete", userId: number | string | undefined) =>
        async (values: any | null) => {
            const message = buildTranslation(t, {
                subject: translations.subject.user,
                method: translations.request[method]
            });

            await performMutation({
                mutation: async () => {
                    method === "put"
                        ? await updateUser({
                              userId: userId!,
                              ...values,
                              roles: [values!.roles]
                          }).unwrap()
                        : method === "post"
                        ? await createUser({
                              ...values,
                              roles: [values!.roles],
                              profiles: {
                                  [parseInt(profileId!)]: {
                                      is_default_profile: 1
                                  }
                              }
                          }).unwrap()
                        : await deleteUser({ userId: userId! }).unwrap();
                },
                successMessage: message(translations.outcome.success),
                errorMessage: message(translations.outcome.error),
                onSuccessCallback: () => {
                    navigate("/profiles/" + profileId + "/users");
                },
                setFieldsWithErrors: (error: Error422) => {
                    if (values && submittedForm) {
                        const valuesWithErrors = mapErrorsToFields({ error, values });
                        submittedForm.setFields(valuesWithErrors);
                    }
                }
            });
        };

    const isDisabledCreateNewUser =
        profile?.max_users === null
            ? false
            : profile?.max_users !== null &&
              profile?.max_users !== undefined &&
              profileUsersData?.data?.length &&
              profileUsersData?.data?.length > 0
            ? profile?.max_users - profileUsersData?.data.length === 0
            : false;

    const error = errorProfileUsersData || errorAvailableRoles || errorProfileUsersData || errorProfile;
    const loading =
        isLoadingProfileUsersData ||
        isFetchingProfileUsersData ||
        isUninitializedProfileUsersData ||
        isLoadingAvailableRoles ||
        isUninitializedAvailableRoles ||
        isFetchingAvailableRoles ||
        isFetchingProfile ||
        isLoadingProfile ||
        isLoadingCreateUser ||
        isLoadingUpdateUser ||
        isLoadingDeleteUser;

    if (error) return <Error message={t("generic-error-message")} />;
    return (
        <Spin spinning={loading}>
            <PageTitle icon={<UsergroupAddOutlined />} title={t("users-page-title")} />
            <ColumnLayout
                firstColumn={
                    <>
                        <StickToTop
                            title={<Typography.Title level={3}>{t("users-list")}</Typography.Title>}
                            actions={
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() => navigate("/profiles/" + profileId + "/users/new-user")}
                                    type="primary"
                                    data-testid="add-new-user-button"
                                    disabled={addNewUser || isDisabledCreateNewUser}
                                >
                                    {t("add-new-user")}
                                </Button>
                            }
                        ></StickToTop>
                        <SearchableTable
                            searchInputPlaceholder={t("search-episodes-table")}
                            columns={usersTableColumns({
                                t,
                                navigate,
                                deleteUser: handleCUDUser(null)
                            })}
                            disableSelectionCheckboxes={true}
                            selectable={true}
                            data={profileUsersData?.data.map((item) => ({ ...item, key: item.id }))}
                            page={pageUsersTable}
                            setPage={setPageUsersTable}
                            paginationTotal={profileUsersData?.data.length}
                            selectedRowKeys={userId ? [parseInt(userId)] : []}
                        />
                        <Divider />
                        {profile?.max_users !== null &&
                            profile?.max_users !== undefined &&
                            profileUsersData?.data?.length &&
                            profileUsersData?.data?.length > 0 && (
                                <Typography.Text type="secondary">
                                    {t("available-user-slots")}
                                    {": "}
                                    {profile?.max_users - profileUsersData?.data.length} /{" "}
                                    {profile?.max_users}
                                </Typography.Text>
                            )}
                    </>
                }
                secondColumn={
                    selectedUser || addNewUser ? (
                        <>
                            <StickToTop
                                title={
                                    <Typography.Title level={3}>
                                        {addNewUser ? t("add-new-user") : selectedUser?.email}
                                    </Typography.Title>
                                }
                            ></StickToTop>
                            <Form
                                items={userFormInputs({
                                    t,
                                    withPassword: addNewUser,
                                    rolesOptions: rolesOptions || []
                                })}
                                form={form}
                                languageSpecificLabel={t("language-specific-episodes-fields")}
                                onSubmit={
                                    addNewUser
                                        ? handleCUDUser(form)("post", undefined)
                                        : handleCUDUser(form)("put", userId!)
                                }
                            ></Form>
                        </>
                    ) : (
                        <>
                            <StickToTop
                                title={<Typography.Title level={3}>{t("user-details")}</Typography.Title>}
                            ></StickToTop>
                            <Alert
                                style={{ marginTop: "9px", padding: "7px 12px" }}
                                message={t("select-user-to-edit")}
                                data-testid="no-user-selected-alert"
                                type="info"
                                icon={<BulbOutlined />}
                                showIcon
                            ></Alert>
                        </>
                    )
                }
                secondColumnBanner={
                    (selectedUser || addNewUser) && (
                        <ActionsBanner>
                            <Space direction="horizontal" align="end">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}
                                    size="large"
                                    onClick={form.submit}
                                    data-testid="event-update-button"
                                >
                                    {addNewUser ? t("save-new-user") : t("update-user")}
                                </Button>
                            </Space>
                        </ActionsBanner>
                    )
                }
            />
        </Spin>
    );
};

export default ProfileUsers;
