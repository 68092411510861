import crud from "@translations/en/crud.json";
import guidedTours from "@translations/en/guided-tours.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import languages from "@translations/en/languages.json";
import mediaGallery from "@translations/en/media-gallery.json";
import styles from "@translations/en/styles.json";
import translations from "@translations/en/translations.json";

i18n.use(initReactI18next).init({
    fallbackLng: "en",
    debug: false,
    resources: {
        en: {
            translations: translations,
            crud: crud,
            styles: styles,
            "media-gallery": mediaGallery,
            "guided-tours": guidedTours,
            languages: languages
        }
    },
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
