export const translations = {
    subject: {
        evalFormCalendar: "evaluation-form-calendar",
        evalFormQuestion: "evaluation-form-question",
        evalFormOption: "evaluation-form-option",
        changeQuestionOptionsOrder: "change-question-options-order",
        changeQuestionsOrder: "change-questions-order",
        attachmentSaved: "attachment-saved",
        mediaAttachment: "media-attachment",
        agenda: "agenda",
        agendaMediaTitle: "agenda-media-title",
        pollingQuestion: "polling-question",
        broadcastQuestion: "broadcast-question",
        episode: "episode",
        event: "event",
        profile: "profile",
        user: "user",
        speaker: "speaker",
        userAvatar: "user-avatar",
        cssMedia: "css-media",
        reportGeneration: "report-generation",
        reportDownload: "report-download",
        saveDraft: "save-draft",
        publishDraft: "publish-draft",
        discardDraft: "discard-draft",
        mediaAvailability: "media-availability",
        passwordChange: "password-change",
        adminUser: "admin-user",
        questionsGroup: "questions-group"
    },
    request: {
        get: "get",
        put: "put",
        post: "post",
        delete: "delete",
        general: "general"
    },
    outcome: {
        success: "success",
        error: "error"
    }
};

export const buildTranslation =
    (t: Function, { subject, method }: { subject: string; method: string }) =>
    (outcome: string) => {
        return `${t(`crud:${subject}`)}: ${t(`crud:${method}-${outcome}`)}`;
    };
