import { AdvancedStyleListElement, advancedStylesListElements } from "./advancedStylesListElements";

import { MapToken } from "antd/es/theme/interface";
import { basicStylesElements } from "./basicStylesListElements";
import i18next from "i18next";
import { theme } from "antd";

export const customSkimmedAlgorithm = ({ token, newStyles }: { token: MapToken; newStyles: MapToken }) => {
    const { defaultAlgorithm } = theme;

    const basicStylesKeys = basicStylesElements.map((basicStyle) => basicStyle.name);
    const advancedStylesKeys = advancedStylesListElements({ t: i18next.t, styles: token }).reduce(
        (prev: string[], next: AdvancedStyleListElement) => {
            return [...prev, ...next.formItems.map((formItem) => formItem.name)];
        },
        []
    );

    const updatedStyles = defaultAlgorithm(newStyles);

    const skimmedStyles = [...basicStylesKeys, ...advancedStylesKeys].reduce((prev: any, next: string) => {
        return { ...prev, [next]: (updatedStyles as any)[next] };
    }, {});

    return skimmedStyles;
};
