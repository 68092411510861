import { Space, Tag } from "antd";

import { specificStylesReference } from "./specificStylesReference";

const getInputFromKey = (key: string) => {
    const typeIdentifyer = key.split("_")?.[1];
    if (typeIdentifyer === "color")
        return {
            type: "color"
        };
    else if (typeIdentifyer === "weight")
        return {
            type: "select",
            inputProps: {
                options: [
                    {
                        value: 100,
                        label: "100"
                    },
                    {
                        value: 200,
                        label: "200"
                    }
                ]
            }
        };
    else
        return {
            type: "number",
            inputProps: {
                addonAfter: "px"
            }
        };
};

export const getSpecificStylesSections = ({ t }: { t: Function }) => {
    const specificStylesSections = Object.keys(specificStylesReference).map((categoryKey) => {
        return {
            label: (
                <Space direction="horizontal">
                    {t("styles:" + categoryKey)}
                    <Tag>S</Tag>
                </Space>
            ),
            key: categoryKey,
            styles: (specificStylesReference as any)[categoryKey].map((specificStyleKey: string) => {
                return {
                    name: specificStyleKey,
                    label: t("styles:" + specificStyleKey),
                    ...getInputFromKey(specificStyleKey)
                };
            })
        };
    });

    return specificStylesSections;
};
