import { Button, Space, Typography } from "antd";

import { EditOutlined } from "@ant-design/icons";
import { EventType } from "@store/events/types";
import { ProfileType } from "@store/profile/types";
import moment from "moment";
import { utcToTimeZone } from "@utils/timezones";

interface ProfilesTableColumnsArgs {
    t: Function;
    navigate: Function;
}

interface BinnedProfilesTableColumnArgs extends ProfilesTableColumnsArgs {
    restoreEvent: Function;
}

const baseRoutePath = "/profiles/";

export const profilesTableColumns = ({ t, navigate }: ProfilesTableColumnsArgs) => [
    {
        title: t("name"),
        dataIndex: "name",
        key: "name",
        render: (name: string, row: EventType) => {
            return (
                <Button type="text" onClick={() => navigate(baseRoutePath + row.id)}>
                    {name}
                </Button>
            );
        }
    },
    {
        title: t("id"),
        dataIndex: "id",
        key: "id"
    },
    {
        title: t("domain"),
        key: "domain",
        dataIndex: "domain"
    },
    {
        title: t("created-at"),
        key: "created_at",
        dataIndex: "created_at",
        render: (time: string, row: ProfileType) => {
            return (
                <Typography.Text>
                    {`${moment(
                        utcToTimeZone({
                            time: time,
                            //TODO: add profile timezone
                            zone: undefined
                        })
                    ).format("dd DD MMM yyyy")}`}
                </Typography.Text>
            );
        }
    },
    {
        title: t("updated-at"),
        key: "updated_at",
        dataIndex: "updated_at",
        render: (time: string) => {
            return (
                <Typography.Text>
                    {`${moment(
                        utcToTimeZone({
                            time: time,
                            //TODO: add profile timezone
                            zone: undefined
                        })
                    ).format("dd DD MMM yyyy")}`}
                </Typography.Text>
            );
        }
    },
    {
        title: t("actions"),
        dataIndex: "id",
        key: "actions",
        render: (id: number | string) => {
            return (
                <Space direction="horizontal">
                    <Button
                        type="primary"
                        onClick={() => navigate(baseRoutePath + id)}
                        icon={<EditOutlined />}
                        data-testid={"edit-profile-button-" + id}
                    >
                        {t("edit-profile")}
                    </Button>
                </Space>
            );
        }
    }
];

export const binnedProfilesTableColumns = ({ t, navigate, restoreEvent }: BinnedProfilesTableColumnArgs) => {
    return [
        {
            title: t("name"),
            dataIndex: "name",
            key: "name-b",
            render: (name: string) => name
        },
        ...profilesTableColumns({ t, navigate }).filter(
            (column) => column.key !== "actions" && column.key !== "name"
        ),
        {
            title: t("restore"),
            key: "id",
            dataIndex: "id",
            render: (id: number | string) => {
                return (
                    <Button
                        type="primary"
                        onClick={async () => await restoreEvent({ event: id })}
                        icon={<EditOutlined />}
                        data-testid="restore-event-button"
                    >
                        {t("restore-event")}
                    </Button>
                );
            }
        }
    ];
};
