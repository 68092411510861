import { Button, Popconfirm, Space, Tag } from "antd";
import { CloseCircleOutlined, DeleteOutlined, EditOutlined, WifiOutlined } from "@ant-design/icons";

import FutureOrPast from "@components/FutureOrPast";
import { PollingQuestionType } from "@store/live/types";
import moment from "moment";

interface EvaluationFormQuestionTableColumnsArgs {
    t: Function;
    setBroadcastQuestionId: (id: number) => void;
    endBroadcastQuestion: Function;
    goToQuestion: (id: number) => void;
    onDeleteItem: (questionId: number) => (values: PollingQuestionType | null) => Promise<void>;
}

export const pollingQuestionTableColumns = ({
    t,
    goToQuestion,
    endBroadcastQuestion,
    setBroadcastQuestionId,
    onDeleteItem
}: EvaluationFormQuestionTableColumnsArgs) => {
    return [
        {
            title: t("body-and-legend"),
            dataIndex: "content_default",
            key: "content_default",
            render: (content_default: string, row: PollingQuestionType) => {
                return (
                    <Button
                        style={{ height: "min-content", textAlign: "left" }}
                        type="text"
                        color="warning"
                        onClick={() => goToQuestion(row.id)}
                    >
                        {content_default ? content_default : <Tag>{t("no-value-available")}</Tag>}
                    </Button>
                );
            }
        },
        {
            title: t("status"),
            dataIndex: "status",
            key: "status",
            render: (_: any, row: PollingQuestionType) => {
                const date = moment(row.logs?.[0]?.created_at || "")
                    .add(row.logs?.[0]?.visible_time, "seconds")
                    .toISOString();
                return (
                    <FutureOrPast
                        date={date}
                        pastElement={<Tag color="red">{t("not-live")}</Tag>}
                        futureElement={<Tag color="green">{t("live")}</Tag>}
                    />
                );
            }
        },
        {
            title: t("actions"),
            dataIndex: "actions",
            key: "actions",
            width: 110,
            render: (_: any, row: PollingQuestionType) => {
                const date = moment(row.logs?.[0]?.created_at || "")
                    .add(row.logs?.[0]?.visible_time, "seconds")
                    .toISOString();

                return (
                    <Space direction="vertical">
                        <Space direction="horizontal">
                            <FutureOrPast
                                date={date}
                                pastElement={
                                    <Button
                                        icon={<WifiOutlined />}
                                        data-testid="broadcast-button"
                                        onClick={() => setBroadcastQuestionId(row.id)}
                                    >
                                        {t("broadcast")}
                                    </Button>
                                }
                                futureElement={
                                    <Button
                                        icon={<CloseCircleOutlined />}
                                        data-testid="unbroadcast-button"
                                        onClick={() => {
                                            endBroadcastQuestion(row.id);
                                        }}
                                    >
                                        {t("end-broadcast")}
                                    </Button>
                                }
                            />
                            <Button
                                icon={<EditOutlined />}
                                data-testid={`edit-polling-question-${row.id}`}
                                onClick={() => goToQuestion(row.id)}
                            />
                            <Popconfirm
                                title={t("delete-item-warning-text")}
                                okText={t("delete")}
                                cancelText={t("cancel")}
                                onConfirm={() => onDeleteItem(row.id)(null)}
                                okButtonProps={{
                                    "data-testid": "confirm-delete-question"
                                }}
                            >
                                <Button
                                    icon={<DeleteOutlined />}
                                    data-testid={`delete-polling-question-${row.id}`}
                                    title={t("delete-question")}
                                />
                            </Popconfirm>
                        </Space>
                    </Space>
                );
            }
        }
    ];
};
