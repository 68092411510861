import { ColorFactory } from "antd/es/color-picker/color";

export function getFormattedStyles(styles: any): any {
    const updatedStyles = styles;
    Object.keys(updatedStyles)?.forEach(function (key) {
        if (typeof styles[key] === "object" && "metaColor" in styles[key]) {
            updatedStyles[key] = (styles[key] as ColorFactory)?.toHex();
        } else updatedStyles[key] = styles[key];
    });
    return updatedStyles;
}
