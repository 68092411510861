import axios from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        "X-Requested-With": "XMLHttpRequest"
    }
});

export interface NormalizedError {
    status?: number;
    message?: string;
}

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    ({ response }) => {
        const normalizeError: NormalizedError = {};

        if (response) {
            normalizeError.status = response.status;

            if (
                (response.data?.error && typeof response.data?.error === "string") ||
                (response.data?.message && typeof response.data?.message === "string")
            ) {
                normalizeError.message = response.data.error || response.data.message;
            }
        }

        return Promise.reject(normalizeError);
    }
);

export default axiosInstance;
