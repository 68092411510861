import { Button, List, Popconfirm, Space, Spin, Typography } from "antd";
import { buildTranslation, translations } from "@translations/crudTranslationBuilder";
import {
    useAmendCssMediaMutation,
    useGetCssMediaQuery,
    useGetEventQuery,
    useGetMediaQuery
} from "@store/events";

import { CameraOutlined } from "@ant-design/icons";
import DoubleColumnLayout from "@components/ColumnLayout/ColumnLayout";
import Error from "@components/Error";
import GalleryDrawer from "@components/GalleryDrawer";
import PageTitle from "@components/PageTitle/PageTitle";
import StickToTop from "@components/StickToTop/StickToTop";
import { getHasRequiredInfo } from "@utils/general";
import { mediaGallery } from "./mediaGallery";
import { useParams } from "react-router-dom";
import { usePerformMutation } from "@utils/perform-mutation";
import { useRedirectEventsHome } from "@hooks/useRedirectEventsHome";
import { useSideMenu } from "@hooks/useSideMenu";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const EventMediaGallery = () => {
    const { eventId } = useParams();
    useSideMenu({ isVisible: true, contentType: "event" });
    const { t } = useTranslation(["translations", "media-gallery"]);
    const [itemNameForMedia, setItemNameForMedia] = useState<string | null>(null);
    const [mediaPage, setMediaPage] = useState<number>(1);
    const { performMutation } = usePerformMutation();

    const [amendCssMedia, { isLoading: isLoadingAmendCssMedia }] = useAmendCssMediaMutation();

    const {
        data: event,
        isLoading: isLoadingEvent,
        isFetching: isFetchingEvent,
        isUninitialized: isUninitializedEvent,
        error: errorEvent
    } = useGetEventQuery({ eventId: eventId! }, { skip: !!!eventId });

    const {
        data: cssMedia,
        isLoading: isLoadingCssMedia,
        isFetching: isFetchingCssMedia,
        isUninitialized: isUninitializedCssMedia,
        error: errorCssMedia
    } = useGetCssMediaQuery({ eventId: eventId! }, { skip: !!!eventId });

    const {
        data: mediaData,
        isLoading: isLoadingMediaData,
        isFetching: isFetchingMediaData,
        error: errorMediaData
    } = useGetMediaQuery({ eventId: eventId!, page: mediaPage });

    const { hasEventRequiredInfo, isLoadingRequiredInfo } = getHasRequiredInfo(event);

    useRedirectEventsHome({
        hasInfo: hasEventRequiredInfo,
        isLoading: isLoadingRequiredInfo,
        event: eventId
    });

    const getMediaItem = (keyname: string) => {
        return cssMedia?.find((media) => {
            return media.pivot.keyname === keyname;
        });
    };

    const handleCssMedia = async (
        method: "post" | "delete",
        { mediaId, itemName }: { mediaId: number; itemName: string }
    ) => {
        const message = buildTranslation(t, {
            subject: translations.subject.cssMedia,
            method: translations.request.general
        });

        await performMutation({
            mutation: async () => {
                await amendCssMedia({
                    eventId: eventId!,
                    mediaId,
                    itemName,
                    method
                }).unwrap();
            },
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error),
            onSuccessCallback: () => setItemNameForMedia(null),
            onErrorCallback: () => setItemNameForMedia(null)
        });
    };

    const error = errorEvent || errorCssMedia || errorMediaData;
    const loading =
        isUninitializedEvent ||
        isLoadingEvent ||
        isFetchingEvent ||
        isLoadingMediaData ||
        isFetchingMediaData ||
        isLoadingCssMedia ||
        isFetchingCssMedia ||
        isUninitializedCssMedia;

    if (error) return <Error message={t("generic-error-message")} />;
    return (
        <Spin spinning={loading} className="page-spinner">
            <PageTitle icon={<CameraOutlined />} title={t("media-gallery")} />
            <DoubleColumnLayout
                firstColumn={
                    <>
                        <StickToTop
                            title={<Typography.Title level={4}>{t("website-images")}</Typography.Title>}
                        />
                        <List
                            dataSource={mediaGallery}
                            renderItem={(item) => {
                                const attachedImageUrl = getMediaItem(item.name)?.url;
                                const mediaId = getMediaItem(item.name)?.id;
                                return (
                                    <List.Item
                                        actions={
                                            attachedImageUrl
                                                ? [<img width={300} src={attachedImageUrl} alt="" />]
                                                : undefined
                                        }
                                    >
                                        <List.Item.Meta
                                            title={t(`media-gallery:${item.label}`)}
                                            description={
                                                <Space direction="vertical">
                                                    {t(`media-gallery:${item.description}`)}
                                                    {attachedImageUrl ? (
                                                        <Popconfirm
                                                            title={t("detach-item-warning-text")}
                                                            okText={t("detach")}
                                                            cancelText={t("cancel")}
                                                            onConfirm={() =>
                                                                handleCssMedia("delete", {
                                                                    mediaId: mediaId!,
                                                                    itemName: item.name
                                                                })
                                                            }
                                                        >
                                                            <Button style={{ marginTop: 10 }}>
                                                                {t("detach")}
                                                            </Button>
                                                        </Popconfirm>
                                                    ) : (
                                                        <Button
                                                            type="primary"
                                                            onClick={() => setItemNameForMedia(item.name)}
                                                            style={{ marginTop: 10 }}
                                                        >
                                                            {t("attach")}
                                                        </Button>
                                                    )}
                                                </Space>
                                            }
                                        />
                                    </List.Item>
                                );
                            }}
                        />
                    </>
                }
            />
            <GalleryDrawer
                profileId={event?.profile_id!}
                eventId={event?.id}
                loading={isLoadingMediaData || isFetchingMediaData}
                title={t("attach-media")}
                setIsDrawerOpen={(_: any) => setItemNameForMedia(null)}
                isDrawerOpen={itemNameForMedia !== null}
                perPage={mediaData?.pagination.perPage}
                gallery={mediaData?.data}
                paginationTotal={mediaData?.pagination.total}
                currentPage={mediaPage}
                pageSetter={setMediaPage}
                onAttachClick={async (itemId: number) => {
                    await handleCssMedia("post", { mediaId: itemId, itemName: itemNameForMedia! });
                    setItemNameForMedia(null);
                }}
                isLoadingAttachMedia={isLoadingAmendCssMedia}
            />
        </Spin>
    );
};

export default EventMediaGallery;
