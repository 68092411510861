import type { AgendaType, EventType } from "@store/events/types";
import { AppstoreOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Avatar, Button, List, Popconfirm, Space, Typography, theme } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import DateDisplayer from "@components/DateDisplayer/DateDisplayer";
import styles from "./AgendaItem.module.scss";
import { useTranslation } from "react-i18next";

interface AgendaItemProps {
    agendaItem: AgendaType;
    eventData: EventType | undefined;
    deleteAgenda: Function;
    disableNested?: boolean;
}

const AgendaItem: React.FC<AgendaItemProps> = ({ agendaItem, eventData, deleteAgenda, disableNested }) => {
    const scrollElementRef = useRef<HTMLDivElement>(null);
    const {
        token: { colorPrimaryBg, colorPrimaryTextActive }
    } = theme.useToken();
    const { eventId, episodeId, agendaId, parentAgendaId } = useParams();
    const { t } = useTranslation(["translations"]);
    const navigate = useNavigate();
    // const [isOpenNested, setIsOpenNested] = useState<boolean>(
    //     !!agendaItem.children.find((ag) => String(ag.id) === agendaId)
    // );

    // useEffect(() => {
    //     if (isOpenNested && agendaItem.children.length === 0) setIsOpenNested(false);
    // }, [agendaItem]);

    useEffect(() => {
        if (agendaId === String(agendaItem.id) && scrollElementRef.current)
            scrollElementRef.current.scrollIntoView({ behavior: "smooth" });
    }, []);

    return (
        <>
            <List.Item
                style={
                    agendaId && agendaItem.id === parseInt(agendaId)
                        ? { background: colorPrimaryBg }
                        : undefined
                }
                data-testid="agenda-list-item"
                className={styles["agenda-item"]}
                actions={[
                    <Space direction="horizontal" className={styles["agenda-item__actions-container"]}>
                        <Popconfirm
                            title={t("delete-item-warning-text")}
                            okText={t("delete")}
                            cancelText={t("cancel")}
                            onConfirm={deleteAgenda("delete", agendaItem.id)}
                        >
                            <Button
                                type="default"
                                icon={<DeleteOutlined />}
                                data-testid="delete-agenda-item-button"
                            >
                                {t("delete")}
                            </Button>
                        </Popconfirm>
                        {/* {!disableNested && (
                            <Button
                                type="primary"
                                disabled={agendaItem.children.length === 0}
                                icon={<AppstoreOutlined />}
                                data-testid="nested-agenda-item-button"
                                onClick={
                                    agendaItem.children.length > 0
                                        ? () => setIsOpenNested((ps) => !ps)
                                        : undefined
                                }
                            >
                                {t("nested-items")}: {agendaItem.children.length}
                            </Button>
                        )} */}
                        {/* {!disableNested && (
                            <Button
                                type="default"
                                icon={<PlusOutlined />}
                                data-testid="add-nested-agenda-item-button"
                                disabled={String(agendaItem.id) === parentAgendaId}
                                onClick={() =>
                                    navigate(
                                        `/events/${eventId}/agenda/${episodeId}/add-new-agenda-item/${agendaItem.id}`
                                    )
                                }
                            >
                                {t("add-nested-item")}
                            </Button>
                        )} */}
                    </Space>
                ]}
            >
                <div className={styles["agenda-item__scroll-element"]} ref={scrollElementRef}></div>
                <List.Item.Meta
                    style={{ marginBottom: 0 }}
                    title={
                        <Button
                            type="text"
                            onClick={() =>
                                navigate(`/events/${eventId}/agenda/${episodeId}/${agendaItem.id}`)
                            }
                            className={styles["agenda-item__title-button"]}
                            data-testid="agenda-item-title-button"
                        >
                            <Typography.Text
                                style={
                                    agendaId && agendaItem.id === parseInt(agendaId)
                                        ? { color: colorPrimaryTextActive, margin: 0 }
                                        : { margin: 0 }
                                }
                            >
                                <b>
                                    {agendaItem.title_default} ({agendaItem.id})
                                </b>
                            </Typography.Text>
                        </Button>
                    }
                    avatar={
                        <DateDisplayer
                            timezone={eventData?.timezone || ""}
                            date={agendaItem.start_at || ""}
                        />
                    }
                    description={
                        <Space direction="vertical" className={styles["agenda-item__description"]}>
                            <Typography.Text type="secondary">
                                {t("duration")}:{" "}
                                <b>
                                    {agendaItem.duration}{" "}
                                    {agendaItem.duration === 1 ? t("minute") : t("minutes")}
                                </b>
                            </Typography.Text>
                            <Space direction="vertical">
                                {agendaItem.speakers.map((speaker) => {
                                    return (
                                        <Space direction="horizontal" key={speaker.id}>
                                            <Avatar size="large" src={speaker?.avatar} />
                                            <Typography.Text>
                                                {speaker.firstname} {speaker.lastname}
                                            </Typography.Text>
                                        </Space>
                                    );
                                })}
                            </Space>
                        </Space>
                    }
                />
            </List.Item>
            {/* {isOpenNested && (
                <List.Item>
                    <List
                        itemLayout="vertical"
                        size="large"
                        dataSource={agendaItem.children}
                        renderItem={(agendaItem) => (
                            <AgendaItem
                                agendaItem={agendaItem}
                                disableNested={true}
                                eventData={eventData}
                                deleteAgenda={deleteAgenda}
                                key={agendaItem.agenda_id}
                            />
                        )}
                    />
                </List.Item>
            )} */}
        </>
    );
};

export default AgendaItem;
