export const listOfModels = [
    "App\\Models\\Agenda",
    "App\\Models\\Calendar",
    "App\\Models\\Channel",
    "App\\Models\\Content",
    "App\\Models\\Episode",
    "App\\Models\\EvaluationFormQuestionsCategory",
    "App\\Models\\Event",
    "App\\Models\\GeneralQuestionCategory",
    "App\\Models\\PollQuestionsCategory",
    "App\\Models\\Question",
    "App\\Models\\QuizQuestion",
    "App\\Models\\QuizQuestionsCategory",
    "App\\Models\\Speaker",
    "App\\Pivots\\AgendaMediaPivot"
];
