import { Button, Popconfirm, Space, Tag, Typography } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { EvaluationFormQuestionType } from "@store/events/types";

interface EvaluationFormQuestionTableColumnsArgs {
    t: Function;
    goToQuestion: Function;
    onDeleteItem: Function;
}

export const evaluationFormQuestionTableColumns = ({
    t,
    goToQuestion,
    onDeleteItem
}: EvaluationFormQuestionTableColumnsArgs) => {
    return [
        {
            title: t("question-content"),
            dataIndex: "content_default",
            key: "content_default",
            render: (content_default: string, row: EvaluationFormQuestionType) => {
                return (
                    <Button
                        style={{ height: "min-content", textAlign: "left" }}
                        type="text"
                        color="warning"
                        onClick={() => goToQuestion(row.id)}
                    >
                        <Space direction="vertical">
                            {content_default ? content_default : t("no-value-available")}
                        </Space>
                    </Button>
                );
            }
        },
        {
            title: t("actions"),
            dataIndex: "actions",
            key: "actions",
            width: 110,
            render: (_: any, row: EvaluationFormQuestionType) => {
                return (
                    <Space direction="vertical">
                        <Space direction="horizontal">
                            <Button
                                icon={<EditOutlined />}
                                data-testid="edit-evaluation-form-question"
                                onClick={() => goToQuestion(row.id)}
                            />
                            <Popconfirm
                                title={t("delete-item-warning-text")}
                                okText={t("delete")}
                                data-testid="delete-evaluation-form-question-confirm"
                                cancelText={t("cancel")}
                                onConfirm={() => onDeleteItem(row.id)()}
                            >
                                <Button
                                    icon={<DeleteOutlined />}
                                    data-testid="delete-evaluation-form-question"
                                    title={t("delete-question")}
                                />
                            </Popconfirm>
                        </Space>
                    </Space>
                );
            }
        }
    ];
};
