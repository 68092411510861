import React, { useEffect, useRef, useState } from "react";
import { Switch, SwitchProps, Typography } from "antd";

interface SwitchWithNoteProps extends SwitchProps {
    checkedlabel?: string;
    uncheckedlabel?: string;
}

const SwitchWithNote: React.FC<SwitchWithNoteProps> = (props) => {
    const switchRef = useRef<HTMLButtonElement>(null);
    const [checked, setChecked] = useState<boolean>(props.checked || false);

    useEffect(() => {
        if (switchRef.current) setChecked(!!switchRef.current?.getAttribute("aria-checked"));
    }, []);

    return (
        <React.Fragment>
            <Switch
                ref={switchRef}
                {...props}
                onChange={(checked, event) => {
                    setChecked(checked);
                    props?.onChange && props?.onChange(checked, event);
                }}
            />
            <Typography.Text type="secondary" style={{ paddingLeft: "10px" }}>
                {checked ? props.checkedlabel : props.uncheckedlabel}
            </Typography.Text>
        </React.Fragment>
    );
};

export default SwitchWithNote;
