import { Button, Popconfirm, Space, Tag, Typography } from "antd";
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    WifiOutlined
} from "@ant-design/icons";

import { EvaluationFormSlotType } from "@store/events/types";

interface EvaluationFormSlotsTableColumnsArgs {
    t: Function;
    goToSlot: Function;
    onDeleteItem: (
        method: "post" | "put" | "delete",
        slotId?: number | string
    ) => (
        values?:
            | {
                  [key: string]: string | boolean;
              }
            | undefined
    ) => Promise<void>;
    duplicate: Function;
    publishSlot: (slotId: number, keepOldSchedule: boolean) => Promise<void>;
}

export const evaluationFormSlotsTableColumns = ({
    t,
    goToSlot,
    onDeleteItem,
    duplicate,
    publishSlot
}: EvaluationFormSlotsTableColumnsArgs) => {
    return [
        {
            title: t("title"),
            dataIndex: "title",
            key: "title",
            render: (title: string, row: EvaluationFormSlotType) => {
                return !!row.published_at ? (
                    <Typography.Text style={{ padding: "10px 15px", whiteSpace: "nowrap" }}>
                        {title}
                    </Typography.Text>
                ) : (
                    <Button
                        style={{ height: "min-content", textAlign: "left" }}
                        type="text"
                        color="warning"
                        onClick={() => goToSlot(row.id)}
                    >
                        {title}
                    </Button>
                );
            }
        },
        {
            title: t("has-been-published"),
            dataIndex: "published_at",
            key: "published_at",
            render: (published_at: string) => {
                return !!published_at ? (
                    <Tag color="green" data-testid="episode-visible-tag-yes">
                        <CheckCircleOutlined /> {t("yes")}
                    </Tag>
                ) : (
                    <Tag color="red" data-testid="episode-visible-tag-no">
                        <CloseCircleOutlined /> {t("no")}
                    </Tag>
                );
            }
        },
        {
            title: t("is-active"),
            dataIndex: "isActive",
            key: "isActive",
            render: (isActive: boolean) => {
                return isActive ? (
                    <Tag color="green" data-testid="episode-visible-tag-yes">
                        <CheckCircleOutlined /> {t("yes")}
                    </Tag>
                ) : (
                    <Tag color="red" data-testid="episode-visible-tag-no">
                        <CloseCircleOutlined /> {t("no")}
                    </Tag>
                );
            }
        },
        {
            title: t("actions"),
            dataIndex: "actions",
            key: "actions",
            width: 110,
            render: (_: any, row: EvaluationFormSlotType) => {
                return (
                    <Space direction="vertical">
                        <Space direction="horizontal">
                            <Popconfirm
                                title={t("keep-old-schedule-warning")}
                                okText={t("keep-old-schedule")}
                                cancelText={t("delete-old-schedule")}
                                onConfirm={() => publishSlot(row.id, true)}
                                onCancel={() => publishSlot(row.id, false)}
                                disabled={!!row.published_at}
                            >
                                <Button disabled={!!row.published_at} icon={<WifiOutlined />}>
                                    {t("publish-now")}
                                </Button>
                            </Popconfirm>
                            <Button
                                icon={<EditOutlined />}
                                data-testid="edit-evaluation-form-question"
                                onClick={() => goToSlot(row.id)}
                                disabled={!!row.published_at}
                            >
                                {t("edit")}
                            </Button>
                        </Space>
                        <Space direction="horizontal">
                            <Button icon={<CopyOutlined />} onClick={() => duplicate(row.id)}>
                                {t("duplicate")}
                            </Button>
                            <Popconfirm
                                title={t("delete-item-warning-text")}
                                okText={t("delete")}
                                cancelText={t("cancel")}
                                onConfirm={() => onDeleteItem("delete", row.id)()}
                                disabled={!!row.published_at}
                            >
                                <Button disabled={!!row.published_at} icon={<DeleteOutlined />}>
                                    {t("delete")}
                                </Button>
                            </Popconfirm>
                        </Space>
                    </Space>
                );
            }
        }
    ];
};
