import { Button, Image, Input, List, Tag, Typography, theme } from "antd";
import { CloseSquareOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";

import FileSize from "@components/FileSize";
import { MediaFileType } from "@store/events/types";
import { PDFViewer } from "@adm-media/pdf-viewer";
import styles from "./MediaListItem.module.scss";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface MediaListItemProps {
    mediaItem: MediaFileType;
    attach: boolean;
    pivot?: any;
    onActionClick: Function;
    onEditNameClick?: Function;
}

const MediaListItem: React.FC<MediaListItemProps> = ({
    mediaItem,
    pivot,
    attach,
    onActionClick,
    onEditNameClick
}) => {
    const {
        token: { colorBorder }
    } = theme.useToken();
    const [fileName, setFileName] = useState<string>(
        !attach && pivot ? pivot?.title : mediaItem.filename.split(".")[0]
    );
    const { t } = useTranslation(["translations"]);

    return (
        <List.Item
            actions={[
                attach ? (
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => onActionClick(fileName)}
                        disabled={fileName === ""}
                    >
                        {t("attach")}
                    </Button>
                ) : (
                    <Button onClick={() => onActionClick()} icon={<CloseSquareOutlined />}>
                        {t("detach")}
                    </Button>
                )
            ]}
            title={mediaItem.filename}
            className={styles["media-list-item"]}
        >
            <div className={styles["media-list-item__content"]}>
                <FilePreview fileType={mediaItem.mime} src={mediaItem.url} />
                <div
                    className={styles["media-list-item__description"]}
                    style={{
                        borderRight: `1px solid ${colorBorder}`
                    }}
                >
                    <div className={styles["media-list-item__description-item"]}>
                        <div className={styles["media-list-item__description-name-field"]}>
                            <div className={styles["media-list-item__input-container"]}>
                                <Typography.Text>
                                    {attach ? t("edit-attachment-name") : t("attachment-name")}
                                </Typography.Text>
                                <Input
                                    value={fileName}
                                    onChange={(e) => setFileName(e.target.value)}
                                    addonAfter={`.${mediaItem.filename.split(".")[1]}`}
                                />
                            </div>
                            {!attach && pivot && (
                                <Button
                                    type="default"
                                    icon={<SaveOutlined />}
                                    disabled={(!attach ? fileName === pivot.title : false) || fileName === ""}
                                    onClick={() => {
                                        if (onEditNameClick) {
                                            onEditNameClick({
                                                media: pivot ? pivot.id : null,
                                                title: fileName
                                            });
                                        }
                                    }}
                                >
                                    {t("update-attachment-name")}
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className={styles["media-list-item__description-item-content"]}>
                        <div>
                            <Typography.Text>{t("attachment-size")}: </Typography.Text>
                            <Typography.Text type="secondary">
                                <FileSize size={mediaItem.size} />
                            </Typography.Text>
                        </div>
                        {pivot && (
                            <div>
                                <Tag color={pivot?.is_visible ? "green" : "red"}>
                                    {t("is-visible")}: {pivot?.is_visible ? t("yes") : t("no")}
                                </Tag>
                                <Tag color={pivot.is_available ? "green" : "red"}>
                                    {t("is-downloadable")}: {pivot?.is_available ? t("yes") : t("no")}
                                </Tag>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </List.Item>
    );
};

interface FilePreviewProps {
    fileType: string;
    src: string;
}

const FilePreview: React.FC<FilePreviewProps> = ({ fileType, src }) => {
    const {
        token: { colorBorder, colorPrimaryHover, colorPrimary, borderRadius }
    } = theme.useToken();

    if (fileType.startsWith("image/")) return <Image src={src} />;
    else if (fileType === "application/pdf")
        return (
            <>
                <div style={{ border: `1px solid ${colorBorder}`, borderRadius: `${borderRadius}px` }}>
                    <PDFViewer height={297} width={210} file={src} pageStart={1} />
                </div>

                <style>
                    {`
                        html body .pdf-viewer {
                            --pdf-viewer-border-radius: ${borderRadius}px;
                            --pdf-viewer-box-shadow: none; 
                            --pdf-viewer-buttton-focus-outline: transparent;
                            --pdf-viewer-button-color: ${colorPrimary};
                            --pdf-viewer-button-hover-color: ${colorPrimaryHover}
                        }
                    `}
                </style>
            </>
        );
    else return null;
};

export default MediaListItem;
