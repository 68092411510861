import { Button, Checkbox, Col, ColorPicker, DatePicker, Form, Input, InputNumber, Row, Select } from "antd";

import { ColProps } from "antd/lib/col";
import type { Moment } from "moment";
import React from "react";
import RichTextEditor from "./RichTextEditor";
import { RollbackOutlined } from "@ant-design/icons";
import { Rule } from "rc-field-form/lib/interface";
import SwitchWithNote from "@components/SwitchWithNote";
import momentGenerateConfig from "rc-picker/lib/generate/moment";
import { selectUser } from "@store/user";
import { useSelector } from "@store/hooks";
import { useTranslation } from "react-i18next";

export enum AuthorizedRoles {
    admin = "is_admin",
    superadmin = "is_superadmin",
    profile_admin = "is_profile_admin"
}

export type MultilingualFormInputTypes =
    | "text"
    | "number"
    | "password"
    | "textarea"
    | "select"
    | "switch"
    | "email"
    | "date-picker"
    | "range-picker"
    | "checkbox-group"
    | "color"
    | "rich-text-editor";

export interface MultilingualFormInput {
    name: string;
    label?: string | React.ReactNode;
    rules?: Rule[];
    authorizedRoles?: AuthorizedRoles[];
    type?: MultilingualFormInputTypes;
    placeholder?: string;
    multilingual?: boolean;
    withoutLabel?: boolean;
    hidden?: boolean;
    skip?: boolean;
    addonAfter?: React.ReactNode;
    mode?: "multiple" | "tags";
    options?: {
        label: string | React.ReactNode;
        value: string;
    }[];
    onlyDefaultRequired?: boolean;
    grid?: {
        col: ColProps;
    };
    spanWidth?: {
        xs?: number;
        sm?: number;
        md?: number;
        lg?: number;
        xl?: number;
        xxl?: number;
    };
    subSpanWidth?: {
        xs?: number;
        sm?: number;
        md?: number;
        lg?: number;
        xl?: number;
        xxl?: number;
    };
    inputProps?: { [key: string]: any };
    formItemProps?: { [key: string]: any };
    dependencies?: {
        [fieldName: string]: any[];
    };
}

const { RangePicker } = DatePicker;

const MomentDatePicker = DatePicker.generatePicker<Moment>(momentGenerateConfig);

interface FormElementsProps {
    items: MultilingualFormInput[];
    language?: string | null;
    languageReferences?: { id: string; label: string }[];
    renderIndividually?: boolean;
    onFieldReset?: (name: string) => any;
}

const FormElements: React.FC<FormElementsProps> = ({
    items,
    language,
    languageReferences = [],
    renderIndividually,
    onFieldReset
}) => {
    const { t } = useTranslation(["translations"]);
    const user = useSelector(selectUser);

    const getItemLabel = ({
        label,
        language,
        languageReferences
    }: {
        label: string | React.ReactNode;
        language: string | undefined | null;
        languageReferences: { id: string; label: string }[];
    }) => {
        return language && languageReferences !== undefined
            ? `${label} (${languageReferences
                  .find((lang) => lang.id === language)
                  ?.label.split("|")[1]
                  .trim()})`
            : label;
    };

    const Wrapper = renderIndividually ? "div" : Col;

    const inputs = items?.map((item: MultilingualFormInput) => {
        if (item.authorizedRoles) {
            const isAuthorized = !!item.authorizedRoles.find((role) => {
                return user.identity?.data[role];
            });
            if (!isAuthorized) return null;
        }
        return (
            <Wrapper
                key={item.name}
                xs={item.spanWidth?.sm || 24}
                lg={item.spanWidth?.lg || (item.type === "date-picker" ? 12 : 24)}
            >
                {item.type === "text" && (
                    <>
                        <Form.Item
                            label={getItemLabel({
                                label: item.label,
                                language,
                                languageReferences
                            })}
                            style={{ display: item?.hidden === true ? "none" : "block" }}
                            name={language ? [item.name, language] : item.name}
                            rules={item.rules}
                        >
                            <Input placeholder={item.placeholder} {...item.inputProps} />
                        </Form.Item>
                        <>{item?.addonAfter && <>{item.addonAfter}</>}</>
                    </>
                )}
                {item.type === "email" && (
                    <Form.Item
                        label={getItemLabel({
                            label: item.label,
                            language,
                            languageReferences
                        })}
                        style={{ display: item?.hidden === true ? "none" : "block" }}
                        name={language ? [item.name, language] : item.name}
                        rules={item.rules}
                    >
                        <Input placeholder={item.placeholder} type="email" {...item.inputProps} />
                    </Form.Item>
                )}
                {item.type === "password" && (
                    <Form.Item
                        label={getItemLabel({
                            label: item.label,
                            language,
                            languageReferences
                        })}
                        name={language ? [item.name, language] : item.name}
                        rules={item.rules}
                    >
                        <Input placeholder={item.placeholder} type="password" {...item.inputProps} />
                    </Form.Item>
                )}
                {item.type === "number" && (
                    <>
                        <Form.Item
                            label={getItemLabel({
                                label: item.label,
                                language,
                                languageReferences
                            })}
                            name={language ? [item.name, language] : item.name}
                            rules={item.rules}
                        >
                            <InputNumber
                                placeholder={item.placeholder}
                                max="1000000"
                                min="0"
                                {...item.inputProps}
                            />
                        </Form.Item>
                        <>{item?.addonAfter && <>{item.addonAfter}</>}</>
                    </>
                )}
                {item.type === "select" && (
                    <>
                        <Form.Item
                            label={getItemLabel({
                                label: item.label,
                                language,
                                languageReferences
                            })}
                            name={language ? [item.name, language] : item.name}
                            key={item.name}
                            rules={item.rules}
                            style={{ display: item?.hidden === true ? "none" : "block" }}
                        >
                            <Select
                                placeholder={item.placeholder}
                                mode={item.mode}
                                allowClear
                                {...item.inputProps}
                                filterOption={(value, options) => {
                                    try {
                                        return (
                                            (options?.children as unknown as string)
                                                .toLocaleLowerCase()
                                                .indexOf(value.toLocaleLowerCase()) !== -1
                                        );
                                    } catch {
                                        return true;
                                    }
                                }}
                            >
                                {item.options?.length &&
                                    item.options.map(({ value, label }) => (
                                        <Select.Option key={value} value={value}>
                                            {label}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                        <>{item?.addonAfter && <>{item.addonAfter}</>}</>
                    </>
                )}
                {item.type === "rich-text-editor" && (
                    <Form.Item
                        label={getItemLabel({
                            label: item.label,
                            language,
                            languageReferences
                        })}
                        name={language ? [item.name, language] : item.name}
                        key={item.name}
                        rules={item.rules}
                    >
                        <RichTextEditor {...item.inputProps} />
                    </Form.Item>
                )}
                {item.type === "textarea" && (
                    <>
                        {item.withoutLabel ? (
                            <Input.TextArea placeholder={item.placeholder} {...item.inputProps} />
                        ) : (
                            <Form.Item
                                label={getItemLabel({
                                    label: item.label,
                                    language,
                                    languageReferences
                                })}
                                name={language ? [item.name, language] : item.name}
                                key={item.name}
                                rules={item.rules}
                            >
                                <Input.TextArea placeholder={item.placeholder} {...item.inputProps} />
                            </Form.Item>
                        )}
                    </>
                )}
                {item.type === "date-picker" && (
                    <Form.Item
                        label={getItemLabel({
                            label: item.label,
                            language,
                            languageReferences
                        })}
                        style={{ display: item?.hidden === true ? "none" : "block" }}
                        name={language ? [item.name, language] : item.name}
                        key={item.name}
                        rules={item.rules}
                    >
                        <MomentDatePicker
                            style={{ width: "100%" }}
                            placeholder={item.placeholder}
                            {...item.inputProps}
                        />
                    </Form.Item>
                )}
                {item.type === "range-picker" && (
                    <Form.Item
                        label={getItemLabel({
                            label: item.label,
                            language,
                            languageReferences
                        })}
                        name={language ? [item.name, language] : item.name}
                        key={item.name}
                        rules={item.rules}
                    >
                        <RangePicker style={{ width: "100%" }} {...item.inputProps} />
                    </Form.Item>
                )}
                {item.type === "switch" && (
                    <Form.Item
                        label={getItemLabel({
                            label: item.label,
                            language,
                            languageReferences
                        })}
                        name={language ? [item.name, language] : item.name}
                        rules={item.rules}
                        valuePropName="checked"
                    >
                        <SwitchWithNote {...item.inputProps} />
                    </Form.Item>
                )}
                {item.type === "color" && (
                    <>
                        <Form.Item
                            label={getItemLabel({
                                label: item.label,
                                language,
                                languageReferences
                            })}
                            name={language ? [item.name, language] : item.name}
                            rules={item.rules}
                        >
                            {item.skip === true ? null : (
                                <ColorPicker placement="topRight" {...item.inputProps} />
                            )}
                        </Form.Item>
                        <>{item?.addonAfter && <>{item.addonAfter}</>}</>
                    </>
                )}
                {item.type === "checkbox-group" && (
                    <Form.Item
                        label={getItemLabel({
                            label: item.label,
                            language,
                            languageReferences
                        })}
                        name={language ? [item.name, language] : item.name}
                        rules={item.rules}
                        valuePropName="value"
                    >
                        <Checkbox.Group style={{ width: "100%" }} name={item.name}>
                            <Row gutter={[12, 12]}>
                                {item.options?.length !== 0 &&
                                    item.options?.map((option) => {
                                        return (
                                            <Col
                                                xs={item.subSpanWidth?.xs || 24}
                                                lg={item.subSpanWidth?.lg || 6}
                                                key={option.value}
                                            >
                                                <Checkbox value={option.value}>{option.label}</Checkbox>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                )}
                {onFieldReset && (
                    <Button
                        style={{ position: "absolute", right: -40, top: 0 }}
                        onClick={() => onFieldReset(item.name)}
                        title={t("reset-field")}
                        icon={<RollbackOutlined />}
                    />
                )}
            </Wrapper>
        );
    });

    return <Row gutter={[8, 8]}>{inputs}</Row>;
};

export default FormElements;
