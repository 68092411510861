export const customTheme = {
    token: {
        colorPrimary: "#2980b9",
        borderRadius: 3,
        fontSize: 15,
        fontSizeHeading1: 30,
        fontSizeHeading2: 26,
        fontSizeHeading3: 21
    }
};
