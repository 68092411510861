import { Button, Space } from "antd";
import { Dispatch, SetStateAction } from "react";

import { EditOutlined } from "@ant-design/icons";
import { SpeakerType } from "@store/events/types";

interface SpeakersTableColumnsArgs {
    t: Function;
    goToSpeaker: Function;
    removeAvatar: Function;
    setSpeakerIdForAvatar: Dispatch<SetStateAction<number | null>>;
}

export const speakersTableColumns = ({
    t,
    goToSpeaker,
    removeAvatar,
    setSpeakerIdForAvatar
}: SpeakersTableColumnsArgs) => {
    return [
        {
            title: t("id"),
            dataIndex: "id",
            key: "id"
        },
        {
            title: t("avatar"),
            dataIndex: "media",
            key: "media",
            render: (media: { url: string }) => {
                if (typeof media?.url === "string")
                    return <img src={media?.url} style={{ maxWidth: 40 }} alt="" />;
                else return t("no-avatar-available");
            }
        },
        {
            title: t("title"),
            dataIndex: "role_default",
            key: "role_default"
        },
        {
            title: t("first-name"),
            dataIndex: "firstname",
            key: "firstname"
        },
        {
            title: t("last-name"),
            dataIndex: "lastname",
            key: "lastname"
        },
        {
            title: t("actions"),
            dataIndex: "actions",
            key: "actions",
            width: 110,
            render: (_: any, row: SpeakerType) => {
                return (
                    <Space direction="horizontal" align="start">
                        <Space direction="vertical" align="end">
                            <Button onClick={() => setSpeakerIdForAvatar(row.id)}>
                                {t("change-avatar")}
                            </Button>
                            <Button
                                onClick={() =>
                                    removeAvatar("delete", { speakerId: row.id, mediaId: row.media_id })
                                }
                                disabled={row.media_id === null}
                            >
                                {t("remove-avatar")}
                            </Button>
                        </Space>
                        <Button
                            icon={<EditOutlined />}
                            data-testid="edit-evaluation-form-question"
                            onClick={() => goToSpeaker(row.id)}
                        ></Button>
                    </Space>
                );
            }
        }
    ];
};
