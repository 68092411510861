import { SpeakerType } from "@store/events/types";

export interface CheckboxGroupOption {
    label: string;
    value: string;
}

export const mapSpeakersToOptions = (speakers: SpeakerType[]): CheckboxGroupOption[] => {
    return speakers.map((speaker: SpeakerType) => ({
        label: `${speaker.firstname} ${speaker.lastname}`,
        value: String(speaker.id)
    }));
};
