import { SideMenuContentTypes } from "./types";
import { createSlice } from "@reduxjs/toolkit";

export interface LayoutState {
    darkMode: boolean;
    isSideMenuCollapsed: boolean;
    isSideMenuVisible: boolean;
    sideMenuContentType: SideMenuContentTypes;
}

const initialState = {
    darkMode: true,
    isSideMenuCollapsed: false,
    isSideMenuVisible: false,
    sideMenuContentType: null
} as LayoutState;

const layoutSlice = createSlice({
    name: "layout",
    initialState,
    reducers: {
        toggleDarkMode: (state) => {
            state.darkMode = !state.darkMode;
        },
        setSideMenuCollapsed: (state, action) => {
            state.isSideMenuCollapsed = action.payload;
        },
        setSideMenuVisible: (state, action) => {
            state.isSideMenuVisible = action.payload;
        },
        setSideMenuContentType: (state, action) => {
            state.sideMenuContentType = action.payload;
        }
    }
});

export const { toggleDarkMode, setSideMenuCollapsed, setSideMenuVisible, setSideMenuContentType } =
    layoutSlice.actions;
export default layoutSlice.reducer;
