import { MultilingualFormInput } from "@components/FormElements";

interface PollingOptionFormArgs {
    t: (str: string) => string;
}

export const pollingOptionFormInputs = ({ t }: PollingOptionFormArgs): MultilingualFormInput[] => {
    return [
        {
            name: "body",
            multilingual: true,
            label: t("content"),
            type: "text",
            inputProps: {
                "data-testid": "option-content-input"
            },
            rules: [{ required: true, message: t("field-required") }]
        },
        {
            name: "is_correct",
            multilingual: false,
            label: t("is-correct"),
            type: "switch",
            inputProps: {
                "data-testid": "option-is-correct-input"
            }
        }
    ];
};
