import { Button, Spin } from "antd";
import { PlusOutlined, UserAddOutlined } from "@ant-design/icons";
import React, { useMemo, useState } from "react";

import ColumnLayout from "@components/ColumnLayout/ColumnLayout";
import Error from "@components/Error";
import PageTitle from "@components/PageTitle/PageTitle";
import SearchableTable from "@components/SearchableTable/SearchableTable";
import StickToTop from "@components/StickToTop/StickToTop";
import { profilesTableColumns } from "@utils/layout/profiles/profilesTableColumns";
import { useGetProfilesQuery } from "@store/profile";
import { useNavigate } from "react-router-dom";
import { useSideMenu } from "@hooks/useSideMenu";
import { useTranslation } from "react-i18next";

const EventsPage: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation(["translations"]);
    useSideMenu({ isVisible: false, contentType: null });
    const [searchParam, setSearchParam] = useState<string>("");
    const [page, setPage] = useState<number>(1);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const {
        data: profilesData,
        isFetching: isFetchingProfilesData,
        isLoading: isLoadingProfilesData,
        error: errorProfilesData,
        isUninitialized: isUninitializedProfilesData
    } = useGetProfilesQuery({
        page,
        searchParam: searchParam
    });

    const tableData = useMemo(() => {
        return profilesData?.data.map((item) => ({ ...item, key: item.id }));
    }, [profilesData]);

    const error = errorProfilesData;
    const loading = isUninitializedProfilesData || isLoadingProfilesData;

    if (error) return <Error message={t("generic-error-message")} />;
    return (
        <Spin spinning={loading}>
            <PageTitle icon={<UserAddOutlined />} title={t("profiles-page-title")} />
            <ColumnLayout
                firstColumn={
                    <>
                        <StickToTop
                            actions={
                                <Button
                                    type="primary"
                                    data-testid="add-profile-button"
                                    icon={<PlusOutlined />}
                                    onClick={() => navigate("/profiles/new-profile")}
                                >
                                    {t("add-new-profile")}
                                </Button>
                            }
                        />
                        <SearchableTable
                            searchInputPlaceholder={t("search-profiles-table")}
                            setPage={setPage}
                            page={page}
                            columns={profilesTableColumns({ t, navigate })}
                            loading={isFetchingProfilesData}
                            selectedRowKeys={selectedRowKeys}
                            setSelectedRowKeys={setSelectedRowKeys}
                            data={tableData}
                            paginationSize={profilesData?.pagination.perPage || undefined}
                            paginationTotal={profilesData?.pagination.total || 1}
                            onSearchParamChange={setSearchParam}
                            selectable={false}
                        ></SearchableTable>
                    </>
                }
            />
        </Spin>
    );
};

export default EventsPage;
