import { AuthorizedRoles } from "@components/FormElements";
import { MultilingualFormInput } from "@components/FormElements";

interface ProfileFormInputsArgs {
    t: (str: string) => string;
}

export const profileFormInputs = ({ t }: ProfileFormInputsArgs): MultilingualFormInput[] => {
    return [
        {
            name: "name",
            label: t("name"),
            type: "text",
            inputProps: {
                "data-testid": "name-input"
            },
            rules: [{ required: true, message: t("field-required") }]
        },
        {
            name: "is_2fa_active",
            label: t("2fa-active"),
            type: "switch",
            authorizedRoles: [AuthorizedRoles.admin, AuthorizedRoles.superadmin],
            inputProps: {
                checkedlabel: t("2fa-is-active"),
                uncheckedlabel: t("2fa-is-inactive"),
                "data-testid": "maintenance"
            }
        }
    ];
};
