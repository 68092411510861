import { Drawer, List, Spin, Tabs } from "antd";
import React, { useState } from "react";
import { buildTranslation, translations } from "@translations/crudTranslationBuilder";

import FileUpload from "@components/FileUpload";
import GalleryListItem from "@components/GalleryListItem";
import { MediaFileType } from "@store/events/types";
import { usePerformMutation } from "@utils/perform-mutation";
import { useSaveAttachmentMutation } from "@store/events";
import { useTranslation } from "react-i18next";

interface GalleryDrawerProps {
    title: string;
    isDrawerOpen: boolean;
    setIsDrawerOpen: Function;
    gallery: MediaFileType[] | undefined;
    onAttachClick: Function;
    loading?: boolean;
    profileId: string | number;
    eventId?: string | number;
    isLoadingAttachMedia: boolean;
    currentPage: number;
    pageSetter: Function;
    paginationTotal: number | undefined;
    perPage: number | undefined;
}

const GalleryDrawer: React.FC<GalleryDrawerProps> = ({
    title,
    setIsDrawerOpen,
    isDrawerOpen,
    gallery,
    loading,
    profileId,
    eventId,
    onAttachClick,
    isLoadingAttachMedia,
    currentPage,
    pageSetter,
    paginationTotal,
    perPage
}) => {
    const { t } = useTranslation(["translations"]);
    const [tabsActiveKey, setTabsActiveKey] = useState<string | null>(null);
    const { performMutation } = usePerformMutation();

    const [saveAttachment, { isLoading: isLoadingSaveAttachment }] = useSaveAttachmentMutation();

    const handleSaveAttachment = async (attachmentInfo: any) => {
        const message = buildTranslation(t, {
            subject: translations.subject.attachmentSaved,
            method: translations.request.post
        });

        await performMutation({
            mutation: async () => {
                const payload = eventId
                    ? {
                          profile_id: profileId,
                          event_id: eventId,
                          ...attachmentInfo
                      }
                    : {
                          profile_id: profileId,
                          ...attachmentInfo
                      };

                await saveAttachment(payload).unwrap();
            },
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error)
        });
    };

    return (
        <Drawer
            title={title}
            maskClosable={false}
            placement="right"
            onClose={() => setIsDrawerOpen(false)}
            open={isDrawerOpen}
            size="large"
        >
            <Spin spinning={!!(isLoadingSaveAttachment || loading)}>
                {gallery !== undefined && (
                    <Tabs
                        centered={true}
                        activeKey={tabsActiveKey || "upload"}
                        onChange={(key) => setTabsActiveKey(key)}
                        items={[
                            {
                                key: "upload",
                                label: t("upload-new"),
                                children: (
                                    <FileUpload
                                        onSaveAttachment={handleSaveAttachment}
                                        onSuccessUpload={() => setTabsActiveKey("gallery")}
                                        mimeTypes={["image/jpeg", "image/png", "image/webp"].join(", ")}
                                    />
                                )
                            },
                            {
                                key: "gallery",
                                label: t("use-existing"),
                                children: (
                                    <List
                                        itemLayout="vertical"
                                        size="large"
                                        loading={isLoadingAttachMedia}
                                        pagination={{
                                            current: currentPage,
                                            onChange: (pageNumber) => pageSetter(pageNumber),
                                            position: "bottom",
                                            showSizeChanger: false,
                                            total: paginationTotal,
                                            pageSize: perPage
                                        }}
                                        dataSource={gallery}
                                        renderItem={(item) => {
                                            return (
                                                <GalleryListItem item={item} onAttachClick={onAttachClick} />
                                            );
                                        }}
                                    />
                                )
                            }
                        ]}
                    ></Tabs>
                )}
            </Spin>
        </Drawer>
    );
};

export default GalleryDrawer;
