import { Button, Form, List, Space, Switch, Tooltip } from "antd";

import { InfoCircleOutlined } from "@ant-design/icons";
import { MediaFileType } from "@store/events/types";
import React from "react";
import { sizeFormatter } from "human-readable";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface GalleryListItemProps {
    item: MediaFileType;
    onAttachClick: Function;
}

const GalleryListItem: React.FC<GalleryListItemProps> = ({ item, onAttachClick }) => {
    const { t } = useTranslation(["translations"]);
    const [background, setBackground] = useState<"white" | "black">("white");
    const defaults = sizeFormatter();

    return (
        <List.Item
            key={item.filename}
            extra={
                <div style={{ background: background }}>
                    <img width={272} src={item.url} alt="gallery item" />
                </div>
            }
        >
            <List.Item.Meta
                title={item.original_filename}
                description={"Size: " + defaults(parseInt(item.size))}
            />
            <Space direction="vertical">
                <Button onClick={() => onAttachClick(item.id)} type="primary">
                    {t("attach")}
                </Button>
                <Form.Item
                    label={
                        <Space direction="horizontal">
                            {t("background")}
                            <Tooltip placement="top" title={t("background-modify-description")}>
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Space>
                    }
                >
                    <Switch
                        checkedChildren="white"
                        unCheckedChildren="black"
                        defaultChecked
                        size="default"
                        onChange={() =>
                            setBackground((ps) => {
                                if (ps === "white") return "black";
                                else return "white";
                            })
                        }
                    />
                </Form.Item>
            </Space>
        </List.Item>
    );
};

export default GalleryListItem;
