import { RootState } from "@store/index";
import { createSelector } from "@reduxjs/toolkit";

const layoutSelector = (state: RootState) => state.layout;

export const selectDarkMode = createSelector(layoutSelector, (layoutState) => layoutState.darkMode);

export const selectSideMenuCollapsedState = createSelector(
    layoutSelector,
    (layoutState) => layoutState.isSideMenuCollapsed
);

export const selectSideMenuVisibleState = createSelector(
    layoutSelector,
    (layoutState) => layoutState.isSideMenuVisible
);

export const selectSideMenuContentType = createSelector(
    layoutSelector,
    (layoutState) => layoutState.sideMenuContentType
);
