import { Button, Modal, Select, Space, Spin } from "antd";
import { DeleteOutlined, OrderedListOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useMemo, useState } from "react";
import { binnedEventsTableColumns, eventsTableColumns } from "@utils/layout/eventsTableColumns";
import { buildTranslation, translations } from "@translations/crudTranslationBuilder";
import {
    useBinEventsMutation,
    useCreateEventMutation,
    useGetEventsQuery,
    useRestoreEventMutation
} from "@store/events";
import { useLocation, useNavigate } from "react-router-dom";

import ColumnLayout from "@components/ColumnLayout/ColumnLayout";
import Error from "@components/Error";
import Form from "@components/Form";
import PageTitle from "@components/PageTitle/PageTitle";
import SearchableTable from "@components/SearchableTable/SearchableTable";
import StickToTop from "@components/StickToTop/StickToTop";
import { selectIsAdmin } from "@store/user";
import { useGetProfilesQuery } from "@store/profile";
import { usePerformMutation } from "@utils/perform-mutation";
import { useSelector } from "@store/hooks";
import { useSideMenu } from "@hooks/useSideMenu";
import { useTranslation } from "react-i18next";

const EventsPage: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation(["translations"]);
    const location = useLocation();
    useSideMenu({ isVisible: false, contentType: null });
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const isAdmin = useSelector(selectIsAdmin);

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const isBinnedEvents = location.pathname.indexOf("binned") === -1 ? 0 : 1;
    const { performMutation } = usePerformMutation();

    const [createEvent, { isLoading: isLoadingCreateEvent }] = useCreateEventMutation();
    const [binEvents, { isLoading: isLoadingBinEvents }] = useBinEventsMutation();
    const [restoreEvent, { isLoading: isLoadingRestoreEvent }] = useRestoreEventMutation();

    const [searchParam, setSearchParam] = useState<string>("");
    const [searchProfileId, setSearchProfileId] = useState<string | null>("");
    const [page, setPage] = useState<number>(1);
    useEffect(() => setPage(1), [isBinnedEvents, searchParam, searchProfileId]);

    const {
        data: eventsData,
        isFetching: isFetchingEventsData,
        isLoading: isLoadingEventsData,
        error: errorEventsData,
        refetch: refetchEvents
    } = useGetEventsQuery({
        page,
        binned: isBinnedEvents,
        searchParam: searchParam,
        searchProfileId: searchProfileId || ""
    });

    const {
        data: profilesData,
        isFetching: isFetchingProfilesData,
        isLoading: isLoadingProfilesData,
        error: errorProfilesData,
        isUninitialized: isUninitializedProfilesData
    } = useGetProfilesQuery(
        {
            mode: "deflate",
            paginate: false
        },
        { skip: !isAdmin }
    );

    const handleFormSubmit = async (values: any) => {
        const message = buildTranslation(t, {
            subject: translations.subject.event,
            method: translations.request.post
        });

        await performMutation({
            mutation: async () =>
                await createEvent({
                    title: values.title
                }).unwrap(),
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error),
            onSuccessCallback: (response: any) => {
                refetchEvents();
                setIsModalOpen(false);
                if ("data" in response) {
                    navigate("/events/" + response.data.id);
                }
            }
        });
    };

    const handleCancelEventModal = () => {
        if (isLoadingCreateEvent) return;
        else setIsModalOpen(false);
    };

    const handleBinEvents = async () => {
        const message = buildTranslation(t, {
            subject: translations.subject.event,
            method: translations.request.general
        });

        await performMutation({
            mutation: async () =>
                await binEvents({
                    ids: selectedRowKeys
                }).unwrap(),
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error),
            onSuccessCallback: () => setSelectedRowKeys([])
        });
    };

    const handleRestoreEvent = async (id: string) => {
        const message = buildTranslation(t, {
            subject: translations.subject.event,
            method: translations.request.general
        });

        await performMutation({
            mutation: async () => await restoreEvent(id).unwrap(),
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error),
            onSuccessCallback: () => setSelectedRowKeys([])
        });
    };

    const tableData = useMemo(() => {
        return eventsData?.data?.map((item) => ({ ...item, key: item.id }));
    }, [eventsData]);

    const error = errorEventsData || errorProfilesData;
    const loading =
        isLoadingEventsData ||
        isLoadingCreateEvent ||
        isLoadingBinEvents ||
        isLoadingRestoreEvent ||
        isFetchingProfilesData ||
        isLoadingProfilesData ||
        (isAdmin && isUninitializedProfilesData);

    if (error) return <Error message={t("generic-error-message")} />;
    return (
        <Spin spinning={loading}>
            <PageTitle
                icon={isBinnedEvents ? <DeleteOutlined /> : <OrderedListOutlined />}
                title={isBinnedEvents ? t("events-binned-page-title") : t("events-page-title")}
            />
            <ColumnLayout
                firstColumn={
                    <>
                        <StickToTop
                            actions={
                                <>
                                    <Space direction="horizontal">
                                        {!isBinnedEvents && (
                                            <Button
                                                type="primary"
                                                onClick={() => setIsModalOpen(true)}
                                                data-testid="add-event-button"
                                                icon={<PlusOutlined />}
                                            >
                                                {t("add-new-event")}
                                            </Button>
                                        )}
                                        {!!!isBinnedEvents && (
                                            <Button
                                                onClick={() => navigate("/events-binned")}
                                                data-testid="show-binned-events-button"
                                            >
                                                <DeleteOutlined />
                                                {t("show-binned-events")}
                                            </Button>
                                        )}
                                        {!!isBinnedEvents && (
                                            <Button
                                                onClick={() => navigate("/events")}
                                                data-testid="show-events-button"
                                            >
                                                <OrderedListOutlined />
                                                {t("show-events")}
                                            </Button>
                                        )}
                                        {selectedRowKeys.length > 0 && !isBinnedEvents && (
                                            <Button
                                                type="link"
                                                color="red"
                                                onClick={handleBinEvents}
                                                style={{ marginLeft: "auto" }}
                                                data-testid="bin-events-button"
                                            >
                                                <DeleteOutlined />
                                                {t("bin-selected-events")}
                                            </Button>
                                        )}
                                    </Space>
                                    {isAdmin && (
                                        <Select
                                            defaultValue={undefined}
                                            placeholder={t("filter-per-profile")}
                                            style={{ width: 250 }}
                                            allowClear
                                            onChange={(value: string) => setSearchProfileId(value)}
                                            options={profilesData?.data.map((profile) => ({
                                                label: profile.name,
                                                value: profile.id
                                            }))}
                                        />
                                    )}
                                </>
                            }
                        ></StickToTop>
                        <SearchableTable
                            searchInputPlaceholder={t("search-events-table")}
                            setPage={setPage}
                            page={page}
                            columns={
                                isBinnedEvents
                                    ? binnedEventsTableColumns({
                                          t,
                                          navigate,
                                          restoreEvent: handleRestoreEvent
                                      })
                                    : eventsTableColumns({ t, navigate })
                            }
                            loading={isFetchingEventsData}
                            selectedRowKeys={selectedRowKeys}
                            setSelectedRowKeys={setSelectedRowKeys}
                            data={tableData}
                            paginationSize={eventsData?.pagination.perPage}
                            paginationTotal={eventsData?.pagination.total || 1}
                            onSearchParamChange={setSearchParam}
                            selectable={!isBinnedEvents}
                        ></SearchableTable>
                        <Modal
                            title={t("add-new-event-title")}
                            maskClosable={false}
                            open={isModalOpen}
                            onOk={form.submit}
                            confirmLoading={isLoadingCreateEvent}
                            onCancel={handleCancelEventModal}
                            data-testid="new-event-modal"
                        >
                            <Form
                                form={form}
                                items={[
                                    {
                                        label: t("event-title"),
                                        name: "title",
                                        type: "text",
                                        inputProps: {
                                            "data-testid": "new-event-title-input"
                                        }
                                    }
                                ]}
                                onSubmit={handleFormSubmit}
                            />
                        </Modal>
                    </>
                }
            />
        </Spin>
    );
};

export default EventsPage;
