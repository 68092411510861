import { Spin, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { AudioOutlined } from "@ant-design/icons";
import EpisodeSelector from "@components/EpisodeSelector/EpisodeSelector";
import Error from "@components/Error";
import PageTitle from "@components/PageTitle/PageTitle";
import Polling from "./Polling";
import QandA from "./Q&A";
import { getHasRequiredInfo } from "@utils/general";
import { useGetEventQuery } from "@store/events";
import { useRedirectEventsHome } from "@hooks/useRedirectEventsHome";
import { useSideMenu } from "@hooks/useSideMenu";
import { useTranslation } from "react-i18next";

const liveTypes = ["polling", "q-and-a"];

const EventLive = () => {
    const getTabPathname = () => {
        if (liveTypes.includes(pathname.split("/")?.[5])) return pathname.split("/")?.[5];
        else return null;
    };
    const navigate = useNavigate();
    const { eventId, episodeId, liveType } = useParams();
    const { pathname } = useLocation();
    useSideMenu({ isVisible: true, contentType: "event" });
    const { t } = useTranslation(["translations"]);
    const [activeTab, setActiveTab] = useState(
        liveType && liveTypes.includes(liveType) ? liveType : liveTypes[0]
    );

    useEffect(() => {
        if (episodeId) {
            navigate(`/events/${eventId}/live/${episodeId}/${activeTab}`);
        }
    }, [activeTab, episodeId]);

    const {
        data: event,
        isLoading: isLoadingEvent,
        isFetching: isFetchingEvent,
        error: errorEvent,
        isUninitialized: isUninitializedEvent
    } = useGetEventQuery({ eventId: eventId! }, { skip: !eventId });

    const { hasEventRequiredInfo, isLoadingRequiredInfo } = getHasRequiredInfo(event);

    useRedirectEventsHome({
        hasInfo: hasEventRequiredInfo,
        isLoading: isLoadingRequiredInfo,
        event: eventId
    });

    const error = errorEvent;
    const loading = isUninitializedEvent || isFetchingEvent || isLoadingEvent;

    if (error) return <Error message={t("generic-error-message")} />;
    return (
        <Spin spinning={loading} className="page-spinner">
            <EpisodeSelector
                redirectionSubpath="live"
                defaultEpisodeId={event?.default_episode.id}
                episodes={event?.episodes}
                afterEpisodePath={getTabPathname()}
            />
            <PageTitle
                icon={<AudioOutlined />}
                title={t("live")}
                additionalContent={
                    <Tabs
                        type="card"
                        activeKey={activeTab}
                        onChange={(key) => setActiveTab(key)}
                        className="full-page-tabs"
                        items={[
                            {
                                forceRender: true,
                                label: t("polling"),
                                key: liveTypes[0],
                                children: <></>
                            },
                            {
                                forceRender: true,
                                label: t("q-and-a"),
                                key: liveTypes[1],
                                children: <></>
                            }
                        ]}
                    />
                }
            />
            {liveTypes[0] === activeTab && <Polling />}
            {liveTypes[1] === activeTab && <QandA />}
        </Spin>
    );
};

export default EventLive;
