import { App } from "antd";
import { Error422 } from "@store/index";
import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface PerformMutationArgs {
    mutation: () => Promise<any>;
    successMessage: string | null;
    errorMessage: string;
    setFieldsWithErrors?: (error: Error422) => void;
    formFieldsResetter?: Function;
    onSuccessCallback?: Function;
    onErrorCallback?: Function;
}

export const usePerformMutation = () => {
    const { t } = useTranslation(["translations"]);
    const { notification } = App.useApp();

    const performMutation = async (mutationArgs: PerformMutationArgs) => {
        let response = null;
        try {
            response = await mutationArgs.mutation();
            if (mutationArgs.successMessage !== null)
                notification.success({
                    message: t("success"),
                    description: mutationArgs.successMessage,
                    placement: "top"
                });
            mutationArgs.onSuccessCallback && mutationArgs.onSuccessCallback(response);
        } catch (error: any) {
            if (error.status === 403 || error.status === 422) {
                notification.error({
                    message: t("error"),
                    description: mutationArgs.errorMessage + ": " + error.data.message,
                    placement: "top"
                });
            } else {
                notification.error({
                    message: t("error"),
                    description: mutationArgs.errorMessage,
                    placement: "top"
                });
            }
            if (error.status === 422) {
                mutationArgs.setFieldsWithErrors && mutationArgs.setFieldsWithErrors(error.data);
            } else {
                mutationArgs.formFieldsResetter && mutationArgs.formFieldsResetter();
            }
            mutationArgs.onErrorCallback && mutationArgs.onErrorCallback();
        }
    };

    return { performMutation };
};
