import { Tour, TourProps } from "antd";

import { useTranslation } from "react-i18next";

interface StylesTourProps {
    isOpen: boolean;
    setIsOpen: Function;
    refs: any[];
}

const StylesTour: React.FC<StylesTourProps> = ({ isOpen, setIsOpen, refs }) => {
    const { t } = useTranslation(["translations", "guided-tours"]);
    const steps: TourProps["steps"] = [
        {
            title: t("basic-styles"),
            description: t("guided-tours:basic-styles-description"),
            target: () => refs[0].current
        },
        {
            title: t("advanced-and-section-specific-styles"),
            description: t("guided-tours:advanced-and-section-specific-styles-description"),
            target: () => refs[1].current
        },
        {
            title: t("save-draft"),
            description: t("guided-tours:save-draft-description"),
            target: () => refs[2].current
        },
        {
            title: t("discard-draft"),
            description: t("guided-tours:discard-draft-description"),
            target: () => refs[3].current
        },
        {
            title: t("publish-styles"),
            description: t("guided-tours:publish-styles-description"),
            target: () => refs[4].current
        },
        {
            title: t("revert-local-changes"),
            description: t("guided-tours:revert-local-changes-description"),
            target: null
        }
    ];

    return <Tour open={isOpen} onClose={() => setIsOpen(false)} steps={steps} />;
};

export default StylesTour;
