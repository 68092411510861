import { useEffect, useState } from "react";

import { Channel } from "pusher-js";
import Pusher from "pusher-js/types/src/core/pusher";
import initPusher from "./index";
import { useGetEventQuery } from "@store/events";
import { useLocation } from "react-router-dom";

export const useProfileChannelSubscription = ({ token }: { token: string | undefined }) => {
    const channelPrefix = "private-profile";
    const { pathname } = useLocation();
    const [currentProfileId, setCurrentProfileId] = useState<number | null>(null);
    const [pusherInstance, setPusherInstance] = useState<Pusher | null>(null);
    const [channel, setChannel] = useState<Channel | null>(null);
    const eventId = pathname.split("/")[1] === "events" ? pathname.split("/")[2] : null;

    const { data: event } = useGetEventQuery({ eventId: eventId! }, { skip: !!!eventId });

    useEffect(() => {
        if (token) setPusherInstance(initPusher(token));
        else {
            setPusherInstance((previousPusherInstance) => {
                if (previousPusherInstance !== null) previousPusherInstance.disconnect();
                return null;
            });
        }
    }, [token]);

    useEffect(() => {
        if (!event?.profile_id || !eventId) setCurrentProfileId(null);
        else if (event?.profile_id && event.id === parseInt(eventId)) setCurrentProfileId(event.profile_id);
    }, [event, eventId]);

    useEffect(() => {
        if (currentProfileId && pusherInstance) {
            setChannel((previousChannel) => {
                if (previousChannel !== null) previousChannel?.unsubscribe();
                return pusherInstance.subscribe(`${channelPrefix}.${currentProfileId}`);
            });
        } else {
            setChannel((previousChannel) => {
                if (previousChannel !== null) previousChannel?.unsubscribe();
                return null;
            });
        }
    }, [currentProfileId, pusherInstance]);

    return { channel };
};
