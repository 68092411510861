import { Select, Space, Typography } from "antd";
import { useDispatch, useSelector } from "@store/hooks";
import { useLocation, useNavigate, useParams } from "react-router";

import { selectEvents } from "@store/ui/selectors";
import { setSelectedEpisode } from "@store/ui";
import styles from "./EpisodeSelector.module.scss";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface EpisodesSelectorProps {
    defaultEpisodeId: number | undefined;
    episodes: { id: number; title: string }[] | undefined;
    redirectionSubpath: string;
    hideContents?: boolean;
    afterEpisodePath?: string | null;
}

const EpisodeSelector: React.FC<EpisodesSelectorProps> = ({
    defaultEpisodeId,
    episodes,
    redirectionSubpath,
    hideContents,
    afterEpisodePath
}) => {
    const { eventId, episodeId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation(["translations"]);
    const dispatch = useDispatch();

    const events = useSelector(selectEvents);

    const handleEpisodeChange = (episodeId: string | number | undefined) => {
        if (episodeId && eventId) {
            dispatch(setSelectedEpisode({ event: eventId, episode: episodeId }));
            navigate(
                "/events/" +
                    eventId +
                    "/" +
                    redirectionSubpath +
                    "/" +
                    episodeId +
                    (afterEpisodePath ? `/${afterEpisodePath}` : "")
            );
        }
    };

    useEffect(() => {
        if (!episodeId) handleEpisodeChange(events[eventId!]?.selectedEpisode || defaultEpisodeId);
    }, [location, defaultEpisodeId]);

    return (
        <Space
            direction="vertical"
            className={styles["episode-selector"]}
            style={{ visibility: hideContents ? "hidden" : "inherit" }}
        >
            <Typography.Text>
                <b>{t("change-selected-episode")}</b>
            </Typography.Text>
            <Select
                value={episodes?.find((eps) => String(eps.id) === episodeId)?.title || ""}
                data-testid="episode-selector"
                onChange={(newValue: string) => {
                    handleEpisodeChange(newValue);
                }}
                className={styles["episode-selector__select"]}
                options={episodes?.map((episode) => {
                    return {
                        value: episode.id,
                        label: episode.title
                    };
                })}
            />
        </Space>
    );
};

export default EpisodeSelector;
