import { Button, Spin, Typography } from "antd";
import { useGetEventQuery, useGetProfileUsersQuery, useShareEventMutation } from "@store/events";

import ActionsBanner from "@components/ActionsBanner/ActionsBanner";
import ColumnLayout from "@components/ColumnLayout/ColumnLayout";
import Error from "@components/Error";
import Form from "@components/Form";
import PageTitle from "@components/PageTitle/PageTitle";
import { ShareAltOutlined } from "@ant-design/icons";
import { UserType } from "@store/events/types";
import { useEffect } from "react";
import { useParams } from "react-router";
import { usePerformMutation } from "@utils/perform-mutation";
import { useSideMenu } from "@hooks/useSideMenu";
import { useTranslation } from "react-i18next";

const Event = () => {
    const { eventId } = useParams();
    const [form] = Form.useForm();
    useSideMenu({ isVisible: true, contentType: "event" });
    const { t } = useTranslation(["translations"]);
    const { performMutation } = usePerformMutation();

    const {
        data: event,
        error: errorEvent,
        isLoading: isLoadingEvent,
        isFetching: isFetchingEvent,
        isUninitialized: isUninitializedEvent,
        refetch: refetchEvent
    } = useGetEventQuery({ eventId: eventId! }, { skip: !!!eventId });

    const {
        data: users,
        error: errorUsers,
        isLoading: isLoadingUsers,
        isFetching: isFetchingUsers,
        isUninitialized: isUninitializedUsers,
        refetch: refetchUsers
    } = useGetProfileUsersQuery(event?.profile_id, {
        skip: !!!event?.profile_id
    });

    const [shareEvent, { isLoading: isLoadingShareEvent, error: errorShareEvent }] = useShareEventMutation();

    const handleOnSubmit = async (values: any) => {
        await performMutation({
            mutation: async () =>
                await shareEvent({
                    event: eventId,
                    users: values.users.map((user: string) => parseInt(user))
                }).unwrap(),
            successMessage: t("event-share-success"),
            errorMessage: t("event-share-fail"),
            onSuccessCallback: () => {
                refetchEvent();
                refetchUsers();
            }
        });
    };

    useEffect(() => {
        if (event && users) {
            form.setFieldsValue({
                users: event?.users?.map((user: UserType) => String(user.id))
            });
        }
    }, [form, event, users]);

    const loading =
        isUninitializedEvent ||
        isUninitializedUsers ||
        isLoadingEvent ||
        isFetchingEvent ||
        isLoadingUsers ||
        isFetchingUsers ||
        isLoadingShareEvent;

    const error = errorEvent || errorShareEvent || errorUsers;

    if (error) return <Error message={t("generic-error-message")} />;
    return (
        <Spin spinning={loading}>
            <PageTitle icon={<ShareAltOutlined />} title={t("share-event")} />
            <ColumnLayout
                firstColumn={
                    <Form
                        form={form}
                        items={[
                            {
                                name: "users",
                                label: t("users"),
                                type: "checkbox-group",
                                spanWidth: {
                                    xs: 24,
                                    lg: 24
                                },
                                subSpanWidth: {
                                    xs: 24,
                                    lg: 6
                                },
                                options: users?.map(
                                    (user: UserType) =>
                                        ({
                                            label: (
                                                <>
                                                    <Typography.Text data-testid="share-event-user-name">
                                                        {String(user.name)}
                                                    </Typography.Text>
                                                    <div>
                                                        <Typography.Text
                                                            type="secondary"
                                                            data-testid="share-event-user-email"
                                                        >
                                                            {String(user.email)}
                                                        </Typography.Text>
                                                    </div>
                                                </>
                                            ),
                                            value: String(user.id)
                                        } as any)
                                )
                            }
                        ]}
                        onSubmit={handleOnSubmit}
                    />
                }
                banner={
                    <ActionsBanner>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            onClick={form.submit}
                            data-testid="share-event-submit-button"
                        >
                            {t("share")}
                        </Button>
                    </ActionsBanner>
                }
            />
        </Spin>
    );
};

export default Event;
