import {
    Alert,
    Form as AntdForm,
    Button,
    Card,
    DatePicker,
    Divider,
    Input,
    List,
    Space,
    Switch,
    Typography
} from "antd";
import { BulbOutlined, PlusOutlined } from "@ant-design/icons";
import { buildTranslation, translations } from "@translations/crudTranslationBuilder";
import { timeZoneToUtc, utcToTimeZone } from "@utils/timezones";
import {
    useCreateEvaluationFormSlotMutation,
    useDeleteEvaluationFormSlotMutation,
    useGetEpisodesQuery,
    useGetEvaluationFormCategoriesQuery,
    useGetEvaluationFormSlotsQuery,
    useGetEventQuery,
    usePublishEvaluationFormSlotMutation,
    useUpdateEvaluationFormSlotMutation
} from "@store/events";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ActionsBanner from "@components/ActionsBanner/ActionsBanner";
import ColumnLayout from "@components/ColumnLayout/ColumnLayout";
import Error from "@components/Error";
import { EvaluationFormSlotType } from "@store/events/types";
import Form from "@components/Form";
import { Moment } from "moment";
import SearchableTable from "@components/SearchableTable/SearchableTable";
import StickToTop from "@components/StickToTop/StickToTop";
import { evaluationFormSlotsTableColumns } from "@utils/layout/evaluation-form/evaluationFormSlotsTableColumns";
import moment from "moment";
import momentGenerateConfig from "rc-picker/lib/generate/moment";
import { produce } from "immer";
import { usePerformMutation } from "@utils/perform-mutation";
import { useSideMenu } from "@hooks/useSideMenu";
import { useTranslation } from "react-i18next";

const MomentDatePicker = DatePicker.generatePicker<Moment>(momentGenerateConfig);

interface QuestionsSlotsProps {
    setIsLoading: (bool: boolean) => void;
}

const QuestionsSlots: React.FC<QuestionsSlotsProps> = ({ setIsLoading }) => {
    useSideMenu({ isVisible: true, contentType: "event" });
    const { t } = useTranslation(["translations"]);
    const { eventId, episodeId, slotId, duplicateId } = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const addNewSlot = slotId === "add-new";
    const addDuplicateSlot = slotId === "add-duplicate";
    const { performMutation } = usePerformMutation();
    const [page, setPage] = useState<number>(1);

    const [createSlot, { isLoading: isLoadingCreateSlot }] = useCreateEvaluationFormSlotMutation();
    const [updateSlot, { isLoading: isLoadingUpdateSlot }] = useUpdateEvaluationFormSlotMutation();
    const [deleteSlot, { isLoading: isLoadingDeleteSlot }] = useDeleteEvaluationFormSlotMutation();
    const [publishSlot, { isLoading: isLoadingPublishSlot }] = usePublishEvaluationFormSlotMutation();

    const {
        data: slotsData,
        isLoading: isLoadingSlotsData,
        isFetching: isFetchingSlotsData,
        error: errorSlotsData,
        isUninitialized: isUninitializedSlotsData
    } = useGetEvaluationFormSlotsQuery({ episodeId: episodeId!, page: page }, { skip: !!!episodeId });

    const {
        data: episodesData,
        isLoading: isLoadingEpisodesData,
        isFetching: isFetchingEpisodesData,
        error: errorEpisodesData,
        isUninitialized: isUninitializedEpisodesData,
        refetch: refetchEpisodes
    } = useGetEpisodesQuery({ event: eventId, searchParam: "", paginate: false });

    const {
        data: event,
        isLoading: isLoadingEvent,
        isFetching: isFetchingEvent,
        isUninitialized: isUninitializedEvent,
        error: errorEvent
    } = useGetEventQuery({ eventId: eventId! }, { skip: eventId === undefined });

    const {
        data: evaluationFormCategories,
        isLoading: isLoadingEvaluationFormCategories,
        isFetching: isFetchingEvaluationFormCategories,
        error: errorEvaluationFormCategories,
        isUninitialized: isUninitializedEvaluationFormCategories
    } = useGetEvaluationFormCategoriesQuery({ episode: episodeId! }, { skip: !!!episodeId });

    const questions = useMemo(() => {
        if (!evaluationFormCategories) return [];
        let evaluationFormCategoryItem = evaluationFormCategories[0];
        if (evaluationFormCategoryItem) return evaluationFormCategoryItem.questions;
        else return [];
    }, [evaluationFormCategories]);

    const selectedSlot = useMemo(() => {
        if (!slotsData) return undefined;
        return slotsData.data.find((slot: EvaluationFormSlotType) => String(slot.id) === slotId);
    }, [slotId, slotsData]);

    useEffect(() => {
        if (addNewSlot) {
            form.resetFields();
            form.setFields(
                questions?.map((question) => {
                    return {
                        name: question.id,
                        value: question.published
                    };
                })
            );
        } else if (duplicateId && addDuplicateSlot) {
            form.resetFields();
            const selectedDuplicate = slotsData?.data.find(
                (slot: EvaluationFormSlotType) => String(slot.id) === duplicateId
            );
            form.setFieldsValue({
                ...selectedDuplicate,
                title: selectedDuplicate?.title + " - duplicate",
                ...selectedSlot,
                start_at: undefined
            });
            form.setFields(
                questions?.map((question) => {
                    return {
                        name: question.id,
                        value: !!selectedDuplicate?.questions_config.includes(question.id)
                    };
                })
            );
        } else if (selectedSlot && form) {
            form.resetFields();
            form.setFieldsValue({
                ...selectedSlot,
                start_at: selectedSlot.start_at
                    ? moment(utcToTimeZone({ time: selectedSlot.start_at, zone: event?.timezone }))
                    : undefined
            });
            form.setFields(
                questions?.map((question) => {
                    return {
                        name: question.id,
                        value: !!selectedSlot.questions_config.includes(question.id)
                    };
                })
            );
        }
    }, [selectedSlot, addNewSlot, questions, form, addDuplicateSlot, duplicateId]);

    const handleCUDSlot =
        (method: "post" | "put" | "delete", slotId?: string | number) =>
        async (values?: { [key: string]: boolean | string }) => {
            const message = buildTranslation(t, {
                subject: translations.subject.questionsGroup,
                method: translations.request[method]
            });

            const slotValues = values || {};
            await performMutation({
                mutation: async () =>
                    method === "post"
                        ? await createSlot({
                              episodeId: episodeId!,
                              title: slotValues.title as string,
                              type: 1,
                              configuration: produce(slotValues, (draft) => {
                                  delete draft["title"];
                                  delete draft["start_at"];
                              }) as { [key: string]: boolean },
                              start_at: slotValues.start_at
                                  ? timeZoneToUtc({
                                        time: slotValues.start_at as string,
                                        zone: event?.timezone!
                                    })
                                  : null
                          }).unwrap()
                        : method === "put"
                        ? await updateSlot({
                              episodeId: episodeId!,
                              title: slotValues.title as string,
                              slotId: slotId!,
                              type: 1,
                              configuration: produce(slotValues, (draft) => {
                                  delete draft["title"];
                                  delete draft["start_at"];
                              }) as { [key: string]: boolean },
                              start_at: slotValues.start_at
                                  ? timeZoneToUtc({
                                        time: slotValues.start_at as string,
                                        zone: event?.timezone!
                                    })
                                  : null
                          }).unwrap()
                        : await deleteSlot({
                              episodeId: episodeId!,
                              slotId: slotId!
                          }).unwrap(),
                successMessage: message(translations.outcome.success),
                errorMessage: message(translations.outcome.error),
                onSuccessCallback: () => {
                    refetchEpisodes();
                    navigate(`/events/${eventId}/evaluation-form/${episodeId}/slots`);
                }
            });
        };

    const handlePublishSlot = async (slotId: number, keepOldSchedule: boolean) => {
        const message = buildTranslation(t, {
            subject: translations.subject.questionsGroup,
            method: translations.request.general
        });

        await performMutation({
            mutation: async () =>
                publishSlot({
                    episodeId: episodeId!,
                    slotId: slotId,
                    keep_old_schedule: keepOldSchedule
                }).unwrap(),
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error),
            onSuccessCallback: () => refetchEpisodes()
        });
    };

    const tableData = useMemo(
        () =>
            slotsData?.data?.map((item) => ({
                ...item,
                key: item.id,
                isActive:
                    item.id ===
                    episodesData?.data?.find((ep) => ep.id === parseInt(episodeId!))
                        ?.current_evaluation_form_survey_slot_id
            })),
        [slotsData, episodesData?.data, episodeId]
    );

    const error = errorSlotsData || errorEvent || errorEvaluationFormCategories || errorEpisodesData;
    const loading =
        isLoadingSlotsData ||
        isFetchingSlotsData ||
        isUninitializedSlotsData ||
        isLoadingEvaluationFormCategories ||
        isFetchingEvaluationFormCategories ||
        isUninitializedEvaluationFormCategories ||
        isLoadingCreateSlot ||
        isLoadingUpdateSlot ||
        isLoadingDeleteSlot ||
        isLoadingPublishSlot ||
        isLoadingEpisodesData ||
        isFetchingEpisodesData ||
        isLoadingEvent ||
        isFetchingEvent ||
        isUninitializedEvent ||
        isUninitializedEpisodesData;

    useEffect(() => {
        setIsLoading(loading);
    }, [loading, setIsLoading]);

    if (error) return <Error message={t("generic-error-message")} />;
    return (
        <>
            <ColumnLayout
                firstColumn={
                    <>
                        <StickToTop
                            title={<Typography.Title level={4}>{t("question-groups")}</Typography.Title>}
                            actions={
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={() =>
                                        navigate(
                                            `/events/${eventId}/evaluation-form/${episodeId}/slots/add-new`
                                        )
                                    }
                                    type="primary"
                                    disabled={addNewSlot || !!!questions.length}
                                >
                                    {t("add-new")}
                                </Button>
                            }
                        ></StickToTop>
                        {!!questions.length && !!slotsData?.data?.length ? (
                            <SearchableTable
                                page={page}
                                setPage={setPage}
                                paginationSize={slotsData?.pagination.perPage}
                                paginationTotal={slotsData?.pagination.total}
                                searchInputPlaceholder={t("search-episodes-table")}
                                columns={evaluationFormSlotsTableColumns({
                                    t,
                                    goToSlot: (slotId: string | number) =>
                                        navigate(
                                            `/events/${eventId}/evaluation-form/${episodeId}/slots/${slotId}`
                                        ),
                                    onDeleteItem: handleCUDSlot,
                                    duplicate: (slotId: string | number) =>
                                        navigate(
                                            `/events/${eventId}/evaluation-form/${episodeId}/slots/add-duplicate/${slotId}`
                                        ),
                                    publishSlot: handlePublishSlot
                                })}
                                selectedRowKeys={slotId && !isNaN(parseInt(slotId)) ? [parseInt(slotId)] : []}
                                disableSelectionCheckboxes={true}
                                selectable={false}
                                data={tableData}
                            />
                        ) : (
                            <Alert
                                message={t("no-questions-groups")}
                                type="info"
                                icon={<BulbOutlined />}
                                showIcon
                            />
                        )}
                    </>
                }
                secondColumn={
                    !!questions.length && (selectedSlot || addNewSlot || addDuplicateSlot) ? (
                        <>
                            <StickToTop
                                title={
                                    <Typography.Title level={4}>
                                        {addNewSlot
                                            ? t("add-new")
                                            : addDuplicateSlot
                                            ? t("add-duplicate")
                                            : selectedSlot?.title}
                                    </Typography.Title>
                                }
                            ></StickToTop>
                            <Alert message={t("slot-no-date-warning-message")} type="warning" />
                            <Divider />
                            <AntdForm
                                layout="vertical"
                                labelWrap
                                scrollToFirstError
                                form={form}
                                onFinish={
                                    addNewSlot || addDuplicateSlot
                                        ? handleCUDSlot("post")
                                        : handleCUDSlot("put", slotId)
                                }
                            >
                                <AntdForm.Item
                                    name="title"
                                    label={t("question-group-title")}
                                    labelCol={{ span: 24 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("field-required")
                                        }
                                    ]}
                                >
                                    <Input name="title" />
                                </AntdForm.Item>
                                <Divider />
                                <List
                                    dataSource={questions || []}
                                    renderItem={(question) => {
                                        return (
                                            <Card
                                                bodyStyle={{ display: "none" }}
                                                title={
                                                    <Typography.Text>
                                                        {question?.content_default}
                                                    </Typography.Text>
                                                }
                                                extra={
                                                    <AntdForm.Item
                                                        name={question.id}
                                                        label={t("publish")}
                                                        style={{ marginBottom: 5 }}
                                                        valuePropName="checked"
                                                    >
                                                        <Switch
                                                            checkedChildren={t("yes")}
                                                            unCheckedChildren={t("no")}
                                                        />
                                                    </AntdForm.Item>
                                                }
                                            />
                                        );
                                    }}
                                />
                                <Divider />
                                <AntdForm.Item name="start_at" label={t("start-at")} labelCol={{ span: 24 }}>
                                    <MomentDatePicker
                                        showTime={{ format: "HH:mm" }}
                                        format={"DD-MM-YYYY HH:mm"}
                                    />
                                </AntdForm.Item>
                            </AntdForm>
                        </>
                    ) : (
                        <>
                            <StickToTop
                                title={
                                    <Typography.Title level={4}>
                                        {t("question-group-details")}
                                    </Typography.Title>
                                }
                            ></StickToTop>
                            <Alert
                                message={t("select-item-to-edit")}
                                data-testid="no-episode-selected-alert"
                                type="info"
                                icon={<BulbOutlined />}
                                showIcon
                            ></Alert>
                        </>
                    )
                }
                secondColumnBanner={
                    !!questions.length &&
                    (selectedSlot || addNewSlot || addDuplicateSlot) && (
                        <>
                            <ActionsBanner>
                                <Space direction="horizontal" align="end">
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        size="large"
                                        onClick={form.submit}
                                        disabled={!!selectedSlot?.published_at}
                                    >
                                        {addNewSlot || addDuplicateSlot ? t("save") : t("update")}
                                    </Button>
                                </Space>
                            </ActionsBanner>
                        </>
                    )
                }
            />
        </>
    );
};

export default QuestionsSlots;
