import { Button, Spin } from "antd";
import { buildTranslation, translations } from "@translations/crudTranslationBuilder";
import { useCreateProfileMutation, useGetProfileQuery, useUpdateProfileMutation } from "@store/profile";
import { useNavigate, useParams } from "react-router-dom";

import ActionsBanner from "@components/ActionsBanner/ActionsBanner";
import ColumnLayout from "@components/ColumnLayout/ColumnLayout";
import Error from "@components/Error";
import { Error422 } from "@store/index";
import Form from "@components/Form";
import { HomeOutlined } from "@ant-design/icons";
import PageTitle from "@components/PageTitle/PageTitle";
import { mapErrorsToFields } from "@utils/mapErrorsToFields";
import { profileFormInputs } from "@utils/forms/profiles/profileFormInputs";
import { useEffect } from "react";
import { usePerformMutation } from "@utils/perform-mutation";
import { useSideMenu } from "@hooks/useSideMenu";
import { useTranslation } from "react-i18next";

const Profile = () => {
    const { profileId } = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    useSideMenu({ isVisible: true, contentType: "profile" });
    const { t } = useTranslation(["translations"]);
    const isAddingProfile = profileId === "new-profile";
    const { performMutation } = usePerformMutation();

    const [updateProfile, { isLoading: isLoadingUpdateProfile }] = useUpdateProfileMutation();
    const [createProfile, { isLoading: isLoadingCreateProfile }] = useCreateProfileMutation();

    const {
        data: profile,
        isFetching: isFetchingProfile,
        isLoading: isLoadingProfile,
        error: errorProfile
    } = useGetProfileQuery({ profileId: profileId! }, { skip: !!!profileId || isAddingProfile });

    const handleCUProfile = (method: "put" | "post") => async (values: any) => {
        const message = buildTranslation(t, {
            subject: translations.subject.profile,
            method: translations.request[method]
        });

        await performMutation({
            mutation: async () => {
                method === "put"
                    ? await updateProfile({
                          profileId: profileId!,
                          ...values
                      }).unwrap()
                    : await createProfile({
                          ...values
                      }).unwrap();
            },
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error),
            setFieldsWithErrors: (error: Error422) => {
                const valuesWithErrors = mapErrorsToFields({ error, values });
                form.setFields(valuesWithErrors);
            },
            onSuccessCallback: () => {
                if (method === "post") navigate("/profiles");
            }
        });
    };

    useEffect(() => {
        if (profile) form.setFieldsValue(profile);
    }, [form, profile]);

    const loading = isLoadingUpdateProfile || isFetchingProfile || isLoadingProfile || isLoadingCreateProfile;

    const eventNotFoundError =
        errorProfile && "originalStatus" in errorProfile && [404, 403].includes(errorProfile.originalStatus);
    const error = errorProfile;

    if (eventNotFoundError)
        return (
            <Error status={errorProfile.originalStatus as 404 | 403} message={t("event-not-found")}>
                <Button type="primary" onClick={() => navigate("/events")}>
                    {t("go-to-events-page")}
                </Button>
            </Error>
        );
    if (error) return <Error message={t("generic-error-message")} />;
    return (
        <Spin spinning={loading}>
            <PageTitle
                icon={<HomeOutlined />}
                title={isAddingProfile ? t("add-new-profile") : t("profile-page-title")}
            />
            <ColumnLayout
                firstColumn={
                    <Form
                        form={form}
                        items={profileFormInputs({ t })}
                        onSubmit={handleCUProfile(isAddingProfile ? "post" : "put")}
                    />
                }
                banner={
                    <ActionsBanner>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            onClick={form.submit}
                            data-testid="event-update-button"
                        >
                            {isAddingProfile ? t("create-profile") : t("update-profile")}
                        </Button>
                    </ActionsBanner>
                }
            />
        </Spin>
    );
};

export default Profile;
