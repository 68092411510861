import { Button, Popconfirm, Space } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { ProfileUserType } from "@store/profile/types";

interface ProfileUsersTableColumnsArgs {
    t: Function;
    navigate: Function;
    deleteUser: (
        method: "put" | "post" | "delete",
        userId: number | undefined
    ) => (values: any) => Promise<any>;
}

export const usersTableColumns = ({ t, navigate, deleteUser }: ProfileUsersTableColumnsArgs) => [
    {
        title: t("id"),
        dataIndex: "id",
        key: "id"
    },
    {
        title: t("email"),
        dataIndex: "email",
        key: "email",
        render: (email: string, row: ProfileUserType) => {
            return (
                <Button
                    type="text"
                    data-testid="episode-title-button"
                    onClick={() => navigate("/profiles/" + row.profile.id + "/users/" + row.id)}
                >
                    {email}
                </Button>
            );
        }
    },
    {
        title: t("actions"),
        dataIndex: "actions",
        key: "actions",
        render: (_: any, row: ProfileUserType) => {
            return (
                <Space direction="horizontal">
                    <Button
                        data-testid={"edit-profile-user-" + row.id}
                        onClick={() => navigate("/profiles/" + row.profile.id + "/users/" + row.id)}
                        title={t("edit-user")}
                        icon={<EditOutlined />}
                    ></Button>
                    <Popconfirm
                        placement="top"
                        key="delete"
                        title={t("delete-user-alert")}
                        onConfirm={() => deleteUser("delete", row.id)(null)}
                        okText={t("delete-user")}
                        cancelText={t("cancel")}
                    >
                        <Button
                            data-testid={"edit-profile-user-" + row.id}
                            title={t("delete-user")}
                            icon={<DeleteOutlined />}
                        />
                    </Popconfirm>
                </Space>
            );
        }
    }
];
