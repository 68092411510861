import { Alert, Button, Col, Form, Input, Modal, Result, Row, Space, Spin, Typography } from "antd";
import { CheckCircleOutlined, MobileOutlined, ReloadOutlined } from "@ant-design/icons";
import {
    LoginFormFields,
    clearLoggingErrors,
    clearUser,
    completeLogin,
    login,
    selectUser,
    setQRCode
} from "@store/user";
import React, { useEffect, useState } from "react";
import adminReducer, { useReset2FAMutation, useRestore2FAMutation } from "@store/admin";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import ChangePasswordModal from "@components/ChangePasswordModal/ChangePasswordModal";
import { Error422 } from "@store/index";
import ErrorMessage from "@components/Error";
import { clearToken } from "@utils/auth";
import eventsApi from "@store/events";
import internationalizationApi from "@store/internationalization";
import liveApi from "@store/live";
import { logout } from "@utils/logout";
import { mapErrorsToFields } from "@utils/mapErrorsToFields";
import profileApi from "@store/profile";
import { usePerformMutation } from "@utils/perform-mutation";
import { useQueryParams } from "@hooks/useQueryParams";
import { useRequestPasswordResetMutation } from "@store/admin";
import { useResetLoginErrors } from "@hooks/useResetLoginErrors";
import { useTranslation } from "react-i18next";

const Login: React.FC = () => {
    const { t } = useTranslation(["translations"]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const {
        token,
        identity,
        qr_code,
        loggedStatus: { error: loggedError },
        completeLoginStatus: { loading: loadingCompleteLogin },
        loginStatus: { loading: loginLoading }
    } = useSelector(selectUser);
    const { performMutation } = usePerformMutation();

    const rParam = useQueryParams("r");
    const twoFactorResetUrl = rParam ? atob(rParam) : null;

    const [form] = Form.useForm();
    const [twoFAForm] = Form.useForm();
    const [emailPasswordResetForm] = Form.useForm();

    const [mandatoryPasswordChange, setMandatoryPasswordChange] = useState<boolean>(false);
    const [resetPassowrdEmailModal, setResetPasswordEmailModal] = useState<boolean>(false);
    const [isSuccessResetPassword, setIsSuccessResetPassword] = useState<boolean>(false);

    const [reset2FA, { isLoading: isLoadingReset2FA, error: reset2FAError }] = useReset2FAMutation();
    const [restore2FA, { isLoading: isLoadingRestore2FA, error: restore2FAError }] = useRestore2FAMutation();
    const [requestPasswordReset, { isLoading: isLoadingPasswordReset, error: passwordResetError }] =
        useRequestPasswordResetMutation();

    const [searchParams] = useSearchParams();

    useEffect(() => {
        dispatch(eventsApi.util.resetApiState());
        dispatch(internationalizationApi.util.resetApiState());
        dispatch(liveApi.util.resetApiState());
        dispatch(adminReducer.util.resetApiState());
        dispatch(profileApi.util.resetApiState());
    }, []);

    useEffect(() => {
        if (identity && qr_code === undefined) {
            if (searchParams.get("redirectTo")) navigate(`${searchParams.get("redirectTo")}`);
            else navigate(`/${location.search}`);
        }
    }, [identity, qr_code, location.search, navigate]);

    useResetLoginErrors({ loginForm: form, twoFAForm: twoFAForm });

    useEffect(() => {
        if (loggedError?.status === 461) setMandatoryPasswordChange(true);
        if (loggedError?.status === 462) setMandatoryPasswordChange(true);
    }, [loggedError]);

    const handleRequestPasswordReset = async (values: any) => {
        await performMutation({
            mutation: async () =>
                await requestPasswordReset({
                    email: values.emailForPasswordReset
                }).unwrap(),
            successMessage: t("password-reset-link-success"),
            errorMessage: t("password-reset-link-error"),
            onSuccessCallback: () => setIsSuccessResetPassword(true),
            setFieldsWithErrors: (error: Error422) => {
                const valuesWithErrors = mapErrorsToFields({ error, values });
                emailPasswordResetForm.setFields(valuesWithErrors);
            }
        });
    };

    const handleReset2FA = async () => {
        await performMutation({
            mutation: async () => {
                await reset2FA().unwrap();
            },
            successMessage: null,
            errorMessage: t("generic-error-message"),
            onSuccessCallback: () => navigate("/reset-2fa")
        });
    };

    const handleRestore2FA = async (url: string) => {
        await performMutation({
            mutation: async () => await restore2FA({ url }).unwrap(),
            successMessage: null,
            errorMessage: t("generic-error-message"),
            onSuccessCallback: (response: { token: string; qr_code: string }) => {
                navigate("/login");
                dispatch(setQRCode(response.qr_code));
            }
        });
    };

    useEffect(() => {
        if (!resetPassowrdEmailModal) emailPasswordResetForm?.resetFields();
    }, [resetPassowrdEmailModal, emailPasswordResetForm]);

    const restartLogin = () => {
        clearToken();
        navigate("/login");
        window.location.reload();
    };

    const handleSubmitForm = (values: LoginFormFields) => dispatch(login(values) as any);
    const handle2FA = (values: { one_type_password: string }) => dispatch(completeLogin(values) as any);

    const error = !(loggedError?.status === 461 || loggedError?.status === 462)
        ? loggedError
        : false || reset2FAError || restore2FAError || passwordResetError;

    if (error?.message === "2FA error")
        return (
            <Result
                status="info"
                title={t("2fa-required")}
                subTitle={t("login-again")}
                extra={
                    <Button onClick={restartLogin} icon={<ReloadOutlined />}>
                        {t("reload")}
                    </Button>
                }
            />
        );
    else if (error)
        return (
            <ErrorMessage message={t("login-error-message")} noHomePageButton={true}>
                <Button icon={<ReloadOutlined />} onClick={restartLogin}>
                    {t("reload")}
                </Button>
            </ErrorMessage>
        );
    return (
        <Spin spinning={isLoadingReset2FA || isLoadingRestore2FA}>
            <Row
                style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "100px"
                }}
            >
                <Col lg={8} md={12} sm={24} xl={8} xxl={6}>
                    {!!token && !!twoFactorResetUrl && (
                        <Result
                            title={t("reset-2fa")}
                            subTitle={t("confirm-reset-2fa-text")}
                            extra={
                                <Space direction="horizontal">
                                    <Button
                                        type="primary"
                                        onClick={() => handleRestore2FA(twoFactorResetUrl)}
                                    >
                                        {t("confirm")}
                                    </Button>
                                    <Button onClick={restartLogin}>{t("cancel")}</Button>
                                </Space>
                            }
                        />
                    )}
                    {!!!token && qr_code === undefined && (
                        <>
                            <Typography.Title>{t("login")}</Typography.Title>
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={handleSubmitForm}
                                data-testid="login-form"
                            >
                                <Row gutter={[16, 10]}>
                                    <Col xs={24}>
                                        <Form.Item
                                            label={t("email")}
                                            name="email"
                                            id="email-field"
                                            rules={[{ required: true, message: t("email-required-message") }]}
                                        >
                                            <Input
                                                data-testid="login-email-field"
                                                placeholder={t("email-placeholder")}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24}>
                                        <Form.Item
                                            label={t("password")}
                                            name="password"
                                            id="password-field"
                                            rules={[
                                                { required: true, message: t("password-required-message") }
                                            ]}
                                        >
                                            <Input.Password
                                                data-testid="login-password-field"
                                                placeholder={t("password-placeholder")}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24}>
                                        <Form.Item>
                                            <Button
                                                loading={loginLoading}
                                                htmlType="submit"
                                                data-testid="login-submit-button"
                                                type="primary"
                                            >
                                                {t("login")}
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                            <Button type="dashed" onClick={() => setResetPasswordEmailModal(true)}>
                                {t("forgot-password")}
                            </Button>
                        </>
                    )}
                    {typeof qr_code === "string" && !twoFactorResetUrl && (
                        <>
                            <Typography.Title>1 - {t("setup-2fa")}</Typography.Title>
                            <div dangerouslySetInnerHTML={{ __html: qr_code }} />
                        </>
                    )}
                    {qr_code !== undefined && !twoFactorResetUrl && (
                        <>
                            <Typography.Title>
                                {typeof qr_code === "string" && "2 -"} {t("complete-2fa-verification")}
                            </Typography.Title>
                            <Form
                                form={twoFAForm}
                                layout="vertical"
                                onFinish={handle2FA}
                                data-testid="login-form"
                            >
                                <Row gutter={[16, 10]}>
                                    <Col xs={24}>
                                        <Form.Item
                                            label={t("2fa-code")}
                                            name="one_time_password"
                                            rules={[{ required: true, message: t("field-required") }]}
                                        >
                                            <Input type="text" />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button
                                                loading={loadingCompleteLogin}
                                                htmlType="submit"
                                                data-testid="complete-login-submit-button"
                                                type="primary"
                                            >
                                                {t("send")}
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                            {!(typeof qr_code === "string" && !twoFactorResetUrl) && (
                                <Button icon={<MobileOutlined />} onClick={handleReset2FA} type="dashed">
                                    {t("reset-2fa")}
                                </Button>
                            )}
                        </>
                    )}
                    <Modal
                        title={t("request-password-reset")}
                        open={resetPassowrdEmailModal}
                        footer={isSuccessResetPassword ? null : undefined}
                        onOk={!isSuccessResetPassword ? emailPasswordResetForm.submit : undefined}
                        confirmLoading={isLoadingPasswordReset}
                        onCancel={() => setResetPasswordEmailModal(false)}
                        maskClosable={false}
                        forceRender={true}
                    >
                        {isSuccessResetPassword ? (
                            <Alert
                                showIcon
                                icon={<CheckCircleOutlined />}
                                message="The password reset link has been sent to the specified email"
                            />
                        ) : (
                            <Form
                                layout="vertical"
                                form={emailPasswordResetForm}
                                onFinish={handleRequestPasswordReset}
                            >
                                <Form.Item
                                    name="emailForPasswordReset"
                                    label={t("email")}
                                    rules={[
                                        {
                                            required: true,
                                            type: "email",
                                            message: t("not-valid-email")
                                        }
                                    ]}
                                >
                                    <Input type="email" />
                                </Form.Item>
                            </Form>
                        )}
                    </Modal>
                    <ChangePasswordModal
                        dismissable={false}
                        isOpen={mandatoryPasswordChange}
                        successCallback={() => {
                            dispatch(clearLoggingErrors());
                            dispatch(clearUser());
                        }}
                        setIsOpen={setMandatoryPasswordChange}
                        description={t("mandatory-password-change-prompt")}
                    />
                </Col>
            </Row>
        </Spin>
    );
};

export default Login;
