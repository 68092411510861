import { GlobalOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { contractOutline, expandOutline, homeOutline } from "ionicons/icons";

import { IonIcon } from "@ionic/react";
import type { MenuProps } from "antd";
import type { NavigateFunction } from "react-router-dom";

const createLabel = (label: string) => {
    return <span data-testid={label.replace(" ", "-").toLowerCase() + "-side-menu-item"}>{label}</span>;
};

const basePath = "/profiles/";

export const profilesSideMenuContents = ({
    t,
    navigate,
    profileId,
    hasRequiredInfo,
    isSideMenuCollapsed,
    setSideMenuCollapsed
}: {
    t: Function;
    navigate: NavigateFunction;
    profileId?: string;
    hasRequiredInfo: boolean;
    isSideMenuCollapsed: boolean;
    setSideMenuCollapsed: Function;
}): MenuProps["items"] => {
    return [
        {
            label: createLabel(t("home")),
            key: "home",
            icon: <IonIcon size="small" icon={homeOutline} />,
            onClick: () => navigate(basePath + profileId)
        },
        {
            label: createLabel(t("users")),
            key: "users",
            disabled: !hasRequiredInfo,
            icon: <UsergroupAddOutlined />,
            onClick: () => navigate(basePath + profileId + "/users")
        },
        {
            label: createLabel(t("domains")),
            key: "domains",
            disabled: !hasRequiredInfo,
            icon: <GlobalOutlined />,
            onClick: () => navigate(basePath + profileId + "/domains")
        },
        {
            label: createLabel(t("toggle-menu")),
            key: "collapse",
            icon: <IonIcon size="small" icon={!isSideMenuCollapsed ? contractOutline : expandOutline} />,
            onClick: () => setSideMenuCollapsed(),
            style: {
                marginTop: "auto"
            }
        }
    ];
};
