import { MultilingualFormInput } from "@components/FormElements";
import QRCodeAndPreview from "@components/QRCodeAndPreview/QRCodeAndPreview";

interface EpisodeFormArgs {
    t: Function;
    validSlug: string | null;
    eventDomain: string | null;
}

export const episodeDetailsFormInputs = ({
    t,
    validSlug,
    eventDomain
}: EpisodeFormArgs): MultilingualFormInput[] => {
    let url = eventDomain;
    if (url && url.substr(-1) !== "/") url += "/";

    const previewUrl = ["https://", url, validSlug].join("");

    const inputs = [
        {
            name: "title",
            multilingual: true,
            label: t("title"),
            type: "text",
            inputProps: {
                "data-testid": "title"
            },
            rules: [{ required: true, message: t("field-required") }]
        },
        {
            name: "subtitle",
            multilingual: true,
            label: t("subtitle"),
            type: "text",
            inputProps: {
                "data-testid": "subtitle"
            }
        },
        {
            name: "evaluation_form_title",
            multilingual: true,
            label: t("evaluation-form-title"),
            type: "text",
            inputProps: {
                "data-testid": "evaluation-form-title"
            }
        },
        {
            name: "closed_message",
            multilingual: true,
            label: t("evaluation-form-closed-message"),
            type: "text",
            inputProps: {
                "data-testid": "episode-form-closed-message"
            }
        },
        {
            name: "pre_message",
            multilingual: true,
            label: t("evaluation-form-pre-message"),
            type: "text",
            inputProps: {
                "data-testid": "episode-form-pre-message"
            }
        },
        {
            name: "post_message",
            multilingual: true,
            label: t("evaluation-form-post-message"),
            type: "text",
            inputProps: {
                "data-testid": "episode-form-post-message"
            }
        },
        {
            name: "dropdown_label",
            multilingual: true,
            label: t("dropdown-label"),
            type: "text",
            inputProps: {
                "data-testid": "dropdown-label"
            }
        },
        {
            name: "show_to_frontend",
            label: t("show-to-frontend"),
            type: "switch",
            inputProps: {
                checkedlabel: t("episode-visible-label"),
                uncheckedlabel: t("episode-hidden-label"),
                "data-testid": "show-to-frontend"
            }
        },
        {
            name: "is_qaa_form_hidden",
            label: t("hide-q&a-label"),
            type: "switch",
            inputProps: {
                checkedlabel: t("q&a-visible-label"),
                uncheckedlabel: t("q&a-hidden-label"),
                "data-testid": "is-qaa-form-hidden"
            }
        },
        {
            name: "slug",
            label: t("episode-slug"),
            type: "text",
            multilingual: false,
            inputProps: {
                addonBefore: url || undefined
            },
            addonAfter:
                url === null || validSlug === null ? undefined : <QRCodeAndPreview previewUrl={previewUrl} />,
            rules: [{ required: true, message: t("field-required") }]
        }
    ];

    return inputs as MultilingualFormInput[];
};
