import type { RequestAction, RootState } from "../index";
import { clearToken, setToken } from "@utils/auth";
import { createAsyncThunk, createDraftSafeSelector, createSlice } from "@reduxjs/toolkit";

import axios from "@utils/axios";

export type LoginFormFields = { email: string; password: string };
export type IUserReducer = {
    identity:
        | {
              data: {
                  id: number;
                  name: string;
                  email: string;
                  email_verified_at: null | string;
                  created_at: null | string;
                  updated_at: null | string;
                  deleted_at: null | string;
                  roles: UserRole[];
                  is_profile_admin: boolean;
                  is_admin: boolean;
                  is_superadmin: boolean;
                  is_mcr: boolean;
                  profile: {
                      id: number;
                  };
              };
          }
        | undefined;
    token?: string;
    qr_code: false | string | undefined;
    loggedStatus: RequestAction;
    completeLoginStatus: RequestAction;
    loginStatus: RequestAction;
    logoutStatus: RequestAction;
};

interface UserRole {
    id: number;
    name: string;
    guard_name: string;
    created_at: string;
    updated_at: string;
    pivot: {
        model_id: number;
        role_id: number;
        model_type: string;
    };
    permissions: any[];
}

const initialState: IUserReducer = {
    identity: undefined,
    token: undefined,
    qr_code: undefined,
    loggedStatus: {
        loading: false,
        error: undefined
    },
    completeLoginStatus: {
        loading: false,
        error: undefined
    },
    loginStatus: {
        loading: false,
        error: undefined
    },
    logoutStatus: {
        loading: false,
        error: undefined
    }
};

export const logout = createAsyncThunk("user/logout", async (values, thunkApi) => {
    try {
        const {
            user: { token }
        } = thunkApi.getState() as RootState;

        const res = await axios.post(
            "/logout",
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        return res.data;
    } catch (error: any) {
        return thunkApi.rejectWithValue(error);
    }
});

export const login = createAsyncThunk<any, LoginFormFields>("user/login", async (values, thunkApi) => {
    try {
        const res = await axios.post("/login", values);
        return res.data;
    } catch (error: any) {
        return thunkApi.rejectWithValue(error);
    }
});

export const completeLogin = createAsyncThunk<any, { one_type_password: string }>(
    "user/complete-login",
    async (values, thunkApi) => {
        try {
            const {
                user: { token }
            } = thunkApi.getState() as RootState;

            if (token) {
                const res = await axios.post("/complete-login", values, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                return res.data;
            }

            return thunkApi.rejectWithValue({
                code: 401
            });
        } catch (error: any) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const logged = createAsyncThunk<any>("user/logged", async (values, thunkApi) => {
    try {
        const {
            user: { token }
        } = thunkApi.getState() as RootState;

        if (token) {
            const res = await axios.get("/users/logged", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return res.data;
        }

        return thunkApi.rejectWithValue({
            code: 401
        });
    } catch (error: any) {
        return thunkApi.rejectWithValue(
            error.response
                ? {
                      code: error.response.status,
                      message: error.response.data.message
                  }
                : error
        );
    }
});

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        stickToken: (state, { payload }) => {
            state.token = payload;
        },
        clearUser: (state) => {
            state.token = undefined;
            state.identity = undefined;

            clearToken();
        },
        clearLoggingErrors: (state) => {
            state.loggedStatus.error = undefined;
        },
        clearLoginErrors: (state) => {
            state.loginStatus.error = undefined;
        },
        clearLogoutErrors: (state) => {
            state.logoutStatus.error = undefined;
        },
        clearCompleteLoginErrors: (state) => {
            state.completeLoginStatus.error = undefined;
        },
        setQRCode: (state, { payload }) => {
            state.qr_code = payload as string;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(logged.pending, (state) => {
            state.loggedStatus.loading = true;
        });

        builder.addCase(logged.fulfilled, (state, { payload }) => {
            state.loggedStatus.loading = false;
            state.identity = payload;
        });

        builder.addCase(logged.rejected, (state, { payload }) => {
            state.loggedStatus.loading = false;
            state.loggedStatus.error = payload;

            const deleteToken = !((payload as any)?.status === 461 || (payload as any)?.status === 462);

            state.token = deleteToken ? undefined : state.token;
            if (deleteToken) clearToken();
        });

        builder.addCase(login.pending, (state) => {
            state.loginStatus.loading = true;
        });

        builder.addCase(login.fulfilled, (state, { payload }) => {
            state.loginStatus.loading = false;
            state.token = payload.token;
            state.qr_code = payload.qr_code;
            setToken(payload.token);
        });

        builder.addCase(login.rejected, (state, { payload }) => {
            state.loginStatus.loading = false;
            state.qr_code = undefined;
            state.loginStatus.error = payload;
        });

        // 2FA LOGIN
        builder.addCase(completeLogin.pending, (state) => {
            state.completeLoginStatus.loading = true;
        });

        builder.addCase(completeLogin.fulfilled, (state, { payload }) => {
            state.completeLoginStatus.loading = false;
            state.qr_code = undefined;
        });

        builder.addCase(completeLogin.rejected, (state, { payload }) => {
            state.completeLoginStatus.loading = false;
            state.completeLoginStatus.error = payload;
        });

        builder.addCase(logout.pending, (state) => {
            state.logoutStatus.loading = true;
        });

        builder.addCase(logout.fulfilled, (state) => {
            state.logoutStatus.loading = false;
            state.identity = undefined;
            state.token = undefined;
            state.qr_code = undefined;
            clearToken();
        });

        builder.addCase(logout.rejected, (state, { payload }) => {
            state.logoutStatus.loading = false;
            state.logoutStatus.error = payload;
        });
    }
});

const selectState = (state: RootState) => state;
const selectSelf = createDraftSafeSelector(selectState, (state) => state.user);

export const selectUser = createDraftSafeSelector(
    selectSelf,
    ({ identity, token, qr_code, completeLoginStatus, loggedStatus, loginStatus, logoutStatus }) => ({
        identity,
        qr_code,
        token,
        loggedStatus,
        completeLoginStatus,
        loginStatus,
        logoutStatus
    })
);

export const selectIsAdmin = createDraftSafeSelector(selectUser, (user) => !!user.identity?.data.is_admin);
export const selectIsProfileAdmin = createDraftSafeSelector(
    selectUser,
    (user) => !!user.identity?.data.is_profile_admin
);

export const {
    stickToken,
    clearLoginErrors,
    clearLoggingErrors,
    clearLogoutErrors,
    clearUser,
    clearCompleteLoginErrors,
    setQRCode
} = userSlice.actions;
export default userSlice.reducer;
