import "./i18n";
import "@styles/reset.css";
import "@styles/fixes.css";

import store, { persistor } from "@store/index";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom/client";
import { getToken } from "@utils/auth";
import reportWebVitals from "./reportWebVitals";
import { stickToken } from "@store/user";

const token = getToken();
if (token) {
    store.dispatch(stickToken(token));
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </PersistGate>
    </Provider>
);

reportWebVitals();
