import React from "react";
import { IonIcon } from "@ionic/react";
import { Switch, Space } from "antd";
import { bulb, moon } from "ionicons/icons";

import { useDispatch } from "@store/hooks";
import { toggleDarkMode } from "@store/layout";

const DarkModeSwitch: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <Space direction="horizontal" align="end">
      <IonIcon size="small" icon={bulb} />
      <Switch
        checkedChildren={null}
        unCheckedChildren={null}
        defaultChecked
        onChange={() => dispatch(toggleDarkMode())}
      />
      <IonIcon size="small" icon={moon} />
    </Space>
  );
};

export default DarkModeSwitch;
