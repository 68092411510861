import { useContext, useEffect } from "react";

import { MessageContext } from "@layout/MessageContext";
import { selectUser } from "@store/user";
import { useNavigate } from "react-router-dom";
import { useSelector } from "@store/hooks";
import { useTranslation } from "react-i18next";

export const usePermissionsCheck = ({ necessaryRoles }: { necessaryRoles: string[] }) => {
    const navigate = useNavigate();
    const { t } = useTranslation(["translations"]);
    const user = useSelector(selectUser);
    const messageApi = useContext(MessageContext);

    const redirect = () => {
        navigate("/");
        messageApi.info({
            message: t("info"),
            description: t("page-not-accessible")
        });
    };

    useEffect(() => {
        let isAuthorized = false;
        necessaryRoles.forEach((necessaryRole) => {
            if (
                user.identity?.data &&
                necessaryRole in user.identity?.data &&
                (user.identity?.data as any)[necessaryRole] === true
            )
                isAuthorized = true;
        });

        if (!isAuthorized) redirect();
    }, [...necessaryRoles, user]);
};
