import { MultilingualFormInput } from "@components/FormElements";

export const qAndAUserInputs = ({
    t,
    availableEpisodes
}: {
    t: Function;
    availableEpisodes: { id: number; title: string }[];
}): MultilingualFormInput[] => {
    const inputs = [
        {
            name: "firstname",
            label: t("first-name"),
            type: "text",
            multilingual: false,
            rules: [{ required: true, message: t("field-required") }]
        },
        {
            name: "lastname",
            label: t("last-name"),
            type: "text",
            multilingual: false,
            rules: [{ required: true, message: t("field-required") }]
        },
        {
            name: "email",
            label: t("user-email"),
            multilingual: false,
            type: "email",
            rules: [{ required: true, message: t("field-required") }]
        },
        {
            name: "role",
            label: t("role"),
            multilingual: false,
            type: "select",
            options: [
                {
                    value: "moderator",
                    label: t("moderator")
                },
                {
                    value: "chairman",
                    label: t("chairman")
                }
            ],
            rules: [{ required: true, message: t("field-required") }]
        },
        {
            name: "episodes",
            label: t("associated-episodes"),
            multilingual: false,
            type: "select",
            inputProps: {
                mode: "multiple"
            },
            options: availableEpisodes?.map((episode) => {
                return {
                    value: episode.id,
                    label: episode.title
                };
            })
        }
    ];

    return inputs as MultilingualFormInput[];
};

export const qAndAUserPasswordInputs = ({ t }: { t: Function }): MultilingualFormInput[] => [
    {
        name: "password",
        label: t("user-password"),
        multilingual: false,
        type: "password",
        rules: [{ required: true, message: t("field-required") }]
    },
    {
        name: "password_confirmation",
        label: t("confirm-password"),
        multilingual: false,
        type: "password",
        rules: [{ required: true, message: t("field-required") }],
        formItemProps: {
            dependencies: ["password"],
            rules: [
                {
                    required: true,
                    message: "Please confirm the user's password"
                },
                ({ getFieldValue }: { getFieldValue: Function }) => ({
                    validator(_: any, value: string) {
                        if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error("The new password that you entered do not match!"));
                    }
                })
            ]
        }
    }
];
