import { Button, Form, Modal, Popconfirm, Space, Spin, Tabs, Typography, theme } from "antd";
import {
    DeleteOutlined,
    IeOutlined,
    InfoCircleOutlined,
    QrcodeOutlined,
    ReconciliationOutlined,
    SaveOutlined
} from "@ant-design/icons";
import { buildTranslation, translations } from "@translations/crudTranslationBuilder";
import {
    useAmendStylesMutation,
    useDiscardStylesDraftMutation,
    useGetEventQuery,
    useGetStylesQuery,
    usePublishStylesDraftMutation
} from "@store/events";
import { useEffect, useMemo, useRef, useState } from "react";

import ActionsBanner from "@components/ActionsBanner/ActionsBanner";
import BasicStyles from "./BasicStyles";
import DoubleColumnLayout from "@components/ColumnLayout/ColumnLayout";
import Error from "@components/Error";
import FormElements from "@components/FormElements";
import { GlobalToken } from "antd/lib";
import { IonIcon } from "@ionic/react";
import { MapToken } from "antd/es/theme/interface";
import PageTitle from "@components/PageTitle/PageTitle";
import QRCodeGenerator from "@components/QRCodeGenerator";
import SpecificStyles from "./SpecificStyles/SpecificStyles";
import StickToTop from "@components/StickToTop/StickToTop";
import StylesGuidedTour from "./StylesGuidedTour";
import { advancedStylesListElements } from "@utils/layout/events/advancedStylesListElements";
import { brushOutline } from "ionicons/icons";
import { customSkimmedAlgorithm } from "@utils/layout/events/customSkimmedAlgorithm";
import { debounce } from "lodash";
import { formatSpecificStyles } from "./SpecificStyles/formatSpecificStyles";
import { getFormattedStyles } from "./getFormattedStyles";
import { getHasRequiredInfo } from "@utils/general";
import { getSpecificStylesSections } from "./SpecificStyles/specificStylesSections";
import { useParams } from "react-router-dom";
import { usePerformMutation } from "@utils/perform-mutation";
import { useRedirectEventsHome } from "@hooks/useRedirectEventsHome";
import { useSideMenu } from "@hooks/useSideMenu";
import { useTranslation } from "react-i18next";

const EventStyles = () => {
    const basicStylesRef = useRef(null);
    const advancedStylesRef = useRef(null);
    const saveDraftRef = useRef(null);
    const discardDraftRef = useRef(null);
    const publishStylesRef = useRef(null);

    const { eventId } = useParams();
    const { performMutation } = usePerformMutation();
    const { t } = useTranslation(["translations", "styles"]);
    const { token } = theme.useToken();

    useSideMenu({ isVisible: true, contentType: "event" });

    const [globalStyles, setGlobalStyles] = useState<GlobalToken | MapToken | null>(null);
    const [stylesForMapping, setStylesForMapping] = useState<GlobalToken | MapToken | null>(null);

    const [disabledSpecificStyles, setDisabledSpecificStyles] = useState<{ [key: string]: null }>({});

    const [renderAdvancedStyles, setRenderAdvancedStlyes] = useState<boolean>(false);
    const [isTourOpen, setIsTourOpen] = useState<boolean>(false);
    const [advancedStylesActiveKey, setAdvancedStylesActiveKey] = useState<undefined | string>(undefined);
    const [touchedAdvancedStyles, setTouchedAdvancedStyles] = useState<string[]>([]);
    const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState<boolean>(false);

    const [basicStylesForm] = Form.useForm();
    const [advancedStylesForm] = Form.useForm();
    const [specificStylesForm] = Form.useForm();

    const [saveDraft, { isLoading: isLoadingAmendStyles }] = useAmendStylesMutation();
    const [publishDraft, { isLoading: isLoadingPublishDraft }] = usePublishStylesDraftMutation();
    const [discardDraft, { isLoading: isLoadingDiscardDraft }] = useDiscardStylesDraftMutation();

    const {
        data: stylesData,
        isLoading: isLoadingStylesData,
        isFetching: isFetchingStylesData,
        error: errorStylesData
    } = useGetStylesQuery({ eventId: eventId! });

    const {
        data: event,
        isLoading: isLoadingEvent,
        isFetching: isFetchingEvent,
        error: errorEvent,
        isUninitialized: isUninitializedEvent
    } = useGetEventQuery({ eventId: eventId! }, { skip: !!!eventId });

    const { hasEventRequiredInfo, isLoadingRequiredInfo } = getHasRequiredInfo(event);

    useRedirectEventsHome({
        hasInfo: hasEventRequiredInfo,
        isLoading: isLoadingRequiredInfo,
        event: eventId
    });

    useEffect(() => {
        if (!stylesData) return;
        if (!(Array.isArray(stylesData) && stylesData.length === 0)) {
            const dbStyles =
                stylesData.draft && !Array.isArray(stylesData.draft) ? stylesData.draft : stylesData.contents;
            setGlobalStyles(dbStyles?.global || customSkimmedAlgorithm({ token, newStyles: token }));
            setStylesForMapping(dbStyles?.global || customSkimmedAlgorithm({ token, newStyles: token }));
        } else {
            const defaultTokenStyles = customSkimmedAlgorithm({ token, newStyles: token });
            setGlobalStyles(defaultTokenStyles);
            setStylesForMapping(defaultTokenStyles);
        }
        setRenderAdvancedStlyes(true);
    }, [stylesData, stylesData?.draft, token]);

    useEffect(() => {
        if (globalStyles && advancedStylesForm && basicStylesForm) {
            basicStylesForm?.setFieldsValue(globalStyles);
            Object.keys(globalStyles)?.forEach((style) => {
                if (!touchedAdvancedStyles.includes(style)) {
                    advancedStylesForm?.setFieldValue(style, (globalStyles as any)[style]);
                }
            });
        }
    }, [globalStyles, advancedStylesForm, basicStylesForm, touchedAdvancedStyles]);

    function processStylesFromForm(changedValues: any) {
        const updatedStyles = getFormattedStyles(changedValues);
        const newStyles = customSkimmedAlgorithm({
            token,
            newStyles: { ...token, ...globalStyles, ...updatedStyles }
        });
        setGlobalStylesDebounced(newStyles);
    }

    const setGlobalStylesDebounced = debounce((globalStyles) => setGlobalStyles(globalStyles), 200);

    const handleFieldReset = (name: string) => {
        setTimeout(() => {
            setTouchedAdvancedStyles((ps) => {
                return ps.filter((elName) => elName !== name);
            });
            const purifiedStyles = {
                ...token,
                ...globalStyles,
                ...basicStylesForm.getFieldsValue(),
                ...advancedStylesForm.getFieldsValue()
            };
            delete (purifiedStyles as any)[name as any];
            const updatedStyles = customSkimmedAlgorithm({ token, newStyles: purifiedStyles });
            advancedStylesForm.setFieldValue(name, (updatedStyles as any)[name as any]);
            setGlobalStyles(updatedStyles);
        }, 200);
    };

    const debounceHanldeResetField = debounce((name: string) => handleFieldReset(name), 200);

    const advancedStylesTabs = useMemo(() => {
        if (!renderAdvancedStyles || !stylesForMapping) return [];
        else
            return advancedStylesListElements({ styles: stylesForMapping!, t }).map((category) => {
                return {
                    key: category.title as string,
                    label: t(`styles:${category.title}`),
                    forceRender: true,
                    children: (
                        <div style={{ paddingTop: 10, width: "510px", maxWidth: "100%" }}>
                            <FormElements
                                items={category.formItems}
                                onFieldReset={(name: string) => debounceHanldeResetField(name)}
                            />
                        </div>
                    )
                };
            });
    }, [stylesForMapping, renderAdvancedStyles, debounceHanldeResetField, t]);

    useEffect(() => {
        if (advancedStylesActiveKey === undefined && advancedStylesTabs) {
            setAdvancedStylesActiveKey(advancedStylesTabs[0]?.key);
        }
    }, [advancedStylesTabs, advancedStylesActiveKey]);

    const handleAdvancedStylesChange = (changedValues: any) => {
        const newlyTouchedValues: string[] = [];
        Object.keys(changedValues)?.forEach((valueName: string) => {
            newlyTouchedValues.push(valueName);
        });

        setTouchedAdvancedStyles((ps) => [...ps, ...newlyTouchedValues]);
    };

    const handleAdvancedStylesChangeDebounced = debounce(handleAdvancedStylesChange, 300);

    const handleSaveDraft = async () => {
        const message = buildTranslation(t, {
            subject: translations.subject.saveDraft,
            method: translations.request?.general
        });

        const stylesToFormat = {
            ...specificStylesForm.getFieldsValue(),
            ...disabledSpecificStyles
        };
        const specificStyles = formatSpecificStyles(stylesToFormat);
        const advancedStyles = getFormattedStyles(advancedStylesForm.getFieldsValue());

        await performMutation({
            mutation: async () => {
                await saveDraft({
                    eventId: eventId!,
                    section: 1,
                    styles: {
                        global: {
                            ...globalStyles,
                            ...advancedStyles
                        },
                        ...specificStyles
                    }
                }).unwrap();
            },
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error),
            onSuccessCallback: () => {
                setTouchedAdvancedStyles([]);
            }
        });
    };

    const handlePublishDraft = async () => {
        const message = buildTranslation(t, {
            subject: translations.subject.publishDraft,
            method: translations.request?.general
        });

        await performMutation({
            mutation: async () => {
                await publishDraft({
                    eventId: eventId!,
                    section: 1
                }).unwrap();
            },
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error)
        });
    };

    const handleDiscardDraft = async () => {
        const message = buildTranslation(t, {
            subject: translations.subject.discardDraft,
            method: translations.request?.general
        });

        await performMutation({
            mutation: async () => {
                await discardDraft({
                    eventId: eventId!,
                    section: 1
                }).unwrap();
            },
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error)
        });
    };

    const error = errorEvent || errorStylesData;
    const loading =
        !renderAdvancedStyles ||
        isLoadingEvent ||
        isFetchingEvent ||
        isUninitializedEvent ||
        isLoadingAmendStyles ||
        isLoadingPublishDraft ||
        isLoadingDiscardDraft ||
        isLoadingStylesData ||
        isFetchingStylesData;

    if (error) return <Error message={t("generic-error-message")} />;
    return (
        <Spin spinning={loading} className="page-spinner">
            <PageTitle
                icon={
                    <Typography.Title style={{ margin: 0 }}>
                        <IonIcon size="large" icon={brushOutline} style={{ marginBottom: -5 }} />
                    </Typography.Title>
                }
                title={t("styles-page-title")}
            >
                <Space direction="horizontal" style={{ position: "absolute", right: 42, top: 0 }}>
                    <Button onClick={() => setIsTourOpen(true)} icon={<InfoCircleOutlined />}>
                        {t("show-me-how-it-works")}
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => setIsQRCodeModalOpen(true)}
                        icon={<QrcodeOutlined />}
                    >
                        {t("show-qr-code")}
                    </Button>
                    {/* TODO: add correct event URL with necessary login parameters for admin preview */}
                    <Button
                        type="primary"
                        onClick={() => event?.domain && window.open(event?.domain, "_blank")}
                        icon={<IeOutlined />}
                    >
                        {t("open-browser-preview")}
                    </Button>
                </Space>
            </PageTitle>
            <DoubleColumnLayout
                banner={
                    <ActionsBanner>
                        <Space direction="horizontal">
                            <Button
                                size="large"
                                type="dashed"
                                onClick={() => handleSaveDraft()}
                                ref={saveDraftRef}
                            >
                                <ReconciliationOutlined />
                                {t("save-draft")}
                            </Button>
                            <div ref={discardDraftRef}>
                                <Popconfirm
                                    title={t("discard-draft-warning-text")}
                                    okText={t("yes")}
                                    cancelText={t("cancel")}
                                    onConfirm={() => handleDiscardDraft()}
                                    disabled={!stylesData?.draft || stylesData?.draft === null}
                                >
                                    <Button
                                        size="large"
                                        type="dashed"
                                        disabled={!stylesData?.draft || stylesData?.draft === null}
                                        onClick={basicStylesForm.submit}
                                    >
                                        <DeleteOutlined />
                                        {t("discard-draft")}
                                    </Button>
                                </Popconfirm>
                            </div>
                            <div ref={publishStylesRef}>
                                <Popconfirm
                                    title={t("publish-styles-warning-text")}
                                    okText={t("yes")}
                                    cancelText={t("cancel")}
                                    onConfirm={() => handlePublishDraft()}
                                    disabled={!stylesData?.draft || stylesData?.draft === null}
                                >
                                    <Button
                                        size="large"
                                        disabled={!stylesData?.draft || stylesData?.draft === null}
                                        type="primary"
                                    >
                                        <SaveOutlined />
                                        {t("publish-styles")}
                                    </Button>
                                </Popconfirm>
                            </div>
                        </Space>
                    </ActionsBanner>
                }
                firstColumn={
                    <BasicStyles
                        basicStylesRef={basicStylesRef}
                        processStylesFromForm={processStylesFromForm}
                        basicStylesForm={basicStylesForm}
                        setAdvancedStylesActiveKey={setAdvancedStylesActiveKey}
                    />
                }
                secondColumn={
                    <>
                        <StickToTop
                            style={{ marginBottom: 0 }}
                            title={
                                <Typography.Title
                                    ref={advancedStylesRef}
                                    level={4}
                                    data-testid="selected-agenda-item-title"
                                >
                                    {t("advanced-and-section-specific-styles")}
                                </Typography.Title>
                            }
                        >
                            <div ref={publishStylesRef} style={{ maxWidth: "100%" }}>
                                <Tabs
                                    type="card"
                                    items={[
                                        ...advancedStylesTabs.map((tab) => ({ ...tab, children: null })),
                                        ...getSpecificStylesSections({ t })
                                    ]}
                                    onChange={(key) => setAdvancedStylesActiveKey(key)}
                                    activeKey={advancedStylesActiveKey || advancedStylesTabs[0]?.key}
                                    tabBarStyle={{
                                        marginBottom: -10,
                                        paddingTop: "15px"
                                    }}
                                />
                            </div>
                        </StickToTop>
                        <div style={{ paddingTop: 20 }} />
                        <Form form={advancedStylesForm} onValuesChange={handleAdvancedStylesChangeDebounced}>
                            {advancedStylesTabs.map((tab) => {
                                return (
                                    <div
                                        key={tab.key}
                                        style={{
                                            display: advancedStylesActiveKey === tab.key ? "block" : "none"
                                        }}
                                    >
                                        {tab.children}
                                    </div>
                                );
                            })}
                        </Form>
                        {renderAdvancedStyles && (
                            <SpecificStyles
                                advancedStylesActiveKey={advancedStylesActiveKey}
                                specificStylesForm={specificStylesForm}
                                disabledSpecificStyles={disabledSpecificStyles}
                                setDisabledSpecificStyles={setDisabledSpecificStyles}
                            />
                        )}
                    </>
                }
            />
            <Modal
                title={t("qr-code-preview")}
                open={isQRCodeModalOpen}
                maskClosable={false}
                onCancel={() => setIsQRCodeModalOpen(false)}
                footer={null}
            >
                {/* TODO: add correct event URL with necessary login parameters for admin preview */}
                <QRCodeGenerator baseUrl={event?.domain || ""} showCode={true} />
            </Modal>
            <StylesGuidedTour
                isOpen={isTourOpen}
                setIsOpen={setIsTourOpen}
                refs={[basicStylesRef, advancedStylesRef, saveDraftRef, discardDraftRef, publishStylesRef]}
            />
        </Spin>
    );
};

export default EventStyles;
