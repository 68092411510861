import FormElements, { MultilingualFormInput, MultilingualFormInputTypes } from "@components/FormElements";

export const basicStylesElements: MultilingualFormInput[] = [
    {
        name: "colorPrimary",
        type: "color",
        label: "colorPrimary"
    },
    {
        name: "colorTextBase",
        type: "color",
        label: "colorText"
    },
    {
        name: "colorBgBase",
        type: "color",
        label: "colorBg"
    },
    {
        name: "fontSize",
        type: "number",
        label: "fontSize",
        inputProps: {
            addonAfter: "px"
        }
    },
    {
        name: "borderRadius",
        type: "number",
        label: "borderRadius",
        inputProps: {
            addonAfter: "px"
        }
    }
];

export const basicStylesListElements = () => {
    return basicStylesElements.map((element) => {
        return {
            title: element.name,
            label: element.label as string,
            description: element.name + "-description",
            formItem: (
                <FormElements
                    renderIndividually={true}
                    items={[
                        {
                            name: element.name,
                            type: element.type as MultilingualFormInputTypes,
                            inputProps: element.inputProps
                        }
                    ]}
                />
            )
        };
    });
};
