import { Form, List, Typography } from "antd";

import { FormInstance } from "antd/lib";
import React from "react";
import StickToTop from "@components/StickToTop/StickToTop";
import { basicStylesListElements } from "@utils/layout/events/basicStylesListElements";
import { useTranslation } from "react-i18next";

interface BasicStylesProps {
    basicStylesRef: React.RefObject<HTMLElement>;
    processStylesFromForm: (changedValues: any, values: any) => void;
    basicStylesForm: FormInstance;
    setAdvancedStylesActiveKey: Function;
    style?: { [key: string]: any };
}

const BasicStyles: React.FC<BasicStylesProps> = ({
    basicStylesRef,
    processStylesFromForm,
    basicStylesForm,
    setAdvancedStylesActiveKey,
    style
}) => {
    const { t } = useTranslation(["translations", "styles"]);
    return (
        <div style={style}>
            <StickToTop
                title={
                    <Typography.Title ref={basicStylesRef} level={4} data-testid="selected-agenda-item-title">
                        {t("basic-styles")}
                    </Typography.Title>
                }
            ></StickToTop>
            <Form form={basicStylesForm} onValuesChange={processStylesFromForm}>
                <List
                    dataSource={basicStylesListElements()}
                    renderItem={(item) => {
                        return (
                            <List.Item
                                actions={[item.formItem]}
                                onClick={() => setAdvancedStylesActiveKey(item.label)}
                                style={{ cursor: "pointer" }}
                            >
                                <List.Item.Meta
                                    title={t(`styles:${item.title}`)}
                                    description={t(`styles:${item.description}`)}
                                />
                            </List.Item>
                        );
                    }}
                />
            </Form>
        </div>
    );
};

export default BasicStyles;
