import { CreatePollingQuestionPayload, GetPollingQuestionCategoriesPayload } from "./payload-types";
import { PollingQuestionCategoryType, QandAQuestionsResponse } from "./types";
import { RootState, paginateResponse } from "@store/index";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import type { BaseQueryResult } from "@reduxjs/toolkit/dist/query/baseQueryTypes";

const POLLING_QUESTION_CATEGORIES = "Polling_Question_Categories";
const QANDA_QUESTIONS = "QandA_Questions";

const liveApi = createApi({
    reducerPath: "live",
    tagTypes: [POLLING_QUESTION_CATEGORIES, QANDA_QUESTIONS],

    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/`,
        prepareHeaders: (headers, { getState }) => {
            const {
                user: { token }
            } = getState() as RootState;
            headers.set(`Authorization`, `Bearer ${token}`);
            headers.set("Accept", "application/json");

            return headers;
        }
    }),

    endpoints: (build) => ({
        getPollingQuestionCategories: build.query<
            PollingQuestionCategoryType[],
            GetPollingQuestionCategoriesPayload
        >({
            query: ({ episodeId }) => {
                return `/episodes/${episodeId}/poll-question-categories`;
            },
            transformResponse: (res: BaseQueryResult<any>): any => res.data,
            providesTags: [POLLING_QUESTION_CATEGORIES]
        }),
        changePollingOptionsOrder: build.mutation<any, { questionId: string; order: number[] }>({
            query: ({ questionId, ...body }) => {
                const url = `/poll-questions/${questionId}/poll-question-options/change-order`;
                return {
                    url: url,
                    method: "POST",
                    body
                };
            },
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [POLLING_QUESTION_CATEGORIES]
        }),
        changePollingQuestionsOrder: build.mutation<any, { categoryId: number; order: number[] }>({
            query: ({ categoryId, ...body }) => {
                const url = `/poll-question-categories/${categoryId}/poll-questions/change-order`;
                return {
                    url: url,
                    method: "POST",
                    body
                };
            },
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [POLLING_QUESTION_CATEGORIES]
        }),
        createPollingQuestion: build.mutation<any, CreatePollingQuestionPayload>({
            query: ({ categoryId, episodeId, eventId, ...body }) => {
                return {
                    url: `/poll-question-categories/${categoryId}/poll-questions`,
                    method: "POST",
                    body: {
                        episode_id: episodeId,
                        event_id: eventId,
                        ...body
                    }
                };
            },
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [POLLING_QUESTION_CATEGORIES]
        }),
        updatePollingQuestion: build.mutation<any, { questionId: string; episodeId: string }>({
            query: ({ questionId, episodeId, ...body }) => {
                return {
                    url: `/poll-questions/${questionId}`,
                    method: "PUT",
                    body: {
                        episode_id: episodeId,
                        ...body
                    }
                };
            },
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [POLLING_QUESTION_CATEGORIES]
        }),
        deletePollingQuestion: build.mutation<any, { questionId: string | number }>({
            query: ({ questionId }) => {
                return {
                    url: `/poll-questions/${questionId}`,
                    method: "DELETE"
                };
            },
            invalidatesTags: [POLLING_QUESTION_CATEGORIES]
        }),
        createPollingOption: build.mutation<any, { questionId: string; episodeId: string }>({
            query: ({ questionId, episodeId, ...body }) => {
                return {
                    url: `/poll-questions/${questionId}/poll-question-options`,
                    method: "POST",
                    body: {
                        episode_id: episodeId,
                        ...body
                    }
                };
            },
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [POLLING_QUESTION_CATEGORIES]
        }),
        updatePollingOption: build.mutation<any, { optionId: string; episodeId: string }>({
            query: ({ optionId, episodeId, ...body }) => {
                return {
                    url: `/poll-question-options/${optionId}`,
                    method: "PUT",
                    body: {
                        episode_id: episodeId,
                        ...body
                    }
                };
            },
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [POLLING_QUESTION_CATEGORIES]
        }),
        deletePollingOption: build.mutation<any, { optionId: string | number }>({
            query: ({ optionId }) => {
                return {
                    url: `/poll-question-options/${optionId}`,
                    method: "DELETE"
                };
            },
            invalidatesTags: [POLLING_QUESTION_CATEGORIES]
        }),
        broadcastPollingQuestion: build.mutation<
            any,
            { questionId: number; visibleTime: number; status: 1 | 0 }
        >({
            query: ({ questionId, visibleTime, status }) => {
                return {
                    url: `/poll-questions/${questionId}/broadcast`,
                    method: "POST",
                    body: {
                        visible_time: visibleTime,
                        status
                    }
                };
            },
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [POLLING_QUESTION_CATEGORIES]
        }),
        getQandAQuestions: build.query<QandAQuestionsResponse, { episodeId: string }>({
            query: ({ episodeId }) => {
                return `episodes/${episodeId}/questions`;
            },
            transformResponse: (res: BaseQueryResult<any>) => paginateResponse(res),
            providesTags: [QANDA_QUESTIONS]
        })
    })
});

export default liveApi;

export const {
    useGetPollingQuestionCategoriesQuery,
    useChangePollingOptionsOrderMutation,
    useChangePollingQuestionsOrderMutation,
    useCreatePollingQuestionMutation,
    useUpdatePollingQuestionMutation,
    useDeletePollingQuestionMutation,
    useCreatePollingOptionMutation,
    useUpdatePollingOptionMutation,
    useDeletePollingOptionMutation,
    useBroadcastPollingQuestionMutation,
    useGetQandAQuestionsQuery
} = liveApi;
