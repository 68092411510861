import type { GlobalToken } from "antd";
import { MapToken } from "antd/es/theme/interface";
import { MultilingualFormInput } from "@components/FormElements";
import { basicStylesElements } from "@utils/layout/events/basicStylesListElements";

export interface AdvancedStyleListElement {
    title: string;
    formItems: MultilingualFormInput[];
}

export const advancedStylesListElements = ({
    styles,
    t
}: {
    styles: GlobalToken | MapToken;
    t: Function;
}): AdvancedStyleListElement[] => {
    const basicStyles = basicStylesElements.map((basicStyle) => basicStyle.name);

    const skimmedStyles = Object.keys(styles).filter((style) => !basicStyles.includes(style));

    const advancedStyles = basicStylesElements.map((basicStylesElement) => {
        return {
            title: basicStylesElement.label as string,
            formItems: skimmedStyles
                .map((style) => {
                    if (style.indexOf(basicStylesElement.label as string) !== -1) {
                        return {
                            name: style,
                            label: t(`styles:${style}`),
                            type: basicStylesElement.type,
                            inputProps: basicStylesElement.inputProps
                        };
                    } else return null;
                })
                .filter((style) => style !== null) as MultilingualFormInput[]
        };
    });

    return advancedStyles;
};
