import moment from "moment";
import momentTimezone from "moment-timezone";
const dateFormat = "YYYY-MM-DD HH:mm";

export const utcToTimeZone = ({
    time,
    zone,
    format
}: {
    time: string | null | undefined;
    zone?: string;
    format?: string;
}): string | undefined => {
    if (!time) return undefined;
    const unformatted = momentTimezone.utc(time).tz(zone || "utc");
    return unformatted.format(format || dateFormat);
};

export const timeZoneToUtc = ({
    time,
    zone,
    format
}: {
    time: string;
    zone: string | undefined;
    format?: string;
}): string => {
    return momentTimezone
        .tz(moment(time).format(format), format || dateFormat, zone || "utc")
        .utc()
        .format(format || dateFormat);
};
