import { CalendarOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { Space, Typography, theme } from "antd";

import React from "react";
import styles from "./DateDisplayer.module.scss";

interface DateDisplayerProps {
    date: string;
    timezone: string;
}

const DateDisplayer: React.FC<DateDisplayerProps> = ({ date, timezone }) => {
    const {
        token: { colorBorder }
    } = theme.useToken();
    const [calendarDate, time] = date.split(" ");
    const [year, month, day] = calendarDate.split("-");

    return (
        <div
            style={{
                borderRight: `1px solid ${colorBorder}`
            }}
            className={styles["date-displayer"]}
        >
            <div className={styles["date-displayer__time-container"]}>
                <Typography.Text>{timezone}</Typography.Text>
                <Typography.Title style={{ margin: 0 }} level={3}>
                    <Space direction="horizontal">
                        <ClockCircleOutlined />
                        {time?.substr(0, 5)}
                    </Space>
                </Typography.Title>
            </div>
            <Space direction="horizontal">
                <CalendarOutlined />
                <Typography.Text type="secondary">
                    {year}/{month}/{day}
                </Typography.Text>
            </Space>
        </div>
    );
};

export default DateDisplayer;
