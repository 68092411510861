import { DomainType, ProfileType, ProfileUsersResponse, ProfilesResponse } from "./types";
import { GetAvailableDomainsPayload, GetProfilesPayload } from "./payload-types";
import { RootState, paginateResponse } from "@store/index";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BaseQueryResult } from "@reduxjs/toolkit/dist/query/baseQueryTypes";

const PROFILES = "Profiles";
const USERS = "Users";
const AVAILABLE_DOMAINS = "Available_domains";

const profileApi = createApi({
    reducerPath: "profile",
    tagTypes: [PROFILES, USERS, AVAILABLE_DOMAINS],
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/`,
        responseHandler: "json",
        prepareHeaders: (headers, { getState }) => {
            const {
                user: { token }
            } = getState() as RootState;
            headers.set(`Authorization`, `Bearer ${token}`);
            headers.set("Accept", "application/json");

            return headers;
        }
    }),
    endpoints: (build) => ({
        getProfiles: build.query<ProfilesResponse, GetProfilesPayload>({
            query: ({ page = 1, searchParam = "", step = 10, mode = "", paginate = true }) => {
                if (!paginate && mode !== "") return `/profiles?paginate=false&mode=${mode}`;
                else if (!paginate) return `/profiles?paginate=false`;
                else if (!step) return `/profiles`;
                else if (!searchParam && step) return `/profiles?page=${page}&step=${step}`;
                else return `/profiles?page=${page}&step=${step}&q=${searchParam}`;
            },
            transformResponse: (res) => paginateResponse(res),
            providesTags: [PROFILES]
        }),
        getProfile: build.query<ProfileType, { profileId: string | undefined }>({
            query: ({ profileId }) => {
                return `/profiles/${profileId}`;
            },
            transformResponse: (res: BaseQueryResult<any>): any => res.data,
            providesTags: [PROFILES]
        }),
        createProfile: build.mutation({
            query: ({ ...body }) => {
                return {
                    url: `/profiles`,
                    method: "POST",
                    body: body
                };
            },
            invalidatesTags: [PROFILES]
        }),
        updateProfile: build.mutation<any, { profileId: string }>({
            query: ({ profileId, ...body }) => {
                return {
                    url: `/profiles/${profileId}`,
                    method: "PUT",
                    body: body
                };
            },
            invalidatesTags: [PROFILES]
        }),
        deleteProfile: build.mutation({
            query: ({ profile, ...body }) => {
                return {
                    url: `/profiles/${profile}`,
                    method: "DELETE",
                    body: body
                };
            },
            invalidatesTags: [PROFILES]
        }),
        getProfileUsers: build.query<ProfileUsersResponse, { profileId: string }>({
            query: ({ profileId }) => {
                return `/profiles/${profileId}/users`;
            },
            transformResponse: (res) => paginateResponse(res),
            providesTags: () => [USERS]
        }),
        createUser: build.mutation({
            query: ({ ...body }) => {
                return {
                    url: `/users`,
                    method: "post",
                    body: body
                };
            },
            transformResponse: (res: BaseQueryResult<any>): any => {
                return res.data;
            },
            invalidatesTags: [USERS, PROFILES]
        }),
        updateUser: build.mutation<any, { userId: string | number; roles: string[] }>({
            query: ({ userId, ...body }) => {
                return {
                    url: `/users/${userId}`,
                    method: "put",
                    body: body
                };
            },
            transformResponse: (res: BaseQueryResult<any>): any => {
                return res.data;
            },
            invalidatesTags: [USERS]
        }),
        deleteUser: build.mutation<any, { userId: string | number }>({
            query: ({ userId, ...body }) => {
                return {
                    url: `/users/${userId}`,
                    method: "delete",
                    body: body
                };
            },
            invalidatesTags: [USERS]
        }),
        getAvailableDomains: build.query<DomainType[], GetAvailableDomainsPayload>({
            query: ({ eventId, profileId }) => {
                if (eventId) return `/available-domains?event_id=${eventId}&profile_id=${profileId}`;
                else if (profileId) return `/available-domains?profile_id=${profileId}&all=true`;
                else return `/available-domains`;
            },
            transformResponse: (res: BaseQueryResult<any>): any => res.data,
            providesTags: [AVAILABLE_DOMAINS]
        }),
        createDomain: build.mutation<any, { profileId: string }>({
            query: ({ profileId, ...body }) => {
                return {
                    url: `/available-domains`,
                    method: "post",
                    body: {
                        profile_id: profileId,
                        ...body
                    }
                };
            },
            transformResponse: (res: BaseQueryResult<any>): any => {
                return res.data;
            },
            invalidatesTags: [AVAILABLE_DOMAINS]
        })
    })
});

export default profileApi;

export const {
    useGetProfilesQuery,
    useGetProfileQuery,
    useUpdateProfileMutation,
    useCreateProfileMutation,
    useGetProfileUsersQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useGetAvailableDomainsQuery,
    useCreateDomainMutation
} = profileApi;
