import { createSlice } from "@reduxjs/toolkit";

export interface UiState {
    events: {
        [event: number | string]: {
            selectedEpisode: number;
        };
    };
    breadcrumb: {
        [index: number]: string | null;
    };
}

const initialState = {
    events: {},
    breadcrumb: {},
    pollingBroadcastedQuestions: {}
} as UiState;

const uiSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        setSelectedEpisode: (state, action) => {
            state.events = {
                ...state.events,
                [action.payload.event]: {
                    selectedEpisode: action.payload.episode
                }
            };
        },
        setBreadcrumb: (state, action) => {
            state.breadcrumb = {
                ...state.breadcrumb,
                [action.payload.index]: action.payload.name
            };
        }
    }
});

export const { setSelectedEpisode, setBreadcrumb } = uiSlice.actions;
export default uiSlice.reducer;
