import {
    AudioOutlined,
    BarChartOutlined,
    CalendarOutlined,
    CameraOutlined,
    FormOutlined,
    PicLeftOutlined,
    QuestionCircleOutlined,
    VideoCameraOutlined
} from "@ant-design/icons";
import { brushOutline, contractOutline, expandOutline, homeOutline } from "ionicons/icons";

import { IonIcon } from "@ionic/react";
import type { MenuProps } from "antd";
import type { NavigateFunction } from "react-router-dom";

const createLabel = (label: string) => {
    return <span data-testid={label.replace(" ", "-").toLowerCase() + "-side-menu-item"}>{label}</span>;
};

export const eventsSideMenuContents = ({
    t,
    navigate,
    eventId,
    episodeId,
    hasRequiredInfo,
    isSideMenuCollapsed,
    setSideMenuCollapsed
}: {
    t: Function;
    navigate: NavigateFunction;
    eventId: string;
    episodeId?: string;
    hasRequiredInfo: boolean;
    isSideMenuCollapsed: boolean;
    setSideMenuCollapsed: Function;
}): MenuProps["items"] => {
    return [
        {
            label: createLabel(t("home")),
            key: "home",
            icon: <IonIcon size="small" icon={homeOutline} />,
            onClick: () => navigate("/events/" + eventId + "/home")
        },
        {
            label: createLabel(t("episodes")),
            key: "episodes",
            disabled: !hasRequiredInfo,
            icon: <VideoCameraOutlined />,
            onClick: () => navigate("/events/" + eventId + "/episodes")
        },
        {
            label: createLabel(t("styles")),
            key: "styles",
            disabled: !hasRequiredInfo,
            icon: <IonIcon size="small" icon={brushOutline} />,
            onClick: () => navigate("/events/" + eventId + "/styles")
        },
        {
            label: createLabel(t("contents")),
            key: "contents",
            disabled: !hasRequiredInfo,
            icon: <PicLeftOutlined />,
            onClick: () => navigate("/events/" + eventId + "/contents")
        },
        {
            label: createLabel(t("media-gallery")),
            key: "media-gallery",
            disabled: !hasRequiredInfo,
            icon: <CameraOutlined />,
            onClick: () => navigate("/events/" + eventId + "/media-gallery")
        },
        {
            label: createLabel(t("live")),
            key: "live",
            disabled: !hasRequiredInfo,
            icon: <AudioOutlined />,
            onClick: () => navigate("/events/" + eventId + "/live" + (episodeId ? `/${episodeId}` : ``))
        },
        {
            label: createLabel(t("evaluation-form")),
            key: "evaluation-form",
            disabled: !hasRequiredInfo,
            icon: <FormOutlined />,
            onClick: () =>
                navigate("/events/" + eventId + "/evaluation-form" + (episodeId ? `/${episodeId}` : ``))
        },
        {
            label: createLabel(t("agenda")),
            key: "agenda",
            disabled: !hasRequiredInfo,
            icon: <CalendarOutlined />,
            onClick: () => navigate("/events/" + eventId + "/agenda" + (episodeId ? `/${episodeId}` : ``))
        },
        {
            label: createLabel(t("reports")),
            key: "reports",
            disabled: !hasRequiredInfo,
            icon: <BarChartOutlined />,
            onClick: () => navigate("/events/" + eventId + "/reports" + (episodeId ? `/${episodeId}` : ``))
        },
        {
            label: createLabel(t("q-and-a")),
            key: "q-and-a",
            disabled: !hasRequiredInfo,
            icon: <QuestionCircleOutlined />,
            onClick: () => navigate("/events/" + eventId + "/q-and-a")
        },
        {
            label: createLabel(t("toggle-menu")),
            key: "collapse",
            icon: <IonIcon size="small" icon={!isSideMenuCollapsed ? contractOutline : expandOutline} />,
            onClick: () => setSideMenuCollapsed(),
            style: {
                marginTop: "auto"
            }
        }
    ];
};
