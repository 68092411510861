import { Button, Popconfirm, Space, Tag, Typography } from "antd";
import {
    CheckCircleFilled,
    CloseCircleFilled,
    DeleteOutlined,
    EditOutlined,
    MinusOutlined,
    PlusOutlined
} from "@ant-design/icons";
import { EvaluationFormQuestionOptionType, MediaFileType } from "@store/events/types";

interface OptionsTableColumnsArgs {
    t: Function;
    selectedLanguage: string;
    modalSetter: Function;
    onDeleteItem: Function;
    handleMedia: Function;
    media: MediaFileType[] | undefined;
}

export const optionsTableColumns = ({
    t,
    selectedLanguage,
    modalSetter,
    onDeleteItem,
    handleMedia,
    media
}: OptionsTableColumnsArgs) => {
    return [
        {
            title: t("content"),
            dataIndex: "body",
            key: "body",
            render: (val: any, row: EvaluationFormQuestionOptionType) => {
                if (row?.media_id) {
                    const mediaSrc = media?.find((mediaItem) => mediaItem.id === row?.media_id)?.url;
                    return <img src={mediaSrc} alt="" style={{ maxWidth: 270 }} />;
                } else if (!val) return <Tag>{t("no-value-available")}</Tag>;
                else return val[selectedLanguage] || <Tag>{t("no-value-available")}</Tag>;
            }
        },
        {
            title: t("is-correct"),
            dataIndex: "is_correct",
            key: "is_correct",
            render: (val: boolean | 0 | 1) => {
                const isCorrect = !!val;
                return isCorrect ? (
                    <Tag icon={<CheckCircleFilled />}>{t("yes")}</Tag>
                ) : (
                    <Tag icon={<CloseCircleFilled />}>{t("no")}</Tag>
                );
            },
            width: 105
        },
        {
            title: t("media"),
            dataIndex: "media_id",
            key: "media_id",
            width: 110,
            render: (media_id: number | null, row: EvaluationFormQuestionOptionType) => {
                return (
                    <>
                        {media_id !== null ? (
                            <Popconfirm
                                title={t("detach-item-warning-text")}
                                okText={t("detach")}
                                cancelText={t("cancel")}
                                onConfirm={() => handleMedia(row.id, row?.media_id)}
                            >
                                <Button icon={media_id !== null ? <MinusOutlined /> : <PlusOutlined />}>
                                    {media_id !== null ? t("detach") : t("attach")}
                                </Button>
                            </Popconfirm>
                        ) : (
                            <Button
                                icon={media_id !== null ? <MinusOutlined /> : <PlusOutlined />}
                                onClick={() => handleMedia(row.id, row?.media_id)}
                            >
                                {media_id !== null ? t("detach") : t("attach")}
                            </Button>
                        )}
                    </>
                );
            }
        },
        {
            title: t("actions"),
            dataIndex: "actions",
            key: "actions",
            width: 110,
            render: (_: any, row: EvaluationFormQuestionOptionType) => {
                return (
                    <Space direction="vertical">
                        <Space direction="horizontal">
                            <Button
                                icon={<EditOutlined />}
                                title={t("edit-option")}
                                onClick={() => modalSetter(row.id)}
                            />
                            <Popconfirm
                                title={t("delete-item-warning-text")}
                                okText={t("delete")}
                                cancelText={t("cancel")}
                                onConfirm={() => onDeleteItem(row.id)()}
                            >
                                <Button icon={<DeleteOutlined />} title={t("delete-option")} />
                            </Popconfirm>
                        </Space>
                    </Space>
                );
            }
        }
    ];
};
