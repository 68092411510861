import {
    Alert,
    Form as AntdForm,
    Button,
    Card,
    Input,
    List,
    Modal,
    Space,
    Spin,
    Switch,
    Tabs,
    Typography
} from "antd";
import { BulbOutlined, FileDoneOutlined, LockFilled, PlusOutlined } from "@ant-design/icons";
import { buildTranslation, translations } from "@translations/crudTranslationBuilder";
import { timeZoneToUtc, utcToTimeZone } from "@utils/timezones";
import {
    useAttachOptionMediaMutation,
    useChangeEvaluationFormOptionsOrderMutation,
    useChangeEvaluationFormQuestionOrderMutation,
    useCreateCalendarMutation,
    useCreateEvaluationFormQuestionMutation as useCreateEvalFormQuestionMutation,
    useCreateEvaluationFormOptionMutation,
    useDeleteCalendarMutation,
    useDeleteEvaluationFormQuestionMutation as useDeleteEvalFormQuestionMutation,
    useDeleteEvaluationFormOptionMutation,
    useDetachOptionMediaMutation,
    useGetEpisodeCalendarsQuery,
    useGetEvaluationFormCategoriesQuery,
    useGetEventQuery,
    useGetMediaQuery,
    useUpdateCalendarMutation,
    useUpdateEvaluationFormOptionMutation,
    useUpdateEvaluationFormQuestionMutation
} from "@store/events";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ActionsBanner from "@components/ActionsBanner/ActionsBanner";
import { Divider } from "antd";
import DoubleColumnLayout from "@components/ColumnLayout/ColumnLayout";
import { DraggableTable } from "@components/SortableTable";
import Error from "@components/Error";
import { Error422 } from "@store/index";
import { EvaluationFormQuestionType } from "@store/events/types";
import Form from "@components/Form";
import GalleryDrawer from "@components/GalleryDrawer/GalleryDrawer";
import StickToTop from "@components/StickToTop/StickToTop";
import { evaluationFormOptionFormInputs } from "@utils/forms/evaluationForm/evaluationFormOptions";
import { evaluationFormQuestionFormInputs } from "@utils/forms/evaluationForm/evaluationFormQuestion";
import { evaluationFormQuestionTableColumns } from "@utils/layout/evaluation-form/evaluationFormQuestionTableColumns";
import { getHasRequiredInfo } from "@utils/general";
import { mapErrorsToFields } from "@utils/mapErrorsToFields";
import { optionsTableColumns } from "@utils/layout/evaluation-form/evaluationFormOptionTableColumns";
import { useGetLanguagesQuery } from "@store/internationalization";
import { usePerformMutation } from "@utils/perform-mutation";
import { useRedirectEventsHome } from "@hooks/useRedirectEventsHome";
import { useSideMenu } from "@hooks/useSideMenu";
import { useTranslation } from "react-i18next";

interface QuestionsListProps {
    setIsLoading: (bool: boolean) => void;
}

const QuestionsList: React.FC<QuestionsListProps> = ({ setIsLoading }) => {
    const navigate = useNavigate();
    const { eventId, episodeId, questionId } = useParams();
    const { t } = useTranslation(["translations"]);
    useSideMenu({ isVisible: true, contentType: "event" });
    const [optionsModalItem, setOptionsModalItem] = useState<null | string | number>(null);
    const [optionIdForMedia, setOptionIdForMedia] = useState<string | null>(null);
    const [mediaPage, setMediaPage] = useState<number>(1);
    const [questionsForm] = Form.useForm();
    const [optionsForm] = Form.useForm();
    const addingNewQuestion = questionId === "new-question";
    const { performMutation } = usePerformMutation();

    const [updateQuestion, { isLoading: isLoadingEditQuestion }] = useUpdateEvaluationFormQuestionMutation();
    const [createQuestion, { isLoading: isLoadingCreateQuestion }] = useCreateEvalFormQuestionMutation();
    const [deleteQuestion, { isLoading: isLoadingDeleteQuestion }] = useDeleteEvalFormQuestionMutation();

    const [updateOption, { isLoading: isLoadingEditOption }] = useUpdateEvaluationFormOptionMutation();
    const [createOption, { isLoading: isLoadingCreateOption }] = useCreateEvaluationFormOptionMutation();
    const [deleteOption, { isLoading: isLoadingDeleteOption }] = useDeleteEvaluationFormOptionMutation();

    const [attachOptionMedia, { isLoading: isLoadingAttachOptionsMedia }] = useAttachOptionMediaMutation();
    const [detachOptionMedia, { isLoading: isLoadingDetachOptionsMedia }] = useDetachOptionMediaMutation();

    const {
        data: languages,
        isFetching: isFetchingLanguages,
        isLoading: isLoadingLanguages,
        error: errorLanguages,
        isUninitialized: isUninitializedLanguages
    } = useGetLanguagesQuery(null);

    const {
        data: event,
        isLoading: isLoadingEvent,
        isFetching: isFetchingEvent,
        error: errorEvent,
        isUninitialized: isUninitializedEvent
    } = useGetEventQuery({ eventId: eventId! }, { skip: !eventId });

    const {
        data: mediaData,
        isLoading: isLoadingMediaData,
        isFetching: isFetchingMediaData,
        error: errorMediaData,
        isUninitialized: isUninitializedMediaData
    } = useGetMediaQuery({ eventId: eventId!, page: mediaPage });

    const { hasEventRequiredInfo, isLoadingRequiredInfo } = getHasRequiredInfo(event);

    useRedirectEventsHome({
        hasInfo: hasEventRequiredInfo,
        isLoading: isLoadingRequiredInfo,
        eventId
    });

    const {
        data: evaluationFormCategories,
        isLoading: isLoadingEvaluationFormCategories,
        isFetching: isFetchingEvaluationFormCategories,
        error: errorEvaluationFormCategories,
        isUninitialized: isUninitializedEvaluationFormCategories
    } = useGetEvaluationFormCategoriesQuery({ episode: episodeId || "" }, { skip: !!!episodeId });

    const selectedEvaluationFormCategory = useMemo(() => {
        if (!evaluationFormCategories) return undefined;
        let evaluationFormCategoryItem = evaluationFormCategories[0];
        if (evaluationFormCategoryItem) return evaluationFormCategoryItem;
        else return undefined;
    }, [evaluationFormCategories]);

    const selectedEvaluationFormQuestion = useMemo(() => {
        if (!questionId || !selectedEvaluationFormCategory) return undefined;
        let evaluationFormQuestionItem = selectedEvaluationFormCategory.questions?.find(
            (efq: EvaluationFormQuestionType) => String(efq.id) === questionId
        );
        if (evaluationFormQuestionItem) return evaluationFormQuestionItem;
        else return undefined;
    }, [selectedEvaluationFormCategory, questionId]);

    useEffect(() => {
        if (addingNewQuestion) questionsForm.resetFields();
        else if (selectedEvaluationFormQuestion) {
            questionsForm.setFieldsValue({ ...selectedEvaluationFormQuestion });
        }
    }, [selectedEvaluationFormQuestion, addingNewQuestion, questionsForm]);

    useEffect(() => {
        if (optionsModalItem === "new" || optionsModalItem === null || !evaluationFormCategories) {
            optionsForm.resetFields();
        } else {
            const selectedOption = selectedEvaluationFormQuestion?.options.find(
                (option: any) => option.id === optionsModalItem
            );
            optionsForm.setFieldsValue(selectedOption);
        }
    }, [optionsModalItem, evaluationFormCategories, optionsForm, selectedEvaluationFormQuestion]);

    const handleCUDQuestion =
        (method: "delete" | "put" | "post", questionId: string | number) => async (values: any) => {
            const message = buildTranslation(t, {
                subject: translations.subject.evalFormQuestion,
                method: translations.request[method]
            });

            await performMutation({
                mutation: async () => {
                    const otherValues = values || {};
                    method === "put"
                        ? await updateQuestion({
                              questionId: questionId,
                              episodeId: episodeId,
                              ...otherValues
                          }).unwrap()
                        : method === "post"
                        ? await createQuestion({
                              episodeId: episodeId,
                              categoryId: selectedEvaluationFormCategory?.id,
                              eventId: eventId,
                              ...otherValues
                          }).unwrap()
                        : await deleteQuestion({
                              questionId: questionId
                          }).unwrap();
                },
                successMessage: message(translations.outcome.success),
                errorMessage: message(translations.outcome.error),
                onSuccessCallback: () => navigate(`/events/${eventId}/evaluation-form/${episodeId}`),
                setFieldsWithErrors: (error: Error422) => {
                    if (values) {
                        const valuesWithErrors = mapErrorsToFields({ error: error, values });
                        questionsForm.setFields(valuesWithErrors);
                    }
                }
            });
        };

    const handleCUDOption =
        (method: "delete" | "put" | "post", optionId: string | number) => async (values: any) => {
            const message = buildTranslation(t, {
                subject: translations.subject.evalFormOption,
                method: translations.request[method]
            });

            await performMutation({
                mutation: async () => {
                    const otherValues = values || {};
                    method === "put"
                        ? await updateOption({
                              optionId: optionId,
                              episodeId: episodeId,
                              ...otherValues
                          }).unwrap()
                        : method === "post"
                        ? await createOption({
                              questionId: questionId,
                              episodeId: episodeId,
                              ...otherValues
                          }).unwrap()
                        : await deleteOption({
                              optionId: optionId
                          }).unwrap();
                },
                successMessage: message(translations.outcome.success),
                errorMessage: message(translations.outcome.error),
                onSuccessCallback: () => setOptionsModalItem(null)
            });
        };

    /** ORDER */
    const [changeOptionsOrder, { isLoading: isLoadingChangeOptionsOrder }] =
        useChangeEvaluationFormOptionsOrderMutation();
    const [changeQuestionsOrder, { isLoading: isLoadingChangeEvaluationFormQuestionOrder }] =
        useChangeEvaluationFormQuestionOrderMutation();

    const handleOptionsOrderChange = async (order: number[]) => {
        const message = buildTranslation(t, {
            subject: translations.subject.changeQuestionOptionsOrder,
            method: translations.request.general
        });
        await performMutation({
            mutation: async () => await changeOptionsOrder({ question: questionId, order }).unwrap(),
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error)
        });
    };

    const handleQuestionOrderChange = async (order: number[]) => {
        const message = buildTranslation(t, {
            subject: translations.subject.changeQuestionsOrder,
            method: translations.request.general
        });
        await performMutation({
            mutation: async () =>
                await changeQuestionsOrder({ category: selectedEvaluationFormCategory?.id, order }).unwrap(),
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error)
        });
    };

    /** OPTION MEDIA */
    const handleOptionMedia = async (
        method: "post" | "delete",
        values: { optionId: string | number; mediaId?: number }
    ) => {
        const message = buildTranslation(t, {
            subject: translations.subject.mediaAttachment,
            method: method === "delete" ? translations.request.delete : translations.request.general
        });
        await performMutation({
            mutation: async () => {
                if (method === "post")
                    await attachOptionMedia({ ...values, mediaId: values.mediaId! }).unwrap();
                else await detachOptionMedia(values).unwrap();
            },
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error)
        });
    };

    const error = errorLanguages || errorMediaData || errorEvent || errorEvaluationFormCategories;

    const loading =
        isUninitializedLanguages ||
        isUninitializedMediaData ||
        isUninitializedEvaluationFormCategories ||
        isUninitializedEvent ||
        isFetchingLanguages ||
        isLoadingEvent ||
        isFetchingEvent ||
        isLoadingEvaluationFormCategories ||
        isFetchingEvaluationFormCategories ||
        isLoadingChangeOptionsOrder ||
        isLoadingEditQuestion ||
        isLoadingChangeEvaluationFormQuestionOrder ||
        isLoadingLanguages ||
        isLoadingCreateQuestion ||
        isLoadingDeleteQuestion ||
        isLoadingCreateOption ||
        isLoadingDeleteOption ||
        isLoadingDetachOptionsMedia ||
        isLoadingMediaData ||
        isFetchingMediaData;

    useEffect(() => {
        setIsLoading(loading);
    }, [loading, setIsLoading]);

    if (error) return <Error message={t("generic-error-message")} />;
    return (
        <>
            <DoubleColumnLayout
                firstColumn={
                    selectedEvaluationFormCategory ? (
                        <>
                            <StickToTop
                                title={<Typography.Title level={4}>{t("questions")}</Typography.Title>}
                                actions={
                                    <Button
                                        type="primary"
                                        disabled={addingNewQuestion}
                                        onClick={() =>
                                            navigate(
                                                `/events/${eventId}/evaluation-form/${episodeId}/questions-list/new-question`
                                            )
                                        }
                                        icon={<PlusOutlined />}
                                        data-testid="add-new-evaluation-form-question"
                                    >
                                        {t("add-new")}
                                    </Button>
                                }
                            ></StickToTop>
                            {selectedEvaluationFormCategory.questions.length === 0 ? (
                                <Alert
                                    message={t("no-questions-alert")}
                                    data-testid="no-evaluation-form-questions-alert"
                                    type="info"
                                    icon={<BulbOutlined />}
                                    showIcon
                                ></Alert>
                            ) : (
                                <DraggableTable
                                    disableSelectionCheckboxes={true}
                                    data={selectedEvaluationFormCategory.questions}
                                    onOrderChange={handleQuestionOrderChange}
                                    selectedRowKeys={
                                        questionId && !isNaN(parseInt(questionId))
                                            ? [parseInt(questionId)]
                                            : []
                                    }
                                    columns={evaluationFormQuestionTableColumns({
                                        t,
                                        goToQuestion: (questionId: string) =>
                                            navigate(
                                                `/events/${eventId}/evaluation-form/${episodeId}/questions-list/${questionId}`
                                            ),
                                        onDeleteItem: (questionId: string | number) =>
                                            handleCUDQuestion("delete", questionId!)
                                    })}
                                />
                            )}
                        </>
                    ) : episodeId && evaluationFormCategories ? (
                        <>
                            <StickToTop
                                title={<Typography.Title level={4}>{t("questions")}</Typography.Title>}
                            />
                            <Alert
                                message={t("select-evaluation-form-category-to-edit")}
                                data-testid="no-agenda-item-selected-alert"
                                type="info"
                                icon={<BulbOutlined />}
                                showIcon
                            ></Alert>
                        </>
                    ) : null
                }
                secondColumn={
                    <>
                        {!questionId ? (
                            <>
                                <StickToTop
                                    title={
                                        <Typography.Title level={4}>{t("question-details")}</Typography.Title>
                                    }
                                />
                                <Alert
                                    type="info"
                                    message={t("select-question-to-edit")}
                                    icon={<BulbOutlined />}
                                    showIcon
                                    data-testid="no-question-selected-banner"
                                />
                            </>
                        ) : (
                            <>
                                <StickToTop
                                    title={
                                        <Typography.Title level={4}>
                                            {addingNewQuestion
                                                ? t("add-new-question")
                                                : selectedEvaluationFormQuestion?.content_default}
                                        </Typography.Title>
                                    }
                                ></StickToTop>
                                <Form
                                    items={evaluationFormQuestionFormInputs({ t })}
                                    languageSpecificLabel={t("language-specific-information")}
                                    form={questionsForm}
                                    onSubmit={handleCUDQuestion(
                                        addingNewQuestion ? "post" : "put",
                                        questionId!
                                    )}
                                    defaultlanguage={event?.default_lang}
                                    languages={event?.languages}
                                    languageReferences={languages}
                                />
                                <Divider plain>{t("question-options")}</Divider>
                                {addingNewQuestion && (
                                    <Alert
                                        type="info"
                                        showIcon
                                        icon={<LockFilled />}
                                        message={t("save-question-to-add-options")}
                                        data-testid="save-question-before-add-option-alert"
                                    />
                                )}
                                {!addingNewQuestion && event?.languages && (
                                    <Space direction="vertical">
                                        <Tabs
                                            style={{ marginTop: "-10px" }}
                                            items={event?.languages.map((lg: string) => {
                                                return {
                                                    label: `${lg.toUpperCase()}`,
                                                    key: lg,
                                                    children: (
                                                        <DraggableTable
                                                            data={
                                                                selectedEvaluationFormQuestion?.options || []
                                                            }
                                                            onOrderChange={handleOptionsOrderChange}
                                                            columns={optionsTableColumns({
                                                                t,
                                                                selectedLanguage: lg,
                                                                modalSetter: setOptionsModalItem,
                                                                onDeleteItem: (optionId: string | number) =>
                                                                    handleCUDOption("delete", optionId),
                                                                handleMedia: async (
                                                                    optionId: string,
                                                                    mediaId: number
                                                                ) => {
                                                                    if (mediaId === null)
                                                                        setOptionIdForMedia(optionId);
                                                                    else
                                                                        await handleOptionMedia("delete", {
                                                                            optionId: optionId!
                                                                        });
                                                                },
                                                                media: mediaData?.data
                                                            })}
                                                        />
                                                    )
                                                };
                                            })}
                                        />
                                        <Button
                                            icon={<PlusOutlined />}
                                            onClick={() => setOptionsModalItem("new")}
                                            data-testid="add-evaluation-question-option"
                                        >
                                            {t("add-new-question-option")}
                                        </Button>
                                    </Space>
                                )}
                            </>
                        )}
                    </>
                }
                secondColumnBanner={
                    questionId ? (
                        <ActionsBanner>
                            <Space direction="horizontal" align="end">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={false}
                                    size="large"
                                    onClick={questionsForm.submit}
                                    data-testid="save-update-evaluation-form-question"
                                >
                                    {addingNewQuestion ? t("save-new-question") : `${t("update-question")}`}
                                </Button>
                            </Space>
                        </ActionsBanner>
                    ) : undefined
                }
            />
            <Modal
                forceRender={true}
                title={optionsModalItem === "new" ? t("add-new-question-option") : t("edit-question-option")}
                open={optionsModalItem !== null}
                onOk={optionsForm.submit}
                confirmLoading={isLoadingEditOption || isLoadingCreateOption}
                onCancel={() => setOptionsModalItem(null)}
            >
                <Form
                    form={optionsForm}
                    onSubmit={(values: string) =>
                        handleCUDOption(
                            optionsModalItem === "new" ? "post" : "put",
                            optionsModalItem!
                        )(values)
                    }
                    items={evaluationFormOptionFormInputs({ t })}
                    defaultlanguage={event?.default_lang}
                    languages={event?.languages}
                    languageReferences={languages}
                />
            </Modal>
            <GalleryDrawer
                profileId={event?.profile_id!}
                eventId={event?.id}
                currentPage={mediaPage}
                pageSetter={setMediaPage}
                title={t("attach-media-to-option")}
                setIsDrawerOpen={(_: any) => setOptionIdForMedia(null)}
                isDrawerOpen={optionIdForMedia !== null}
                gallery={mediaData?.data}
                perPage={mediaData?.pagination.perPage}
                paginationTotal={mediaData?.pagination.total}
                onAttachClick={async (itemId: number) => {
                    await handleOptionMedia("post", { optionId: optionIdForMedia!, mediaId: itemId });
                    setOptionIdForMedia(null);
                }}
                isLoadingAttachMedia={isLoadingAttachOptionsMedia}
            />
        </>
    );
};

export default QuestionsList;
