import { AuthorizedRoles, MultilingualFormInput } from "@components/FormElements";

import { ProfileType } from "@store/profile/types";

interface SpeakersFormInputsArgs {
    t: (str: string) => string;
    isAdmin: boolean;
    profiles: ProfileType[];
}

export const speakersFormInputs = ({
    t,
    isAdmin,
    profiles
}: SpeakersFormInputsArgs): MultilingualFormInput[] => {
    const speakerFormInputs = [
        {
            name: "profile_id",
            type: "select",
            label: t("associated-profile"),
            authorizedRoles: [AuthorizedRoles.admin, AuthorizedRoles.superadmin],
            options: profiles.map((profile: ProfileType) => {
                return {
                    label: <>{profile.name}</>,
                    value: profile.id
                };
            }),
            rules: [{ required: true, message: t("field-required") }]
        },
        {
            name: "description",
            label: t("description"),
            type: "text",
            multilingual: true,
            inputProps: {
                "data-testid": "description-speaker-input"
            }
        },
        {
            name: "role",
            label: t("title"),
            type: "text",
            multilingual: true,
            inputProps: {
                "data-testid": "role-speaker-input"
            }
        },
        {
            name: "firstname",
            label: t("first-name"),
            type: "text",
            inputProps: {
                "data-testid": "first-name-speaker-input"
            },
            rules: [{ required: true, message: t("field-required") }]
        },
        {
            name: "lastname",
            label: t("last-name"),
            type: "text",
            inputProps: {
                "data-testid": "last-name-speaker-input"
            },
            rules: [{ required: true, message: t("field-required") }]
        }
    ];

    return speakerFormInputs as MultilingualFormInput[];
};
