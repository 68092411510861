import { ConfigProvider as AntDesignConfigProvider, App as AntdApp, Layout, Spin, theme } from "antd";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import React, { Suspense, useEffect } from "react";
import { logged as loggedAction, selectUser } from "@store/user";

import { AuthCheck } from "@utils/general";
import AuthRoutes from "@routes/auth/index";
import BaseLayout from "@layout/BaseLayout/BaseLayout";
import EventsRoutes from "@routes/events/index";
import LogsRoutes from "@routes/logs/index";
import Page404 from "@routes/errors/404";
import type { RouteEntry } from "@routes/types";
import SpeakersRoutes from "@routes/speakers/index";
import UsersRoutes from "@routes/profiles/index";
import { customTheme } from "./theme";
import liveApi from "@store/live";
import { selectDarkMode } from "@store/layout/selectors";
import { useDispatch } from "@store/hooks";
import { useProfileChannelSubscription } from "./pusher/useProfileChannelSubscription";
import { useQueryParams } from "@hooks/useQueryParams";
import { useSelector } from "@store/hooks";

function App() {
    const { defaultAlgorithm, darkAlgorithm } = theme;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isDarkTheme = useSelector(selectDarkMode);
    const {
        token,
        qr_code,
        identity,
        completeLoginStatus: { loading: completeLoginLoading, error: completeLoginError },
        loggedStatus: { loading: loggedLoading, error: loggedError }
    } = useSelector(selectUser);

    const rParam = useQueryParams("r");
    const twoFactorResetUrl = rParam ? atob(rParam) : null;

    useEffect(() => {
        const hasToken = !!token;
        const is2FADone = qr_code === undefined && !completeLoginLoading && !completeLoginError;
        const clearForLogged = !loggedLoading && !!!loggedError;

        if (hasToken && is2FADone && !twoFactorResetUrl && !identity && clearForLogged)
            dispatch(loggedAction());

        if (loggedError?.status === 461 || loggedError?.status === 462) navigate("/login");
    }, [
        token,
        identity,
        loggedLoading,
        loggedError,
        completeLoginError,
        completeLoginLoading,
        qr_code,
        dispatch
    ]);

    const isAuth = !!identity && !!token;
    const isLoading = loggedLoading && !!token;
    const hasError = loggedError;

    const routes = [...AuthRoutes, ...EventsRoutes, ...LogsRoutes, ...SpeakersRoutes, ...UsersRoutes];

    const { channel } = useProfileChannelSubscription({ token });

    useEffect(() => {
        if (channel) {
            channel.bind("App\\Events\\BroadcastPollQuestionAdmin", () => {
                dispatch(liveApi.util.invalidateTags(["Polling_Question_Categories"]));
            });
            channel.bind("App\\Events\\NewQuestionAdmin", () => {
                dispatch(liveApi.util.invalidateTags(["QandA_Questions"]));
            });
        }
    }, [channel, dispatch]);

    return (
        <AntDesignConfigProvider
            theme={{
                ...customTheme,
                algorithm: isDarkTheme ? darkAlgorithm : defaultAlgorithm
            }}
        >
            <AntdApp style={{ width: "100vw", height: "100vh", display: "flex" }}>
                <Layout>
                    <Suspense fallback={<Spin size="large" />}>
                        <Routes>
                            <Route
                                element={
                                    <BaseLayout isAuth={isAuth} isLoading={isLoading} hasError={hasError} />
                                }
                            >
                                <React.Fragment>
                                    <Route index path="/" element={<Navigate to="/events" />} />
                                    {routes.map((route: RouteEntry) => {
                                        if (typeof route.path === "string")
                                            return (
                                                <Route
                                                    path={route.path}
                                                    element={
                                                        <AuthCheck
                                                            page={route.page}
                                                            isAuth={isAuth}
                                                            isAuthProtected={route.auth}
                                                        />
                                                    }
                                                    key={route.path}
                                                />
                                            );
                                        else
                                            return route.path.map((routePath: string) => {
                                                return (
                                                    <Route
                                                        path={routePath}
                                                        element={
                                                            <AuthCheck
                                                                page={route.page}
                                                                isAuth={isAuth}
                                                                isAuthProtected={route.auth}
                                                            />
                                                        }
                                                        key={routePath}
                                                    />
                                                );
                                            });
                                    })}
                                </React.Fragment>
                            </Route>
                            <Route path="*" element={<Page404 />} />
                        </Routes>
                    </Suspense>
                </Layout>
            </AntdApp>
        </AntDesignConfigProvider>
    );
}

export default App;
