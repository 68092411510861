import { Editor } from "@tinymce/tinymce-react";
import React from "react";
import { selectDarkMode } from "@store/layout/selectors";
import { useSelector } from "@store/hooks";

export interface RichTextEditorProps {
    placeholder?: string;
    value?: any;
    disabled?: boolean;
    onChange?: (value: any) => void;
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({ placeholder, value, disabled, onChange }) => {
    const isDarkTheme = useSelector(selectDarkMode);

    const getConfig = () => {
        const config = {
            height: 300,
            menubar: false,
            forced_root_block: "p",
            deprecation_warnings: false,
            placeholder,
            plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount autoresize"
            ],
            toolbar: "undo redo |" + "bold italic backcolor | " + "removeformat |"
        };

        if (isDarkTheme) {
            return { ...config, skin: "oxide-dark", content_css: "dark" };
        } else return config;
    };

    return (
        <div style={disabled ? { opacity: "0.4" } : undefined}>
            <Editor
                onEditorChange={onChange}
                apiKey={process.env.REACT_APP_TINY_LICENCE}
                value={value || ""}
                disabled={disabled}
                init={getConfig()}
            />
        </div>
    );
};

export default RichTextEditor;
