import { RootState } from "@store/index";
import { createSelector } from "@reduxjs/toolkit";

const uiSelector = (state: RootState) => state.ui;

export const selectEvents = createSelector(uiSelector, (uiState) => uiState.events);
export const selectBreadcrumb = createSelector(uiSelector, (uiState) => uiState.breadcrumb);
export const selectPollingBroadcastedQuestions = createSelector(
    uiSelector,
    (uiState) => uiState.pollingBroadcastedQuestions
);
