import { Col, Result, Row, Spin } from "antd";

import React from "react";
import { useTranslation } from "react-i18next";

const ResetPassword: React.FC = () => {
    const { t } = useTranslation(["translations"]);

    return (
        <Spin spinning={false}>
            <Row
                style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "100px"
                }}
            >
                <Col lg={8} md={12} sm={24} xl={8} xxl={6}>
                    <Result status="success" title={t("2fa-reset-title")} subTitle={t("2fa-reset-text")} />
                </Col>
            </Row>
        </Spin>
    );
};

export default ResetPassword;
