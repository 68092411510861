import { Form, FormInstance, Spin } from "antd";
import FormElements, { MultilingualFormInput } from "@components/FormElements";
import React, { useEffect, useMemo } from "react";

import SpecificStylesSwitch from "./SpecificStylesSwitch";
import { getSpecificStylesLabel } from "./getSpecificStyleLabel";
import { getSpecificStylesSections } from "./specificStylesSections";
import { specificStylesReference } from "./specificStylesReference";
import { useGetStylesQuery } from "@store/events";
import { useParams } from "react-router-dom";
import { useSideMenu } from "@hooks/useSideMenu";
import { useTranslation } from "react-i18next";

export const specificStyleCategorySeparator = "__";

interface SpecificStylesProps {
    advancedStylesActiveKey: string | undefined;
    specificStylesForm: FormInstance;
    disabledSpecificStyles: { [key: string]: any };
    setDisabledSpecificStyles: Function;
}

const SpecificStyles: React.FC<SpecificStylesProps> = ({
    advancedStylesActiveKey,
    specificStylesForm,
    disabledSpecificStyles,
    setDisabledSpecificStyles
}) => {
    const { eventId } = useParams();
    const { t } = useTranslation(["translations", "style"]);

    useSideMenu({ isVisible: true, contentType: "event" });

    const {
        data: stylesData,
        isLoading: isLoadingStylesData,
        isFetching: isFetchingStylesData
    } = useGetStylesQuery({ eventId: eventId! });

    useEffect(() => {
        if (!stylesData) return;

        const dbStyles = !(Array.isArray(stylesData) && stylesData.length === 0)
            ? stylesData.draft && !Array.isArray(stylesData.draft)
                ? stylesData.draft
                : stylesData.contents
            : {};

        const specificStylesCategories = Object.keys(specificStylesReference).filter(
            (element) => element !== "global"
        );

        const formattedReferenceStyles = specificStylesCategories.reduce(
            (prev: any, currentSpecificKey: any) => {
                const array = (specificStylesReference as any)[currentSpecificKey].map(
                    (key: string) => currentSpecificKey + specificStyleCategorySeparator + key
                );

                return [...prev, ...array];
            },
            []
        );

        const formattedStylesFromDb = formattedReferenceStyles.reduce(
            (prev: any, currentSpecificKey: any) => {
                const keyParts = currentSpecificKey.split(specificStyleCategorySeparator);
                return {
                    ...prev,
                    [currentSpecificKey]: (dbStyles as any)?.[keyParts[0]]?.[keyParts[1]]
                };
            },
            {}
        );

        formattedReferenceStyles.forEach((styleName: string) => {
            if (formattedStylesFromDb[styleName] === null || formattedStylesFromDb[styleName] === undefined) {
                setDisabledSpecificStyles((ps: any) => ({ ...ps, [styleName]: null }));
            }
        });

        specificStylesForm.setFieldsValue(formattedStylesFromDb);
    }, [stylesData]);

    const specificStylesTabs = useMemo(() => {
        return getSpecificStylesSections({ t }).map((specificStyle) => {
            return {
                ...specificStyle,
                children: (
                    <div key={specificStyle.key} style={{ paddingTop: 10, width: "510px", maxWidth: "100%" }}>
                        <FormElements
                            items={specificStyle.styles.map((item: any) => {
                                return {
                                    name: specificStyle.key + specificStyleCategorySeparator + item.name,
                                    label: getSpecificStylesLabel({ label: item.label, t: t }),
                                    type: item.type,
                                    addonAfter: (
                                        <SpecificStylesSwitch
                                            setDisabledSpecificStyles={setDisabledSpecificStyles}
                                            disabledSpecificStyles={disabledSpecificStyles}
                                            inputName={
                                                specificStyle.key + specificStyleCategorySeparator + item.name
                                            }
                                            specificStylesForm={specificStylesForm}
                                            specificStyles={
                                                stylesData?.draft && !Array.isArray(stylesData.draft)
                                                    ? stylesData.draft
                                                    : stylesData?.contents
                                            }
                                            specificStyleCategory={specificStyle.key}
                                            specificStyleKey={item.name}
                                            inputType={item.type}
                                        />
                                    ),
                                    inputProps: {
                                        ...item.inputProps,
                                        disabled:
                                            disabledSpecificStyles[
                                                specificStyle.key + specificStyleCategorySeparator + item.name
                                            ] === null
                                    }
                                } as MultilingualFormInput;
                            })}
                        />
                    </div>
                )
            };
        });
    }, [disabledSpecificStyles, t, stylesData, specificStylesForm, setDisabledSpecificStyles]);

    const loading = isLoadingStylesData || isFetchingStylesData;

    return (
        <Spin spinning={loading}>
            <Form form={specificStylesForm}>
                {specificStylesTabs.map((tab) => {
                    return (
                        <div
                            key={tab.key}
                            style={{
                                display: advancedStylesActiveKey === tab.key ? "block" : "none"
                            }}
                        >
                            {tab.children}
                        </div>
                    );
                })}
            </Form>
        </Spin>
    );
};

export default SpecificStyles;
