import { Alert, Button, Space, Spin, Typography } from "antd";
import { BulbOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { buildTranslation, translations } from "@translations/crudTranslationBuilder";
import { qAndAUserInputs, qAndAUserPasswordInputs } from "@utils/forms/q&a/q&aUserFormInputs";
import {
    useCreateModeratorMutation,
    useDeleteModeratorMutation,
    useGetModeratorsQuery,
    useUpdateModeratorMutation
} from "@store/admin";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ActionsBanner from "@components/ActionsBanner/ActionsBanner";
import DoubleColumnLayout from "@components/ColumnLayout/ColumnLayout";
import EpisodeSelector from "@components/EpisodeSelector/EpisodeSelector";
import Error from "@components/Error";
import { Error422 } from "@store/index";
import Form from "@components/Form";
import PageTitle from "@components/PageTitle/PageTitle";
import SearchableTable from "@components/SearchableTable/SearchableTable";
import StickToTop from "@components/StickToTop/StickToTop";
import { adminProfilesTableColumns } from "@utils/layout/q&a/adminProfilesTableColumns";
import { mapErrorsToFields } from "@utils/mapErrorsToFields";
import { setBreadcrumb } from "@store/ui";
import { useDispatch } from "@store/hooks";
import { useGetEventQuery } from "@store/events";
import { usePerformMutation } from "@utils/perform-mutation";
import { useSideMenu } from "@hooks/useSideMenu";
import { useTranslation } from "react-i18next";

const EventLive = () => {
    const { eventId, userId } = useParams();
    const navigate = useNavigate();
    useSideMenu({ isVisible: true, contentType: "event" });
    const { t } = useTranslation(["translations", "crud"]);
    const [form] = Form.useForm();
    const addingNewUser = userId === "new-admin-user";
    const [page, setPage] = useState<number>(1);
    const { performMutation } = usePerformMutation();
    const dispatch = useDispatch();

    const [updateAdminUser, { isLoading: isLoadingEditAdminUser }] = useUpdateModeratorMutation();
    const [createAdminUser, { isLoading: isLoadingCreateAdminUser }] = useCreateModeratorMutation();
    const [deleteAdminUser, { isLoading: isLoadingDeleteAdminUser }] = useDeleteModeratorMutation();

    const {
        data: event,
        isLoading: isLoadingEvent,
        isFetching: isFetchingEvent,
        error: errorEvent,
        isUninitialized: isUninitializedEvent
    } = useGetEventQuery({ eventId: eventId! }, { skip: !eventId });

    const {
        data: moderatorsData,
        isLoading: isLoadingModeratorsData,
        isFetching: isFetchingModeratorsData,
        error: errorModeratorsData,
        isUninitialized: isUninitializedModeratorsData
    } = useGetModeratorsQuery(
        { eventId: eventId!, page: page },
        { skip: !eventId || isUninitializedEvent || isLoadingEvent || !!errorEvent }
    );

    const handleCUDAdminUser =
        (method: "delete" | "put" | "post", moderatorId?: number) => async (values: any) => {
            const message = buildTranslation(t, {
                subject: translations.subject.adminUser,
                method: translations.request[method]
            });

            await performMutation({
                mutation: async () => {
                    method === "put"
                        ? await updateAdminUser({
                              eventId: eventId,
                              moderatorId: userId,
                              ...values
                          }).unwrap()
                        : method === "post"
                        ? await createAdminUser({
                              eventId: eventId,
                              ...values
                          }).unwrap()
                        : await deleteAdminUser({
                              eventId: eventId!,
                              moderatorId: moderatorId!
                          }).unwrap();
                },
                successMessage: message(translations.outcome.success),
                errorMessage: message(translations.outcome.error),
                onSuccessCallback: () => navigate(`/events/${eventId}/q-and-a`),
                setFieldsWithErrors: (error: Error422) => {
                    if (values) {
                        const valuesWithErrors = mapErrorsToFields({ error: error, values });
                        form.setFields(valuesWithErrors);
                    }
                }
            });
        };

    const selectedUser = useMemo(() => {
        if (userId && moderatorsData?.data) {
            return moderatorsData.data.find((moderator) => moderator.id === parseInt(userId));
        }
    }, [userId, moderatorsData]);

    useEffect(() => {
        if (selectedUser && form) {
            form.setFieldsValue(selectedUser);
            dispatch(setBreadcrumb({ index: 4, name: selectedUser.email }));
        } else {
            form?.resetFields();
            dispatch(setBreadcrumb({ index: 4, name: null }));
        }
        return () => {
            dispatch(setBreadcrumb({ index: 4, name: null }));
        };
    }, [selectedUser, form]);

    const error = errorEvent || errorModeratorsData;
    const loading =
        isUninitializedEvent ||
        isLoadingEvent ||
        isFetchingEvent ||
        isLoadingModeratorsData ||
        isLoadingDeleteAdminUser ||
        isLoadingEditAdminUser ||
        isUninitializedModeratorsData ||
        isLoadingCreateAdminUser;

    if (error) return <Error message={t("generic-error-message")} />;
    return (
        <Spin spinning={loading}>
            <PageTitle icon={<QuestionCircleOutlined />} title={t("q-and-a")} />
            <DoubleColumnLayout
                firstColumn={
                    <>
                        <StickToTop
                            title={<Typography.Title level={4}>{t("admin-users")}</Typography.Title>}
                            actions={
                                <Button
                                    type="primary"
                                    disabled={addingNewUser}
                                    onClick={() => navigate(`/events/${eventId}/q-and-a/new-admin-user`)}
                                    icon={<PlusOutlined />}
                                >
                                    {t("add-new")}
                                </Button>
                            }
                        />
                        {moderatorsData?.data && moderatorsData?.data?.length === 0 ? (
                            <Alert
                                message={t("no-admin-users-alert")}
                                data-testid="no-qanda-users-alert"
                                type="info"
                                icon={<BulbOutlined />}
                                showIcon
                            ></Alert>
                        ) : (
                            <SearchableTable
                                selectable={true}
                                page={page}
                                setPage={setPage}
                                loading={isFetchingModeratorsData}
                                paginationSize={moderatorsData?.pagination.perPage}
                                paginationTotal={moderatorsData?.pagination.total || 1}
                                disableSelectionCheckboxes={true}
                                data={moderatorsData?.data?.map((moderator) => ({
                                    ...moderator,
                                    key: moderator.id
                                }))}
                                selectedRowKeys={userId && !isNaN(parseInt(userId)) ? [parseInt(userId)] : []}
                                columns={adminProfilesTableColumns({
                                    t,
                                    goToAdminUser: (userId: number) =>
                                        navigate(`/events/${eventId}/q-and-a/${userId}`),
                                    handleCUDAdminUser
                                })}
                            />
                        )}
                    </>
                }
                secondColumn={
                    !userId ? (
                        <>
                            <StickToTop
                                title={
                                    <Typography.Title level={4}>{t("admin-user-details")}</Typography.Title>
                                }
                            />
                            <Alert
                                type="info"
                                message={t("select-admin-user-to-edit")}
                                icon={<BulbOutlined />}
                                showIcon
                                data-testid="no-question-selected-banner"
                            />
                        </>
                    ) : (
                        <>
                            <StickToTop
                                title={
                                    <Typography.Title level={4}>
                                        {addingNewUser ? t("add-new-user") : selectedUser?.email}
                                    </Typography.Title>
                                }
                            />
                            <Form
                                items={
                                    addingNewUser
                                        ? [
                                              ...qAndAUserInputs({
                                                  t,
                                                  availableEpisodes: event?.episodes || []
                                              }),
                                              ...qAndAUserPasswordInputs({ t })
                                          ]
                                        : [
                                              ...qAndAUserInputs({
                                                  t,
                                                  availableEpisodes: event?.episodes || []
                                              })
                                          ]
                                }
                                languageSpecificLabel={t("language-specific-information")}
                                form={form}
                                onSubmit={
                                    addingNewUser ? handleCUDAdminUser("post") : handleCUDAdminUser("put")
                                }
                            />
                        </>
                    )
                }
                secondColumnBanner={
                    userId ? (
                        <ActionsBanner>
                            <Space direction="horizontal" align="end">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    onClick={form.submit}
                                    data-testid="save-new-polling-question"
                                >
                                    {addingNewUser ? t("save-new-admin-user") : `${t("update-admin-user")}`}
                                </Button>
                            </Space>
                        </ActionsBanner>
                    ) : undefined
                }
            />
        </Spin>
    );
};

export default EventLive;
