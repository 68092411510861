import { Form, FormInstance, Tabs, Typography } from "antd";
import React, { Ref, useEffect, useMemo, useState } from "react";

import FormElements from "@components/FormElements";
import { InternalNamePath } from "antd/es/form/interface";
import type { MultilingualFormInput } from "@components/FormElements";

export interface MultilingualFormProps {
    form?: FormInstance;
    onSubmit: (values: any) => void;
    actions?: React.ReactElement;
    ref?: Ref<FormInstance>;
    items: MultilingualFormInput[];
    languageReferences?: { id: string; label: string }[];
    mostUsedLanguages?: string[] | undefined;
    languages?: string[] | undefined;
    defaultlanguage?: string;
    languageSpecificLabel?: string;
    forceRender?: boolean;
    onValuesChange?: (changedValues: any, values: any) => void;
}

const MultilingualForm: React.ForwardRefExoticComponent<MultilingualFormProps> & {
    useForm: typeof Form.useForm;
} = React.forwardRef(
    (
        {
            onSubmit,
            actions,
            form,
            items,
            mostUsedLanguages,
            languageReferences,
            defaultlanguage,
            languages,
            forceRender,
            languageSpecificLabel,
            onValuesChange
        }: MultilingualFormProps,
        ref: Ref<FormInstance>
    ) => {
        const orderedLanguages = useMemo(() => {
            if (!mostUsedLanguages) return languages;
            const filteredLanguages: string[] = [];
            const normalLanguages =
                languages?.filter((ln: string) => {
                    if (mostUsedLanguages.includes(ln)) {
                        filteredLanguages.push(ln);
                        return false;
                    } else return true;
                }) || [];

            const orderedMostUsed =
                mostUsedLanguages.filter((ln: string) => filteredLanguages.includes(ln)) || [];

            return [...orderedMostUsed, ...normalLanguages];
        }, [languages, mostUsedLanguages]);

        const [activeTab, setActiveTab] = useState<string | null>(orderedLanguages?.[0] || null);

        useEffect(() => {
            if (orderedLanguages && orderedLanguages.length) setActiveTab(orderedLanguages[0]);
        }, [orderedLanguages]);

        const handleFormValidationError = (errorFields: { name: InternalNamePath; errors: string[] }[]) => {
            errorFields.every((errorField) => {
                if (languages?.indexOf(errorField.name[1] as string) !== -1) {
                    setActiveTab(errorField.name[1] as string);
                    return false;
                } else return true;
            });
            setTimeout(() => {
                const element = document.querySelector(`*[for="${errorFields[0].name.join("_")}"]`);
                if (element) element.scrollIntoView({ behavior: "smooth", block: "center" });
            }, 100);
        };

        return (
            <Form
                layout="vertical"
                form={form || undefined}
                ref={ref}
                style={{ width: "100%" }}
                onFinish={(values) => onSubmit(values)}
                onFinishFailed={({ errorFields }) => handleFormValidationError(errorFields)}
                onValuesChange={onValuesChange}
            >
                {languages !== undefined && languages?.length > 0 ? (
                    <>
                        <FormElements items={items.filter((item) => !item.multilingual)} language={null} />
                        {languageSpecificLabel && (
                            <Typography.Text type="secondary">{languageSpecificLabel}</Typography.Text>
                        )}
                        {activeTab && (
                            <Tabs
                                style={{ marginTop: "9px" }}
                                activeKey={activeTab}
                                onChange={(tabKey) => setActiveTab(tabKey)}
                                items={orderedLanguages?.map((language: string) => {
                                    const formItems = items.filter((item) => item.multilingual);
                                    const parsedFormItems = !defaultlanguage
                                        ? formItems
                                        : formItems.map((formItem) => {
                                              return {
                                                  ...formItem,
                                                  rules:
                                                      defaultlanguage === language
                                                          ? formItem.rules
                                                          : formItem?.rules?.filter(
                                                                (rule) => (rule as any)["required"] !== true
                                                            )
                                              };
                                          });
                                    return {
                                        forceRender: !!forceRender || true,
                                        label: `${language.toUpperCase()}`,
                                        key: language,
                                        children: (
                                            <FormElements
                                                items={parsedFormItems}
                                                language={language}
                                                languageReferences={languageReferences}
                                            />
                                        )
                                    };
                                })}
                            />
                        )}
                    </>
                ) : (
                    <FormElements items={items} language={null} />
                )}
                {actions && <Form.Item>{actions}</Form.Item>}
            </Form>
        );
    }
) as any;

MultilingualForm.useForm = Form.useForm;

export default MultilingualForm;
