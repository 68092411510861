interface FormValues {
    [key: string]: any;
}

interface GetFormValuesArgs {
    values: FormValues;
    valuesToGet?: string[];
    excludeValues?: string[];
}

export const getFormValues = ({ values, valuesToGet, excludeValues }: GetFormValuesArgs) => {
    if (valuesToGet) {
        return Object.keys(values).reduce((acc: FormValues, curr: any) => {
            if (valuesToGet?.includes(curr)) return { ...acc, [curr]: values[curr] };
            else return { ...acc };
        }, {});
    } else if (excludeValues) {
        return Object.keys(values).reduce((acc: FormValues, curr: any) => {
            if (!excludeValues?.includes(curr)) return { ...acc, [curr]: values[curr] };
            else {
                return { ...acc };
            }
        }, {});
    }
};
