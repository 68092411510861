import { useEffect, useState } from "react";

import moment from "moment";

interface FutureOrPastProps {
    date: string;
    futureElement: React.ReactNode;
    pastElement: React.ReactNode;
}

const FutureOrPast: React.FC<FutureOrPastProps> = ({ date, futureElement, pastElement }) => {
    const [isDateInTheFuture, setIsDateInTheFutiure] = useState<boolean>(moment(date).isAfter());

    useEffect(() => {
        setIsDateInTheFutiure(moment(date).isAfter());
        const checkDate = setInterval(() => setIsDateInTheFutiure(moment(date).isAfter()), 1000);
        return () => clearInterval(checkDate);
    }, [date]);

    return <>{isDateInTheFuture ? futureElement : pastElement}</>;
};

export default FutureOrPast;
