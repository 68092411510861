import { FormInstance } from "antd/lib";
import { MultilingualFormInputTypes } from "@components/FormElements";
import React from "react";
import { Switch } from "antd";
import { useTranslation } from "react-i18next";

interface SpecificStylesSwitchProps {
    disabledSpecificStyles: { [key: string]: null };
    setDisabledSpecificStyles: Function;
    inputName: string;
    specificStylesForm: FormInstance;
    specificStyleKey: string;
    specificStyleCategory: string;
    specificStyles: any;
    inputType: MultilingualFormInputTypes;
}

const SpecificStylesSwitch: React.FC<SpecificStylesSwitchProps> = ({
    disabledSpecificStyles,
    inputName,
    setDisabledSpecificStyles,
    specificStylesForm,
    specificStyleKey,
    specificStyleCategory,
    specificStyles,
    inputType
}) => {
    const { t } = useTranslation(["translations"]);

    return (
        <Switch
            checked={disabledSpecificStyles[inputName] !== null}
            checkedChildren={t("enabled")}
            unCheckedChildren={t("disabled")}
            onChange={(checked: boolean) => {
                let defaultValue = undefined;
                if (inputType === "color") {
                    defaultValue = specificStyles?.[specificStyleCategory]?.[specificStyleKey] || "#fff";
                }

                if (checked && inputType === "color") {
                    specificStylesForm.setFieldValue(inputName, defaultValue);
                } else if (!checked && inputType !== "color") {
                    specificStylesForm.setFieldValue(inputName, defaultValue);
                }

                setDisabledSpecificStyles((ps: { [key: string]: null }) => {
                    const object = { ...ps };
                    if (object[inputName] !== undefined) {
                        delete object[inputName];
                        return object;
                    } else {
                        return {
                            ...object,
                            [inputName]: null
                        };
                    }
                });
            }}
            style={{ position: "absolute", right: -95, top: 5 }}
        />
    );
};

export default SpecificStylesSwitch;
