import { MiddlewareArray, Reducer, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";

import { BaseQueryResult } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import type { LayoutState } from "@store/layout";
import type { UiState } from "@store/ui";
import adminReducer from "@store/admin";
import eventsApi from "@store/events";
import internationalizationApi from "@store/internationalization";
import layoutReducer from "@store/layout";
import liveApi from "@store/live";
import profileApi from "@store/profile";
import storage from "redux-persist/lib/storage";
import uiReducer from "@store/ui";
import userReducer from "@store/user";

const persistConfig = {
    key: "layout",
    storage: storage
};

const uiPersistConfig = {
    key: "ui",
    storage: storage
};

const store = configureStore({
    reducer: {
        user: userReducer,
        layout: persistReducer(persistConfig, layoutReducer) as Reducer<LayoutState & any, any>,
        ui: persistReducer(uiPersistConfig, uiReducer) as Reducer<UiState & any, any>,
        [eventsApi.reducerPath]: eventsApi.reducer,
        [internationalizationApi.reducerPath]: internationalizationApi.reducer,
        [liveApi.reducerPath]: liveApi.reducer,
        [adminReducer.reducerPath]: adminReducer.reducer,
        [profileApi.reducerPath]: profileApi.reducer
    },
    middleware: (getDefaultMiddleware) => {
        let middleware = getDefaultMiddleware({
            serializableCheck: false
        });

        if (process.env.NODE_ENV === "development") {
            // middleware = middleware.concat(logger) as MiddlewareArray<any>;
        }

        middleware = middleware.concat(eventsApi.middleware) as MiddlewareArray<any>;
        middleware = middleware.concat(internationalizationApi.middleware) as MiddlewareArray<any>;
        middleware = middleware.concat(liveApi.middleware) as MiddlewareArray<any>;
        middleware = middleware.concat(adminReducer.middleware) as MiddlewareArray<any>;
        middleware = middleware.concat(profileApi.middleware) as MiddlewareArray<any>;

        return middleware;
    }
});

export default store;

export interface RequestAction {
    loading: boolean;
    success?: boolean;
    item?: any;
    error: any;
}
export interface Error422 {
    errors: {
        [key: string]: string[];
    };
    message: string;
}

export const paginateResponse = (res: BaseQueryResult<any>): any => {
    return {
        data: res.data,
        pagination: {
            total: res.meta?.total,
            perPage: res.meta?.per_page ? parseInt(res.meta?.per_page) : undefined,
            current: res.meta?.current_page
        }
    };
};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
