import { Layout, Menu, theme } from "antd";
import {
    selectSideMenuCollapsedState,
    selectSideMenuContentType,
    selectSideMenuVisibleState
} from "@store/layout/selectors";
import { useDispatch, useSelector } from "@store/hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { eventsSideMenuContents } from "@utils/layout/events/sideMenuContents";
import { getHasRequiredInfo } from "@utils/general";
import { profilesSideMenuContents } from "@utils/layout/profiles/sideMenuContents";
import { setSideMenuCollapsed } from "@store/layout";
import styles from "./SideMenu.module.scss";
import { useGetEventQuery } from "@store/events";
import { useTranslation } from "react-i18next";

const { Sider } = Layout;

const SideMenu = () => {
    const {
        token: { colorBorder }
    } = theme.useToken();
    const isSideMenuCollapsed = useSelector(selectSideMenuCollapsedState);
    const isSideMenuVisible = useSelector(selectSideMenuVisibleState);
    const contentType = useSelector(selectSideMenuContentType);
    const { t } = useTranslation(["translations"]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { eventId, episodeId, profileId } = useParams();

    const { data: eventData } = useGetEventQuery({ eventId: eventId! }, { skip: !!!eventId });
    const { hasEventRequiredInfo } = getHasRequiredInfo(eventData);

    const sideMenuItems = () => {
        if (contentType === "event")
            return eventsSideMenuContents({
                t,
                navigate,
                eventId: eventId!,
                episodeId: episodeId,
                hasRequiredInfo: hasEventRequiredInfo,
                isSideMenuCollapsed: isSideMenuCollapsed,
                setSideMenuCollapsed: () => dispatch(setSideMenuCollapsed(!isSideMenuCollapsed))
            });
        else if (contentType === "profile")
            return profilesSideMenuContents({
                t,
                navigate,
                profileId,
                hasRequiredInfo: !!profileId && profileId !== "new-profile",
                isSideMenuCollapsed: isSideMenuCollapsed,
                setSideMenuCollapsed: () => dispatch(setSideMenuCollapsed(!isSideMenuCollapsed))
            });
    };

    const activeMenuItem = sideMenuItems()?.find((menuItem: any) => {
        if (menuItem?.key === "home" && location.pathname.split("/")[3] === undefined) return menuItem?.key;
        return menuItem?.key === location.pathname.split("/")[3];
    })?.key;

    const activeItems = activeMenuItem ? [activeMenuItem.toString()] : undefined;

    return (
        <Sider
            trigger={null}
            collapsible
            collapsed={isSideMenuCollapsed}
            hidden={!isSideMenuVisible}
            className={styles["side-menu"]}
            style={{
                borderRight: `1px solid ${colorBorder}`,
                outline: "none"
            }}
        >
            <>
                {isSideMenuVisible && (
                    <Menu
                        mode="inline"
                        selectedKeys={activeItems}
                        className={styles["side-menu__element"]}
                        items={sideMenuItems()}
                        data-testid="side-menu"
                        style={{ border: 0 }}
                    />
                )}
            </>
        </Sider>
    );
};

export default SideMenu;
