import { LinkOutlined, QrcodeOutlined } from "@ant-design/icons";
import { Space, Typography } from "antd";

import QRCodeGenerator from "@components/QRCodeGenerator";
import React from "react";
import { useTranslation } from "react-i18next";

interface QRCodeAndPreviewProps {
    previewUrl: string;
}

const QRCodeAndPreview: React.FC<QRCodeAndPreviewProps> = ({ previewUrl }) => {
    const { t } = useTranslation(["translations"]);

    return (
        <Space direction="horizontal" style={{ marginBottom: 20 }}>
            <QRCodeGenerator baseUrl={previewUrl}>
                <Space direction="horizontal">
                    <QrcodeOutlined />
                    {t("download-qr-code")}
                </Space>
            </QRCodeGenerator>
            <Typography.Link
                style={{ height: 30, padding: "0 10px" }}
                href={previewUrl}
                target="_blank"
                rel="noreferrer"
            >
                <Space direction="horizontal">
                    <LinkOutlined />
                    {t("open-preview")}
                </Space>
            </Typography.Link>
        </Space>
    );
};

export default QRCodeAndPreview;
