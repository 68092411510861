import React from "react";
import styles from "./StickToTop.module.scss";
import { theme } from "antd";

interface StickToTopProps {
    title?: React.ReactNode;
    children?: React.ReactNode;
    actions?: React.ReactNode;
    style?: any;
}

const StickToTop: React.FC<StickToTopProps> = ({ title, children, actions, style }) => {
    const {
        token: { colorBgContainer, colorBorder }
    } = theme.useToken();

    return (
        <div
            style={{
                backgroundColor: colorBgContainer,
                borderBottom: `1px solid ${colorBorder}`,
                ...style
            }}
            className={styles["stick-to-top"]}
        >
            <div className={styles["stick-to-top__main-content"]}>
                {title}
                {actions && <div className={styles["stick-to-top__actions-container"]}>{actions}</div>}
            </div>
            <div className={styles["stick-to-top__content"]}>{children}</div>
        </div>
    );
};

export default StickToTop;
