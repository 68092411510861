import { Button, Tag } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import { EpisodeType } from "@store/events/types";

interface EventsTableColumnsArgs {
    t: Function;
    navigate: Function;
}

export const episodesTableColumns = ({ t, navigate }: EventsTableColumnsArgs) => [
    {
        title: t("id"),
        dataIndex: "id",
        key: "id"
    },
    {
        title: t("title"),
        dataIndex: "title",
        key: "title",
        render: (title: string, row: EpisodeType) => {
            return (
                <Button
                    type="text"
                    data-testid="episode-title-button"
                    onClick={() => navigate("/events/" + row.event_id + "/episodes/" + row.id)}
                >
                    {row.title_default}
                </Button>
            );
        }
    },
    {
        title: t("visible"),
        dataIndex: "show_to_frontend",
        key: "show_to_frontend",
        render: (show_to_frontend: number) => {
            return !!show_to_frontend ? (
                <Tag color="default" data-testid="episode-visible-tag-yes">
                    <CheckCircleOutlined /> {t("yes")}
                </Tag>
            ) : (
                <Tag color="default" data-testid="episode-visible-tag-no">
                    <CloseCircleOutlined /> {t("no")}
                </Tag>
            );
        }
    },
    {
        title: t("channels"),
        dataIndex: "channels",
        key: "channels",
        render: (_: any, row: EpisodeType) => row.channels.length
    }
];
