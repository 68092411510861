import { Button, Tag } from "antd";
import { Dispatch, SetStateAction } from "react";

import moment from "moment";

export const logsTableColumns = ({
    modalSetter
}: {
    modalSetter: Dispatch<SetStateAction<{ old: any; attributes: any } | null>>;
}) => [
    {
        title: "E-mail",
        dataIndex: "causer",
        key: "email",
        render: (causer: { email: string } | null) => {
            return causer?.email;
        }
    },
    {
        title: "Timestamp",
        dataIndex: "updated_at",
        key: "updated_at",
        render: (value: string) => {
            let timestamp;
            try {
                timestamp = moment(value).format("MMMM DD YYYY HH:mm");
            } catch {
                timestamp = value;
            }
            return timestamp;
        }
    },
    {
        title: "Subject",
        dataIndex: "subject_type",
        key: "subject_type",
        render: (value: string) => {
            let formattedValue = "";
            try {
                formattedValue = value.split("\\")?.[2];
            } catch {}
            return formattedValue;
        }
    },
    {
        title: "Description",
        dataIndex: "description",
        key: "address",
        render: (value: string) => {
            let color;
            switch (value) {
                case "updated":
                    color = "blue";
                    break;
                case "created":
                    color = "green";
                    break;
                case "deleted":
                    color = "red";
                    break;
                default:
                    color = "orange";
            }
            return <Tag color={color}>{value}</Tag>;
        }
    },
    {
        title: "View changes",
        dataIndex: "properties",
        key: "properties",
        render: (value: { old: any; attributes: any }) => {
            return <Button onClick={() => modalSetter(value)}>View changes</Button>;
        }
    }
];
