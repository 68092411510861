import { Button, Modal, Space, Spin, Tabs } from "antd";
import { buildTranslation, translations } from "@translations/crudTranslationBuilder";
import { timeZoneToUtc, utcToTimeZone } from "@utils/timezones";
import {
    useCreateCalendarMutation,
    useDeleteCalendarMutation,
    useGetEpisodeCalendarsQuery,
    useGetEventQuery,
    useUpdateCalendarMutation
} from "@store/events";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import EpisodeSelector from "@components/EpisodeSelector/EpisodeSelector";
import Error from "@components/Error";
import { Error422 } from "@store/index";
import Form from "@components/Form";
import { FormOutlined } from "@ant-design/icons";
import PageTitle from "@components/PageTitle/PageTitle";
import QuestionsList from "./QuestionsList";
import QuestionsSlots from "./QuestionsSlots";
import VisibilityTag from "@components/VisibilityTag/VisibilityTag";
import { evaluationFormCalendarFormInputs } from "@utils/forms/evaluationForm/evaluationFormCalendar";
import { getHasRequiredInfo } from "@utils/general";
import { mapErrorsToFields } from "@utils/mapErrorsToFields";
import moment from "moment";
import { usePerformMutation } from "@utils/perform-mutation";
import { useRedirectEventsHome } from "@hooks/useRedirectEventsHome";
import { useSideMenu } from "@hooks/useSideMenu";
import { useTranslation } from "react-i18next";

const evaluationFormTypes = ["questions-list", "slots"];

const EventEvaluationForm = () => {
    const { pathname } = useLocation();
    const getTabPathname = () => {
        if (evaluationFormTypes.includes(pathname.split("/")?.[5])) return pathname.split("/")?.[5];
        else return null;
    };
    const navigate = useNavigate();
    const { eventId, episodeId } = useParams();
    const { t } = useTranslation(["translations"]);
    useSideMenu({ isVisible: true, contentType: "event" });
    const [isCalendarModalOpen, setIsCalendarModalOpen] = useState<boolean>(false);
    const [calendarForm] = Form.useForm();
    const { performMutation } = usePerformMutation();

    const [activeTab, setActiveTab] = useState<string>(evaluationFormTypes[0]);
    const [isLoadingChild, setIsLoadingChild] = useState<boolean>(true);

    const [updateCalendar, { isLoading: isLoadingUpdateCalendar }] = useUpdateCalendarMutation();
    const [createCalendar, { isLoading: isLoadingCreateCalendar }] = useCreateCalendarMutation();
    const [deleteCalendar, { isLoading: isLoadingDeleteCalendar }] = useDeleteCalendarMutation();

    useEffect(() => {
        if (episodeId) {
            navigate(`/events/${eventId}/evaluation-form/${episodeId}/${activeTab}`);
        }
    }, [activeTab, episodeId]);

    const {
        data: calendars,
        isLoading: isLoadingCalendars,
        isFetching: isFetchingCalendars,
        error: errorCalendars,
        isUninitialized: isUninitializedCalendars
    } = useGetEpisodeCalendarsQuery(
        { episode: episodeId!, event: eventId!, type: 3 },
        { skip: !!!episodeId || !!!eventId }
    );

    const {
        data: event,
        isLoading: isLoadingEvent,
        isFetching: isFetchingEvent,
        error: errorEvent,
        isUninitialized: isUninitializedEvent
    } = useGetEventQuery({ eventId: eventId! }, { skip: !eventId });

    const { hasEventRequiredInfo, isLoadingRequiredInfo } = getHasRequiredInfo(event);

    useRedirectEventsHome({
        hasInfo: hasEventRequiredInfo,
        isLoading: isLoadingRequiredInfo,
        eventId
    });

    /** CALENDARS */
    useEffect(() => {
        if (!event || !calendars || !calendarForm) return;
        const startAt = utcToTimeZone({ time: calendars?.[0]?.start_at, zone: event.timezone });
        const finishAt = utcToTimeZone({ time: calendars?.[0]?.finish_at, zone: event.timezone });

        calendarForm.setFieldsValue({
            start_at: startAt ? moment(startAt) : undefined,
            finish_at: finishAt ? moment(finishAt) : undefined
        });
    }, [calendars, event, isCalendarModalOpen, calendarForm]);

    const handleCalendar = (values: any) => {
        const startAt = values.start_at
            ? timeZoneToUtc({ time: values.start_at, zone: event?.timezone })
            : null;
        const finishAt = values.finish_at
            ? timeZoneToUtc({ time: values.finish_at, zone: event?.timezone })
            : null;
        const normalizedValues = {
            type: 3,
            start_at: startAt,
            finish_at: finishAt
        };

        if (calendars && calendars[0] && (startAt || finishAt)) handleUpdateCalendar(normalizedValues);
        else if (calendars && calendars[0] && !(startAt || finishAt)) handleDeleteCalendar();
        else handleCreateCalendar(normalizedValues);
    };

    const handleCreateCalendar = async (values: any) => {
        const message = buildTranslation(t, {
            subject: translations.subject.evalFormCalendar,
            method: translations.request.post
        });
        await performMutation({
            mutation: async () =>
                await createCalendar({
                    eventId: eventId,
                    episodeId: episodeId,
                    ...values
                }).unwrap(),
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error),
            setFieldsWithErrors: (error: Error422) => {
                const valuesWithErrors = mapErrorsToFields({ error: error, values });
                calendarForm.setFields(valuesWithErrors);
            },
            onSuccessCallback: () => {
                setIsCalendarModalOpen(false);
            }
        });
    };

    const handleUpdateCalendar = async (values: any) => {
        const message = buildTranslation(t, {
            subject: translations.subject.evalFormCalendar,
            method: translations.request.put
        });
        await performMutation({
            mutation: async () =>
                await updateCalendar({
                    eventId: eventId,
                    calendarId: calendars && calendars[0]?.id,
                    ...values
                }).unwrap(),
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error),
            onSuccessCallback: () => setIsCalendarModalOpen(false)
        });
    };

    const handleDeleteCalendar = async () => {
        const message = buildTranslation(t, {
            subject: translations.subject.evalFormCalendar,
            method: translations.request.delete
        });
        await performMutation({
            mutation: async () => {
                if (calendars) {
                    await deleteCalendar({
                        eventId: eventId!,
                        calendarId: calendars[0]?.id
                    }).unwrap();
                }
            },
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error),
            onSuccessCallback: () => setIsCalendarModalOpen(false)
        });
    };

    const error = errorCalendars || errorEvent;
    const loading =
        isUninitializedCalendars ||
        isUninitializedEvent ||
        isLoadingEvent ||
        isFetchingEvent ||
        isLoadingUpdateCalendar ||
        isLoadingCreateCalendar ||
        isLoadingDeleteCalendar ||
        isLoadingCalendars ||
        isFetchingCalendars ||
        isLoadingChild;

    if (error) return <Error message={t("generic-error-message")} />;
    return (
        <Spin spinning={loading} className="page-spinner">
            <EpisodeSelector
                redirectionSubpath="evaluation-form"
                defaultEpisodeId={event?.default_episode.id}
                episodes={event?.episodes}
                afterEpisodePath={getTabPathname()}
            />
            <PageTitle
                icon={<FormOutlined />}
                title={t("evaluation-form-page-title")}
                additionalContent={
                    <>
                        <Space direction="horizontal" style={{ marginBottom: 23 }}>
                            {calendars && (
                                <VisibilityTag
                                    startAt={calendars[0]?.start_at}
                                    finishAt={calendars[0]?.finish_at}
                                    timezone={event?.timezone || ""}
                                />
                            )}
                            <Button type="default" onClick={() => setIsCalendarModalOpen(true)}>
                                {t("open-calendar")}
                            </Button>
                        </Space>
                        <Tabs
                            type="card"
                            activeKey={activeTab}
                            onChange={(key) => setActiveTab(key)}
                            items={[
                                {
                                    key: evaluationFormTypes[0],
                                    label: t("questions-list"),
                                    children: null
                                },
                                {
                                    key: evaluationFormTypes[1],
                                    label: t("evaluation-form-groups-publish"),
                                    children: null
                                }
                            ]}
                        />
                    </>
                }
            />
            {activeTab === evaluationFormTypes[0] && <QuestionsList setIsLoading={setIsLoadingChild} />}
            {activeTab === evaluationFormTypes[1] && <QuestionsSlots setIsLoading={setIsLoadingChild} />}
            <Modal
                forceRender={true}
                maskClosable={false}
                title={t("edit-evaluation-form-visibility")}
                open={isCalendarModalOpen}
                onOk={calendarForm.submit}
                confirmLoading={isLoadingCreateCalendar || isLoadingDeleteCalendar || isLoadingUpdateCalendar}
                onCancel={() => {
                    setIsCalendarModalOpen(false);
                }}
            >
                <Spin spinning={loading}>
                    <Form
                        form={calendarForm}
                        onSubmit={handleCalendar}
                        items={evaluationFormCalendarFormInputs({ t })}
                    />
                </Spin>
            </Modal>
        </Spin>
    );
};

export default EventEvaluationForm;
