import { Language } from "@store/internationalization/types";

export interface CheckboxGroupOption {
    label: string;
    value: string;
}

export const mapLanguagesToOptions = (languages: Language[]): CheckboxGroupOption[] => {
    return languages.map((language: Language) => ({
        label: language.label,
        value: language.id
    }));
};
