import { Button, List, Modal, Popconfirm, Skeleton, Space, Spin, Tabs, Tag, Typography, theme } from "antd";
import { DeleteOutlined, ReconciliationOutlined, SaveOutlined } from "@ant-design/icons";
import { buildTranslation, translations } from "@translations/crudTranslationBuilder";
import {
    useAmendContentsMutation,
    useCheckContentsQuery,
    useDiscardSectionDraftMutation,
    useGetContentSectionQuery,
    useGetEventQuery,
    usePublishSectionDraftMutation
} from "@store/events";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ActionsBanner from "@components/ActionsBanner/ActionsBanner";
import DoubleColumnLayout from "@components/ColumnLayout/ColumnLayout";
import Error from "@components/Error";
import Form from "@components/Form";
import { MultilingualFormInput } from "@components/FormElements";
import PageTitle from "@components/PageTitle/PageTitle";
import { PicLeftOutlined } from "@ant-design/icons";
import StickToTop from "@components/StickToTop/StickToTop";
import { eventContentsInputsErrors } from "@utils/forms/contents/eventContentsInputsErrors";
import { eventContentsInputsGeneral } from "@utils/forms/contents/eventContentsInputsGeneral";
import { getHasRequiredInfo } from "@utils/general";
import { useGetLanguagesQuery } from "@store/internationalization";
import { usePerformMutation } from "@utils/perform-mutation";
import { useRedirectEventsHome } from "@hooks/useRedirectEventsHome";
import { useSideMenu } from "@hooks/useSideMenu";
import { useTranslation } from "react-i18next";

const EventReports = () => {
    const {
        token: { colorPrimaryBg, colorBgContainer }
    } = theme.useToken();
    const { performMutation } = usePerformMutation();
    const navigate = useNavigate();
    const [generalForm] = Form.useForm();
    const [errorsForm] = Form.useForm();
    const [comparisonPublishedForm] = Form.useForm();
    const [comparisonCurrentForm] = Form.useForm();
    const { eventId, contentType, language } = useParams();
    useSideMenu({ isVisible: true, contentType: "event" });
    const { t } = useTranslation(["translations", "languages"]);
    const [comparisonModalKeyname, setComparisonModalKeyname] = useState<null | string>(null);
    const contentSections = [
        {
            label: "general",
            id: 1
        },
        {
            label: "errors",
            id: 3
        }
    ];
    const [tabsActiveKey, setTabsActiveKey] = useState<string>(
        contentType || String(contentSections[0].label)
    );
    const contentTypeId = contentSections.find((section) => section.label === contentType)?.id!;

    useEffect(() => {
        if (language) navigate(`/events/${eventId}/contents/${tabsActiveKey}/${language}`);
        else navigate(`/events/${eventId}/contents/${tabsActiveKey}`);
    }, [tabsActiveKey]);

    const [amendContents, { isLoading: isLoadingAmendContents }] = useAmendContentsMutation();
    const [publishSectionDraft, { isLoading: isLoadingPublishSectionDraft }] =
        usePublishSectionDraftMutation();
    const [discardSectionDraft, { isLoading: isLoadingDiscardSectionDraft }] =
        useDiscardSectionDraftMutation();

    const {
        data: event,
        isLoading: isLoadingEvent,
        isFetching: isFetchingEvent,
        isUninitialized: isUninitializedEvent,
        error: errorEvent
    } = useGetEventQuery({ eventId: eventId! }, { skip: !!!eventId });

    const {
        data: languages,
        isFetching: isFetchingLanguages,
        isLoading: isLoadingLanguages,
        error: errorLanguages,
        isUninitialized: isUninitializedLanguages
    } = useGetLanguagesQuery(null);

    const {
        data: checkContents,
        isLoading: isLoadingCheckContents,
        isFetching: isFetchingCheckContents,
        error: errorCheckContents,
        isUninitialized: isUninitializedCheckContents
    } = useCheckContentsQuery({ eventId: eventId! }, { skip: !!!eventId });

    const {
        data: contents,
        isLoading: isLoadingContents,
        isFetching: isFetchingContents,
        error: errorContents,
        isUninitialized: isUninitializedContents
    } = useGetContentSectionQuery(
        {
            eventId: eventId!,
            langId: language!,
            section: contentSections.find((section) => section.label === contentType)?.id!
        },
        {
            skip: !!!eventId || !!!language || !!!contentTypeId
        }
    );

    useEffect(() => {
        if (event && (language === undefined || contentType === undefined)) {
            const setLanguage = language || event.languages[0];
            const setContentType = contentType || contentSections[0].label;
            navigate(`/events/${eventId}/contents/${setContentType}/${setLanguage}`);
        }
    }, [event, language, eventId, contentType]);

    useEffect(() => {
        if (contents && checkContents) {
            if (contentType === "general") {
                const inputsKeys = eventContentsInputsGeneral({ t }).map((input) => input.name);
                const formFields = inputsKeys?.reduce((prev: any, next: string) => {
                    const content = contents?.find((content) => content.keyname === next);
                    return { ...prev, [next]: content?.admin_value || "" };
                }, {});
                generalForm.setFieldsValue(formFields);
            } else if (contentType === "errors") {
                const inputsKeys = eventContentsInputsErrors({ t }).map((input) => input.name);
                const formFields = inputsKeys?.reduce((prev: any, next: string) => {
                    const content = contents?.find((content) => content.keyname === next);
                    return { ...prev, [next]: content?.admin_value || "" };
                }, {});
                errorsForm.setFieldsValue(formFields);
            }
        }
    }, [contents, checkContents, contentType, generalForm, errorsForm]);

    const { hasEventRequiredInfo, isLoadingRequiredInfo } = getHasRequiredInfo(event);

    useRedirectEventsHome({
        hasInfo: hasEventRequiredInfo,
        isLoading: isLoadingRequiredInfo,
        event: eventId
    });

    const handleAmendContents = async (values: { [key: string]: string }) => {
        const message = buildTranslation(t, {
            subject: translations.subject.saveDraft,
            method: translations.request?.general
        });

        const valueKeys = Object.keys(values);
        const persedValues = valueKeys.reduce((prev: any, next: string) => {
            return { ...prev, [next]: { content: values[next] } };
        }, {});

        await performMutation({
            mutation: async () =>
                await amendContents({
                    eventId: eventId!,
                    langId: language!,
                    section: contentTypeId,
                    ...persedValues
                }).unwrap(),
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error)
        });
    };

    const handlePublishSectionDraft = async () => {
        const message = buildTranslation(t, {
            subject: translations.subject.publishDraft,
            method: translations.request?.general
        });

        await performMutation({
            mutation: async () =>
                await publishSectionDraft({
                    eventId: eventId!,
                    langId: language!,
                    section: contentTypeId
                }).unwrap(),
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error)
        });
    };

    const handleDiscardSectionDraft = async () => {
        const message = buildTranslation(t, {
            subject: translations.subject.discardDraft,
            method: translations.request?.general
        });

        await performMutation({
            mutation: async () => {
                await discardSectionDraft({
                    eventId: eventId!,
                    section: 1,
                    langId: language!
                }).unwrap();
            },
            successMessage: message(translations.outcome.success),
            errorMessage: message(translations.outcome.error)
        });
    };

    const comparisonModalElement = comparisonModalKeyname
        ? {
              ...[...eventContentsInputsGeneral({ t }), ...eventContentsInputsErrors({ t })].find(
                  (element) => element.name === comparisonModalKeyname
              )!
          }
        : ({} as MultilingualFormInput);

    useEffect(() => {
        if (!comparisonCurrentForm || !comparisonPublishedForm) return;
        if (comparisonModalKeyname === null) {
            comparisonCurrentForm.resetFields();
            comparisonPublishedForm.resetFields();
        } else {
            const selectedForm = tabsActiveKey === "general" ? generalForm : errorsForm;
            const selectedValue = selectedForm.getFieldValue(comparisonModalKeyname);
            const publishedContent =
                contents?.find((content) => content.keyname === comparisonModalKeyname)?.content || "";
            comparisonPublishedForm.setFieldValue(comparisonModalKeyname, publishedContent);
            comparisonCurrentForm.setFieldValue(comparisonModalKeyname, selectedValue);
        }
    }, [comparisonModalKeyname, tabsActiveKey]);

    const error = errorEvent || errorLanguages || errorCheckContents || errorContents;
    const loading =
        isUninitializedEvent ||
        isLoadingEvent ||
        isFetchingEvent ||
        isLoadingCheckContents ||
        isLoadingContents ||
        isLoadingAmendContents ||
        isLoadingPublishSectionDraft ||
        isLoadingDiscardSectionDraft ||
        isUninitializedContents ||
        isFetchingCheckContents ||
        isUninitializedCheckContents ||
        isFetchingLanguages ||
        isLoadingLanguages ||
        isUninitializedLanguages;

    if (error) return <Error message={t("generic-error-message")} />;
    return (
        <Spin spinning={loading} className="page-spinner">
            <PageTitle icon={<PicLeftOutlined />} title={t("contents-page-title")} />
            <DoubleColumnLayout
                firstColumn={
                    <>
                        <StickToTop
                            style={{ marginBottom: 0 }}
                            title={<Typography.Title level={4}>{t("content-categories")}</Typography.Title>}
                        />
                        <Tabs
                            type="card"
                            activeKey={tabsActiveKey}
                            onChange={(key) => setTabsActiveKey(key)}
                            items={contentSections.map((section) => {
                                return {
                                    label: t(section.label),
                                    key: section.label,
                                    children: (
                                        <List
                                            bordered
                                            style={{
                                                display: tabsActiveKey === section.label ? "block" : "none"
                                            }}
                                            dataSource={event?.languages}
                                            renderItem={(lang: string) => {
                                                return (
                                                    <List.Item
                                                        style={
                                                            language === lang
                                                                ? { backgroundColor: colorPrimaryBg }
                                                                : undefined
                                                        }
                                                        actions={[
                                                            <Tag
                                                                color={
                                                                    !!checkContents?.[contentTypeId]?.[
                                                                        lang!
                                                                    ]?.[1]?.drafts
                                                                        ? "yellow"
                                                                        : !!checkContents?.[contentTypeId]?.[
                                                                              lang!
                                                                          ]?.[1]?.published
                                                                        ? "green"
                                                                        : "red"
                                                                }
                                                            >
                                                                {!!checkContents?.[contentTypeId]?.[
                                                                    lang!
                                                                ]?.[1]?.drafts
                                                                    ? t("unpublished")
                                                                    : !!checkContents?.[contentTypeId]?.[
                                                                          lang!
                                                                      ]?.[1]?.published
                                                                    ? t("published")
                                                                    : t("no-contents")}
                                                            </Tag>
                                                        ]}
                                                    >
                                                        <List.Item.Meta
                                                            style={{ textTransform: "capitalize" }}
                                                            title={
                                                                <Button
                                                                    type="text"
                                                                    style={{ textTransform: "capitalize" }}
                                                                    onClick={() =>
                                                                        navigate(
                                                                            `/events/${eventId}/contents/${contentType}/${lang}`
                                                                        )
                                                                    }
                                                                >
                                                                    {languages
                                                                        ? t(
                                                                              `languages:` +
                                                                                  languages?.find(
                                                                                      (lng) => lng.id === lang
                                                                                  )?.id || ""
                                                                          )
                                                                        : ""}
                                                                </Button>
                                                            }
                                                        />
                                                    </List.Item>
                                                );
                                            }}
                                        />
                                    )
                                };
                            })}
                            tabBarStyle={{
                                position: "sticky",
                                top: "54px",
                                paddingBottom: "10px",
                                paddingTop: "15px",
                                background: colorBgContainer,
                                zIndex: 10
                            }}
                        />
                    </>
                }
                secondColumn={
                    <>
                        <StickToTop
                            title={<Typography.Title level={4}>{t("content-categories")}</Typography.Title>}
                        />
                        <Skeleton loading={isFetchingContents} active={true}>
                            <div style={{ display: tabsActiveKey === "general" ? "block" : "none" }}>
                                <Form
                                    form={generalForm}
                                    onSubmit={handleAmendContents}
                                    items={eventContentsInputsGeneral({
                                        t,
                                        handleComparisonModal: setComparisonModalKeyname
                                    })}
                                />
                            </div>
                            <div style={{ display: tabsActiveKey === "errors" ? "block" : "none" }}>
                                <Form
                                    form={errorsForm}
                                    onSubmit={handleAmendContents}
                                    items={eventContentsInputsErrors({
                                        t,
                                        handleComparisonModal: setComparisonModalKeyname
                                    })}
                                />
                            </div>
                        </Skeleton>
                    </>
                }
                secondColumnBanner={
                    <ActionsBanner>
                        <Space direction="horizontal">
                            <Button
                                size="large"
                                type="dashed"
                                onClick={() => {
                                    if (contentTypeId === 1) generalForm.submit();
                                    else errorsForm.submit();
                                }}
                            >
                                <ReconciliationOutlined />
                                {t("save-draft")}
                            </Button>
                            <div ref={null}>
                                <Popconfirm
                                    title={t("discard-draft-warning-text")}
                                    okText={t("yes")}
                                    cancelText={t("cancel")}
                                    onConfirm={() => handleDiscardSectionDraft()}
                                    disabled={!!!checkContents?.[contentTypeId]?.[language!]?.[1]?.drafts}
                                >
                                    <Button
                                        size="large"
                                        type="dashed"
                                        disabled={!!!checkContents?.[contentTypeId]?.[language!]?.[1]?.drafts}
                                    >
                                        <DeleteOutlined />
                                        {t("discard-draft")}
                                    </Button>
                                </Popconfirm>
                            </div>

                            <div ref={null}>
                                <Popconfirm
                                    title={t("publish-styles-warning-text")}
                                    okText={t("yes")}
                                    cancelText={t("cancel")}
                                    onConfirm={() => handlePublishSectionDraft()}
                                    disabled={!!!checkContents?.[contentTypeId]?.[language!]?.[1]?.drafts}
                                >
                                    <Button
                                        size="large"
                                        disabled={!!!checkContents?.[contentTypeId]?.[language!]?.[1]?.drafts}
                                        type="primary"
                                    >
                                        <SaveOutlined />
                                        {t("publish-contents")}
                                    </Button>
                                </Popconfirm>
                            </div>
                        </Space>
                    </ActionsBanner>
                }
            />
            <Modal
                title={`${t("view-changes")}`}
                open={comparisonModalKeyname !== null}
                maskClosable={false}
                onCancel={() => setComparisonModalKeyname(null)}
                footer={null}
                width={"80%"}
            >
                <Space.Compact block size="small" direction="horizontal" style={{ minWidth: "100%" }}>
                    <Form
                        form={comparisonPublishedForm}
                        items={[
                            {
                                ...comparisonModalElement,
                                label: t("published-content"),
                                inputProps: {
                                    ...comparisonModalElement.inputProps,
                                    disabled: true
                                }
                            }
                        ]}
                        onSubmit={() => {}}
                    />
                    <Form
                        form={comparisonCurrentForm}
                        items={[
                            {
                                ...comparisonModalElement,
                                label: t("current-content"),
                                inputProps: {
                                    ...comparisonModalElement.inputProps,
                                    disabled: true
                                }
                            }
                        ]}
                        onSubmit={() => {}}
                    />
                </Space.Compact>
            </Modal>
        </Spin>
    );
};

export default EventReports;
